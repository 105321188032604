import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, FooterSummary } from '../../components'
import { fetchPricings } from '../../redux/acitons/pricingActions'
import { withNamespaces } from 'react-i18next';
import { addPricingToUserProduct, fetchUserProducts } from '../../redux/acitons/userProductAction'
import './pricing.css'
const Pricing = ({fetchPricings, defaultuserKeys, userProductData, pricingData, addPricingToUserProduct, fetchUserProducts,t}) => {
  const [monthlyVal, setMonthlyVal] = useState(0)
  const [quarterlyVal, setQuarterlyVal] = useState(0)
  const [yearlyVal, setYearlyVal] = useState(0)
  const [sumPriceSelectedApp, setSumPriceSelectedApp] = useState(0)
  const [numberOfModules, setNumberOfModules] = useState(0)
  const [numberOfUser, setNumberOfUser] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState('monthly')
  const [userKeys, setUserKeys] = useState({monthly:null, quarterly:null, yearly:null})
  const [fetched_pricing, setFetched_pricing] = useState({})
  const [disableNexBtn, setDisableNexBtn] = useState(true)
  const calculatePricing =(userProduct=[])=>{

    // console.log('userProductData', userProduct)
    let _result = [...userProduct].reduce((prev, curr)=>{

      curr.module.submodules.reduce((acc, _curr) =>{
        // console.log('acc', parseFloat(acc), acc);
        // console.log('monthly', _curr);
        
        return parseFloat(acc) + parseFloat(_curr.price.monthly)
      },0) 

      return {
        monthly: prev.monthly +curr.module.submodules.reduce((acc, _curr) => parseFloat(acc) + parseFloat(_curr.price.monthly),0), 
        quarterly: prev.quarterly +curr.module.submodules.reduce((acc, _curr) => parseFloat(acc) + parseFloat(_curr.price.quarterly),0), 
        yearly: prev.yearly +curr.module.submodules.reduce((acc, _curr) => parseFloat(acc) + parseFloat(_curr.price.yearly),0)
      }
    },{monthly: 0, quarterly: 0, yearly: 0})
    // console.log('_result', _result)
    _result.quarterly = _result.quarterly * 3
    _result.yearly = _result.yearly * 12
    setSumPriceSelectedApp(_result)
    return _result 
  }
  useEffect(() => {
    // declare the data fetching function
    fetchUserProducts().then(()=>{
      calculatePricing()
    })
    
    const fetchData = async () => {
      
      let _res = await fetchUserProducts()
      let fetched_pricing = await fetchPricings()  
      return {_res, fetched_pricing}
    }
  
    // call the function
    fetchData().then(({_res, fetched_pricing})=>{
      setFetched_pricing(fetched_pricing)
      console.log('--- first', _res, fetched_pricing)
      // console.log('--- userProductData', data)
      setdefaultNbrOfUser(fetched_pricing)
      let _numOfModules = _res.reduce((acc, curr)=> {
        // console.log('acc, curr', acc, curr)

        return acc + curr.module.submodules.length
      }, 0)
      setNumberOfModules(_numOfModules)
      // console.log('_numOfModules', _numOfModules)
      calculatePricing(_res)
    }).then(()=>{      
      document.querySelector('#monthly-pricing-select-btn').click();
    })
      // make sure to catch any error
      .catch(console.error);
  }, [])

  // console.log('-- Pricing:>>', pricingData.price_ranges)
  
  const handleChangeVal=(e, plan)=> {
    // Object.keys(e.target.value).map((key, index) => ( 
      let valOj = pricingData.price_ranges[e.target.value][plan]
      console.log("  Selected!!", e.target.value)      
    // )) 
    

    if (plan==='monthly') {
      setMonthlyVal(valOj)
      setUserKeys({...userKeys,
        'quarterly': userKeys.quarterly,
        'yearly': userKeys.yearly,
        'monthly' : e.target.value
      })
    } else if (plan==='quarterly') {
      setQuarterlyVal(valOj)
      setUserKeys({...userKeys,
        'quarterly' : e.target.value,
        'yearly': userKeys.yearly,
        'monthly' : userKeys.monthly,
      })
    } else if (plan==='yearly') {
      setYearlyVal(valOj)
      setUserKeys({...userKeys,
        'yearly' : e.target.value,
        'quarterly': userKeys.quarterly,
        'monthly' : userKeys.monthly,
      })
    } 

  }

  const handleSelectProductionCycle = (plan) => {
    let _pricing = {}
    console.log('pricingData', pricingData)
    // console.log('userKeys', userKeys)
    // console.log('monthlyVal', pricingData.price_ranges[userKeys[plan]][plan], plan, userKeys)
    setSelectedPlan(plan)
    // return
    let _num_of_users = null
    if (pricingData.price_ranges[userKeys[plan]]){
      _num_of_users = pricingData.price_ranges[userKeys[plan]][plan]
    }else{ return;}
    
    setNumberOfUser(userKeys[plan])
    console.log('_num_of_users', _num_of_users)
    if (plan==='monthly') {
      // setNumberOfUser(monthlyVal)
      _pricing.paiement_cycle = 'monthly'
      setDisableNexBtn(false)
    } else if (plan==='quarterly') {
      // setNumberOfUser(quarterlyVal)
      _pricing.paiement_cycle = 'quarterly'
      setDisableNexBtn(false)
    } else if (plan==='yearly') {
      // setNumberOfUser(yearlyVal) 
      setDisableNexBtn(false)
      _pricing.paiement_cycle = 'yearly'
    }     
    console.log('userKeys', userKeys)
    _pricing.token = localStorage.getItem("userToken")
    _pricing.users = userKeys[plan]
    _pricing.price = sumPriceSelectedApp[plan] - pricingData.price_ranges[userKeys[plan]][plan].promotion
    
    
    addPricingToUserProduct(localStorage.getItem("userToken"), _pricing, setDisableNexBtn);
  }
  
  const setdefaultNbrOfUser = (_pricingData) => {
    console.log('fetched_pricing', _pricingData)
      setUserKeys({
        'quarterly': _pricingData.price_ranges[Object.keys(_pricingData.price_ranges)[0]]?.number?.value,
        'yearly': _pricingData.price_ranges[Object.keys(_pricingData.price_ranges)[0]]?.number?.value,
        'monthly' : _pricingData.price_ranges[Object.keys(_pricingData.price_ranges)[0]]?.number?.value
      })
  }


  return (
    <>
    <Breadcrumb links={[{link: './', title: 'Home'},{link: './pricing', title: 'Pricing'}]} title={'Pricing Plan'}/>
    {/* <p className=''>{JSON.stringify(default_pricinData)}</p> */}
  <div className="container-fluid">
    <div className="container p-5">

      <div className='row m-5' style={{borderBottomStyle: 'inset'}}>
        <div className='col-3'><span className="h1">{numberOfModules}</span>{" "}{t("our_pricing_application")}</div>
        
        <div className='col-3'><span className="h1">{numberOfUser || pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.number?.value}</span>{" "}{t("our_pricing_application_titlea")}</div>
        
        <div className='col-3'><span className="h1">{parseFloat((sumPriceSelectedApp[selectedPlan] || sumPriceSelectedApp.monthly)*numberOfUser).toFixed(2)}</span>{" "}<strong>{t("our_pricing_application_titleb")}</strong>/{`${selectedPlan || '{t("our_pricing_application_titlebc")}'}`}</div>

        <div className='col-3'><span className="h1">${ parseFloat(sumPriceSelectedApp[selectedPlan]*numberOfUser - pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.[selectedPlan]?.promotion).toFixed(2) || (pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.monthly?.promotion && parseFloat(sumPriceSelectedApp.monthly) - parseFloat(pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.monthly?.promotion)) }</span>{" "}<strong>{`${ selectedPlan || `${t("our_pricing_application_titlebca")}` }`} {`${t("our_pricing_application_titlebcb")}`}</strong></div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-12 mb-4">
          <div className={`card card1 h-100 ${selectedPlan === 'monthly' ? 'active' : ''}`}>
            <div className="card-body">
             
              <h5 className="card-title">{t("our_pricing_application_titlec")}</h5>
              <small className='text-muted'>{t("our_pricing_application_titled")}</small>
              <br/><br/>
              <span className="h2">${parseFloat(sumPriceSelectedApp.monthly).toFixed(2)}</span>{t("our_pricing_application_titlee")} {" "} <span className="h4 text-success">-${monthlyVal.promotion || pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.monthly?.promotion}</span>
              <br/><br/>
              {/* <p>{ JSON.stringify(pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.number?.value ) }</p> */}
              <select defaultValue={defaultuserKeys?.monthly?.value} onChange={(e)=>handleChangeVal(e,'monthly')} className="form-select form-select-md" aria-label=".form-select-sm example">
                  {/* <option value={pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.number?.value}>{Choose Users}</option> */}

                  {
                    Object.keys(pricingData.price_ranges).map((key, index) => ( 
                      <option key={index} value={key}>{key}</option>
                    ))
                  }
                  
              </select>
              <div className="d-grid my-3">
                <button id="monthly-pricing-select-btn" onClick={()=>handleSelectProductionCycle('monthly')} className="btn btn-outline-dark btn-block">{t("our_pricing_application_button")}</button>
              </div>
              <ul>
                <li>{t("our_pricing_application_liena")}</li>
                <li>{t("our_pricing_application_lienb")}</li>
                <li>{t("our_pricing_application_lienc")}</li>
              </ul>
            </div>

            
          </div>
        </div>
        <div className="col-lg-4 col-md-12 mb-4">
        <div className={`card card2 h-100 ${selectedPlan === 'quarterly' ? 'active' : ''}`}>
            <div className="card-body">
             
              <h5 className="card-title">{t("our_pricing_application_titlex")}</h5>
              <small className='text-muted'>{t("our_pricing_application_contentx")}</small>
              <br/><br/>
              <span className="h2">${parseFloat(sumPriceSelectedApp.quarterly).toFixed(2)}</span>{t("our_pricing_application_contentbx")} {" "} <span className="h4 text-success">-${quarterlyVal.promotion || pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.quarterly?.promotion}</span>
              <br/><br/>
              
              <select defaultValue={defaultuserKeys?.quarterly?.value} onChange={(e)=>handleChangeVal(e, 'quarterly')} className="form-select form-select-md" aria-label=".form-select-sm example">
                  {/* <option>Choose Users</option>                   */}
                  {
                    Object.keys(pricingData.price_ranges).map((key, index) => ( 

                      <option key={index} value={key}>{key}</option>
                    ))
                  }
              </select>

              <div className="d-grid my-3">
                <button onClick={()=>handleSelectProductionCycle('quarterly')} className="btn btn-outline-dark btn-block">{t("our_pricing_application_buttony")}</button>
              </div>
              <ul>
                <li>{t("our_pricing_application_lienay")}</li>
                <li>{t("our_pricing_application_lienby")}</li>
                <li>{t("our_pricing_application_liency")}</li>
                
              </ul>
            </div>

            
          </div>
        </div>
        <div className="col-lg-4 col-md-12 mb-4">
        <div className={`card card3 h-100 ${selectedPlan === 'yearly' ? 'active' : ''}`}>
            <div className="card-body">
             
              <h5 className="card-title">{t("our_pricing_application_titley")}</h5>
              <small className='text-muted'>{t("our_pricing_application_contenty")}</small>
              <br/><br/>
              
              <span className="h2">${parseFloat(sumPriceSelectedApp.yearly).toFixed(2)}</span>{t("our_pricing_application_contentby")} {" "} <span className="h4 text-success">-${yearlyVal.promotion || pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.yearly?.promotion}</span>
              <br/><br/>
              
              <select defaultValue={defaultuserKeys?.yearly?.value} onChange={(e)=>handleChangeVal(e, 'yearly')} className="form-select form-select-md" aria-label=".form-select-sm example">
                  {/* <option>Choose Users</option> */}
                  
                  {
                    Object.keys(pricingData.price_ranges).map((key, index) => ( 

                      <option key={index} value={key}>{key}</option>
                    ))
                  }
              </select>
              
              <div className="d-grid my-3">
                <button onClick={()=>handleSelectProductionCycle('yearly')} className="btn btn-outline-dark btn-block">{t("our_pricing_application_buttony")}</button>
              </div>
              <ul>
                <li>{t("our_pricing_application_lienay")}</li>
                <li>{t("our_pricing_application_lienby")}</li>
                <li>{t("our_pricing_application_liency")}</li>
                
              </ul>
            </div>

            
          </div>
        </div>
      </div>

      
      <div className='row m-5' style={{borderBottomStyle: 'inset'}}>
        <div className='col-3'><span className="h1">{numberOfModules}</span>{" "}Applications</div>
        
        <div className='col-3'><span className="h1">{numberOfUser || pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.number?.value}</span>{" "}Users</div>
        
        <div className='col-3'><span className="h1">{parseFloat((sumPriceSelectedApp[selectedPlan] || sumPriceSelectedApp.monthly)*numberOfUser).toFixed(2)}</span>{" "}<strong>Total</strong>/{`${selectedPlan || 'monthly'}`}</div>

        <div className='col-3'><span className="h1">${ parseFloat(sumPriceSelectedApp[selectedPlan]*numberOfUser - pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.[selectedPlan]?.promotion).toFixed(2) || (pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.monthly?.promotion && parseFloat(sumPriceSelectedApp.monthly).toFixed(2) - parseFloat(pricingData.price_ranges[Object.keys(pricingData.price_ranges)[0]]?.monthly?.promotion).toFixed(2)) }</span>{" "}<strong>{`${ selectedPlan || `monthly` } Biling`}</strong></div>
      </div>
        
      </div>  
      </div>  
      <div className='summary_container p-3'>
        <FooterSummary link={'/registration'} title={'Sign Up'} disableNexBtn={disableNexBtn}/>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    pricingData : state.pricing.pricingData,
    userProductData : state.user_product.selected_modules,
    defaultuserKeys: state.pricing.pricingData.price_ranges[Object.keys(state.pricing.pricingData.price_ranges)[0]]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPricings: ()=> dispatch(fetchPricings()),
    addPricingToUserProduct: (...data)=> dispatch(addPricingToUserProduct(...data)),
    fetchUserProducts: (token)=> dispatch(fetchUserProducts(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Pricing))
