import React, { useEffect, useState, createRef } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import { env_config, API, GOOGLE_RECAPTCHA_KEY } from '../../env';
import { ClipLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";

const TEST_SITE_KEY = GOOGLE_RECAPTCHA_KEY;

export const BecomePartnerRVAForm = (props) => {
	let [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false)
    
    const formik = useFormik({
        initialValues: {
          last_name: "",
          first_name: "",
          company: "",
          country: "",
          phoneNumber: "",
          email: "",
          message: "",
          your_customer: "",
          recaptcha: "",
          country: "",
          customer_profile_1: "",
          target:"",
        },
        validationSchema: Yup.object({
  
          first_name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
          last_name: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
          company: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),            
          company: Yup.string(),               
          target: Yup.array() ,       
          customer_profile_1: Yup.array(),
          email: Yup.string().email("Invalid email address").required("Required"),
          phoneNumber: Yup.number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .required("A phone number is required"),
          your_customer: Yup.array()
          .required('Please enter your_customer'),
          message: Yup.string().required('Please let\'s us know your requirement'),
          recaptcha: Yup.string().required(),
        }),
        onSubmit: async (values, { resetForm }) => {
          
                    //   console.log('first', values)
            const config = env_config;
            
          try {
              setLoading(true)
            await API
              .post("/email/become_partner", {
                  last_name: values.last_name,
                  first_name: values.first_name,
                  company: values.company,
                  phoneNumber: values.phoneNumber,
                  country: values.country,
                  email: values.email,
                  message: values.message,
                  your_customer: values.your_customer,
                  country: values.country,
                  customer_profile_1: values.customer_profile_1,
                  target: values.target,
              }, config)
              .then((res) => {
                console.log('res', res)
                setMessage({code: 200, message:"Your request has reached our staff, we will get back to you."});    
                setTimeout(() => {
                  // Most recent value
                  setMessage(false);    
                }, 8000);
                setLoading(false)
  
                resetForm({ values: "" });
              });
          } catch (error) {
            console.error("-- error", error);
            setLoading(false)
            setMessage({code: 404, message: error})
          }
        },
      });

  return (
    <>
        
        <div className="modal-dialog ">
            <div className="modal-content contact-form-area p-0">
            {/* Modal Header */}
            <div className="modal-header">
                <h4 className="modal-title">Devenir partenaire Equerre</h4>
                <button type="button" className="close" data-dismiss="modal">×</button>
            </div>
            {/* Modal body */}
            <div className="modal-body" style={{maxHeight:'75vh', overflow: 'auto'}}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						{/* <!-- Contact Form --> */}
						<div className="">
							
							{message && message.code ? 
								message.code  == "200" ? (
								<div className="alert alert-success mb-5" role="alert">
									{message.message}
								</div>
								) : (
									<div className="alert alert-danger mb-5" role="alert">
									{JSON.stringify(message?.message?.message)}
									</div>
								)          
							:null}
							<form 
                                htmlFor="become_partner-form"
								onSubmit={formik.handleSubmit}
								id="become_partner-form"
								className="form" method="post" action="" >
								<div className="row">
                                    {/* address mail */}
									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-envelope"></i></div>
												<input													
													id="email"
													name="email"
													type="email"
													placeholder="Email"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
												) : null}
										</div>
									</div>
                                    
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input
													
													id="first_name"
													name="first_name"
													type="text"
													placeholder="Prénom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.first_name}
												/>
												{formik.touched.first_name && formik.errors.first_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.first_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input													
													id="last_name"
													name="last_name"
													type="text"
													placeholder="Nom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.last_name}
												/>
												{formik.touched.last_name && formik.errors.last_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.last_name}</div>
												) : null}
										</div>
									</div>
                                    
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-building"></i></div>
											
											<input													
													id="company"
													name="company"
													type="text"
													placeholder="Entreprise"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.company}
												/>
												{formik.touched.company && formik.errors.company ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.company}</div>
												) : null}
												
										</div>
									</div>
                                    
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-building"></i></div>
											
											<input													
													id="country"
													name="country"
													type="text"
													placeholder="Pays"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.country}
												/>
												{formik.touched.country && formik.errors.country ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.country}</div>
												) : null}
												
										</div>
									</div>
									
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-phone"></i></div>
											
											<input
												id="phoneNumber"
												name="phoneNumber"
												type="tel"
												placeholder="Numéro de téléphone"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.phoneNumber}
											/>
											{formik.touched.Name && formik.errors.phoneNumber ? (
												<div style={{ color: "#dc3545" }}>
												{formik.errors.phoneNumber}
												</div>
											) : null}
												
										</div>
									</div>

									
									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
                                            <label>Vous êtes</label>
                                            <select  multiple={true}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.customer_profile_1}
                                                    placeholder="Vous êtes" 
                                                    id="customer_profile_1"
                                                    name='customer_profile_1'
                                                    // style={{width: '-webkit-fill-available', height: '50px'}}
                                                    className="form-select form-select-md">
                                                {/* <option value="default" disabled>Vous êtes</option> */}
                                                
                                                <option value="Revendeur à valeur ajoutée (RVA) de logiciels">Revendeur à valeur ajoutée (RVA) de logiciels</option>
                                                <option value="Apporteur d'affaires">Apporteur d'affaires</option>
                                                <option value="Développeur indépendant">Développeur indépendant</option>
                                                <option value="Entreprises de Service Numérique">Entreprises de Service Numérique</option>
                                            </select>

												{formik.touched.customer_profile_1 && formik.errors.customer_profile_1 ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.customer_profile_1}</div>
												) : null}
												
										</div>                                        
									</div>

                                    <div className="col-12">
                                        <div className="form-group textarea">
                                            <div className="icon"><i className="fa fa-pencil"></i></div>
                                            <span className="wpcf7-form-control-wrap" data-name="textarea-120">
                                            <textarea 
                                                    
                                                    style={{maxHeight: '120px'}}
													id="message"
													name="message"
													type="text"
													placeholder="Précisez votre demande"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.message} cols="20" rows="3"></textarea>
                                                    
                                                    {formik.touched.message && formik.errors.message ? (
                                                        <div style={{ color: "#dc3545" }}>{formik.errors.message}</div>
                                                    ) : null}
                                            
                                            </span>
                                        </div>
                                    </div>

									
									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">			
                                            <label>Cible</label>
                                            <select multiple={true} 
                                                    id="target"
                                                    name='target'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.target}
                                                    placeholder="Cible"
                                                    size="3"
                                                    // style={{width: '-webkit-fill-available', height: '50px'}}
                                                    className="form-control form-select form-select-md">
                                                        
                                                    <option>Equerre SMB 50</option>
                                                    <option>Equerre SMB 100</option>
                                                    <option>Equerre SMB 1000</option>
                                                    <option>Equerre Paie 500</option>
                                                    <option>Equerre Cloud ERP</option>
                                                    <option>Equerre vente et CRM</option>

                                                    <option>Equerre Finance 1000</option>
                                                    <option>Equerre gestion de projet</option>
                                                    <option>Equerre POS</option>
                                                    <option>Equerre Paie 500</option>
                                                    <option>Equerre Gdoc</option>
                                                    <option>Equerre Santé</option>

                                                    <option>Equerre Cloud Hôtel</option>
                                                </select>

												{formik.touched.target && formik.errors.target ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.target}</div>
												) : null}
												
										</div>                                        
									</div>
									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">	
                                            <label>Domaine - Précisez le ou les domaines fonctionnels attendus ?</label>										
                                            <select multiple={true} 
                                                    id='your_customer'
                                                    name='your_customer'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.your_customer}
                                                    size="3"
                                                    placeholder="Domaine - Précisez le ou les domaines fonctionnels attendus ?"
                                                    // style={{width: '-webkit-fill-available', height: '50px'}}
                                                    className="form-control form-select form-select-md">
                                                
                                                    <option>ERP</option>
                                                    <option>Gestion Comptable & Financière</option>
                                                    <option>Gestion des achats</option>
                                                    <option>Gestion Commerciale</option>
                                                </select>

												{formik.touched.your_customer && formik.errors.your_customer ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.your_customer}</div>
												) : null}
												
										</div>                                        
									</div>
									
									
									<div className="col-lg-12 col-md-122 col-12">
									<div className="form-group">
										{/* <label>Recaptcha Validation</label> */}
										<ReCAPTCHA
										sitekey={TEST_SITE_KEY}
										render="explicit"
										onChange={(response) => {formik.setFieldValue("recaptcha", response); }}
										onExpired={() => { formik.setFieldValue("recaptcha", ""); }}
										/>
										
										{formik.touched.recaptcha && formik.errors.recaptcha ? (
											<div style={{ color: "#dc3545" }}>{formik.errors.recaptcha}</div>
										) : null}
									</div>                                       
									</div>
{/*                                     

									<div className="col-8 mr-4 d-flex justify-item-center">
										<div className="form-group button">
										</div>
									</div> */}

								</div>
							</form>
						</div>
						{/* <!--/ End contact Form --> */}
					</div>
				</div>
			</div>
            </div>
            {/* Modal footer */}
            <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal">Fermez</button>
                <button disabled={loading} type="submit" className="bizwheel-btn theme-2" form='become_partner-form'>
                        <div style={{display: 'flex', alignItems: 'center',}}>
                        <div style={{marginRight: '.4em'}}>Envoyez ma demande de partenariat</div> <ClipLoader color={"#fff"} loading={loading} size={20} />
                        </div>
                </button>		
            </div>
            </div>
        </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BecomePartnerRVAForm)