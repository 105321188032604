import { Comp404 } from "../components"
import { Pricing, EquerreGpao, registration, Newsletter, Actuality, CareerFrontEndDeveloper,CareerBusinessDeveloperJunior, CareerBusinessDeveloperSenior, CareerBackEndDeveloper, EquerrePaie500,EquerreCloudERP, EquerreProjectManagement, EquerreCloudHotel,EquerreHealth, CheckOut,EquerrePOS,EquerreGdoc, SuccessCheckout, BecomePartner, SignIn, SignUp, Module, ModuleDetails, LicenceProduct, LicensePage1, Marketplace, OurProduct,  AboutUs, OurProductDetailsbudget, OurProductSupplyChain, OurProductHrManagement, OurProductFinancialManagement, OurProductCrmAndSales, OurProductReportingAndDashboard, OpEquerreGDoc, OpEquerreProjectManagement, OpEquerreCloudHotel, OpEquerreHealth, OpEquerreMarketplace, ContactUs, EquerreSMB50, EquerreSMB100, EquerreRH1000, Career, AboutUsCst, ProductList, EquerreSalesCRM, SideMap, MentionLegale, EquerreFinance1000    } from "../containers"
import ActualityDetails from "../containers/Actuality/ActualityDetails";
import OurProductOtherTools from "../containers/OurProductOtherTools/OurProductOtherTools"

const mainRoutes = [
  {
    exact: true,
    path: '/equerre_gpao',
    name: 'equerre_gpao',
    component: EquerreGpao,
    key: 'EquerreGpao'
  },
  {
    exact: true,
    path: '/equerre-Finance-1000',
    name: 'equerre-Finance-1000',
    component: EquerreFinance1000,
    key: 'EquerreFinance1000'
  },
  {
    exact: true,
    path: '/mentions_legales',
    name: 'mentions_legales',
    component: MentionLegale,
    key: 'MentionLegale'
  },
  {
    exact: true,
    path: '/side_map',
    name: 'side_map',
    component: SideMap,
    key: 'SideMap'
  },
  {
    exact: true,
    path: '/equerre_sales_crm',
    name: 'equerre_sales_crm',
    component: EquerreSalesCRM,
    key: 'EquerreSalesCRM'
  },
  {
    exact: true,
    path: '/product_list',
    name: 'product_list',
    component: ProductList,
    key: 'ProductList',
  },
  {
    exact: true,
    path: '/actuality_details',
    name: 'actuality_details',
    component: ActualityDetails,
    key: 'ActualityDetails',
  },
  {
    exact: true,
    path: '/newsletter',
    name: 'newsletter',
    component: Newsletter,
    key: 'Newsletter',
  },
  {
    exact: true,
    path: '/actuality',
    name: 'actuality',
    component: Actuality,
    key: 'Actuality',
  },
  {
    exact: true,
    path: '/career-business-developer-junior',
    name: 'business-developer-junior',
    component: CareerBusinessDeveloperJunior,
    key: 'CareerBusinessDeveloperJunior',
  },
  {
    exact: true,
    path: '/career-business-developer-senior',
    name: 'business-developer-senior',
    component: CareerBusinessDeveloperSenior,
    key: 'CareerBusinessDeveloperSenior',
  },
  {
    exact: true,
    path: '/career-front-end-developer',
    name: 'front-end-developer',
    component: CareerFrontEndDeveloper,
    key: 'CareerFrontEndDeveloper',
  },
  {
    exact: true,
    path: '/career-back-end-developer',
    name: 'back-end-developer',
    component: CareerBackEndDeveloper,
    key: 'CareerBackEndDeveloper',
  },
  {
    exact: true,
    path: '/equerre-paie-500',
    name: 'equerre-paie-500',
    component: EquerrePaie500,
    key: 'EquerrePaie500',
  },
  {
    exact: true,
    path: '/equerre_cloud_erp',
    name: 'equerre_cloud_erp',
    component: EquerreCloudERP,
    key: 'EquerreCloudERP',
  },
  {
    exact: true,
    path: '/equerre_project_management',
    name: 'equerre_project_management',
    component: EquerreProjectManagement,
    key: 'EquerreProjectManagement',
  },
  {
    exact: true,
    path: '/equerre_cloud_hotel',
    name: 'equerre_cloud_hotel',
    component: EquerreCloudHotel,
    key: 'EquerreCloudHotel',
  },
  {
    exact: true,
    path: '/equerre_health',
    name: 'equerre_health',
    component: EquerreHealth,
    key: 'EquerreHealth',
  },
  {
    exact: true,
    path: '/equerre_gdoc',
    name: 'equerre_gdoc',
    component: EquerreGdoc,
    key: 'EquerreGdoc',
  },
  {
    exact: true,
    path: '/equerre_pos',
    name: 'equerre_pos',
    component: EquerrePOS,
    key: 'EquerrePOS',
  },
  {
    exact: true,
    path: '/become_partner',
    name: 'become_partner',
    component: BecomePartner,
    key: 'BecomePartner',
  },
  {
    exact: true,
    path: '/career',
    name: 'career',
    component: Career,
    key: 'Career',
  },
  {
    exact: true,
    path: '/equerre-hr-1000',
    name: 'equerre-hr-1000',
    component: EquerreRH1000,
    key: 'EquerreRH1000',
  },
  {
    exact: true,
    path: '/equerre-smb-100',
    name: 'equerre-smb-100',
    component: EquerreSMB100,
    key: 'EquerreSMB100',
  },
  {
    exact: true,
    path: '/equerre-smb-50',
    name: 'equerre-smb-50',
    component: EquerreSMB50,
    key: 'EquerreSMB50',
  },
  {
    exact: true,
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
    key: 'pricing',
  },
  {
    exact: true,
    path: '/registration',
    name: 'Registration',
    component: registration,
    key: 'registration',
  },
  {
    exact: true,
    path: '/checkout',
    name: 'CheckOut',
    component: CheckOut,
    key: 'CheckOut',
  },
  {
    exact: true,
    path: '/success_payment',
    name: 'SuccessCheckout',
    component: SuccessCheckout,
    key: 'SuccessCheckout',
  },
  {
    exact: true,
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
    key: 'Marketplace'
  },
  // {
  //   exact: true,
  //   path: '/signin',
  //   name: 'SignIn',
  //   component: SignIn,
  //   key: 'SignIn',
  // },
  // {
  //   exact: true,
  //   path: '/signup',
  //   name: 'SignUp',
  //   component: SignUp,
  //   key: 'SignUp',
  // },
  // {
  //   exact: true,
  //   path: '/module/:module_name_slug',
  //   name: 'Module',
  //   component: Module,
  //   key: 'Module',
  // },
  {
    exact: true,
    path: '/module/:module_name_slug/:submodule_name_slug',
    name: 'ModuleDetails',
    component: ModuleDetails,
    key: 'ModuleDetails',
  },
  // {
  //   exact: true,
  //   path: '/license_product',
  //   name: 'LicenceProduct',
  //   component: LicenceProduct,
  //   key: 'LicenceProduct',
  // },
  // {
  //   exact: true,
  //   path: '/license_product-1',
  //   name: 'LicensePage1',
  //   component: LicensePage1,
  //   key: 'LicensePage1'
  // },
  {
    exact: true,
    path: '/our_products',
    name: 'OurProduct',
    key: 'OurProduct',
  },
  {
    exact: true,
    path: '/our_products/budget',
    name: 'OurProductDetailsbudget',
    key: 'OurProductDetailsbudget',
},
{
  exact: true,
    path: '/about_us',
    name: 'AboutUs',
    component: AboutUsCst,
    key: 'AboutUs',
  },
  //{
   // exact: true,
    //path: '/our_products/:product_slug',
    //name: 'ProductDetails',
    //component: ProductDetails,
    //key: 'ProductDetails',
  //},
  {
    exact: true,
    path: '/our_products/supply-chain',
    //path: '/claudia',
    name: 'OurProductSupplyChain',
    key: 'OurProductSupplyChain',
  },
  {
    exact: true,
    path: '/our_products/hr-management',
    name: 'OurProductHrManagement',
    key: 'OurProductHrManagement',
  },
  {
    exact: true,
    path: '/our_products/financial-management',
    name: 'OurProductFinancialManagement',
    key: 'OurProductFinancialManagement',
  },
  {
    exact: true,
    path: '/our_products/crm-and-sales',
    name: 'OurProductCrmAndSales',
    key: 'OurProductCrmAndSales',
  },
  {
    exact: true,
    path: '/our_products/reporting-and-dashboards',
    name: 'OurProductReportingAndDashboard',
    key: 'OurProductReportingAndDashboard',
  },
  {
    exact: true,
    path: '/our_products/others-tools',
    name: 'OurProductOtherTools',
    key: 'OurProductOtherTools',
  },
  {
    exact: true,
    path: '/our_products/equerre-project-management',
    name: 'OpEquerreProjectManagement',
    component: OpEquerreProjectManagement,
    key: 'OpEquerreProjectManagement',
  },
  {
    exact: true,
    path: '/our_products/equerre-cloudhotel',
    name: 'OpEquerreCloudHotel',
    component: OpEquerreCloudHotel,
    key: 'OpEquerreCloudHotel',
  },
  {
    exact: true,
    path: '/our_products/equerre-health',
    name: 'OpEquerreHealth',
    component: OpEquerreHealth,
    key: 'OpEquerreHealth',
  },
  {
    exact: true,
    path: '/our_products/equerre-marketplace',
    name: 'OpEquerreMarketplace',
    component: OpEquerreMarketplace,
    key: 'OpEquerreMarketplace',
  },
  {
    exact: true,
    path: '/our_products/equerre-gdoc',
    name: 'OpEquerreGDoc',
    component: OpEquerreGDoc,
    key: 'OpEquerreGDoc',
  },
  {
    exact: true,
    path: '/contact_us',
    name: 'ContactUs',
    component: ContactUs,
    key: 'ContactUs',
  },

  // {
  //   exact: true,
  //   path: '/our_products/:product_slug',
  //   name: 'ProductDetails',
  //   component: ProductDetails,
  //   key: 'ProductDetails',
  // },
  {
    exact: true,
    path: '',
    name: 'Comp404',
    component: Comp404,
    key: 'comp404',
  },
]

export default mainRoutes;