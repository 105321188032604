import styled from "styled-components";

const DivMkplHomeHero = styled.div`
  background-image: url('./assets/img/mrkt-img-9.png');
  background-size: "100% auto%";
`
const ExtendButton = styled.a`
  color: var(--red);
  
  &:hover {
    /* color: #fff !important;
    background-color: var(--red) !important;
    border: 1px solid transparent !important; */
  }
  background-color: red;
`

const AddToCartButton = styled.div`  
    cursor: pointer;
    -moz-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
    background: #fff;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    border-top: 3px solid transparent;
    transition: all 0.4s ease;
    
    width: 100%;
    input.hidden  {
      position: absolute;
      visibility: hidden;
      opacity: 0;
    }
    label {
      cursor: pointer;
      width: 100%;
    }
    input[type=checkbox]+label {
      width: 100%;
    }

    input[type=checkbox]:checked+label {
      color: #f00;
      font-style: normal;
      width: 100%;
    }

`


const MarketPlaceComponents = {
  ExtendButton,
  AddToCartButton,
  DivMkplHomeHero
}

export default MarketPlaceComponents
