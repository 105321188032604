import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';
import utils from '../../utils/utlis';
// import datas from '../Marketplace/datas';
import { Helmet } from 'react-helmet-async';
import datas from './datas';
import { withNamespaces } from 'react-i18next';
import { FooterSummary } from '../../components';
const {modulelines2, modulelines1} = datas

const OurProduct = (props) => {
    const { t } = props
  return (
    <div style={{background: '#fff'}}>
    <Helmet>
        <title>{t("our_product_page_head_pagetitle")}</title>
        <meta name="description" content="Un système ERP complet disponible Sur Cloud public ou privé, ou dans un environnement hybride"/>
        <meta property="og:title"content="Equerre Our Product"/>
        <meta
        property="og:description"
        content="Un système ERP complet disponible Sur Cloud public ou privé, ou dans un environnement hybride"/>
        <link rel="canonical" href="https://www.equerre.com/our_products" />
        <meta property="og:image"  content="/assets/img/mrkt-img-6.png"/>
        <meta name="twitter:image" content="/assets/img/mrkt-img-6.png"/>
    </Helmet>
        <div className='container'>
            <div className='row pt-4' style={{marginBottom: '4rem'}}>
                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-4  mt-lg-5 py-lg-5' >
                    <h1 style={{lineHeight: 'em', fontWeight: 'lighter', color: '#000', marginBottom: '1.5rem'}}>{t("our_product_page_head_titlea")} 
 <br/>{t("our_product_page_head_titleab")} </h1>
                    <p style={{color: 'rgba(0,0,0,0.5)'}}>{t("our_product_page_head_paragrapha")}</p>
                    <div className="button mt-4"  id='management_software'>
                        <a href="blog.html" className="bizwheel-btn theme-3 effect">{t("our_product_page_head_button")}</a>
                    </div>
                </div>
                <div className='col-6' style={{backgroundImage:"url('/assets/img/mrkt-img-4.png')",  backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat' }}>
                    
                </div>
            </div>
        </div>
            <section>
                <>
                <section className='mb-5'  >
                    
        <TitleContainer className="container title_content my-4">
        <div className="title_content">
            <h2>{t("our_product_blocc_title")}</h2>
            <p>{t("our_product_blocc_paragraph")}</p>
        </div>
        </TitleContainer>

        <div className='container'>
            
            <div className="row pb-4 text-center" >
                <div className='col-lg-1 '></div>
                {modulelines1[t("data_language")] && modulelines1[t("data_language")].slice(0,6).map(moduleline=>(

                    <div className="col-lg-2 col-md-4 col-12" key={moduleline._id}>
                    {/* Single Feature */}
                    <IconContainer className="single-feature">
                    <div className="" style={{heigth: '30px'}}>
                        {/* <i className="fa fa-podcast" /> */}
                    </div>
                    <h4>
                        <a href="#">
                        {moduleline.name}
                        </a>
                    </h4>
                    <p>{moduleline.description.toString().substring(0,70)}{'...'}</p>
                    <div className="button">
                        <a
                        href={`/our_products/${utils.slugify(moduleline.slug_name || 'not_value')}`}
                        className="bizwheel-btn"
                        >
                        <i className="fa fa-arrow-circle-o-right" style={{fontSize: '15px'}} />
                        Learn More
                        </a>
                    </div>
                    </IconContainer>
                    {/*/ End Single Feature */}
                    </div>

                ))}
                </div>
                <div style={{textAlign: 'center'}} className="mt-2 pb-4">
                    <div className="button">
                        <a href="blog.html" className="bizwheel-btn theme-3 effect">{t("our_product_page_bloca_button")}</a>
                    </div>
                </div>
        </div>
        {/* Call To Action */}
        <section
            className="call-action mt-2"
            style={{ backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.75), transparent), url("./assets/img/mrkt-img-5.png")' }}
        >
            <div className="container">
            <div className="row">
                <div className="col-lg-9 col-12">
                <div className="call-inner">
                    <h2>{t("our_product_blocb_title")} </h2>
                    <p>
                    {t("our_product_blocb_paragraph")}
                    </p>
                </div>
                </div>
                <div className="col-lg-3 col-12">
                <div className="button" id='specialized_software'>
                    <a
                    href="#brochure_unavailable_yet"
                    className="bizwheel-btn"
                    >
                    {t("our_product_page_blocb_button")}
                    </a>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/*/ End Call to action */}
        </section>
                </>
            </section>

            <section >
                <>
                <section className='mb-5'>
                    
        <TitleContainer className="container title_content my-4" >
        <div className="title_content">
            <h2>{t("our_product_bloca_title")}</h2>
            <p>{t("our_product_bloca_paragraph")}</p>
        </div>
        </TitleContainer>

        <div className='container'>
            
            <div className="row pb-4">
                
            <div className='col-lg-1 '></div>
                {modulelines2[t("data_language")] && modulelines2[t("data_language")].slice(0,6).map(moduleline=>(

                    <div className="col-lg-2 col-md-4 col-12" key={moduleline._id}>
                    {/* Single Feature */}
                    <IconContainer className="single-feature">
                    <div className="" style={{heigth: '30px'}}>
                        {/* <i className="fa fa-podcast" /> */}
                    </div>
                    <h4>
                        <a href="#">
                        {moduleline.name}
                        </a>
                    </h4>
                    <p>{moduleline.description.toString().substring(0,70)}{'...'}</p>
                    <div className="button">
                        <a
                        href={`/our_products/${utils.slugify(moduleline.slug_name)}`}
                        className="bizwheel-btn"
                        >
                        <i className="fa fa-arrow-circle-o-right" style={{fontSize: '15px'}} />
                        Learn More
                        </a>
                    </div>
                    </IconContainer>
                    {/*/ End Single Feature */}
                    </div>

                ))}
                </div>
                <div style={{textAlign: 'center'}} className="mt-2 pb-4">
                    <div className="button">
                        <a href="blog.html" className="bizwheel-btn theme-3 effect">{t("our_product_page_blocc_button")}</a>
                    </div>
                </div>
        </div>
        {/* Call To Action */}
        <section
            className="call-action mt-2"
            style={{ backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.75), transparent), url("./assets/img/mrkt-img-5.png")' }}
        >
            <div className="container">
            <div className="row">
                <div className="col-lg-9 col-12">
                <div className="call-inner">
                    <h2>{t("our_product_blocd_title")} </h2>
                    <p>
                    {t("our_product_blocd_paragraph")}
                    </p>
                </div>
                </div>
                <div className="col-lg-3 col-12">
                <div className="button">
                    <a
                    href="#brochure_unavailable_yet"
                    className="bizwheel-btn"
                    >
                    {t("our_product_page_blocd_button")}
                    </a>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/*/ End Call to action */}
        </section>
                </>
            </section>
        <section>
            
        <div className='container'>
            <div className='row' style={{marginBottom: '4rem'}}>
                <div className='col-lg-6 col-md-6 col-sm-12 mt-md-5 py-md-5'>
                    <h1 style={{lineHeight: 'em', fontWeight: 'normal', marginBottom: '1.5rem'}}>{t("our_product_bloce_title")}</h1>
                    <p style={{color: 'rgba(0,0,0,0.5)'}}>{t("our_product_bloce_paragraph")}</p>
                    <div className="button mt-4">
                        <a href="/marketplace" className="bizwheel-btn theme-3 effect">{t("our_product_page_bloce_button")} </a>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12' style={{backgroundImage:"url('/assets/img/mrkt-img-6.png')",  backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat' }}>
                
                </div>
            </div>
        </div>
        </section>
        

        
        {/* Call To Action */}
        <section
            className="call-action mt-2"
            style={{ backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent)' }}
        >
            <div className="container">
            <div className="row">
                <div className="col-lg-8 col-12">
                <div className="call-inner">
                    <h4 style={{fontWeight: 'light', color: 'rgba(0,0,0,0.5)'}}>{t("our_product_bloce_paragraphl")}</h4>
                </div>
                </div>
                <div className="col-lg-4 col-12">
                <div className="button d-flex">
                    <a href="/contact_us" className="bizwheel-btn effect">{t("our_product_page_bloce_buttonx")}</a>
                    <a href="/contact_us" className="bizwheel-btn theme-1 effect">{t("our_product_page_bloce_buttony")}</a>
                </div>
                </div>
            </div>
            </div>
        </section>
        {/*/ End Call to action */}

         {/* card */}
         
         <div className="collapse cartContainer" id="collapseExample">
            <div
              className="sticky-card p-1 fixed-bottom stickyAddCart d-flex align-items-center justify-content-space-between"
              id="stickyCard"
              hidden={true}
            >
              <FooterSummary link={"/marketplace"} title={"MarketPlace"} />
            </div>
          </div>
         {/* end cart */}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps) (withNamespaces() (OurProduct))


const TitleContainer = styled.div`
    width: 90%;
    margin: 0 auto;
    & > .title_content{
        text-align: center;
    }
    & > .title_content > p{
        font-size: 1.2em;
        font-weight: lighter;
        
    }
`


const IconContainer = styled.div`
  cursor: pointer;
  & i {
    color:var(--bs-warning);
    width:40px;
    height:40px;
    line-height:40px;
    background:#fff;
    border: 1px solid #ebebeb;
    border-radius:100%;
    font-size: 20px;
    position:relative;
    -webkit-transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    transition:all 0.3s ease; 
  }
  

`

