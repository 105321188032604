import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import "./header.css"
import styled from "styled-components";
import { BACKEND_DOMAIN } from '../../env';

const AContainer = styled.a`
  color: #fff !important;
  
	&:hover {
    color: #ccc !important;
  }
`
export const Header = (props) => {
  // console.log('props', props)
  const [hasLogIn, setHasLogIn] = useState(false)
  useEffect(() => {
      if (props.authData) {
        setHasLogIn(true)
      }
    return () => {
      
    }
  }, [])
  
  return (
    <>        
    
      {/* <!-- Header --> */}
      <header className="header sticky">
        {/* <!-- Topbar --> */}
        <div className="topbar d-none d-sm-block">
          <div className="container-fluid">
            <div className="row">
              <div className="offset-lg-1 col-lg-10 col-12">
                {/* <!-- Top Contact --> */}
                <div className="top-contact">
                  <div className="single-contact" style={{color: '#fff'}}><i className="fa fa-phone"></i>Phone: <AContainer href="tel:+237233480903">(+237) 233 48 09 03
</AContainer>{" / "}<AContainer href="tel:+2250767405590">+(225) 076 740 55 90</AContainer>{" / "}<AContainer href="tel:301.70485343">301.70485343</AContainer></div> 
                  <div className="single-contact"><AContainer href='mailto:sales@equerre.com'><i className="fa fa-envelope-open"></i>Email: sales@equerre.com</AContainer>{" / "}<AContainer href='mailto:info@equerre.com'>info@equerre.com</AContainer>{" / "}<AContainer href='mailto:support@equerre.com'>support@equerre.com</AContainer></div>	
                  {/* <div className="single-contact"><i className="fa fa-clock-o"></i>Opening: 08AM - 09PM</div>  */}
                </div>
                {/* <!-- End Top Contact --> */}
              </div>	
              <div className="col-lg-4 col-12">
                <div className="topbar-right">
                  {/* <!-- Social Icons --> */}
                  {/* <ul className="social-icons">
                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                    <li><a href="#"><i className="fa fa-youtube"></i></a></li>
                  </ul>															 */}
                  <div className="button">
                  {/* <button onClick={()=> window.open(`${BACKEND_DOMAIN}`, "_blank")} className='btn btn_dashboard primary-color py-1'>{hasLogIn ? 'Dashboard' : "Log In"}</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ End Topbar --> */}
        {/* <!-- Middle Header --> */}
        <div className="middle-header" style={{ display: 'block'}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12" >
                <div className="middle-inner">
                  <div className="row">
                    <div className="offset-lg-1 col-lg-2 col-md-3 col-12" >
                      {/* <!-- Logo --> */}
                      <div className="logo">
                        {/* <!-- Image Logo --> */}
                        <div className="img-logo">
                          <a href="/">
                            <img className='logo-width-size' src="/assets/img/logo.png" alt="#"/>
                          </a>
                        </div>
                      </div>								
                      <div className="mobile-nav"></div>
                    </div>
                    <div className="col-lg-8 col-md-9 col-12">
                      <div className="menu-area">
                        {/* <!-- Main Menu --> */}
                        <nav className="navbar navbar-expand-lg">
                          <div className="navbar-collapse">	
                            <div className="nav-inner">	
                              <div className="menu-home-menu-container">
                                {/* <!-- Naviagiton --> */}
                                <ul id="nav" className="nav main-menu menu navbar-nav">
                                  <li><a href="/">Acceuil</a></li>
                                  
                                  <li className="mega-menu-mobile icon-active"><a href="#">Produits</a>
                                    <ul className="sub-menu">

                                      <li><a href="#">Gestion financière et commerciale </a>
                                        <ul className="sub-menu">
                                          <li><a href="/equerre-smb-50">Equerre SMB 50</a></li>
                                          <li><a href="/equerre-smb-100">Equerre SMB 100</a></li>
                                        </ul>
                                      </li>
                                      
                                      <li><a href="#">Ressources humaines et paie</a>
                                        <ul className="sub-menu">
                                          <li><a href="/equerre-hr-1000">Equerre RH 1000</a></li>
                                          <li><a href="/equerre-paie-500">Equerre Paie 500</a></li>
                                        </ul>
                                      </li>
                                      
                                      <li><a href="#">Gestion de la production</a>
                                        <ul className="sub-menu">
                                          <li><a href="/equerre_gpao">Equerre GPAO</a></li>
                                        </ul>
                                      </li>

                                      <li><a href="#">ERP et gestion 
                                          d’entreprise 
                                          </a>
                                        <ul className="sub-menu">
                                          <li><a href="/equerre_cloud_erp">Equerre Cloud ERP</a></li>
                                          <li><a href="/equerre_sales_crm">Equerre vente et CRM</a></li>
                                          <li><a href="/equerre-Finance-1000">Equerre Finance 1000</a></li>
                                        </ul>
                                      </li> 

                                      
                                      <li><a href="#">Autres produits</a>
                                        <ul className="sub-menu">
                                          <li><a href="/equerre_project_management">Equerre gestion de projet</a></li>
                                          <li><a href="/equerre_pos">Equerre POS </a></li>
                                          <li><a href="/equerre_gdoc">Equerre Gdoc </a></li>
                                          <li><a href="/equerre_health">Equerre Santé</a></li>
                                          <li><a href="/equerre_cloud_hotel">Equerre Cloud Hôtel </a></li>
                                        </ul>
                                      </li>

                                    </ul>
                                  </li>

                                  <li className="d-none mega-menu-col-5"><a href="#">Produits</a>
                                    <div className="sub-menu mega-menu pr-3">
                                        
                                      <div className="row pl-5 my-4" >
                                        
                                        <div className="col-lg-3" >
                                          
                                          <div className="row">
                                            <a>
                                              <h4 className='sub-menu-head'>Gestion financière et commerciale</h4>
                                            </a>
                                            <a className="column pr-5" href="/equerre-smb-50">
                                              <h5 className='sub-menu-item'>Equerre SMB 50</h5>
                                              <p>Devis & Factures,</p>
                                              <p> bons de commandes et   
                                              livraisons, 
                                              </p>
                                              <p> Bilans/Comptes de résultats</p>
                                              <p> gestion de la trésorerie et des  
                                                immobilisations,
                                                </p>
                                              <p>gestion des achats, des stocks  
                                                et inventaires…
                                                </p>
                                            </a>
                                            <a className="column pr-5" href="/equerre-smb-100">
                                              <h5 className='sub-menu-item'>Equerre SMB 100</h5>
                                              <p>Devis, commandes, factures, stocks, marketing, force de vente, service client, comptabilité générale, auxiliaire, analytique et budgétaire, gestion des immobilisations, inventaires …</p>
                                            </a>
                                          </div>

                                        </div>

                                        <div className="col-lg-3">
                                            <div className="row">
                                            <a>
                                              <h4 className=' pr-5 sub-menu-head'>Ressources humaines et paie</h4>
                                            </a>
                                              <a className="column pr-5" href="/equerre-hr-1000#">
                                                <h5 className='sub-menu-item'>Equerre RH 1000</h5>
                                                <p>Gestion administrative des employés, gestion de la paie du personnel, structure organisationnelle, 
                                                  recrutements, formation, carrière, gestion des évaluations, Self-service du personnel …
                                                  </p>
                                              </a>
                                            <a className="column pr-5" href="/equerre-paie-500">
                                              <h5 className='sub-menu-item'>Equerre Paie 500</h5>
                                              <p>Fichier du personnel,
                                                contrats, gestion de la paie inverse, prêts, bonus, ancienneté gelée, self-service du personnel, …
                                                </p>
                                            </a>
                                            </div>

                                        </div>


                                        <div className="col-lg-2">
                                          
                                          <div className="row">
                                            <a> 
                                              <h4 className='sub-menu-head pr-5'>Gestion de la production</h4>
                                            </a>
                                            <a className="column pr-5" href="/equerre_gpao">
                                              <h5 className='sub-menu-item'>Equerre GPAO</h5>
                                              <p>Suivi de bout en bout de la production, Gestion de la qualité, Suivi optimisé des calendriers de production, Opérations digitales : gestion centralisée, à distance et sur mobile, Gestion des projets, Analytique avancée et en temps réel</p>
                                            </a>
                                          </div>

                                        </div>


                                        <div className="col-lg-2">
                                          
                                          <div className="row">
                                            <a> 
                                              <h4 className='sub-menu-head pr-5'>ERP et gestion d’entreprise</h4>
                                            </a>
                                            <a className="column pr-5" href="/equerre_cloud_erp">
                                              <h5 className='sub-menu-item'>Equerre Cloud ERP</h5>
                                              <p>Comptabilité, Budget, immobilisations, inventaires, achats, ventes, stocks, marketing, force de vente, Service client, ressources humaines, paie…</p>
                                            </a>
                                            <a className="column pr-5" href="/equerre_sales_crm">
                                              <h5 className='sub-menu-item'>Equerre vente et CRM</h5>
                                              <p>Ventes, stocks, marketing, force de vente, Service client…</p>
                                            </a>
                                            <a className="column pr-5" href="/equerre-Finance-1000">
                                              <h5 className='sub-menu-item'>Equerre Finance 1000</h5>
                                              <p>Comptabilité, Budget, immobilisations, inventaires…</p>
                                            </a>
                                          </div>

                                        </div>

                                        <div className="col-lg-2 pl-3">
                                          
                                          <div className="row">
                                            <a>
                                              <h4 className='sub-menu-head'>Autres produits <span style={{visibility: 'hidden'}}>_________________</span></h4>
                                            </a>
                                            <a className="column pr-5" href="/equerre_project_management">
                                              <h5 className='sub-menu-item'>Equerre gestion de projet</h5>
                                            </a>
                                              
                                            <a className="column pr-5" href="/equerre_pos">
                                              <h5 className='sub-menu-item mt-0'>Equerre POS </h5>
                                            </a>
                                              
                                            <a className="column pr-5" href="/equerre_gdoc">
                                              <h5 className='sub-menu-item mt-0'>Equerre Gdoc </h5>
                                            </a>
                                              
                                            <a className="column pr-5" href="/equerre_health">
                                              <h5 className='sub-menu-item mt-0'>Equerre Santé</h5>
                                            </a>
                                              
                                            <a className="column pr-5" href="/equerre_cloud_hotel">
                                              <h5 className='sub-menu-item mt-0'>Equerre Cloud Hôtel </h5>
                                            </a>
                                            <a className="column pr-5" href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal">
                                              <h5 className='sub-menu-item mkt-place-sub-menu'>Market place</h5>
                                              <p>Accédez à notre </p>
                                              <p>plateforme SaaS</p>
                                            </a>
                                          </div>

                                        </div>

                                      </div>

                                    </div>
                                  </li>               

                                 <li><a className="" href="/marketplace">MarketPlace</a></li>

                                  <li><a href="/become_partner">Partenaire</a></li>

                                  <li><a href="#">L'entreprise </a>
                                    <ul className="sub-menu">
                                      <li><a href="/about_us">A propos de Equerre</a></li>
                                      <li><a href="/career">Carrières</a></li>
                                      <li><a href="/actuality">Actualités / Evènements</a></li>
                                      <li><a href="/newsletter">Newsletter </a></li>
                                      <li><a href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal">Nous suivre </a></li>
                                    </ul>
                                  </li> 
                   

                                  <li><a href="/contact_us">Contacts</a></li>

                                  {/* <li className="icon-active"><a href="#">Pages</a>
                                    <ul className="sub-menu">
                                      <li><a href="about.html">About Us</a></li>
                                      <li><a href="404.html">404</a></li>
                                    </ul>
                                  </li> */}
                                  {/* <li style={{margin: "auto 0"}}><button onClick={()=> window.open(`${BACKEND_DOMAIN}`, "_blank")} className='btn btn_dashboard primary-color'>{hasLogIn ? 'Dashboard' : "Log In"}</button></li> */}
                                </ul>
                                {/* <!--/ End Naviagiton --> */}
                              </div>
                            </div>
                          </div>
                        </nav>
                        {/* <!--/ End Main Menu -->	 */}
                        {/* <!-- Right Bar --> */}
                        {/* <!--/ End Right Bar --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ End Middle Header --> */}
        {/* <!-- Sidebar Popup --> */}
        <div className="sidebar-popup">
          <div className="cross">
            <a className="btn"><i className="fa fa-close"></i></a>
          </div>
          <div className="single-content">
            <h4>About Equerre</h4>
            <p>The main component of a healthy environment for self esteem is that it needs be nurturing. It should provide unconditional warmth.</p>
            {/* <!-- Social Icons --> */}
            <ul className="social">
              <li><a href="https://web.facebook.com/profile.php?id=100088494751623&is_tour_dismissed=true"><i className="fa fa-facebook"></i></a></li>
              <li><a href="https://twitter.com/EquerreSa"><i className="fa fa-twitter"></i></a></li>
              <li><a href="https://www.linkedin.com/company/equerre-sa/about/?viewAsMember=true"><i className="fa fa-linkedin"></i></a></li>
              <li><a href="https://www.youtube.com/@equerre"><i className="fa fa-youtube"></i></a></li>
            </ul>
          </div>
          <div className="single-content">
            <h4>Important Links</h4>   
            <ul className="links">
              {/* <li><a href="#">About Us</a></li> */}
              <li><a href="#">Nos Produits</a></li>
              <li><a href="#">Accompagnement</a></li>
              <li><a href="#">Devenir Partenaire</a></li>
              {/* <li><a href="#">Blog & News</a></li>
              <li><a href="#">Contact us</a></li> */}
            </ul>
          </div>	
        </div>
        {/* <!--/ Sidebar Popup -->	 */}
      </header>
      {/* <!--/ End Header --> */}		

    
	  {/* The Modal */}
<div className="modal fade market_place_modal" id="myMarketplaceModal">
  <div className="modal-dialog modal-xl">
    <div className="modal-content">
      {/* Modal Header */}
      <div className="modal-header">
        <h3 className="modal-title pl-3 "></h3>
        <button type="button" className="close" data-dismiss="modal">×</button>
      </div>
      {/* Modal body */}
      <div className="modal-body">
        <div className='row'>
            <div className='col-lg-6 col-12 py-4'>
              
            <p className="h2 modal-title pl-3 text-white">Désolé,</p>
              <p className="mt-4 pl-3 " style={{fontSize:'2.5em', lineHeight:'initial', color: '#fff', fontWeight: 'normal'}}>
                

                Encore un peu de patience, nous sommes en train de coudre une nouvelle robe a cette page.
              </p>
            </div>
        </div>
      </div>
      {/* Modal footer */}
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermez</button>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    authData: state.auth.authData
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
