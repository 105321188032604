import React from 'react'
import { CardCvcElement,CardExpiryElement,CardNumberElement, ElementsConsumer} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import MaintContainer from './CheckoutStyledComponent'
import { withRouter } from 'react-router-dom';
import { BACKEND_SERVER_DOMAIN, env_config } from '../../env'
import axios from 'axios'

const config = env_config;

const InputCst = styled.input`
  heigth: 45px !important;
  margin-bottom: 0px !important;
  width: 98.6% !important;
`
const LabelCst = styled.label`
margin-bottom: 0px !important;
`
class CheckoutForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      body: {},
      message: "",
      disabled_btn:true,
      email: props.userProductData instanceof Array ? props.userProductData[0]?.user?.email : '',
      succeeded: false,
      error:null,
      processing:'',
      disabled: true,
      clientSecret: '',  
    };
  }

    state = {
        body: {},
        message: "",
        disabled_btn:true,
        email: '',
        succeeded: false,
        error:null,
        processing:'',
        disabled: true,
        clientSecret: '',  
    }

    componentDidUpdate (){

    }
    componentDidMount(){
      

        console.log('--- CheckoutForm this.props:>>', this.props)

        const { userProductData,  } = this.props
        userProductData instanceof Array && this.setState({email: userProductData[0].user?.email});
        if (userProductData[0]?.user === undefined ) {
          this.setState({disabled_btn:true, message: {code:200, message: `No user register, please go back to the previous page and register yourself.`, user_missing: true}})
          
        } else {
        this.setState(prevState=> {
              localStorage.setItem("body", JSON.stringify({
                ...prevState,
                body: {
                    _ids: userProductData.reduce((arr, curr)=> { arr.push(curr._id); return arr} ,[]),
                    user: {
                        ...userProductData[0].user,
                        status: "paid",
                        user_token: localStorage.getItem("userToken")
                    },
                    email: userProductData[0].user?.email, 
                }
            }))
            return {
                ...prevState,                
                disabled_btn:false,
                name: `${userProductData[0].user?.last_name}, ${userProductData[0].user?.first_name}`,
                body: {
                    _ids: userProductData.reduce((arr, curr)=> { arr.push(curr._id); return arr} ,[]),
                    user: {
                        ...userProductData[0].user,
                        status: "paid",
                    },
                    email: userProductData[0].user?.email,
                }
            }
          })
        }
    }
    

  handleChange = (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    this.setState({disabled:event.empty});
    this.setState({error:event.error ? event.error.message : ""});
  };

  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    this.setState({processing: true})
    const {stripe, elements} = this.props;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      this.setState({processing: false})
      return;
    }
    // console.log('element', element)
    try {
      const result1 = await stripe.createPaymentMethod({
        card: elements.getElement("cardNumber"),
        type: "card",
        billing_details: {
          email: this.state.email,
        },
      });
      
      let _user_token = localStorage.getItem("userToken");
      const response = await fetch(`${BACKEND_SERVER_DOMAIN}/pricing/recurring_sub`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...this.state.body,
          paymentCycle: this.props.paymentCycle,
          user_token: _user_token,
          name: this.state.name,
          email: this.state.email,
          paymentMethod: result1.paymentMethod?.id,
        }),
      });
      const res = await response.json();
      if (!response.ok) {
        // const data = await response.json();
        // console.log('on post recurring_sub res', data)
        
        this.setState({error:`Payment failed ${res.message}`});
        this.setState({processing:false});

        console.log('on post recurring_sub res', res)
        this.setState({
            message: "Payment unsuccessful! " + res.message,
            code: '404'
        }); return};   //alert("Payment unsuccessful!");
      
        
      const confirm = await stripe.confirmCardPayment(res.clientSecret);
      // confirm = confirm.json()
      console.log('confirm', confirm)
      if (confirm.error) {
        
        this.setState({error:`Payment failed ${res.message}`});
        this.setState({processing:false});

        this.setState((prevState)=>{
          return {...prevState,
          message: "Payment unsuccessful! " + res.message,
          code: '403'
          }
        });
        return
      } else if (confirm) {
        console.log('b confirm', confirm)
        console.log('b res', res)
        
        axios.post(`${BACKEND_SERVER_DOMAIN}/user_product/update_user_product_stripe`,{
          stripe:{...res, ...confirm, token: _user_token},
        },config).then(res=>{
            localStorage.clear();
            this.props.history.push({
              pathname: `/success_payment`,
              //state: param
          });
        })
        //   localStorage.clear();
        //   this.props.history.push({
        //     pathname: `/success_payment`,
        //     //state: param
        // });
      } //alert("Payment unsuccessful!");
        // everything went well
        
        this.setState({error:null});
        this.setState({processing:false});
        this.setState({succeeded:true});

        this.setState((prevState)=>{
          
          return {...prevState,
          message: "Payment Successful! Subscription active.\n" + res.message,
          code: "200"
          }
        });
        
    } catch (err) {
      console.error(err);  
      this.setState((prevState)=>{
          return {...prevState,
          message: "Payment failed! " + err.message
        }
      })
    }
  };

  render() {
      // console.log('this.props', this.props)
    return (
        <MaintContainer>
		{this.state.message && this.state.code ? 
			this.state.code  == "200" ? (
			<div className="card-error alert alert-success mb-2" role="alert">
				{this.state.message } 
			</div>
			) : (
				<div className="card-error alert alert-danger mb-5" role="alert">
				{this.state.user_missing ? (<a style={{color: 'var(--blue'}} src="/registration">Registration Page</a>) : this.state.message}
				</div>
			)          
		:null}
    {this.state.error && (
        <div className="card-error" role="alert">
          {this.state.error}
        </div>
      )}
        <form className="form" onSubmit={this.handleSubmit}>
          <div style={{marginBottom: '1rem'}} className="row">
              <div className='col-16 pr-1'>
                <div className="form-group">
                    <LabelCst htmlFor="email" style={{ fontSize: '1.1em',fontWeight:'400', color: 'rgba(48, 49, 61,0.8)', marginBottom: '0px !important'}} >Email</LabelCst>
                    {this.props.userProductData[0]?.user?.email &&<InputCst  style={{height: '45px', borderRadius: '5px', boxShadow: '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)'}}
                      value={this.state.email}
                      onChange={(event)=> this.setState({email: event.target.value})}
                      type="email" className="form-control" id="email" placeholder="email"/>}
                </div>
              </div>
              {/* <div className='col-6' style={{alignSelf: 'flex-end'}}>
                <LinkAuthenticationElement disabled={true}/>                
              </div> */}
              <div className='col-12 mt-3'>
              <CardNumberElement id="card-element" options={cardStyle} onChange={this.handleChange} />
              </div>
              <div className='col-6 mt-3'>
              <CardExpiryElement id="card-element" options={cardStyle} onChange={this.handleChange} />
              </div>
              <div className='col-6 mt-3'>
              <CardCvcElement id="card-element" options={cardStyle} onChange={this.handleChange} />
              </div>
          </div>
          <button id="stripe_btn" disabled={ this.state.processing} className="my-4 bizwheel-btn theme-2 btn-lg btn-block" >
            
            <span id="button-text" style={{display: 'inline-flex'}}>
              {this.state.processing ? (<>
              {" Pay now "}<div style={{marginLeft: '.2em'}} className="spinner" id="spinner"></div>
              </>
              ) : (
              "Pay now"
              )}
            </span>

          </button>
        </form>

        </MaintContainer>
    );
  }
}
// export default CheckoutForm;
export default withRouter(function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer className="mb-2">
      {({stripe, elements}) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
})

const cardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#32325d"
      }
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};