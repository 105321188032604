import React, { useEffect, useRef } from 'react'
import { BACKEND_SERVER_DOMAIN, env_config } from '../../env'
import axios from 'axios'

const config = env_config;

const Paypal =(props)=> {
    const paypal = useRef()
    useEffect(() => {
      // console.log('In Paypal props:>>', props)
        if (!window.paypal){
          props.setMessage({code: 501, message: 'Oop ! please make sure you are connected to internet or contact the Support'})
          
        } else {
          window.paypal.Buttons({
              // createOrder: function () {
                
              //   return fetch(`${BACKEND_SERVER_DOMAIN}/paypal_payment_method/create-order`, {
              //     method: "POST",
              //     headers: {
              //       "Content-Type": "application/json",
              //     },
              //     body: JSON.stringify({
              //       total_amount: props.total_amount,
              //     }),
              //   })
              //     .then(res => {
              //       if (res.ok) return res.json()
              //       // logique to add a company or can combine company and history here
              //       return res.json().then(json => Promise.reject(json))
              //     })
              //     .then(({ id }) => {
              //         //logique to add history
              //       return id
              //     })
              //     .catch(e => {
              //       console.error(e.error)
              //     })
              // },
              createSubscription: function(data, actions) {
                return actions.subscription.create({
                  'plan_id': 'P-0HR35973E0849172FMMM3YEI'
                });
              },
              onApprove: function (data, actions) {
                console.log('-- userData:>>', props.userData)
                axios.post(`${BACKEND_SERVER_DOMAIN}/paypal_payment_method/success_payment`,{
                  email:props.email,
                  user: props.userData,
                  email: props.userData.email
                },config).then(res=>{
                  // console.log('res', res)
                  if(res.status.toString().substr(0,2) === '20'){
                    window.location.href = `/success_payment`
                  }                
                })
                return actions.order.capture()
              },
            }).render(paypal.current)

        }
    }, [])
    
  return (
    <div>
        <div ref={paypal}></div>
    </div>
  )
}

export default Paypal;

