import React from 'react'
import { withNamespaces } from 'react-i18next';
import { Helmet } from 'react-helmet-async'

const SideMap = (props) => {
    const { t, title } = props
  return (
    <>     
    <Helmet>
        <title>{'Equerre | SideMap'}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre | SideMap"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/side-map" />
  
        
        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/side-map" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        
        <meta property="og:type" content="article" />
        <meta property="og:title" content="SideMap" />
        <meta property="og:description" content="Your company resource manage 360 degrees" />
  
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:title" content="SideMap" />
        <meta property="twitter:description" content="Your company resource manage 360 degrees" />
        <meta property="og:title" content="Side Map" />
        <meta property="og:description" content="Your company resource manage 360 degrees." />
  
    </Helmet>

    <div className="breadcrumbs overlay" style={{backgroundImage: 'url("/assets/img/breadcrumb.jpg")'}}>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="bread-inner">
          {/* Bread Menu */}
          <div className="bread-menu">
            <ul>
              <li><a href="/">Accueil</a></li>
              <li><a href="#" className='text-muted'>Plan du Site</a></li>
            </ul>
          </div>
          {/* Bread Title */}
          <div className="bread-title">
            <h2>Plan du Site</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section>
                <div className="container">
                <div className="row ">
                    <div className="col-12">
                        <div className="section-title style2 text-center">
                        </div>
                    </div>
                </div>
                <div className="row text-dark">

                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                            <h3 className=' mb-3'><a href="/" className='fw-semi-bold'>Accueil</a></h3>
                            <h3 className=' mb-3'><a href="/product_list" className='fw-semi-bold'>Produits</a></h3>
                            <ul>
                                <li className=' mb-2'><a href="/equerre-smb-50" className='fw-semi-bold'>/ Equerre SMB 50</a></li>
                                <li className=' mb-2'><a href="/equerre-smb-100" className='fw-semi-bold'>/ Equerre SMB 100</a></li>
                                <li className=' mb-2'><a href="/equerre-hr-1000#" className='fw-semi-bold'>/ Equerre RH 1000</a></li>
                                <li className=' mb-2'><a href="/product_list#" className='fw-semi-bold'>/ Equerre Paie 500</a></li>
                                <li className=' mb-2'><a href="/equerre_cloud_erp" className='fw-semi-bold'>/ Equerre Cloud ERP</a></li>

                                
                                <li className=' mb-2'><a href="/equerre_cloud_erp#" className='fw-semi-bold'>/ Equerre vente et CRM</a></li>
                                <li className=' mb-2'><a href="/equerre-finance-1000" className='fw-semi-bold'>/ Equerre Finance 1000</a></li>
                                <li className=' mb-2'><a href="/equerre_project_management" className='fw-semi-bold'>/ Equerre gestion de projet</a></li>
                                <li className=' mb-2'><a href="/equerre_pos" className='fw-semi-bold'>/ Equerre POS</a></li>
                                <li className=' mb-2'><a href="/equerre_gdoc" className='fw-semi-bold'>/ Equerre Gdoc</a></li>


                                <li className=' mb-2'><a href="/equerre_health" className='fw-semi-bold'>/ Equerre Santé</a></li>
                                <li className=' mb-2'><a href="/equerre_cloud_hotel" className='fw-semi-bold'>/ Equerre Cloud Hôtel</a></li>

                            </ul>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>

                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                            <h3 className=' mb-3'><a href="#" className='fw-semi-bold'>Marketplace</a></h3>
                            <ul>
                                <li className=' mb-2'><a href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal">Logiciels de gestion </a></li>
                                <li className=' mb-2'><a href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal">Logiciels spécialisés  </a></li>
                               
                            </ul>

                            
                            <h3 className=' my-3'><a href="/become_partner" className='fw-semi-bold'>Partenaire</a></h3>
                            <h3 className=' my-3'><a href="/contact_us" className='fw-semi-bold'>Contacts</a></h3>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>


                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                            <h3 className=' mb-3'><a href="/" className='fw-semi-bold'>L'entreprise</a></h3>
                            
                            <ul>
                                <li className=' mb-2'><a href="/about_us" className='fw-semi-bold'>/ A propos de Equerre</a></li>
                                <li className=' mb-2'><a href="/career" className='fw-semi-bold'>/ Carrières</a></li>
                                <li className=' mb-2'><a href="/actuality" className='fw-semi-bold'>/ Actualités / Evènements</a></li>
                                <li className=' mb-2'><a href="/newsletter" className='fw-semi-bold'>/ Newsletter</a></li>
                            </ul>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>

                    
                </div>
                
                </div>

</section>
    </>
  )
}

export default withNamespaces() (SideMap)
