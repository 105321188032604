import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchUserProducts } from "../../redux/acitons/userProductAction";

const FooterSummary = ({ fetchUserProducts, userProductData, link, title, disableNexBtn }) => {
  useEffect(() => {
    let userToken = localStorage.getItem("userToken");
    fetchUserProducts(userToken);
    return () => {};
  }, []);

  return (
    <>
    <div className='row'>
      <div className='col-lg-9 d-flex flex-row'>
        {[...userProductData]?.map((_data, i) => (
          <div
            className=" "
            key={i}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* {JSON.stringify(_data.module) }{console.log('_data', _data)} */}
            {_data && _data.module ? (
              <div className="module-card">
                <div className="module-card_content">
                  <div className="module-card_content_1">
                    <div>
                      <h1 className="submodule_title">
                        {_data.module.name.substring(0, 2)}
                      </h1>
                      <p style={{ color: "var(--red)", fontWeight: "bolder" }}>
                        $
                        {_data.module.submodules.reduce((acc, curr) => {
                          acc += parseFloat(curr.price?.monthly) || 0;
                          return acc;
                        }, 0)}
                      </p>
                    </div>
                    <div className="card_content_submodules">
                      <ul
                        style={{ color: "rgb(0, 154, 222)", textAlign: "left" }}
                      >
                        {_data.module.submodules.map((submodule, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <li
                              style={{
                                textAlign: "left",
                                fontSize: "0.8rem",
                                lineHeight: "1rem",
                                marginRight: "0.5em",
                              }}
                            >
                              {submodule.name}
                            </li>
                            <p
                              style={{
                                color: "var(--red)",
                                textAlign: "left",
                                fontSize: "0.8rem",
                                lineHeight: "1rem",
                              }}
                            >
                              $ {parseFloat(submodule.price?.monthly).toFixed(2) || 0}
                            </p>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="module-card_content_2">
                    <p>{_data.module.name}</p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {/* <div className="col-md-1 offset-md-1 col-sm-0"></div> */}
      <div className="p-2 col-sm-12 col-lg-2" style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
        { disableNexBtn && link=='/registration'? (
          <button data-toggle="tooltip" data-placement="top" title="Please select a payment cycle !" disabled={disableNexBtn} className="btn-continue">{title} <i className="p-2 fa fa-arrow-right"></i></button>
        ): (
          <a className="btn-continue" href={link}>{title} <i className="p-2 fa fa-arrow-right"></i></a>
        )}
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pricingData: state.pricing.pricingData,
    userProductData: state.user_product.selected_modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProducts: (token) => dispatch(fetchUserProducts(token)),
    // fetchPricings: ()=> dispatch(fetchPricings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterSummary);
