export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const FETCH_MODULES = "FETCH_MODULES"

export const INITIATE_USER_PRODUCT_REQUEST = "INITIATE_USER_PRODUCT_REQUEST";
export const INITIATE_USER_PRODUCT_FAILURE = "INITIATE_USER_PRODUCT_FAILURE";

export const CREATE_OR_UPDATE_USER_PRODUCT = "CREATE_OR_UPDATE_USER_PRODUCT"
export const FETCH_USER_PRODUCT = "FETCH_USER_PRODUCT"

export const ADD_USER_PRODUCT = "ADD_USER_PRODUCT"
export const REMOVE_USER_PRODUCT = "REMOVE_USER_PRODUCT"

// modulelines
// initiators ModuleLine
export const INITIATE_MODULE_LINE_REQUEST = "INITIATE_MODULE_LINE_REQUEST";
export const INITIATE_MODULE_LINE_FAILURE = "INITIATE_MODULE_LINE_FAILURE";


export const CREATE_MODULELINES = "CREATE_MODULELINES"

export const FETCH_MODULELINES = "FETCH_MODULELINES"

// Pricing
export const FECTH_PRICING = "FETCH_PRICING"
export const ADD_PRICING_TO_USER_PRODUCT = "ADD_PRICING_TO_USER_PRODUCT"

export const FETCH_STRIPE_PUBLIC_KEY = "FETCH_STRIPE_PUBLIC_KEY"

export const FETCH_CLIENT_KEY = "FETCH_CLIENT_KEY"

export const SEND_SUCCESS_PAYMENT_MAIL = "SEND_SUCCESS_PAYMENT_MAIL"