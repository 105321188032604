import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';

function MentionLegale(props) {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{'Mentions légales'}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Mentions légales"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/mention_legale" />
        
        

        
        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/mention_legale" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        
        
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Mentions légales" />
        <meta property="og:description" content="Your company resource manage 360 degrees" />
        
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@EquerreSa" />
        <meta name="twitter:creator" content="@EquerreSa" />
        <meta property="twitter:title" content="Mentions légales" />
        <meta property="twitter:description" content="Your company resource manage 360 degrees" />
        
        
        <meta property="og:title" content="Mentions légales" />
        <meta property="og:description" content="Your company resource manage 360 degrees." />
        
    </Helmet>
    <div className="breadcrumbs overlay" style={{backgroundImage: 'url("/assets/img/breadcrumb.jpg")'}}>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="bread-inner">
          {/* Bread Menu */}
          <div className="bread-menu">
            <ul>
              <li><a href="/">Accueil</a></li>
              <li><a href="#" className='text-muted'>Mention Légale</a></li>
            </ul>
          </div>
          {/* Bread Title */}
          <div className="bread-title">
            <h2>Mentions Légales</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section className="news-area archive blog-single section-padding">
  <div className="container-lg text-dark">
    <div className="row">
      <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-12">
        <div className="row">
          <div className="col-12">
            <div className="blog-single-main">
              <div className="blog-detail">
                {/* <h2 className="blog-title">Mentions légales</h2> */}
                <h3 className='h3 fw-bold pt-3'>1. Présentation du site.</h3>
                <p className='h5 fw-normal pt-3'>
                Il est précisé aux utilisateurs du site https://www.equerre.com/ l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                </p>
                <ul>
                    <li className='h5 fw-normal pt-3'><b>Propriétaire :</b> {" "} Equere </li>
                    <li className='h5 fw-normal pt-3'><b>Créateur  :</b> {" "} Equere </li>
                    <li className='h5 fw-normal pt-3'><b>Responsable publication  :</b> {" "} info@equerre.com </li>
                    <li className='h5 fw-normal pt-3'><b>Webmaster  :</b> {" "} Claudia Ngoguinya – claudia.ngonguinya@equerre.com </li>
                    <li className='h5 fw-normal pt-3'><b>Hébergeur  :</b> {" "} AWS </li>
                </ul>

                <h3 className='h3 fw-bold pt-3'>2. Conditions générales d’utilisation du site et des services proposés. </h3>
                <p className='h5 fw-normal pt-3'>
                L’utilisation du site https://www.equerre.com/ implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site https://www.equerre.com/ sont donc invités à les consulter de manière régulière.
                </p>
                <p className='h5 fw-normal pt-3'>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Equerre, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.</p>

                <p className='h5 fw-normal pt-3'>Le site https://www.equerre.com/ est mis à jour régulièrement par Claudia Esther Ngonguinya. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.</p>


                <h3 className='h3 fw-bold pt-3'>3. Description des services fournis.</h3>
                <p className='h5 fw-normal pt-3'>
                Le site https://www.equerre.com/ a pour objet de fournir une information concernant l’ensemble des activités de la société. Equerre s’efforce de fournir sur le site https://www.equerre.com/ des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
                </p>
                <p className='h5 fw-normal pt-3'>Toutes les informations indiquées sur le site https://www.equerre.com/ sont données à titre indicatif,  et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site https://www.equerre.com/ ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.</p>


                <h3 className='h3 fw-bold pt-3'>4. Limitations contractuelles sur les données techniques.</h3>
                <p className='h5 fw-normal pt-3'>
                Le site utilise les technologies React GS et Express GS
                </p>
                <p className='h5 fw-normal pt-3'>Le site Internet ne pourra être tenu responsable des dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.</p>


                <h3 className='h3 fw-bold pt-3'>5. Propriété intellectuelle et contrefaçons.</h3>
                <p className='h5 fw-normal pt-3'>
                Equerre est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
                </p>
                <p className='h5 fw-normal pt-3'>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Equerre</p>
                
                <p className='h5 fw-normal pt-3'>Toute exploitation non autorisée du site ou de l’un des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions du Code de Propriété Intellectuelle.</p>

                
                <h3 className='h3 fw-bold pt-3'>6. Limitations de responsabilité.</h3>
                <p className='h5 fw-normal pt-3'>
                Equerre ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site https://www.equerre.com/ et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité. 
                </p>
                <p className='h5 fw-normal pt-3'>Equerre ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site https://www.equerre.com/. </p>
                
                <p className='h5 fw-normal pt-3'>Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la disposition des utilisateurs. Equerre se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable dans les pays où il exerce ses activités, en particulier aux dispositions relatives à la protection des données. Le cas échéant, Equerre se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie…). </p>

                
                <h3 className='h3 fw-bold pt-3'>7. Gestion des données personnelles.</h3>
                <p className='h5 fw-normal pt-3'>
                A l’occasion de l’utilisation du site https://www.equerre.com/, peuvent être recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site https://www.equerre.com/, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur. 
                </p>
                <p className='h5 fw-normal pt-3'>En tout état de cause Equerre ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés par le site https://www.equerre.com/. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur  saisie. Il est alors précisé à l’utilisateur du https://www.equerre.com/ l’obligation ou non de fournir ces informations.</p>
                
                <p className='h5 fw-normal pt-3'>Aucune information personnelle de l’utilisateur du sitehttps://www.equerre.com/ n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de Equerre et de ses droits permettrait la transmission des dites informations à l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l’utilisateur du site https://www.equerre.com/</p>

                
                <h3 className='h3 fw-bold pt-3'>8. Liens hypertextes et cookies. </h3>
                <p className='h5 fw-normal pt-3'>
                Le site https://www.equerre.com/ contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de Equerre. Cependant, Equerre n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.
                </p>
                <p className='h5 fw-normal pt-3'>La navigation sur le site https://www.equerre.com/ est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas  l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.</p>
                
                <p className='h5 fw-normal pt-3'>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :</p>

                <ul>
                    <li className='h5 fw-normal pt-3'><b>Sous Internet Explorer : </b>onglet outil (pictogramme en forme de rouage en haut à droite) / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.</li>
                    <li className='h5 fw-normal pt-3'><b>Sous Firefox : </b>en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller dans l’onglet Options. Cliquer sur l’onglet Vie privée. Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés pour l’historique. Enfin décochez-la pour désactiver les cookies.</li>
                    <li className='h5 fw-normal pt-3'><b>Sous Safari : </b>Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur Paramètres de contenu. Dans la section « Cookies », vous pouvez bloquer les cookies.</li>
                    <li className='h5 fw-normal pt-3'><b>Sous Chrome : </b>Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur préférences. Dans l’onglet « Confidentialité », vous pouvez bloquer les cookies.</li>
                </ul>

                <h3 className='h3 fw-bold pt-3'>9. Droit applicable et attribution de juridiction. </h3>
                <p className='h5 fw-normal pt-3'>
                Tout litige en relation avec l’utilisation du site https://www.equerre.com/ est soumis au droit américain. Il est fait attribution exclusive de juridiction aux tribunaux compétents de l’Etat du Maryland. 
                </p>
                


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default withNamespaces() (MentionLegale)