import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Auth } from '../components';
import { Home, Dashboard, SignUp, SignIn, HomePage } from '../containers';


import mainRoutes from "../route/mainRoutes"

  function Router() {
    const user = JSON.parse(localStorage.getItem('profile'));
  
    return (
        <BrowserRouter>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/billing" exact component={Home} />
              <Route path="/saas_product" exact component={Home} />
              <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/billing" />)} />
              {mainRoutes.map(route => (<Route exact {...route} />))}
            </Switch>
        </BrowserRouter>
    );
  }
  
  export default Router;