import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchUserProducts } from "../../redux/acitons/userProductAction";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js"; //
import {
  fetchClientKey,
  fetchStripePublicKey,
  sendSuccessCheckout,
} from "../../redux/acitons/pricingActions";
import CheckoutForm from "./CheckoutForm";
import "./checkout_styles.css";
import { PayPal, SummaryWidget } from "../../components";
import { withNamespaces } from 'react-i18next';
import { BACKEND_SERVER_DOMAIN, env_config, PAYPAL_CLIENT_ID, STRIPE_PUB_KEY } from "../../env";
import axios from "axios";
import { useLocation } from "react-router-dom";

const config = env_config;
const stripePromise = loadStripe(STRIPE_PUB_KEY);
// const stripeClientSecretkey = STRIPE_PUB_KEY;
const CheckOut = ({
  userProductData,
  sendSuccessCheckout,
  fetchUserProducts,
  stripe_public_key,
  client_secret_key,
  fetchClientKey,
  fetchStripePublicKey,
}) => {
  const [total_amount, setTotal_amount] = useState(false);
  const [userData, setUserData] = useState(false);
  const [paymentCycle, setPaymentCycle] = useState("monthly");
  const [payPalCredentials, setPayPalCredentials] = useState({})
  const location = useLocation();
  useEffect(() => {
    (async () => {
      console.log('--- props email', location && location?.state?.email)
      let _user_token = localStorage.getItem("userToken");
      if (!_user_token){
        return setMessage({code: 403, message:'Oop!!  No data provided !'})
      }
      let inner_user_products = await fetchUserProducts(_user_token);
      console.log('inner_user_products', inner_user_products)
      return {  inner_user_products };
    })().then((data) => {
      const { inner_user_products } = data;
      console.log("--- inner_user_product", inner_user_products);
      if (inner_user_products.length <= 0) {
        return setMessage({code: 403, message:'Oop!!  No data provided !'})
      }
      if (inner_user_products){        
        setTotal_amount(
          inner_user_products[0].pricing.price *
            (inner_user_products[0].pricing.users || 1)
        );
        setUserData(inner_user_products[0].user);
        setPaymentCycle(inner_user_products[0].pricing.paiement_cycle);
      }
    });
    return () => {
      setTotal_amount(false); // This worked for me
      setUserData(false);
      setPaymentCycle('monthly');
    };
  }, [location]);

  const [message, setMessage] = useState(false);
  const [paymentMethodChecked, setPaymentMethodChecked] = useState({
    stripe: true,
    paypal: false,
  });
  const handleOnClickPaymentMethod = (name) => {
    switch (name) {
      case "stripe":
        setPaymentMethodChecked({
          stripe: !paymentMethodChecked.stripe,
          paypal: paymentMethodChecked.paypal,
        });
        break;
      case "paypal":
        setPaymentMethodChecked({
          stripe: paymentMethodChecked.stripe,
          paypal: !paymentMethodChecked.paypal,
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="container mb-5">
        <div className="py-5 text-center">
          <h2>Checkout</h2>
          <span className="text-muted">
          To complete your  order enter your payment details.
          </span>
        </div>

        <div className="row">
          <div className="col-md-4 order-md-2 mb-4">
          <SummaryWidget />
          </div>
          <div className="contact-form-area col-md-8 order-md-1">
            {message && message.code ? (
              message.code === "200" ? (
                <div className="alert alert-success mb-5" role="alert">
                  {message.message}
                </div>
              ) : (
                <div className="alert alert-danger mb-5" role="alert">
                  {message.message}
                </div>
              )
            ) : null}

            <h4 className="mb-3">Billing Information</h4>
            <p>
              <a
                className="btn btn-ghost-primary"
                data-toggle="collapse"
                href="#stripe"
                role="button"
                onClick={() => handleOnClickPaymentMethod("stripe")}
                aria-expanded={paymentMethodChecked.stripe}
                aria-controls="stripe"
              >
                <i className="fa fa-cc-stripe" aria-hidden="true"></i> Stripe
              </a>
            </p>
            <div className="row">
              <div className="col">
                <div className="collapse multi-collapse show" id="stripe">
                  <div className="card card-body">
                    {stripePromise !== null &&
                    // client_secret_key !== undefined &&
                    userProductData instanceof Array && userProductData.length > 0 &&
                    userProductData !== [] ? (
                      <Elements
                        stripe={stripePromise}
                        // options= {{
                        //   clientSecret: client_secret_key
                        // }}
                      >
                        {userProductData && location && (
                          <CheckoutForm 
                          location={location}
                          paymentCycle={paymentCycle}
                            sendSuccessCheckout={sendSuccessCheckout}
                            userProductData={userProductData}
                          />
                        )}
                      </Elements>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='py-2'> */}
            <hr />
            {/* </div> */}
            {/* <button
              className="btn btn-ghost-primary"
              type="button"
              data-toggle="collapse"
              data-target="#multiCollapseExample2"
              onClick={() => handleOnClickPaymentMethod("paypal")}
              aria-expanded={paymentMethodChecked.paypal}
              aria-controls="multiCollapseExample2"
            >
              <i className="fa fa-cc-paypal" aria-hidden="true"></i> PayPal
            </button>
            <div className="row">
              <div className="col">
                <div
                  className="collapse multi-collapse"
                  id="multiCollapseExample2"
                >
                  <div className="card card-body">
                    {total_amount && userData && (
                      <PayPal
                        paymentCycle={paymentCycle}
                        setMessage={setMessage}
                        userData={userData}
                        total_amount={total_amount}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  // console.log("state :>>", state);
  return {
    userProductData: state.user_product.selected_modules,
    stripe_public_key: state.pricing.stripe_public_key,
    client_secret_key: state.pricing.client_secret_key,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserProducts: (token) => dispatch(fetchUserProducts(token)),
    fetchStripePublicKey: () => dispatch(fetchStripePublicKey()),
    fetchClientKey: (...data) => dispatch(fetchClientKey(...data)),
    sendSuccessCheckout: (data) => dispatch(sendSuccessCheckout(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (CheckOut));


