import { FETCH_MODULES } from '../constants/actionTypes';
import { fetchModulesApi} from '../../api'

export const fetchModules = (formData, router) => async (dispatch) => {

  try {
    const { data } = await fetchModulesApi(formData);

    dispatch({ type: FETCH_MODULES, payload: data });

    // router.push('/');
  } catch (error) {
    console.log('In fetchModules',error);
  }
};

