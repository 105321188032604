import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import CareerForm from './CareerForm';
import './Career.css'
function Career(props) {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Career"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/career" />
        <meta property="og:image"  content="/assets/img/career-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/career-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/career" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

        {/* <!-- Hero Slider --> */}
        <section className="style1 section-bg-white" >
			<div className="" 
                style={{ height: '647px', backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/career-img-2.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "top left", borderBottom: '5px solid #fff', backgroundPosition: "top right"}}
                // style={{backgroundColor: 'rgb(1 176 241 / 90%)', height: '647px'}}
                >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
                        <div className='row'>
                            <div className='col-12 my-5 py-5'></div>
                        </div>
						<div className="row p-top-50">
							<div className="offset-lg-3 col-lg-7 col-md-8 col-12 pt-5 my-5">
								<div className="welcome-text "> 
									<div className="hero-text  text-center"> 
										<h2 style={{textTransform: "normal",fontSize:'3.5em', lineHeight:'1em', color: 'white'}}>Carrières </h2>
											<p className="mt-4 center" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Si vous voulez explorer un monde d’excellence, Equerre est fait pour vous.</p>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

        <section className="application-form">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-12 my-5">
                        <div className="section-title default text-center">
                            <div className="section-top">
                            <h1 className='text-dark'><b> Envoyez-nous une candidature spontanée et rejoignez notre équipe </b></h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 mb-5">
                        <CareerForm />
                    </div>
                </div>
            </div>
        </section>


        <section className="mb-5 py-5" style={{backgroundColor: '#F8B080'}}>
            <div className="container col-lg-6 offset-lg-3 title_content py-4 ">
                <div className="title_content py-4" style={{textAlign: 'center', }}>
                    <h2 className='text-dark'>Pourquoi travailler chez Equerre ?</h2>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="single-content text-dark">

                        <p className='my-4 text-dark' style={{fontSize:'1.5em', lineHeight:'initial'}}>Chez Equerre, nous cultivons l’excellence. Pour parvenir à cette excellence, il nous est indispensable de 
                            collaborer avec des profils qui poursuivent le même but. Nous sommes convaincus que l’élément 
                            prépondérant qui fait la réussite d’une organisation, est la caractéristique des personnes qui la compose. </p>

                        <p className='text-dark' style={{fontSize:'1.5em', lineHeight:'initial'}}>Ainsi, notre politique sociale est basée sur la motivation et la fidélisation de nos collaborateurs. 
                            Au-delà de nos offres de rémunérations motivantes, nous mettons l'accent sur la reconnaissance, un 
                            management de proximité et la mise en place de divers programmes de récompenses, d’avantages et 
                            de services, en ligne avec notre culture d’entreprise :</p>

                        <div className="small-list-feature">
                            <ul>
                                <li><i className="fa fa-check " ></i><h3 className=' text-dark' style={{display: 'inline-block',}}>Une évaluation juste de la performance</h3></li>
                                <li><i className="fa fa-check "></i><h3  className=' text-dark'style={{display: 'inline-block',}}>Une politique de rémunération motivante</h3></li>
                                <li><i className="fa fa-check "></i><h3  className=' text-dark'style={{display: 'inline-block',}}>Une structure de formation pour vous accompagner tout au long de votre parcours</h3></li>
                                <li><i className="fa fa-check "></i><h3  className=' text-dark'style={{display: 'inline-block',}}>Des avantages sociaux </h3></li>
                                <li><i className="fa fa-check "></i><h3  className=' text-dark'style={{display: 'inline-block',}}>Un environnement stimulant et agréable</h3></li>
                            </ul>
                        </div>
                     </div>

                </div>
            </div>            
        </section>
                
        
        <section className="mb-5">
            <div className="container col-lg-6 offset-lg-3 title_content py-4 mb-4">
                <div className="title_content py-4" style={{textAlign: 'center', backgroundColor: 'inherit'}}>
                    <h2 className='text-dark'>Offres d’emplois </h2>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="single-content">
                        <h3 className='text-dark mb-2 title'><a href="/career-back-end-developer">Back end developer</a></h3>
                        <div className="single-widget widget">
                            <ul className="address-widget-list">
                                <li className="footer-mobile-number mb-1" style={{display: 'flex'}}><i className="fa fa-map-marker mr-2 text-danger" style={{fontSize: 'x-large'}}></i><h4 className='text-dark'>Douala, Yaoundé, Abidjan </h4></li>
                                <li className="footer-mobile-number"><h5 className='text-dark'>Job Description</h5></li>
                            </ul>
                        </div>

                        <p className='my-4' style={{fontSize:'1.5em', lineHeight:'initial'}}>
                        Sous l'autorité d’un Tech Lead, vous avez en charge de concevoir et de coder des programmes et des applications 
                        fonctionnels. Vous travaillerez à la fois au sein d’une équipe et de façon individuelle, avec peu de supervision. Vous 
                        jouissez d’excellentes connaissances des langages de programmation et maîtrisez différentes plateformes, ainsi que 
                        différents systèmes d’exploitation. Ces compétences vous permettent de rédiger un code propre et sans faille, gage de 
                        production des applications logicielles entièrement fonctionnelles conformes aux exigences.</p>                                                        
                    </div>


                    <div className="single-content mt-4">
                        <h3 className='text-dark mb-2 title'><a href="/career-front-end-developer">Front end developer</a></h3>
                        <div className="single-widget widget">
                            <ul className="address-widget-list">
                                <li className="footer-mobile-number mb-1" style={{display: 'flex'}}><i className="fa fa-map-marker mr-2 text-danger" style={{fontSize: 'x-large'}}></i><h4 className='text-dark'>Douala, Yaoundé, Abidjan </h4></li>
                                <li className="footer-mobile-number"><h5 className='text-dark'>Job Description</h5></li>
                            </ul>
                        </div>

                        <p className='my-4' style={{fontSize:'1.5em', lineHeight:'initial'}}>
                        Sous l'autorité d’un Tech Lead, vous avez en charge de concevoir et de coder des programmes et des applications fonctionnels. 
                        Vous travaillerez à la fois au sein d’une équipe et de façon individuelle, avec peu de supervision. Vous jouissez d’excellentes 
                        connaissances des langages de programmation et maîtrisez différentes plateformes, ainsi que différents systèmes d’exploitation. 
                        Ces compétences vous permettent de rédiger un code propre et sans faille, gage de production des applications logicielles 
                        entièrement fonctionnelles conformes aux exigences..</p>                                                        
                    </div>


                    <div className="single-content mt-4">
                        <h3 className='text-dark mb-2 title'><a href="/career-business-developer-senior">Business developer senior</a></h3>
                        <div className="single-widget widget">
                            <ul className="address-widget-list">
                                <li className="footer-mobile-number mb-1" style={{display: 'flex'}}><i className="fa fa-map-marker mr-2 text-danger" style={{fontSize: 'x-large'}}></i><h4 className='text-dark'>Douala, Yaoundé, Abidjan</h4></li>
                                <li className="footer-mobile-number"><h5 className='text-dark'>Job Description</h5></li>
                            </ul>
                        </div>

                        <p className='my-4' style={{fontSize:'1.5em', lineHeight:'initial'}}>
                        Sous l'autorité d’un Directeur Commercial, vous participez activement au développement d’un réseau de clients, en local et dans 
                        votre sous-région. Pour cela, vos principales missions s'articulent autour des grands axes suivants : business development, stratégie 
                        et Management.</p>                                                        
                    </div>

                    <div className="single-content mt-4">
                        <h3 className='text-dark mb-2 title'><a href="/career-business-developer-junior">Business developer junior</a></h3>
                        <div className="single-widget widget">
                            <ul className="address-widget-list">
                                <li className="footer-mobile-number mb-1" style={{display: 'flex'}}><i className="fa fa-map-marker mr-2 text-danger" style={{fontSize: 'x-large'}}></i><h4 className='text-dark'>Douala, Yaoundé, Abidjan</h4></li>
                                <li className="footer-mobile-number"><h5 className='text-dark'>Job Description</h5></li>
                            </ul>
                        </div>

                        <p className='my-4' style={{fontSize:'1.5em', lineHeight:'initial'}}>
                        Sous l'autorité d’un Business Developer Senior, vous participez activement au développement d’un réseau de clients, en local et dans 
                        votre sous-région. Vos principales missions s'articulent autour de : prospection clients, réponses à appels d'offres, analyse des 
                        besoins/propositions financières, etc. </p>                                                        
                    </div>

                </div>
            </div>

            
        </section>
                  
    </>
  )
}

export default withNamespaces() (Career)