import React from 'react'
import { Helmet } from 'react-helmet'

function ActualityDetails() {
  return (
    <>
    <Helmet>
        <title>Equerre | Dernières actualités détaillées</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Dernières actualités détaillées"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/actuality_details" />
        <meta property="og:image"  content="/assets/img/actuality_details-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/actuality_details-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/actuality_details" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>
		<section className="style1 section-bg-white" 
              style={{ minHeight: '500px', backgroundImage: "linear-gradient(90deg,rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))", backgroundSize: '100% auto',backgroundRepeat: 'no-repeat', borderBottom: '5px solid #fff', borderTop: '5px solid #fff' }}>
              
        <div className='container-lg'>
            <div className="row my-5">
                <div className="col-lg-6 col-md-8 col-12">
                    <h2 style={{color:'var(--eqr-primary-color)', extTransform: "normal",fontSize:'3.5em', lineHeight:'1em'}}>Toute l'actualité sur Equerre</h2>
                </div>
            </div>

            <div className="panel panel-default mb-5">
                {/* <div className="panel-heading collapsed" data-toggle="collapse" data-target="#collapseOrderItems1"> 
                    <h3 className='mb-3 text-dark'>Pourquoi rejoindre Equerre ? <i className="ml-2 chevron fa fa-fw" /></h3>
                </div>
                <div className="collapse mb-4 show" id="collapseOrderItems1">
                <div className='text-dark'>
                    <p className='mb-3 h5 fw-normal'>Chez Equerre, nous cultivons l’excellence. Pour parvenir à cette excellence, il nous est indispensable de collaborer avec des profils qui poursuivent le même but. Nous sommes convaincus que l’élément prépondérant qui fait la réussite d’une organisation, est la caractéristique des personnes qui la compose.  </p>
                    <p className='mb-3 h5 fw-normal'>Ainsi, notre politique sociale est basée sur la motivation et la fidélisation de nos collaborateurs. Au-delà de nos offres de rémunérations motivantes, nous mettons l'accent sur la reconnaissance, un management de proximité et la mise en place de divers programmes de récompenses, d’avantages et de services, en ligne avec notre culture d’entreprise :</p>
                </div>
                </div>
                <div className="panel-heading collapsed" data-toggle="collapse" data-target="#collapseOrderItems2"> 
                    <h3 className='mb-3 text-dark'>oops c'est vide, revenez plus tard </h3>
                </div>
                <div className="collapse text-dark" id="collapseOrderItems2">
                    <p className='mb-3 h5 fw-normal'>Sous l'autorité d’un Tech Lead, vous avez en charge de concevoir et de coder des programmes et des applications fonctionnels. Vous travaillerez à la fois au sein d’une équipe et de façon individuelle, avec peu de supervision. Vous jouissez d’excellentes connaissances des langages de programmation et maîtrisez différentes plateformes, ainsi que différents systèmes d’exploitation. Ces compétences vous permettent de rédiger un code propre et sans faille, gage de production des applications logicielles entièrement fonctionnelles conformes aux exigences. </p>
                </div> */}
                <div className="panel-heading collapsed" data-toggle="collapse" data-target="#collapseOrderItems2"> 
                    <h3 className='mb-3 text-dark'>Oops c'est vide, revenez plus tard </h3>
                </div>
                
                <div className="button mt-5 d-flex" style={{ justifyContent: 'flex-start'}}>      
                        <a  style={{height: '100%', display: 'block'}}  href="/actuality" className="bizwheel-btn theme-2  effect primary-color"><i class="fa fa-arrow-left mr-3"></i>Actualités</a> 
					</div>
            </div>

        </div>
		</section>  
    </>
  )
}

export default ActualityDetails