import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import Layout from './hoc/Layout/Layout';
import UserAdminLayout from './hoc/UserAdminLayout/UserAdminLayout.js';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import generateToken from './utils/generateToken';
import { Dashboard, SignIn, SignUp } from './containers';
import Router from './router/index'
// import 'bootstrap/dist/css/bootstrap.min.css';
const App = (props) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  
  const { pathname } = window.location
  useEffect(() => {
    
    window.addEventListener('scroll', function() {
      
      if(!document.body.classList.contains('scroll-space')) {
        console.log('scroll', )
        document.body.classList.remove('scroll-space')
      }
      
    })
    // console.log('-- In App props:>>', window.location)
    let userToken = localStorage.getItem('userToken');
    if (userToken === null) {
      localStorage.setItem('userToken', generateToken())
    }
  }, [])

  return (    
    <>    
    
      {/* <!-- Preloader --> */}
      <div className="preeloader">
        <div className="preloader-spinner"></div>
      </div>
      {/* <!--/ End Preloader --> */}
    {pathname?.substr(0,10) === "/dashboard" ? (
        <UserAdminLayout>
          <BrowserRouter>
              <Switch>
                <Route path="/signin" exact component={SignIn} />
                <Route path="/signup" exact component={SignUp} />
                <Route path="/dashboard" exact component={() => (!user ? <Redirect to="/signin"/> :  <Dashboard />)}/>
              </Switch>
          </BrowserRouter>
        </UserAdminLayout>
    ) : (
      <Layout>
        <div className='inner-header scroll-space'>
          <Router />
        </div>
      </Layout>
    )}
    </>
  );
};
const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    // stripe_public_key : state.pricing.stripe_public_key,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // fetchStripePublicKey: ()=> dispatch(fetchStripePublicKey()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

