import React from 'react'
import { Helmet } from 'react-helmet-async'
import { withNamespaces } from 'react-i18next'
import { ProductPageCommonComponent } from '../../components'

function EquerreSalesCRM(props) {
  const { t } = props
return (
  <>
  <Helmet>
      <title>{'Equerre Vente et CRM'}</title>
      <meta name="description" content="Your company resource manage 360 degrees"/>
      <meta property="og:title"content="Equerre Vente et CRM"/>
      <meta
      property="og:description"
      content="Your company resource manage 360 degrees"/>
      <link rel="canonical" href="https://www.equerre.com/equerre-smb-50" />
      <meta property="og:image"  content="/assets/img/sales-crm-mg-1.jpg"/>
      <meta name="twitter:image" content="/assets/img/sales-crm-mg-1.jpg"/>

      
      <meta property="og:locale" content="en_US" />
      {/* <meta property="og:type" content="article" /> */}
      <meta property="og:url" content="https://www.equerre.com/equerre-smb-50" />
      <meta property="og:site_name" content="Your company resource manage 360 degrees" />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="500" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image_url" content="/assets/img/sales-crm-mg-1.jpg" />
      
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Equerre Vente et CRM" />
      <meta property="og:description" content="Your company resource manage 360 degrees" />
      <meta property="og:image" content="/assets/img/sales-crm-mg-1.jpg" />
      <meta property="og:url" content="ENLACE PERMANENTE" />
      <meta property="og:site_name" content="NOMBRE DEL SITIO" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@nytimesbits" />
      <meta name="twitter:creator" content="@nickbilton" />
      <meta property="twitter:title" content="Equerre Vente et CRM" />
      <meta property="twitter:description" content="Your company resource manage 360 degrees" />
      <meta name="twitter:image" content="/assets/img/sales-crm-mg-1.jpg" />
      <meta property="og:url" content="/assets/img/sales-crm-mg-1.jpg" />
      <meta property="og:title" content="A Twitter for My Sister" />
      <meta property="og:description" content="Your company resource manage 360 degrees." />
      <meta property="og:image" content="/assets/img/sales-crm-mg-1.jpg" />

  </Helmet>
  
    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white hero_img_block wrapper_blur mb-lg-0 mb-5 equerre_sales_crm_style">
  
  <div class="foreground_blur" style={{ height: '647px', backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/sales-crm-mg-1.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "bottom left", borderBottom: '5px solid #fff', backgroundPosition: "top right"}}
      ></div>

			<div className="" 
            // style={{backgroundColor: 'rgb(215 94 15 / 90%)'}}
            
            
            >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-6 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'3.5em', lineHeight:'1.5em', color: 'white'}}>Equerre Vente et CRM</h2>
											<p className="mt-4" style={{fontSize:'2.5em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Nous avons combiné vente et relation client pour connecter le service à la chaîne de valeur.</p>
										<div className="button  my-4">       
											{/* <a href="/equerre-hr-1000#video-feature" className="bizwheel-btn theme-1 effect mt-lg-3 primary-color">Voir la vidéo</a> */}
											<a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3">Demandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                            <div className="col-lg-5 col-md-6 col-12">
                                
                                {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/sales-crm-mg-1.jpg'/> */}
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>
    <section>
              
        <div className="container title_content my-5" style={{textAlign: 'center'}}>
          <div className='row mb-5'>
            <div className='col-12'>
                  
              <div className="title_content">
                  <h2 style={{color: '#000' , fontSize: '2.5em', lineHeight: "1.2em"}}>Qu’est-ce que Equerre Vente et CRM ?</h2>
                  <p style={{fontSize:'1.5em', lineHeight:'initial', marginBottom: '1em'}} >Equerre Vente et CRM est un progiciel de gestion commerciale complet et intégré conçu pour répondre aux attentes des entreprises et organisations de toutes les tailles en matière de processus de planification, d'organisation, de supervision et de coordination de la force de vente d'une entreprise. </p>
              </div>
            </div>
          </div>
          
          <div className="row mt-5">

              <div className="col-12">
                  <div className="section-title style2 text-center">
                      <h2 style={{color: '#000' , fontSize: '2.5em', lineHeight: "1em"}}>
                      Découvrez comment Equerre Vente et CRM connecte le service à la chaîne de valeur pour améliorer la valeur vie client
                      </h2>
                  </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Le suivi client repensé</h3>
                      </a>
                  </h4>
                  <p className=''>
                  
Equerre Vente et CRM propose un portail client qui permet de se connecter et d'avoir accès à différents services : factures, demande des devis, base de connaissance, envoi des requêtes, suivi de compte e-commerce, etc.
Côté administrateur, ce portail permet également la gestion optimale et intelligente des clients : suivi des CA, factures, la communication avec les clients 
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Revalorisez vos prospects</h3>
                      </a>
                  </h4>
                  <p>Equerre Vente et CRM vous offre la couverture fonctionnelle la plus fournie pour votre activité. Que ce soit pour suivre la relation avec vos prospects ou vos clients, notre solution éprouvée vous génère des indicateurs de performance fiables et enrichis pour monitorer toutes les étapes de votre cycle de vente.   
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>
              
              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Diversifiez vos canaux de vente</h3>
                      </a>
                  </h4>
                  <p>Equerre Vente et CRM est le logiciel qui simplifie la commercialisation de vos produits et services. Grâce à la diversification de ses canaux de vente, nous privilégions la digitalisation de vos processus métiers afin de vous faire gagner davantage de temps pour votre stratégie commerciale.  Equerre Vente et CRM vous donne le privilège de gérer vos fournisseurs et clients de manière centralisée. En outre, le reporting en temps réel est automatisé et accessible.   
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Honorez tous vos rendez-vous</h3>
                      </a>
                  </h4>
                  <p>Votre activité nécessite des prises de rendez-vous de la part de vos clients ? Vous avez du mal à gérer les réservations et à mettre à jour votre agenda ? Adoptez la solution Equerre Vente et CRM pour perfectionner votre gestion de rendez-vous. Que ce soit pour vos rendez-vous écoulés, les rappels et la personnalisation de vos habitudes professionnelles, la solution vous assiste dans toutes vos initiatives.
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

              
              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Donnez vie à vos appels</h3>
                      </a>
                  </h4>
                  <p>La gestion des journaux d'appels vous aide à juguler le volume des appels professionnels que vous effectuez au quotidien. Pratique et interactive, cette fonctionnalité vous donne l'avantage de suivre en permanence l'historique de vos échanges avec chacun de vos clients. Avec la gestion du journal d'appel d'Equerre, vous vitalisez vos entretiens téléphoniques.
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>
              
              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Pérennisez votre relation client</h3>
                      </a>
                  </h4>
                  <p>La relation client est un facteur majeur de croissance pour une entreprise. En effet, une entreprise doit bien connaître ses clients pour pouvoir déployer des actions permettant d’assurer leur satisfaction. L’outil que vous propose Equerre Vente et CRM maximise la gestion de vos interactions marketing et commerciales avec vos prospects et clients pour bâtir des relations d’affaires durables.
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Plus agile et flexible</h3>
                      </a>
                  </h4>
                  <p>Avec Equerre Vente et CRM, optimisez la gestion de votre chaîne d'approvisionnement en intégrant les facteurs les plus significatifs pour le suivi de bout-en-bout de vos workflows. Notre logiciel permet d'apporter une réponse agile et efficace aux fluctuations de votre chaîne d'approvisionnement en s'y adaptant avec simplicité. Equerre Vente et CRM renforce la collaboration entre vous et vos différents fournisseurs, dès leur intégration au processus d'appel d'offres jusqu'à la production et la livraison.
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Automatisation, Conformité, Gain de temps</h3>
                      </a>
                  </h4>
                  <p>Accélérez vos processus de vente avec Equerre Vente et CRM, de la création de devis au suivi de la trésorerie, en passant par la relance client et la gestion des moyens de paiement et notes de crédit. Notre progiciel élimine les tâches chronophages de votre travail en les automatisant de façon native. 
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Gérez stratégiquement le cycle de vie de vos contrats</h3>
                      </a>
                  </h4>
                  <p>Les documents contractuels ne sont pas figés. Notre progiciel vous permet de gérer vos contrats de façon évolutive, de leur création à leur expiration. Il vous assure une vue à 360 degrés de vos relations contractuelles commerciales, en passant par les différents avenants et documents associés au contrat. Intuitif, il a été imaginé pour être utilisé par toutes les directions. 
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>


              <div className="col-lg-4 col-md-4 col-12">
              {/* Single Service */}
              <div className="single-service equerre-rh">
                  <div className="service-content">
                  <h4>
                      <a href="#" className='fw-bold'>
                      <h3 className='text-dark'>Ne perdez plus aucun recouvrement </h3>
                      </a>
                  </h4>
                  <p>Dans un modèle économique par abonnements, les factures sont très nombreuses. Afin de ne pas perdre du temps à réaliser des tâches répétitives et sans valeur ajoutée, tout en éliminant le risque d’erreur liée à l’intervention humaine, un outil de gestion adapté est indispensable. Equerre Vente et CRM facilite l'automatisation et la sécurisation de vos facturations récurrentes. Désormais, vous pourrez recouvrer un nombre considérable d’abonnés sans le moindre stress. 
                  </p>
                  </div>
              </div>
              {/*/ End Single Service */}
              </div>

          </div>

        </div>

      </section>
  <section>
            <ProductPageCommonComponent title={'Equerre Vente et CRM'} />
        </section>

    </>
  )
}

export default withNamespaces() (EquerreSalesCRM)