import React from 'react'
import { connect } from 'react-redux'

const Breadcrumb = (props) => {
	const {links, title} = props;
  return (
    <div>
        
		{/* <!-- Breadcrumb --> */}
		<div className="breadcrumbs overlay" style={{backgroundImage:"url('/assets/img/img-5.jpg')"}}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="bread-inner">
							{/* <!-- Bread Menu --> */}
							<div className="bread-menu">
								<ul>
									{links.map((item, i)=> 
										<li key={i}><a href={item.link}>{item.title}</a></li>
									)}
								</ul>
							</div>
							{/* <!-- Bread Title --> */}
							<div className="bread-title" ><h2>{title}</h2></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!--/ End Breadcrumb --> */}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)