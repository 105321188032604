import { CREATE_MODULELINES, FETCH_MODULELINES, INITIATE_MODULE_LINE_FAILURE, INITIATE_MODULE_LINE_REQUEST } from '../constants/actionTypes';
import { BACKEND_SERVER_DOMAIN } from '../../env';
import axios from 'axios';
import { fetchModuleLinesApi } from '../../api';


// module Actions Creator
export const initiateModuleLineRequest = value => {
    return {
      type: INITIATE_MODULE_LINE_REQUEST,
      payload: value,
    };
  };
  export const initiateModuleLineFailure = error => {
    return {
      type: INITIATE_MODULE_LINE_FAILURE,
      payload: error,
    };
  };
  
  
export const fetchModuleLines = (formData, router) => async (dispatch) => {

  try {
    const { data } = await fetchModuleLinesApi();

    dispatch({ type: FETCH_MODULELINES, payload: data });
    return data
  } catch (error) {
    console.log('In fetchModuleLines',error);
  }
};


// createModuleLineSuccess
export const createModuleLineSuccess = module => {
    // message.success({ content: 'ModuleLine Created!', key, duration: 2 });
    return {
      type: CREATE_MODULELINES,
      payload: module,
    };
  };

  
export const createModuleLine = modules => {
    console.log('---- createModuleLine', modules);
  
    let params = {
      token: modules.token,
      active: modules.active,
      submodules: modules.submodules,
    };
    // let url = `${BACKEND_SERVER_DOMAIN}/modulelines/${params.id}/`;
  
    return dispatch => {
        dispatch(initiateModuleLineRequest);
        let stored_token = localStorage.getItem('token');

        axios
          .post(BACKEND_SERVER_DOMAIN + '/modulelines/', params)
          .then(response => {
            const module = response.data;
            dispatch(createModuleLineSuccess(module));
            // message.success({ content: 'ModuleLine added successfully!', key, duration: 2 });
            return module;
          })
          .catch(error => {
            console.error('---- postModuleLine error', error);
            const errorMsg = error.message;
            dispatch(initiateModuleLineFailure(errorMsg));
          })
          .finally(() => console.log('first', ));
    };
  };
  