import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import NewsletterForm from './NewsletterForm';

function Newsletter(props) {
  return (
    <div style={{overflowX: 'clip'}}>
    
    <Helmet>
        <title>Equerre | Newsletter</title>
        <meta name="description" content="Subscribe to Equerre newsletter, and get notify to our latest news."/>
        <meta property="og:title"content="Equerre | Newsletter"/>
        <meta
        property="og:description"
        content="Subscribe to Equerre newsletter, and get notify to our latest news"/>
        <link rel="canonical" href="https://www.equerre.com/newsletter" />
        <meta property="og:image"  content="/assets/img/newsletter-img-1.jpg"/>
        <meta name="twitter:image" content="/assets/img/newsletter-img-1.jpg"/>
    </Helmet>
	<section style={{borderBottom: '5px solid #fff'}}>
		<div>

			
		{/* <div className=''> */}
		<div className='row'>

	<div className='col-lg-5 col-sm-12 pt-5' style={{background: '#009ade'}}>
		<div className='row mb-5 offset-lg-1'>
			
					<div className="offset-lg-1 col-10 ">
						<div className="welcome-text "> 
							<div className="hero-text px-2"> 
								<div className="p-text">
								</div>
								<h2 style={{textTransform: "normal",fontSize:'4.2em', color: '#fff', lineHeight:'1em'}}>Abonnez-vous à la newsletter de Equerre</h2>
									{/* <p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em', color: '#fff',lineHeight:'1.2em', fontWeight: 'normal'}}>Vous voulez explorer un monde d’excellence, Equerre est fait pour vous.</p> */}
								<div className="button">       
									{/* <a href="/equerre-hr-1000#video-feature"  className="bizwheel-btn theme-1 effect mt-lg-3 primary-color">Regardez la vidéo de présentation</a>
									<a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3">Téléchargez la brochure</a> */}
								</div>
							</div>
						</div>
					</div>

		</div>
		<div className='row'>
			<NewsletterForm />
		</div>

	</div>
	<div className='col-7' style={{backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0)), url('/assets/img/newsletter-img-3.jpg')",backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', }}></div>
	</div>
	{/* </div> */}

		</div>
	</section>
    <section className="style1 section-bg-white d-none" style={{backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0)), url('/assets/img/newsletter-img-2.jpg')", backgroundSize: '100% auto',backgroundRepeat: 'no-repeat', height:'90vh', backgroundColor: '#f9fbfd', }} >
			<div className="" >
				{/* <!-- Single Slider --> */}
				<div className="single-slider pt-1" >
					<div className="container-fluid">
						<div className="row">
							<div className="offset-lg-2 col-lg-6 col-md-8 col-12 py-5 mt-5 ">
								<div className="welcome-text "> 
									<div className="hero-text">
										<h2  style={{textTransform: "normal", fontSize:'3.5em', color: '#fff', lineHeight:'1em'}}>Abonnez-vous à la newsletter de Equerre</h2>
											{/* <p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em', color: '#fff',lineHeight:'1.2em', fontWeight: 'normal'}}>Vous voulez explorer un monde d’excellence, Equerre est fait pour vous.</p> */}
										<div className="button">       
											{/* <a href="/equerre-hr-1000#video-feature"  className="bizwheel-btn theme-1 effect mt-lg-3 primary-color">Regardez la vidéo de présentation</a>
											<a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3">Téléchargez la brochure</a> */}
										</div>
									</div>
								</div>
							</div>
                            <div className="col-lg-5 col-md-6 col-12">
                                <NewsletterForm />
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>

    </div>
  )
}

export default withNamespaces() (Newsletter)