import React from 'react'

function ProductList() {
  return (
    <>

        <section className='bg-dark py-5 products-list-page'>
            <div className='container-lg'>
                <div className='row mb-5'>
                    <div className='col-12'>
                        <h1 className='text-white text-center'>Nos produits sont des outils cohérents qui permettent d’avoir une vue d’ensemble bien structurée de votre entreprise</h1>
                    </div>
                </div>
                <div className='row'>
                    
                    
                <div className="col-lg-3" >
                                          
                    <div className="row">
                        <a>
                            <h4 className='sub-menu-head text-white mb-4'>Gestion financière et commerciale</h4>
                        </a>
                        <div className="column pr-5 mt-2" >
                            <a href="/equerre-smb-50"><h5 className='sub-menu-item'>Equerre SMB 50</h5></a>
                            <p>Devis & Factures,</p>
                            <p> bons de commandes et   
                            livraisons, 
                            </p>
                            <p> Bilans/Comptes de résultats</p>
                            <p> gestion de la trésorerie et des  
                            immobilisations,
                            </p>
                            <p>gestion des achats, des stocks  
                            et inventaires…
                            </p>
                        </div>
                        <div className="column pr-5 mt-4">
                            <a href="/equerre-smb-100"><h5 className='sub-menu-item'>Equerre SMB 100</h5></a>
                            <p>Devis, commandes, factures, stocks, marketing, force de vente, service client, comptabilité générale, auxiliaire, analytique et budgétaire, gestion des immobilisations, inventaires …</p>
                        </div>
                        </div>

                    </div>

                    <div className="col-lg-3">
                        <div className="row">
                        <a>
                            <h4 className='pr-5 sub-menu-head text-white mb-4'>Ressources humaines et paie</h4>
                        </a>
                        <div className="column pr-5 mt-2">
                            <a href="/equerre-hr-1000#"><h5 className='sub-menu-item'>Equerre RH 1000</h5></a>
                            <p>Gestion administrative des employés, gestion de la paie du personnel, structure organisationnelle, 
                                recrutements, formation, carrière, gestion des évaluations, Self-service du personnel …
                                </p>
                            </div>
                        <div className="column pr-5 mt-4" href="/equerre-paie-500">
                            <a href='#'><h5 className='sub-menu-item'>Equerre Paie 500</h5></a>
                            <p>Fichier du personnel,
                            contrats, gestion de la paie inverse, prêts, bonus, ancienneté gelée, self-service du personnel, …
                            </p>
                        </div>
                        </div>

                    </div>

                    <div className="col-lg-3">
                        
                        <div className="row">
                        <a> 
                            <h4 className='sub-menu-head text-white mb-4 pr-5'>ERP et gestion d’entreprise</h4>
                        </a>
                        <div className="column pr-5 mt-2">
                            <a href='/equerre_cloud_erp'><h5 className='sub-menu-item'>Equerre Cloud ERP</h5></a>
                            <p>Comptabilité, Budget, immobilisations, inventaires, achats, ventes, stocks, marketing, force de vente, Service client, ressources humaines, paie…</p>
                        </div>
                        <a className="column pr-5 mt-2" >
                            <a href='/equerre_sales_crm'><h5 className='sub-menu-item'>Equerre vente et CRM</h5></a>
                            <p>Ventes, stocks, marketing, force de vente, Service client…</p>
                        </a>
                        <a className="column pr-5 mt-2">
                            <a href='/equerre-Finance-1000'><h5 className='sub-menu-item'>Equerre Finance 1000</h5></a>
                            <p>Comptabilité, Budget, immobilisations, inventaires…</p>
                        </a>
                        </div>

                    </div>

                    <div className="col-lg-3 pl-3">
                        
                        <div className="row">
                        <a>
                            <h4 className='sub-menu-head text-white mb-4'>Autres produits <span style={{visibility: 'hidden'}}>_________________</span></h4>
                        </a>
                        <div className="column pr-5 mt-2" >
                            <a href='/equerre_project_management'><h5 className='sub-menu-item'>Equerre gestion de projet</h5></a>
                        </div>
                            
                        <a className="column pr-5 mt-2">
                            <a href='/equerre_pos'><h5 className='sub-menu-item mt-0'>Equerre POS </h5></a>
                        </a>
                            
                        <a className="column pr-5 mt-2">
                            <a href='/equerre_gdoc'><h5 className='sub-menu-item mt-0'>Equerre Gdoc </h5></a>
                        </a>
                            
                        <a className="column pr-5 mt-2">
                            <a href='/equerre_health'><h5 className='sub-menu-item mt-0'>Equerre Santé</h5></a>
                        </a>
                            
                        <a className="column pr-5 mt-2">
                            <a href='/equerre_cloud_hotel'><h5 className='sub-menu-item mt-0'>Equerre Cloud Hôtel </h5></a>
                        </a>
                        <a className="column pr-5 mt-4">
                            <a href='#'><h5 className='sub-menu-item mkt-place-sub-menu'>Market place</h5></a>
                            <p>Accédez à notre </p>
                            <p>plateforme SaaS</p>
                        </a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        
    </>
  )
}

export default ProductList