import React from 'react'

function HomePageBlock4() {
  return (
    <section
      className="saas-block  third-block hero_img_block"
      style={{ backgroundImage: 'linear-gradient(rgba(47, 47, 74, .85), rgba(0, 0, 0, .6)), url("/assets/img/home-block-2-img-2.jpg")' , borderBottom: '5px solid #fff'}}
    >
      <div className="container">
			<div className="row align-items-center">
				<div className="col-lg-12 col-md-8 col-12">
					<div className="welcome-text my-5"> 
						<div className="hero-text d-flex"> 
							<h2 style={{fontSize:'4em', lineHeight:'initial', color: 'white'}}>Equerre Market place</h2>
							<p className="mt-4" style={{fontSize:'3em', wordSpacing: '.2em',lineHeight:'initial', color: '#fff', fontWeight: 'normal'}}>Un accès direct et privilégié à notre plateforme SaaS
							</p>
							<h2 className='m-4' style={{fontSize:'3em', lineHeight:'initial', color: '#009ADE'}}>La gestion 100% Cloud</h2>
							<div className="button mt-4">
								<a href="#"   className="bizwheel-btn theme-bloc-4 primary-color" type="button" data-toggle="modal" data-target="#myMarketplaceModal">Logiciels de gestion </a>
								<div className='col-lg-2 d-inline' ></div>
								<a href="#"   className="bizwheel-btn theme-bloc-4 primary-color mt-lg-0 mt-3" type="button" data-toggle="modal" data-target="#myMarketplaceModal">Logiciels spécialisés  </a>
							</div>
						</div>
					</div>
				</div>
			</div>
      </div>

    </section>
	
  )
}

export default HomePageBlock4