import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { FooterSummary } from '../../components';

function AboutUs(props) {
  const { t } = props
  return (
    <>
        <Helmet>
            <title>Equerre AboutUs</title>
            <meta name="description" content="About us description"/>
            <meta property="og:title"content="Equerre AboutUs"/>
            <meta
            property="og:description"
            content="About us description"/>
            <link rel="canonical" href="https://www.equerre.com/about_us" />
            <meta property="og:image"  content="/assets/img/about_us_1.jpg"/>
            <meta name="twitter:image" content="/assets/img/about_us_1.jpg"/>
        </Helmet>
        
        <section>
            
        <div className='container'>
            <div className='row' style={{marginBottom: '4rem'}}>
                <div className='col-6 my-4 py-2 '>
                    
                <h1 style={{lineHeight: 'em', fontWeight: 'lighter', color: '#000', marginBottom: '1.5rem'}}></h1>

<h2 style={{lineHeight: 'em', fontWeight: 'lighter', color: '#000', marginBottom: '1.5rem'}}>{t("aboutUs_page_title_a")}</h2>
                                      <p style={{color: 'rgba(0,0,0,0.5)'}}>{t("aboutUs_page_paragraph_a")} </p>
<h2 style={{lineHeight: 'em', fontWeight: 'lighter', color: '#000', marginBottom: '1.5rem'}}>{t("aboutUs_page_title_b")}</h2>
                                       <p style={{color: 'rgba(0,0,0,0.5)'}}> {t("aboutUs_page_paragraph_b")}<br/>{t("aboutUs_page_paragraph_ba")}</p>
<h2 style={{lineHeight: 'em', fontWeight: 'lighter', color: '#000', marginBottom: '1.5rem'}}>{t("aboutUs_page_title_c")}</h2>                                      
                                       <p style={{color: 'rgba(0,0,0,0.5)'}}>{t("aboutUs_page_paragraph_c")}</p>
                </div>
                <div className='col-6' style={{backgroundImage:"url('/assets/img/about_us_1.jpg')",  backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat', }}>
                
                </div>
            </div>
        </div>
        </section>


      {/* Call To Action */}
      <section
        className="call-action"
         style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url('/assets/img/about_us_1.jpg')", backgroundSize: '100% auto',backgroundRepeat: 'no-repeat',padding: '5rem 0' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="call-inner">
                <h5 style={{ color: "#fff" }}>
                 {t("aboutUs_page_bloc_titre")}
                </h5>
                <p>
                {t("aboutUs_page_bloc_paragraph")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="button">
                <a
                  href="#brochure_unavailable_yet"
                  className="bizwheel-btn theme-1 effect"
                >
                  {t("aboutUs_page_bloc_button")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End Call to action */}
        
      {/* features */}


      <div style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row">
            {/* title */}
            <div className="col-12">
              <div className="section-title style2 text-center mb-0" style={{ padding: "0 2.4em", backgroundColor: "#fff" }}>
                
                <div className="section-bottom">
                  <div className="text-style-two botton"></div>
                </div>
              </div>
            </div>
            {/* end title */}
            <div
              className="col-4"
              style={{ padding: "2em 3em", backgroundColor: "#fff" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: ".6em",
                  color: "#fff",
                  backgroundColor: "var(--blue)",
                  height: "3em",
                  width: "3em",
                  borderRadius: "50%",
                }}
                className="key_features_nbr"
              >
                01
              </div>
              <h5 style={{ color: "#000" }}>{t("aboutUs_page_titre2_a")} </h5>
              <p>
              {t("aboutUs_page_paragraph2_a")}
              </p>
              
      <div className="button mt-3">
        <a className="bizwheel-btn theme-3" href="contact.html">{t("aboutUs_page_button_a")}</a>
      </div>
            </div>
            <div
              className="col-4 mb-2"
              style={{ padding: "2em 3em", backgroundColor: "#fff" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: ".6em",
                  color: "#fff",
                  backgroundColor: "var(--blue)",
                  height: "3em",
                  width: "3em",
                  borderRadius: "50%",
                }}
                className="key_features_nbr"
              >
                02
              </div>
              <h5 style={{ color: "#000" }}>{t("aboutUs_page_titre2_b")}</h5>
              <p>
              {t("aboutUs_page_paragraph2_b")}
              </p>
              
      <div className="button mt-3">
        <a className="bizwheel-btn theme-3" href="contact.html">{t("aboutUs_page_button_b")}</a>
      </div>
            </div>
            <div
              className="col-4"
              style={{ padding: "2em 3em", backgroundColor: "#fff" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: ".6em",
                  color: "#fff",
                  backgroundColor: "var(--blue)",
                  height: "3em",
                  width: "3em",
                  borderRadius: "50%",
                }}
                className="key_features_nbr"
              >
                03
              </div>
              <h5 style={{ color: "#000" }}>{t("aboutUs_page_titre2_c")}</h5>
              <p>
              {t("aboutUs_page_paragraph2_c")}
              </p>
              
            <div className="button mt-3">
                <a className="bizwheel-btn theme-3" href="contact.html">{t("aboutUs_page_button_c")}</a>
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* emd features */}
      
         {/* card */}
         
         <div className="collapse cartContainer" id="collapseExample">
            <div
              className="sticky-card p-1 fixed-bottom stickyAddCart d-flex align-items-center justify-content-space-between"
              id="stickyCard"
              hidden={true}
            >
              <FooterSummary link={"/marketplace"} title={"MarketPlace"} />
            </div>
          </div>
         {/* end cart */}

    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (AboutUs))