export {default as Home } from "./Home/Home"
export {default as registration} from './Registration/Registration'
export {default as Pricing} from "./Pricing/Pricing"
export {default as CheckOut} from "./CheckOut/CheckOut"
export {default as SuccessCheckout} from "./SuccessCheckout/SuccessCheckout"
export {default as SignUp} from "./SignUp/SignUp"
export {default as SignIn} from "./SignIn/SignIn"
export {default as Dashboard} from "./Dashboard/Dashboard"
export {default as Module} from "./Module/Module"
export {default as ModuleDetails} from "./ModuleDetails/ModuleDetails"
export {default as LicenceProduct} from './LicenceProduct/LicenceProduct'
export {default as HomePage} from './HomePage/HomePage'
export {default as LicensePage1} from './LicensePage1/LicensePage1'
export {default as Marketplace} from './Marketplace/Marketplace'
export {default as OurProduct} from './OurProduct/OurProduct'
export {default as OurProductDetailsbudget} from './OurProductDetailsbudget/OurProductDetailsbudget'
export {default as OurProductSupplyChain} from './OurProductSupplyChain/OurProductSupplyChain'
export {default as OurProductHrManagement} from './OurProductHrManagement/OurProductHrManagement'
export {default as OurProductFinancialManagement} from './OurProductFinancialManagement/OurProductFinancialManagement'
export {default as OurProductCrmAndSales} from './OurProductCrmAndSales/OurProductCrmAndSales'
export {default as OurProductReportingAndDashboard} from './OurProductReportingAndDashboard/OurProductReportingAndDashboard'
export {default as OpEquerreProjectManagement} from './OpEquerreProjectManagement/OpEquerreProjectManagement'
export {default as OpEquerreGDoc} from './OpEquerreGDoc/OpEquerreGDoc'
export {default as OpEquerreCloudHotel} from './OpEquerreCloudHotel/OpEquerreCloudHotel'
export {default as OpEquerreHealth} from './OpEquerreHealth/OpEquerreHealth'
export {default as OpEquerreMarketplace} from './OpEquerreMarketplace/OpEquerreMarketplace'
export {default as AboutUs} from './AboutUs/AboutUs'
export {default as ContactUs} from './ContactUs/ContactUs'
export {default as EquerreSMB50} from './EquerreSMB50/EquerreSMB50'
export {default as EquerreSMB100} from './EquerreSMB100/EquerreSMB100'
export {default as EquerreRH1000} from './EquerreRH1000/EquerreRH1000'
export {default as Career} from './Career/Career'
export {default as AboutUsCst} from './AboutUsCst/AboutUsCst'
export {default as BecomePartner} from './BecomePartner/BecomePartner'
export {default as EquerrePOS} from './EquerrePOS/EquerrePOS'
export {default as EquerreGdoc} from './EquerreGdoc/EquerreGdoc'
export {default as EquerreHealth} from './EquerreHealth/EquerreHealth'
export {default as EquerreCloudHotel} from './EquerreCloudHotel/EquerreCloudHotel'
export {default as EquerreProjectManagement} from './EquerreProjectManagement/EquerreProjectManagement'
export {default as EquerreCloudERP} from './EquerreCloudERP/EquerreCloudERP'
export {default as EquerrePaie500} from './EquerrePaie500/EquerrePaie500'
export {default as CareerBackEndDeveloper} from './CareerBackEndDeveloper/CareerBackEndDeveloper'
export {default as CareerBusinessDeveloperSenior} from './CareerBusinessDeveloperSenior/CareerBusinessDeveloperSenior'
export {default as CareerBusinessDeveloperJunior} from './CareerBusinessDeveloperJunior/CareerBusinessDeveloperJunior.js'
export {default as CareerFrontEndDeveloper} from './CareerFrontEndDeveloper/CareerFrontEndDeveloper.js'
export {default as Actuality} from './Actuality/Actuality'
export {default as Newsletter} from './Newsletter/Newsletter'
export {default as ProductList} from './ProductList/ProductList'
export {default as EquerreSalesCRM} from './EquerreSalesCRM/EquerreSalesCRM'
export {default as SideMap} from './SideMap/SideMap'
export {default as MentionLegale} from './MentionLegale/MentionLegale'
export {default as EquerreFinance1000} from './EquerreFinance1000/EquerreFinance1000'
export {default as EquerreGpao} from './EquerreGpao/EquerreGpao'