import React from 'react'
import { connect } from 'react-redux'

export const LicenceProduct = (props) => {
  return (
    <div>
  {/* Hero Slider */}
    <section className="">
            <div className="home-hero" style={{ backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.75), transparent), url('./assets/img/slider-image1.jpg')" }}>
                
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-7 col-md-8 col-12">
                            <div className="welcome-text"> 
                                <div className='hero-logo'><img src='./assets/img/logo-equerre-cloud-erp.gif'/></div>
                                <div className="hero-text mt-4"> 										
                                    <h1 style={{color: '#fff'}}>Un système ERP complet disponible Sur Cloud public ou privé, ou dans un environnement hybride  </h1>
                                    <div className="button mt-4">
                                        <a href="/contact_us" className="bizwheel-btn theme-1 effect">Découvrez EquerreCloud ERP</a>
                                        <a href="/contact_us" className="bizwheel-btn theme-1 effect">Demandez une demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 right-img'>
                            {/* <img src='./assets/img/hero-img/hero-img-1.jpg'/> */}
                        </div>
                    </div>
                </div>
            </div>
    </section>
  {/*/ End Hero Slider */}
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Feature */}
          {/*/ End Single Feature */}
        </div>
  {/* Features Area */}
  <section className="features-area section-bg">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Feature */}
          <div className="single-feature">
            <div className="icon-head">
              <i className="fa fa-podcast" />
            </div>
            <h4>
              <a href="https://picmaticweb.com/html/bizwheel/service-single.html">
                Creative Design
              </a>
            </h4>
            <p>
              Aenean aliquet rutrum enimn scelerisque. Nam dictumanpo, antequis
              laoreet ullamcorper, velitsd odio scelerisque tod
            </p>
            <div className="button">
              <a
                href="https://picmaticweb.com/html/bizwheel/service-single.html"
                className="bizwheel-btn"
              >
                <i className="fa fa-arrow-circle-o-right" />
                Learn More
              </a>
            </div>
          </div>
          {/*/ End Single Feature */}
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Feature */}
          <div className="single-feature">
            <div className="icon-head">
              <i className="fa fa-podcast" />
            </div>
            <h4>
              <a href="https://picmaticweb.com/html/bizwheel/service-single.html">
                Quality Service
              </a>
            </h4>
            <p>
              Aenean aliquet rutrum enimn scelerisque. Nam dictumanpo, antequis
              laoreet ullamcorper, velitsd odio scelerisque tod
            </p>
            <div className="button">
              <a
                href="https://picmaticweb.com/html/bizwheel/service-single.html"
                className="bizwheel-btn"
              >
                <i className="fa fa-arrow-circle-o-right" />
                Learn More
              </a>
            </div>
          </div>
          {/*/ End Single Feature */}
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Feature */}
          <div className="single-feature active">
            <div className="icon-head">
              <i className="fa fa-podcast" />
            </div>
            <h4>
              <a href="https://picmaticweb.com/html/bizwheel/service-single.html">
                On-time Delivery
              </a>
            </h4>
            <p>
              Aenean aliquet rutrum enimn scelerisque. Nam dictumanpo, antequis
              laoreet ullamcorper, velitsd odio scelerisque tod
            </p>
            <div className="button">
              <a
                href="https://picmaticweb.com/html/bizwheel/service-single.html"
                className="bizwheel-btn"
              >
                <i className="fa fa-arrow-circle-o-right" />
                Learn More
              </a>
            </div>
          </div>
          {/*/ End Single Feature */}
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Feature */}
          <div className="single-feature">
            <div className="icon-head">
              <i className="fa fa-podcast" />
            </div>
            <h4>
              <a href="https://picmaticweb.com/html/bizwheel/service-single.html">
                24/7 Live support
              </a>
            </h4>
            <p>
              Aenean aliquet rutrum enimn scelerisque. Nam dictumanpo, antequis
              laoreet ullamcorper, velitsd odio scelerisque tod
            </p>
            <div className="button">
              <a
                href="https://picmaticweb.com/html/bizwheel/service-single.html"
                className="bizwheel-btn"
              >
                <i className="fa fa-arrow-circle-o-right" />
                Learn More
              </a>
            </div>
          </div>
          {/*/ End Single Feature */}
        </div>
      </div>
    </div>
  </section>
  {/*/ End Features Area */}
  {/* Video Feature */}
  <section className="video-feature side overlay section-bg">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="img-feature ">
            <img
              src="/assets/img/why-choose.jpg"
              alt="Video Thumbnail"
            />
            <div className="video-play">
              <a
                href="https://www.youtube.com/watch?v=RLlPLqrw8Q4"
                className="video video-popup mfp-iframe"
              >
                <i className="fa fa-play" />
              </a>
              <div className="waves-block">
                <div className="waves wave-1" />
                <div className="waves wave-2" />
                <div className="waves wave-3" />
              </div>
            </div>
            <span>Watch 2 minutes</span>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div className="features-main ">
            <div className="title">
              <h2>Our Best Features</h2>
              <p>
                Our studio is not just about graphic design; it’s more than
                that. We offer communication services, and we’re responsible
              </p>
            </div>
            <div className="b-features">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  {/* Single List Feature */}
                  <div className="single-list-feature">
                    <i className="fa fa-code" />
                    <h4>Web Development</h4>
                    <p>
                      Molitia modi quae labori osam nemo minimaafb services
                      dafeandable
                    </p>
                  </div>
                  {/*/ End Single List Feature */}
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  {/* Single List Feature */}
                  <div className="single-list-feature">
                    <i className="fa fa-html5" />
                    <h4>Creative Design</h4>
                    <p>
                      Molitia modi quae labori osam nemo minimaafb services
                      dafeandable
                    </p>
                  </div>
                  {/*/ End Single List Feature */}
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  {/* Single List Feature */}
                  <div className="single-list-feature">
                    <i className="fa fa-newspaper-o" />
                    <h4>Quality Service</h4>
                    <p>
                      Molitia modi quae labori osam nemo minimaafb services
                      dafeandable
                    </p>
                  </div>
                  {/*/ End Single List Feature */}
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  {/* Single List Feature */}
                  <div className="single-list-feature">
                    <i className="fa fa-coffee" />
                    <h4>Without Hassle</h4>
                    <p>
                      Molitia modi quae labori osam nemo minimaafb services
                      dafeandable
                    </p>
                  </div>
                  {/*/ End Single List Feature */}
                </div>
              </div>
            </div>
            <div className="feature-btn">
              <a
                href="/our_products"
                className="bizwheel-btn theme-2"
              >
                Know More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/ End Video Feature */}
  {/* Services */}
  <section className="services section-bg section-space">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="section-title style2 text-center">
            <div className="section-top">
              <h1>
                <span>Creative</span>
                <b>Service We Provide</b>
              </h1>
              <h4>We provide quality service &amp; support..</h4>
            </div>
            <div className="section-bottom">
              <div className="text-style-two">
                <p>
                  Aliquam Sodales Justo Sit Amet Urna Auctor Scelerisquinterdum
                  Leo Anet Tempus Enim Esent Egetis Hendrerit Vel Nibh Vitae
                  Ornar Sem Velit Aliquam
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-12">
          {/* Single Service */}
          <div className="single-service">
            <div className="service-head">
              <div className="icon-bg">
                <i className="fa fa-handshake-o" />
              </div>
              <img
                src="/assets/img/service-01.jpg"
                alt="#"
              />
            </div>
            <div className="service-content">
              <h4>
                <a href="#">
                  Business Strategy
                </a>
              </h4>
              <p>
                Cras venenatis, purus sit amet tempus mattis, justo nisi
                facilisis metus, in tempus ipsum ipsum eu ipsum. Class aptent
                taciti
              </p>
              <a
                className="btn"
                href="#"
              >
                <i className="fa fa-arrow-circle-o-right" />
                View Service
              </a>
            </div>
          </div>
          {/*/ End Single Service */}
        </div>
        <div className="col-lg-4 col-md-4 col-12">
          {/* Single Service */}
          <div className="single-service">
            <div className="service-head">
              <div className="icon-bg">
                <i className="fa fa-html5" />
              </div>
              {/* <div className="featured">Featured</div> */}
              <img
                src="/assets/img/service-02.jpg"
                alt="#"
              />
            </div>
            <div className="service-content">
              <h4>
                <a href="https://picmaticweb.com/html/bizwheel/service-develop.html">
                  Web Development
                </a>
              </h4>
              <p>
                Cras venenatis, purus sit amet tempus mattis, justo nisi
                facilisis metus, in tempus ipsum ipsum eu ipsum. Class aptent
                taciti
              </p>
              <a
                className="btn"
                href="https://picmaticweb.com/html/bizwheel/service-develop.html"
              >
                <i className="fa fa-arrow-circle-o-right" />
                View Service
              </a>
            </div>
          </div>
          {/*/ End Single Service */}
        </div>
        <div className="col-lg-4 col-md-4 col-12">
          {/* Single Service */}
          <div className="single-service">
            <div className="service-head">
              <div className="icon-bg">
                <i className="fa fa-cube" />
              </div>
              <img
                src="/assets/img/service-03.jpg"
                alt="#"
              />
            </div>
            <div className="service-content">
              <h4>
                <a href="https://picmaticweb.com/html/bizwheel/service-market.html">
                  Market Research
                </a>
              </h4>
              <p>
                Cras venenatis, purus sit amet tempus mattis, justo nisi
                facilisis metus, in tempus ipsum ipsum eu ipsum. Class aptent
                taciti
              </p>
              <a
                className="btn"
                href="https://picmaticweb.com/html/bizwheel/service-market.html"
              >
                <i className="fa fa-arrow-circle-o-right" />
                View Service
              </a>
            </div>
          </div>
          {/*/ End Single Service */}
        </div>
      </div>
    </div>
  </section>
  {/*/ End Services */}
  {/* Call To Action */}
  <section
    className="call-action overlay"
    style={{ backgroundImage: 'url("./assets/img/cta-bg.jpg")' }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-9 col-12">
          <div className="call-inner">
            <h2>Brand Products &amp; Creativity is our Fashion</h2>
            <p>
              ehicula maximus velit. Morbi non tincidunt purus, a hendrerit
              nisi. Vivamus elementum
            </p>
          </div>
        </div>
        <div className="col-lg-3 col-12">
          <div className="button">
            <a
              href="#brochure_unavailable_yet"
              className="bizwheel-btn"
            >
              Download our brochure
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/ End Call to action */}
  {/* Portfolio */}
  <section className="portfolio section-space">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
          <div className="section-title default text-center">
            <div className="section-top">
              <h1>
                <span>Project</span>
                <b>Our Works</b>
              </h1>
            </div>
            <div className="section-bottom">
              <div className="text">
                <p>
                  Lorem Ipsum Dolor Sit Amet, Conse Ctetur Adipiscing Elit, Sed
                  Do Eiusmod Tempor Ares Incididunt Utlabore. Dolore Magna Ones
                  Baliqua
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="portfolio-menu">
            {/* Portfolio Nav */}
            <ul
              id="portfolio-nav"
              className="portfolio-nav tr-list list-inline cbp-l-filters-work"
            >
              <li data-filter="*" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item active">
                All
              </li>
              <li data-filter=".animation" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item">
                Animation
              </li>
              <li data-filter=".branding" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item">
                Branding
              </li>
              <li data-filter=".business" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item">
                Business
              </li>
              <li data-filter=".consulting" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item">
                Consulting
              </li>
              <li data-filter=".marketing" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item">
                Marketing
              </li>
              <li data-filter=".seo" style={{backgroundColor: '#f7f8fc'}} className="cbp-filter-item">
                SEO
              </li>
            </ul>
            {/*/ End Portfolio Nav */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="portfolio-main">
            <div
              id="portfolio-item"
              className="portfolio-item-active cbp cbp-caption-active cbp-caption-overlayBottomPush cbp-ready"
              style={{ height: 662 }}
            >
              <div className="cbp-wrapper-outer">
                <div className="cbp-wrapper">
                  <div
                    className="cbp-item business animation"
                    style={{ width: 360, left: 0, top: 0 }}
                  >
                    <div className="cbp-item-wrapper">
                      {/* Single Portfolio */}
                      <div className="single-portfolio">
                        <div className="portfolio-head overlay">
                          <img
                            src="/assets/img/portfolio-1.jpg"
                            alt="#"
                          />
                          <a
                            className="more"
                            href="https://picmaticweb.com/html/bizwheel/portfolio-single.html"
                          >
                            <i className="fa fa-long-arrow-right" />
                          </a>
                        </div>
                        <div className="portfolio-content">
                          <h4>
                            <a href="https://picmaticweb.com/html/bizwheel/portfolio-single.html">
                              Creative Marketing
                            </a>
                          </h4>
                          <p>Business, Aniamtion</p>
                        </div>
                      </div>
                      {/*/ End Single Portfolio */}
                    </div>
                  </div>
                  <div
                    className="cbp-item seo consulting"
                    style={{ width: 360, left: 390, top: 0 }}
                  >
                    <div className="cbp-item-wrapper">
                      {/* Single Portfolio */}
                      <div className="single-portfolio">
                        <div className="portfolio-head overlay">
                          <img
                            src="/assets/img/portfolio-2.jpg"
                            alt="#"
                          />
                          <a
                            className="more"
                            href="https://picmaticweb.com/html/bizwheel/portfolio-single.html"
                          >
                            <i className="fa fa-long-arrow-right" />
                          </a>
                        </div>
                        <div className="portfolio-content">
                          <h4>
                            <a href="https://picmaticweb.com/html/bizwheel/portfolio-single.html">
                              Creative Marketing
                            </a>
                          </h4>
                          <p>Seo, Consulting</p>
                        </div>
                      </div>
                      {/*/ End Single Portfolio */}
                    </div>
                  </div>
                  <div
                    className="cbp-item marketing seo"
                    style={{ width: 360, left: 780, top: 0 }}
                  >
                    <div className="cbp-item-wrapper">
                      {/* Single Portfolio */}
                      <div className="single-portfolio">
                        <div className="portfolio-head overlay">
                          <img
                            src="/assets/img/portfolio-3.jpg"
                            alt="#"
                          />
                          <a
                            className="more"
                            href="https://picmaticweb.com/html/bizwheel/portfolio-single.html"
                          >
                            <i className="fa fa-long-arrow-right" />
                          </a>
                        </div>
                        <div className="portfolio-content">
                          <h4>
                            <a href="https://picmaticweb.com/html/bizwheel/portfolio-single.html">
                              Creative Marketing
                            </a>
                          </h4>
                          <p>Marketing, SEO</p>
                        </div>
                      </div>
                      {/*/ End Single Portfolio */}
                    </div>
                  </div>
                  <div
                    className="cbp-item animation branding"
                    style={{ width: 360, left: 0, top: 346 }}
                  >
                    <div className="cbp-item-wrapper">
                      {/* Single Portfolio */}
                      <div className="single-portfolio">
                        <div className="portfolio-head overlay">
                          <img
                            src="/assets/img/portfolio-4.jpg"
                            alt="#"
                          />
                          <a
                            className="more"
                            href="https://picmaticweb.com/html/bizwheel/portfolio-single.html"
                          >
                            <i className="fa fa-long-arrow-right" />
                          </a>
                        </div>
                        <div className="portfolio-content">
                          <h4>
                            <a href="https://picmaticweb.com/html/bizwheel/portfolio-single.html">
                              Creative Marketing
                            </a>
                          </h4>
                          <p>Animation, Branding</p>
                        </div>
                      </div>
                      {/*/ End Single Portfolio */}
                    </div>
                  </div>
                  <div
                    className="cbp-item branding consulting"
                    style={{ width: 360, left: 390, top: 346 }}
                  >
                    <div className="cbp-item-wrapper">
                      {/* Single Portfolio */}
                      <div className="single-portfolio">
                        <div className="portfolio-head overlay">
                          <img
                            src="/assets/img/portfolio-5.jpg"
                            alt="#"
                          />
                          <a
                            className="more"
                            href="https://picmaticweb.com/html/bizwheel/portfolio-single.html"
                          >
                            <i className="fa fa-long-arrow-right" />
                          </a>
                        </div>
                        <div className="portfolio-content">
                          <h4>
                            <a href="https://picmaticweb.com/html/bizwheel/portfolio-single.html">
                              Creative Marketing
                            </a>
                          </h4>
                          <p>Branding, Consulting</p>
                        </div>
                      </div>
                      {/*/ End Single Portfolio */}
                    </div>
                  </div>
                  <div
                    className="cbp-item business marketing"
                    style={{ width: 360, left: 780, top: 346 }}
                  >
                    <div className="cbp-item-wrapper">
                      {/* Single Portfolio */}
                      <div className="single-portfolio">
                        <div className="portfolio-head overlay">
                          <img
                            src="/assets/img/portfolio-6.jpg"
                            alt="#"
                          />
                          <a
                            className="more"
                            href="https://picmaticweb.com/html/bizwheel/portfolio-single.html"
                          >
                            <i className="fa fa-long-arrow-right" />
                          </a>
                        </div>
                        <div className="portfolio-content">
                          <h4>
                            <a href="https://picmaticweb.com/html/bizwheel/portfolio-single.html">
                              Creative Marketing
                            </a>
                          </h4>
                          <p>Business</p>
                        </div>
                      </div>
                      {/*/ End Single Portfolio */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/ End Portfolio */}
  {/* Testimonials */}
  <section
    className="testimonials section-space"
    style={{ backgroundImage: 'url("./assets/img/testimonial-bg.jpg")' }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-9 col-12">
          <div className="section-title default text-left">
            <div className="section-top">
              <h1>
                <b>Our Satisfied Clients</b>
              </h1>
            </div>
            <div className="section-bottom">
              <div className="text">
                <p>some of our great clients and their review</p>
              </div>
            </div>
          </div>
          <div className="testimonial-inner">
            <div className="testimonial-slider owl-carousel owl-theme owl-loaded">
              {/* Single Testimonial */}
              {/* / End Single Testimonial */}
              {/* Single Testimonial */}
              {/* / End Single Testimonial */}
              {/* Single Testimonial */}
              {/* / End Single Testimonial */}
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(-1110px, 0px, 0px)",
                    transition: "all 0s ease 0s",
                    width: 3885
                  }}
                >
                  <div
                    className="owl-item cloned"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi2.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Buman Panama <span>Founder, Jolace Group</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi1.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Donald Bonam <span>Developer, Soft IT</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item active"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi1.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Julias Dien <span>CEO / Creative IT</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi2.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Buman Panama <span>Founder, Jolace Group</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi1.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Donald Bonam <span>Developer, Soft IT</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi1.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Julias Dien <span>CEO / Creative IT</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="owl-item cloned"
                    style={{ width: 555, marginRight: 0 }}
                  >
                    <div className="single-slider">
                      <ul className="star-list">
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                        <li>
                          <i className="fa fa-star" />
                        </li>
                      </ul>
                      <p>
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the{" "}
                        <strong>industry's standard</strong> dummy text ever
                        sinsimply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                      </p>
                      {/* Client Info */}
                      <div className="t-info">
                        <div className="t-left">
                          <div className="client-head">
                            <img
                              src="/assets/img/testi2.png"
                              alt="#"
                            />
                          </div>
                          <h2>
                            Buman Panama <span>Founder, Jolace Group</span>
                          </h2>
                        </div>
                        <div className="t-right">
                          <div className="quote">
                            <i className="fa fa-quote-right" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="owl-controls">
                <div className="owl-nav">
                  <div className="owl-prev" style={{ display: "none" }}>
                    prev
                  </div>
                  <div className="owl-next" style={{ display: "none" }}>
                    next
                  </div>
                </div>
                <div style={{}} className="owl-dots">
                  <div className="owl-dot active">
                    <span />
                  </div>
                  <div className="owl-dot">
                    <span />
                  </div>
                  <div className="owl-dot">
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/ End Testimonials */}
  {/* Counterup */}
  <section className="counterup">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Counterup */}
          <div className="single-counter">
            <div className="icon">
              <i className="fa fa-book" />
            </div>
            <div className="conter-content">
              <div className="counter-head">
                <h3>
                  <b className="number">105</b>
                  <span>K</span>
                </h3>
              </div>
              <p>Complete Works</p>
            </div>
          </div>
          {/*/ End Single Counterup */}
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Counterup */}
          <div className="single-counter">
            <div className="icon">
              <i className="fa fa-users" />
            </div>
            <div className="conter-content">
              <div className="counter-head">
                <h3>
                  <b className="number">5999</b>
                  <span>+</span>
                </h3>
              </div>
              <p>Happy clients</p>
            </div>
          </div>
          {/*/ End Single Counterup */}
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Counterup */}
          <div className="single-counter">
            <div className="icon">
              <i className="fa fa-life-ring" />
            </div>
            <div className="conter-content">
              <div className="counter-head">
                <h3>
                  <b className="number">5988</b>
                  <span>+</span>
                </h3>
              </div>
              <p>Support Ticket</p>
            </div>
          </div>
          {/*/ End Single Counterup */}
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          {/* Single Counterup */}
          <div className="single-counter">
            <div className="icon">
              <i className="fa fa-fire" />
            </div>
            <div className="conter-content">
              <div className="counter-head">
                <h3>
                  <b className="number">8569</b>
                  <span>+</span>
                </h3>
              </div>
              <p>Company Work</p>
            </div>
          </div>
          {/*/ End Single Counterup */}
        </div>
      </div>
    </div>
  </section>
  {/*/ End Counterup */}
  {/* Our Team */}
    <section className="team section-bg section-space">
    <div className="container">
        <div className="row">
        <div className="col-12">
            <div className="section-title  style2 text-center">
            <div className="section-top">
                <h1><span>leaders</span><b>Creative Team</b></h1>
                <h4>Our experts leaders are waiting for you.</h4>
            </div>
            <div className="section-bottom">
                <div className="text-style-two">
                <p>Aliquam Sodales Justo Sit Amet Urna Auctor Scelerisquinterdum Leo Anet Tempus
                    Enim Esent Egetis Hendrerit Vel Nibh Vitae Ornar Sem Velit Aliquam</p>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div className="team-slider">
        <div className="single-slider">
            {/* Single Team */}
            <div className="single-team">
            <div className="team-head">
                <img src="/assets/img/team-1.jpg" alt="#" />
                <ul className="team-social">
                <li><a href="#"><i className="fa fa-facebook-official" /></a></li>
                <li><a href="#"><i className="fa fa-twitter-square" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                <li><a href="#"><i className="fa fa-dribbble" /></a></li>
                </ul>
            </div>
            <div className="t-content">
                <div className="content-inner">
                <h4 className="name"><a href="#">Camdeaz Pour</a></h4>
                <span className="designation">Ceo- Founder/ Designer</span>
                </div>
            </div>
            </div>
            {/*/ End Single Team */}
        </div>
        <div className="single-slider">
            {/* Single Team */}
            <div className="single-team">
            <div className="team-head">
                <img src="/assets/img/team-2.jpg" alt="#" />
                <ul className="team-social">
                <li><a href="#"><i className="fa fa-facebook-official" /></a></li>
                <li><a href="#"><i className="fa fa-twitter-square" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                <li><a href="#"><i className="fa fa-dribbble" /></a></li>
                </ul>
            </div>
            <div className="t-content">
                <div className="content-inner">
                <h4 className="name"><a href="#">Bramon Damana</a></h4>
                <span className="designation">Marketing Head</span>
                </div>
            </div>
            </div>
            {/*/ End Single Team */}
        </div>
        <div className="single-slider">
            {/* Single Team */}
            <div className="single-team">
            <div className="team-head">
                <img src="/assets/img/team-3.jpg" alt="#" />
                <ul className="team-social">
                <li><a href="#"><i className="fa fa-facebook-official" /></a></li>
                <li><a href="#"><i className="fa fa-twitter-square" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                <li><a href="#"><i className="fa fa-dribbble" /></a></li>
                </ul>
            </div>
            <div className="t-content">
                <div className="content-inner">
                <h4 className="name"><a href="#">Losama Princia</a></h4>
                <span className="designation">Animation Designer</span>
                </div>
            </div>
            </div>
            {/*/ End Single Team */}
        </div>
        <div className="single-slider">
            {/* Single Team */}
            <div className="single-team">
            <div className="team-head">
                <img src="/assets/img/team-4.jpg" alt="#" />
                <ul className="team-social">
                <li><a href="#"><i className="fa fa-facebook-official" /></a></li>
                <li><a href="#"><i className="fa fa-twitter-square" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                <li><a href="#"><i className="fa fa-dribbble" /></a></li>
                </ul>
            </div>
            <div className="t-content">
                <div className="content-inner">
                <h4 className="name"><a href="#">Putian Jason</a></h4>
                <span className="designation">Founder/ Designer</span>
                </div>
            </div>
            </div>
            {/*/ End Single Team */}
        </div>
        <div className="single-slider">
            {/* Single Team */}
            <div className="single-team">
            <div className="team-head">
                <img src="/assets/img/team-5.jpg" alt="#" />
                <ul className="team-social">
                <li><a href="#"><i className="fa fa-facebook-official" /></a></li>
                <li><a href="#"><i className="fa fa-twitter-square" /></a></li>
                <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                <li><a href="#"><i className="fa fa-dribbble" /></a></li>
                </ul>
            </div>
            <div className="t-content">
                <div className="content-inner">
                <h4 className="name"><a href="#">Ray Simmons</a></h4>
                <span className="designation">Team leader</span>
                </div>
            </div>
            </div>
            {/*/ End Single Team */}
        </div>
        </div>
    </div>
    </section>
  {/*/ End Team */}
  {/* Latest Blog */}
  
  <div>
        {/* Latest Blog */}
        <section className="latest-blog section-space" style={{backgroundColor: '#ffffff'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                <div className="section-title default text-center">
                  <div className="section-top">
                    <h1><span>Latest</span><b> Published</b></h1>
                  </div>
                  <div className="section-bottom">
                    <div className="text">
                      <p>Lorem Ipsum Dolor Sit Amet, Conse Ctetur Adipiscing Elit, Sed Do Eiusmod Tempor
                        Ares Incididunt Utlabore. Dolore Magna Ones Baliqua</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="blog-latest blog-latest-slider">
                  <div className="single-slider">
                    {/* Single Blog */}
                    <div className="single-news ">
                      <div className="news-head overlay">
                        <span className="news-img" style={{backgroundImage: 'url("./assets/img/blog-1.jpg")'}} />
                        <div className="cat"><a href="#" className="bizwheel-btn theme-2">Business</a></div>
                      </div>
                      <div className="news-body">
                        <div className="news-content">
                          <h3 className="news-title"><a href="blog-single.html">We Provide you Best &amp;
                              Creative Consulting Service</a></h3>
                          <ul className="news-meta">
                            <li className="date"><i className="fa fa-calendar" />April 2020</li>
                            <li className="view"><i className="fa fa-comments" />15</li>
                            <li className="heart"><i className="fa fa-heart" />35</li>
                          </ul>
                          <div className="news-text">
                            <p>Sed tempus pulvinar augue ut euismod. Donec a nisi volutpat,
                              dignissim mauris eget. Quisque vitae nunc sit amet eros pellentesque
                              tempus at sit amet sem</p>
                            <a href="blog-single.html" className="bizwheel-btn"><i className="fa fa-angle-left" />Read more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*/ End Single Blog */}
                  </div>
                  <div className="single-slider">
                    {/* Single Blog */}
                    <div className="single-news ">
                      <div className="news-head overlay">
                        <span className="news-img" style={{backgroundImage: 'url("./assets/img/blog-2.jpg")'}} />
                        <div className="cat"><a href="#" className="bizwheel-btn theme-2">Consulting</a></div>
                      </div>
                      <div className="news-body">
                        <div className="news-content">
                          <h3 className="news-title"><a href="blog-single.html">We Provide you Best &amp;
                              Creative Consulting Service</a></h3>
                          <ul className="news-meta">
                            <li className="date"><i className="fa fa-calendar" />April 2020</li>
                            <li className="view"><i className="fa fa-comments" />10</li>
                            <li className="heart"><i className="fa fa-heart" />55</li>
                          </ul>
                          <div className="news-text">
                            <p>Sed tempus pulvinar augue ut euismod. Donec a nisi volutpat,
                              dignissim mauris eget. Quisque vitae nunc sit amet eros pellentesque
                              tempus at sit amet sem</p>
                            <a href="blog-single.html" className="bizwheel-btn"><i className="fa fa-angle-left" />Read more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*/ End Single Blog */}
                  </div>
                  <div className="single-slider">
                    {/* Single Blog */}
                    <div className="single-news ">
                      <div className="news-head overlay">
                        <span className="news-img" style={{backgroundImage: 'url("./assets/img/blog-3.jpg")'}} />
                        <a href="#" className="bizwheel-btn theme-2">Read more</a>
                      </div>
                      <div className="news-body">
                        <div className="news-content">
                          <h3 className="news-title"><a href="blog-single.html">We Provide you Best &amp;
                              Creative Consulting Service</a></h3>
                          <ul className="news-meta">
                            <li className="date"><i className="fa fa-calendar" />April 2020</li>
                            <li className="view"><i className="fa fa-comments" />18</li>
                            <li className="heart"><i className="fa fa-heart" />69</li>
                          </ul>
                          <div className="news-text">
                            <p>Sed tempus pulvinar augue ut euismod. Donec a nisi volutpat,
                              dignissim mauris eget. Quisque vitae nunc sit amet eros pellentesque
                              tempus at sit amet sem</p>
                            <a href="blog-single.html" className="bizwheel-btn"><i className="fa fa-angle-left" />Read more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*/ End Single Blog */}
                  </div>
                  <div className="single-slider">
                    {/* Single Blog */}
                    <div className="single-news ">
                      <div className="news-head overlay">
                        <span className="news-img" style={{backgroundImage: 'url("./assets/img/blog-4.jpg")'}} />
                        <a href="#" className="bizwheel-btn theme-2">Read more</a>
                      </div>
                      <div className="news-body">
                        <div className="news-content">
                          <h3 className="news-title"><a href="blog-single.html">We Provide you Best &amp;
                              Creative Consulting Service</a></h3>
                          <ul className="news-meta">
                            <li className="date"><i className="fa fa-calendar" />April 2020</li>
                            <li className="view"><i className="fa fa-comments" />89</li>
                            <li className="heart"><i className="fa fa-heart" />20</li>
                          </ul>
                          <div className="news-text">
                            <p>Sed tempus pulvinar augue ut euismod. Donec a nisi volutpat,
                              dignissim mauris eget. Quisque vitae nunc sit amet eros pellentesque
                              tempus at sit amet sem</p>
                            <a href="blog-single.html" className="bizwheel-btn"><i className="fa fa-angle-left" />Read more</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*/ End Single Blog */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*/ End Latest Blog */}
      </div>
  
  {/*/ End Latest Blog */}
  {/* Client Area */}
  <div className="clients section-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="partner-slider">
                {/* Single client */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" ><img src="/assets/img/client/client-1.png" alt="#" /></a>
                  </div>
                </div>
                {/*/ End Single client */}
                {/* Single client */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" ><img src="/assets/img/client/client-2.png" alt="#" /></a>
                  </div>
                </div>
                {/*/ End Single client */}
                {/* Single client */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" ><img src="/assets/img/client/client-3.png" alt="#" /></a>
                  </div>
                </div>
                {/*/ End Single client */}
                {/* Single client */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" ><img src="/assets/img/client/client-4.png" alt="#" /></a>
                  </div>
                </div>
                {/*/ End Single client */}
                {/* Single client */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" ><img src="/assets/img/client/client-5.png" alt="#" /></a>
                  </div>
                </div>
                {/*/ End Single client */}
                {/* Single client */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" ><img src="/assets/img/client/client-6.png" alt="#" /></a>
                  </div>
                </div>
                {/*/ End Single client */}
              </div>
            </div>
          </div>
        </div>
      </div>
  {/*/ End Client Area */}
</div>

  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LicenceProduct)