import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

function EquerreGpao(props) {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{"Equerre GPAO"}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre GPAO"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre_gpao" />
        <meta property="og:image"  content="/assets/img/equerre_gpao.png"/>
        <meta name="twitter:image" content="/assets/img/equerre_gpao.png"/>

        
        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre_gpao" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image_url" content="/assets/img/equerre_gpao.png" />
        
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Equerre GPAO" />
        <meta property="og:description" content="Your company resource manage 360 degrees" />
        <meta property="og:image" content="/assets/img/equerre_gpao.png" />
        <meta property="og:url" content="ENLACE PERMANENTE" />
        <meta property="og:site_name" content="NOMBRE DEL SITIO" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@nytimesbits" />
        <meta name="twitter:creator" content="@nickbilton" />
        <meta property="twitter:title" content="Equerre GPAO" />
        <meta property="twitter:description" content="Your company resource manage 360 degrees" />
        <meta name="twitter:image" content="/assets/img/equerre_gpao.png" />
        <meta property="og:url" content="/assets/img/equerre_gpao.png" />
        <meta property="og:title" content="A Twitter for My Sister" />
        <meta property="og:description" content="Your company resource manage 360 degrees." />
        <meta property="og:image" content="/assets/img/equerre_gpao.png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white hero_img_block wrapper_blur mb-lg-0">
  
            <div class="foreground_blur" style={{backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/rh-1000-mg-1.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "bottom left", borderBottom: '5px solid #fff', backgroundPosition: "top right"}}
            ></div>

			<div className="" 
            // style={{backgroundColor: 'rgb(215 94 15 / 90%)'}}
            
            
            >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-6 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'3.5em', lineHeight:'1em', color: 'white'}}>Avec Equerre GPAO,</h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Améliorez en permanence la qualité, la productivité et la performance de vos opérations de  production.</p>
										<div className="button  my-4">       
											<a href="/equerre-hr-1000#video-feature" className="bizwheel-btn theme-1 effect mt-lg-3 primary-color d-none">Voir la vidéo</a>
											<a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3">Demandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                            <div className="col-lg-5 col-md-6 col-12">
                                
                                {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_gpao.png'/> */}
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>

        
            {/* Services */}
            <section className="equerre_rh_1000 services section-bg section-space mt-0 pb-5 section-bg-white">
                <div className="container">
                    
                <div className="row">
                    <div className="col-12">
                        <div className="section-title style2 text-center">
                            <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                            Qu’est-ce que Equerre GPAO ?
                            </h2>
                        </div>
                    </div>
                    
                    <div className='col-12 mb-4'>
                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre GPAO (Gestion de la Production Assistée par Ordinateur) est un logiciel qui permet de gérer de façon précise l’ensemble de la production d’une entreprise.</p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>En effet, Equerre GPAO assure un suivi de bout en bout de la production depuis la commande jusqu'à l'expédition, en passant par la nomenclature des composants / matières premières, la gestion des stocks, l'analyse des coûts et l'ordonnancement des postes du cycle de production. Cette gestion complète de l'exécution répond aux besoins critiques d'une production sérialisée de produits à volume élevé, à valeur élevée et à forte variabilité (tels que l'agriculture, l'agro-industrie, l'automobile, les appareils électroménagers, l'électronique, l'équipement lourd, les produits d'équitation de loisir, etc.). </p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre GPAO vous permet de suivre chaque article sérialisé qui se déplace dans votre processus de production, fournissant des informations détaillées et continues pour vous aider à prendre les meilleures décisions pour construire un avantage concurrentiel.</p>

                    </div>
                                        
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="section-title style2 text-center">
                            <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.3"}}>
                            Découvrez comment Equerre GPAO améliore en permanence la qualité, la productivité et la performance de vos opérations de  production.
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Suivi de bout en bout de la production</h3>
                            </a>
                        </h4>
                        <p className=''>
                        Depuis la commande jusqu'à l'expédition, en passant par la nomenclature des composants / matières premières, la gestion des stocks, l'analyse des coûts et l'ordonnancement des postes du cycle de production. Cette gestion complète de l'exécution répond aux besoins critiques d'une production sérialisée de produits à volume élevé, à valeur élevée et à forte variabilité (tels que l'agriculture, l'agro-industrie, l'automobile, les appareils électroménagers, l'électronique, les équipements lourds, les produits d'équitation de loisir, etc). 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion de la qualité</h3>
                            </a>
                        </h4>
                        <p>Equerre GPAO assure une qualité constante dans votre ligne de produits, en vous aidant à définir les spécifications des produits et les recettes nécessaires à une production de qualité et en fournissant en temps réel les tendances, statistiques et notifications pour contrôler les niveaux de qualité. Par l'analyse en temps réel et l'optimisation dynamique des processus, vous réduisez les coûts de production en minimisant les déchets, les rejets, et vous identifiez en amont les causes des productions non conformes. De cette manière vous pouvez résoudre plus rapidement les problèmes de qualité, réduire les rejets, rétrogradations, diminuer les plaintes et les retours des clients, et faciliter la démarche de conformité aux normes ISO. Par ailleurs, Equerre GPAO vous permet de créer des rapports de qualité ad hoc pour rechercher des problèmes de qualité spécifiques et fonder votre combat pour l'amélioration de vos performances sur des méthodologies éprouvées.  
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Suivi optimisé des calendriers de production</h3>
                            </a>
                        </h4>
                        <p>Répondant aux besoins d'une gestion de production diversifiée, Equerre GPAO ne se limite pas à superviser les opérations de production, il prend en charge l'exécution du calendrier de production et le suivi des produits par rapport aux délais d'exécution prévus en proposant des ajustements pour optimiser l'efficacité. Notre solution prend en charge la traçabilité complète des produits individuels à chaque étape du processus de fabrication tout en permettant une généalogie vérifiable et des rapports de production riches en information.</p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Opérations digitales : gestion centralisée, à distance et sur mobile</h3>
                            </a>
                        </h4>
                        <p>Vous opérez sur des sites de production distants, reliés par des moyens de communication (routes, ou réseaux intranet/internet) instables ? Vous avez pourtant un besoin impérieux de fluidifier la circulation de l'information, de capturer les données en temps utile et d'en tirer des informations précieuses pour la prise de décision ? Alors Equerre GPAO a pensé à vous : propulsé par les technologies de streaming de dernière génération Kafka, Equerre GPAO génère et propage dans tout le système des flux asynchrones d'informations "légères" (évènements) pour un accès et une analyse facile, n'importe où, n'importe quand, depuis n'importe quel appareil. </p>
                        
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des projets</h3>
                            </a>
                        </h4>
                        <p>Equerre GPAO prend également en charge la production en mode projet (à l’affaire) ainsi que la sous-traitance partielle ou globale. Soutenu par les modules de gestion de projet de la suite Equerre, Equerre GPAO constitue un système effectif de pilotage et de contrôle de gestion industrielle qui vous permet de piloter la rentabilité de vos projets de fabrication.  </p>
</div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4 className='mt-0'>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion dynamique des procédés </h3>
                            </a>
                        </h4>
                        <p>Les capacités de planification d'Equerre GPAO permettent aux entreprises de créer et de maintenir des ordres de production dynamiques et efficaces en temps réel en fonction des ressources et de la capacité des actifs. En fournissant un vue graphique interactive du plan de production dans l'ensemble de l'usine, Equerre aide les planificateurs à ordonnancer et exécuter dynamiquement les ordres de production, puis à surveiller l'avancement pour assurer une plus grande efficacité des procédés de fabrication. Equerre suit la production, la consommation, les déchets et les rendements réels mesurés et met à jour automatiquement les autres modules d'Equerre. Il fournit également des rapports et des outils d'analyse.</p>
                        
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4 className='mt-0'>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Analytique avancée et en temps réel </h3>
                            </a>
                        </h4>
                        <p>Les données de toutes les instances du système sont récupérées par le connecteur OVERXLS, nettoyées et organisées dans la base de données centralisée PostgreSQL pour nourrir tous les usages analytiques avancés. Equerre GPAO fournit des outils d'analytique avancés par machine learning pour permettre aux ingénieurs de procédés de combiner des données issues de sources de données massives et ainsi d'identifier rapidement les problèmes et en explorer les causes profondes, de prédire les performances futures et d'automatiser les actions pour améliorer en permanence la qualité, la productivité et la performance des opérations de production.</p>
                        
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>

                </div>
                
                </div>
            </section>
            {/*/ End Services */} 
            
    <section>
            <ProductPageCommonComponent title={'Equerre GPAO'} />
        </section>

    </>
  )
}

export default withNamespaces() (EquerreGpao)