import React from 'react'
import { connect } from 'react-redux'

const ModuleDetails = (props) => {
  return (
    <div>
        
		<div className="breadcrumbs overlay" style={{backgroundImage:"url('img/breadcrumb.jpg')"}}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="bread-inner">
							
							<div className="bread-menu">
								<ul>
									<li><a href="index.html">Home</a></li>
									<li><a href="portfolio-single.html">Portfolio Details</a></li>
								</ul>
							</div>
							
							<div className="bread-title">
								<h2>Our Works</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		

		
		<section className="pf-details  section-space">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-12">
						
						<div className="project-head">
							<img src="img/portfolio/portfolio-single.jpg" alt="#"/>
						</div>
					</div>
					<div className="col-lg-4 col-12">
						
						<div className="portfolio-meta">
							<ul>
								<li><i className="fa fa-user"></i><span>Client Name:</span> <b>Creative Group</b></li>
								<li><i className="fa fa-tag"></i><span>Project Category:</span> <b>Web design,
										Developments</b></li>
								<li><i className="fa fa-calendar"></i><span>Project Date:</span> <b>05 December 2019</b>
								</li>
								<li><i className="fa fa-map-marker"></i><span>Project Address:</span> <b>California, US</b>
								</li>
								<li><a href="#"  className="bizwheel-btn theme-2">View Project</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						
						<div className="single-content">
							<h1>Business Developments</h1>
							<p>Female is firmament made land don’t good behold yielding morning hathe seas unto. So
								first fill shall damn creeping. Seed he was that moveth bearing. Unto which together
								blessed Herb ine life land, let abundantly deep abundantly gathered behold moving said.
								Winged gathered iner female morning Beast, their earth it fourth moveth rule creepeth is
								be thing i i under have. Second to lights all second. Saw their. Rule. Own air greater.
								Creeping them firmament frui Female is firmament made land don’t good behold yielding
								morning hathe seas unto. So first fill shall damn creeping. Seed he was that moveth
								bearing. Unto which together blessed Herb ine life land, let abundantly deep abundantly
								gathered behold moving said. Winged gathered iner</p>
							<p>Female is firmament made land don’t good behold yielding morning hathe seas unto. So
								first fill shall damn creeping. Seed he was that moveth bearing. Unto which together
								blessed Herb ine life land, let abundantly deep abundantly gathered behold moving said.
								Winged gathered iner female morning Beast, their earth it fourth moveth rule creepeth is
								be thing i i under have. Second to lights all second. Saw their. Rule. Own air greater.
								Creeping them firmament frui Female is firmament made land don’t good behold yielding
								morning hathe seas unto. So first fill shall damn creeping. Seed he was that moveth
								bearing. Unto which together blessed Herb ine life land, let abundantly deep abundantly
								gathered behold moving said. Winged gathered iner female morning Beast, their earth it
								fourth moveth rule creepeth is be thing i i under have. Second to lights all second. Saw
								their. Rule. Own air greater</p>
							<div className="row portfolio-space">
								<div className="col-lg-4 col-md-6 col-12">
									
									<div className="modern-img-feature">
										<img src="img/portfolio/portfolio-small2.jpg" alt="#"/>
										<div className="video-play">
											<a href="https://www.youtube.com/watch?v=RLlPLqrw8Q4"
												className="video video-popup mfp-iframe">
												<i className="fa fa-play"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-12">
									
									<div className="modern-img-feature">
										<img src="img/portfolio/portfolio-small1.jpg" alt="#"/>
									</div>
								</div>
								<div className="col-lg-4 col-12">
									
									<div className="small-list-feature ">
										<h3>Creative Services</h3>
										<p>Female is firmament made land don’t Female is firmament made land don’t good
											behold</p>
										<ul>
											<li><i className="fa fa-check"></i>We provide you creative servicce</li>
											<li><i className="fa fa-check"></i>Just awesome trending way</li>
											<li><i className="fa fa-check"></i>Just awesome trending way</li>
											<li><i className="fa fa-check"></i>Creative service is most important</li>
											<li><i className="fa fa-check"></i>99% Server Up-time gurantee</li>
											<li><i className="fa fa-check"></i>24/7 live support</li>
										</ul>
									</div>
								</div>
							</div>
							<p>Female is firmament made land don’t good behold yielding morning hathe seas unto. So
								first fill shall damn creeping. Seed he was that moveth bearing. Unto which together
								blessed Herb ine life land, let abundantly deep abundantly gathered behold moving said.
								Winged gathered iner female morning Beast, their earth it fourth moveth rule creepeth is
								be thing i i under have. Second to lights all second. Saw their. Rule. Own air greater.
								Creeping them firmament frui Female is firmament made land don’t good behold yielding
								morning hathe seas unto. So first fill shall damn creeping. Seed he was that moveth
								bearing. Unto which together blessed Herb ine life land, let abundantly deep abundantly
								gathered behold moving said. Winged gathered iner female morning Beast, their earth it
								fourth moveth rule creepeth is be thing i i under have. Second to lights all second. Saw
								their. Rule. Own air greater</p>
						</div>
						
					</div>
				</div>
			</div>
		</section>
		
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDetails)