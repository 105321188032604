import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

const EquerrePOS = (props) => {
    const { t } = props
    useEffect(() => {
      
    }, [])
    
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre POS"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre_pos" />
        <meta property="og:image"  content="/assets/img/equerre_pos-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/equerre_pos-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre_pos" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="hero_img_block" 
              style={{ height: '90vh', backgroundImage: "linear-gradient(45deg,rgba(0, 0, 0, 0), rgba(0, 0, 0, .7)), url('/assets/img/equerre_pos-img-2.jpg')",borderBottom: '5px solid #fff', backgroundPosition: 'center right' }}
        
        >
        <div className="container-lg">
            <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
            
            {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_pos-img-1.png'/> */}
        </div>
                <div className="col-lg-6 col-md-8 col-12">
                    <div className="welcome-text py-5 "> 
                        <div className="hero-text"> 
                            <div className="p-text">
                            </div>
                            <h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'white'}}>Equerre POS </h2>
                                <p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Offrez-vous un logiciel de gestion de points de vente et d’encaissement innovant.</p>
                            <div className="button  mt-4">                      
                                <a href="#joined_us" className="bizwheel-btn theme-1 px-3 effect mt-lg-3 primary-color">Demandez une demo </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		</section>  
    

        <section className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container-lg">
                <div className="row mb-4">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Découvrez comment Equerre POS offre <br/>aux commerces de détail la possibilité de gérer toute <br/>leur chaîne d’activité.
                        
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className='offset-lg-3 col-lg-6 offset-md-2 col-md-8  col-12'>
                    <ul class="fa-ul">
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestion des produits </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Encaissement des ventes sur TPV, Windows, iOS, Android  </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>CRM et fidélisation  </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestion centralisée des stocks et des inventaires </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Statistiques et tableaux de bord </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>E-commerce synchronisé  </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestion multi-magasins en temps réel  </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Soldes, remises et promotions </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>SMS  </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Sondage </h3></li>
                        <li><h3 className='text-dark'><span class="fa-li"><i class="fa fa-check"></i></span>Extranet fournisseur </h3></li>
                    </ul>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ProductPageCommonComponent title={'Equerre POS'} />
        </section>

        </>
  )
}

export default withNamespaces() (EquerrePOS)