import React from 'react'
import './ShareButton.css'
function ShareButton(props) {
  const {url, title} = props
  return (
    <>
      
    {url ? (
      
      <div id="wrapper_share_container" className='wrapper_share_container'>
      <input type="checkbox" className="checkbox" id="share"  />
      <label htmlFor="share" className="label entypo-export">Partagez <i className="fa fa-share" aria-hidden="true" /></label>
      <div className="social">
        <ul>
          <li className="entypo-twitter twitter"><a href={`https://twitter.com/share?url=https://www.equerre.com${url}&text=${title}&via=EquerreSa&hashtags=erp&hashtags=equerre`} target='_blanc'> <i className="fa fa-twitter" aria-hidden="true" /></a></li>
          <li className="entypo-youtube"><a href={`https://www.youtube.com/@equerre`} target='_blanc'> <i className="fa fa-youtube" aria-hidden="true" /></a></li>
          <li className="entypo-facebook"><a href={`https://www.facebook.com/sharer.php?u=https://www.equerre.com${url}`} target='_blanc'> <i className="fa fa-facebook" aria-hidden="true" /></a></li>    
          {/* <li className="entypo-gplus"><a href={`https://www.facebook.com/sharer.php?u=${url}`} target='_blanc'> <i className="fa fa-google-plus" aria-hidden="true" /></a></li> */}
          <li className="entypo-envelope"><a href={`mailto:info@equerre.com$c=support@equerre.comc&subject=${title}`} target='_blanc'> <i className="fa fa-envelope" aria-hidden="true" /></a></li>     
        </ul>
      </div>
    </div>

    ) : (<p className='text-dark fw-bold py-2'>Aucun url attaché </p>)}

    </>
  )
}

export default ShareButton