import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { getUserDetailsByPaymentIntent } from '../../redux/acitons/pricingActions';
import { signup } from '../../redux/acitons/auth';
import { useHistory } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { BACKEND_IP } from '../../env';

  const validate = values => {
    const errors = {};
    const passwordRegex = /(?=.*[0-9])/;
    
    if (!values.password) {
      errors.password = 'Required';
    } else if (values.password.length < 8) {
    errors.password = "*Password must be 8 characters long.";
    } else if (values.password.length > 15) {
    errors.password = 'Must be 15 characters or less';
    } else if (!values.password) {
    errors.password = "*Required";
    }  else if (!passwordRegex.test(values.password)) {
    errors.password = "*Invalid password. Must contain one number.";
    } 
  
    if (values.password && values.confirm_password ) {
        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Password not matched";
        }
    }
    return errors;
  };

const SignUp = (props) => {
  const { t } = props
  // console.log('props', props)
  let history = useHistory();
    const {userProductData, getUserDetailsByPaymentIntent, location , userDetails} = props
    const queryParams = new URLSearchParams(location.search.toString())
    const [payment_intent, setPayment_intent] = useState("")
    const [payment_intent_client_secret, setPayment_intent_client_secret] = useState("")
    const [message, setMessage] = useState(false)
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const formik = useFormik({
      initialValues: {
        password: "Goufan2017",
        confirm_password: "Goufan2017",
        email: "",
      },
      validate,
    //   password: Yup.password(),
    //   confirm_password: Yup.password(),
      validationSchema: Yup.object({
        email: Yup.string().email("Invalid email address")//.required("Required"),
      }),
      onSubmit: async (values, { resetForm }) => {
        
        // setDisableSubmitButton(true)
        console.log('-- values', values)
        // return
      // let body =
      props.signup({
        email: userDetails.email,
        lastName: userDetails?.first_name,
        firstName: userDetails?.last_name,
        password:  values.password
       },history)
            .then((res) => {
              console.log('res', res)
              setMessage(JSON.stringify(res));    
              setTimeout(() => {
                // Most recent value
                setMessage(false);    
              }, 8000);
              window.location.href = `${BACKEND_IP}/signin`
              resetForm({ values: "" });
            }).catch ((error)=> {
              console.error("-- error", error);
              setMessage({code: 404, message: error})
                
            }).finally(()=> setDisableSubmitButton(false))
      },
    });
    useEffect(() => {
      
    const _payment_intent = queryParams.get("payment_intent")
    const _payment_intent_client_secret = queryParams.get("payment_intent_client_secret")
    setPayment_intent(_payment_intent)
    setPayment_intent_client_secret(_payment_intent_client_secret)
        if (_payment_intent && _payment_intent_client_secret){
          // console.log('queryParams', payment_intent, payment_intent_client_secret)
            let res = props.getUserDetailsByPaymentIntent(_payment_intent, _payment_intent_client_secret)
            console.log('user', res)
            if (res && res.code === 500){
              setMessage({code: res.code, message: res.message})
            }
        }
      return () => {
        
      }
    }, [])
    
  return (
    <div>
        <section className="" style={{backgroundColor: "#eee"}}>
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-lg-12 col-xl-11">
          
          <div className="card-body p-md-5">
            <div className="row justify-content-center">


              <div className="contact-form-area row">
                <div className='col-lg-6 order-2 oder-lg-1'>
                    
							{message && message.code ? 
								message.code  == "200" ? (
								<div className="alert alert-success mb-5" role="alert">
									{message.message}
								</div>
								) : (
									<div className="alert alert-danger mb-5" role="alert">
									{JSON.stringify(message?.message?.message)}
									</div>
								)          
							:null}

              <h4>Sign Up</h4>
                <form 
								onSubmit={formik.handleSubmit}
								id="signup-form"
								className="form" method="post" action="">
								<div className="row">
                                    

                                <div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-envelope"></i></div>
											
											<input	
													value={userDetails?.email || ""}
                          disabled={true}										
													id="email"
													name="email"
													type="email"
													placeholder="Email"
													readOnly={true}
													// onBlur={formik.handleBlur}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
												) : null}
												
										</div>
									</div>


                                <div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
											{/* <div className="icon"><i className="fa fa-unlock-alt"></i></div> */}
                                            <div className='icon'><a href=''><i className='fa fa-eye-slash' aria-hidden="true"></i></a></div>
											
											<input												
													id="Password"
													name="password"
													type="password"
													placeholder="Password"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.password}
                                                    // validate={validatePassword}
												/>
												{formik.touched.password && formik.errors.password ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.password}</div>
												) : null}
												
										</div>
									</div>

									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-unlock-alt"></i></div>
											
											<input													
													id="Password"
													name="confirm_password"
													type="password"
													placeholder="Confirm Password"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.confirm_password}
                                                    // validate={validateConfirmPassword}
												/>
												{formik.touched.confirm_password && formik.errors.confirm_password ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.confirm_password}</div>
												) : null}
												
										</div>
									</div>

									<div className="col-12">
										<div className="form-group button">
											<button disabled={disableSubmitButton} type="submit" className="bizwheel-btn theme-2">{t("home_page_button")}Submit</button>
										</div>
									</div>
								</div>
			    </form>


                </div>
                <div className="col-lg-6 col-md-10  d-flex align-items-center order-1 order-lg-2">

                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" className="img-fluid" alt="Sample image"/>

                </div>

                </div>




            </div>
          </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

const mapStateToProps = (state) => {
    console.log('state', state)
    return {
        userDetails: state.pricing.user
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        getUserDetailsByPaymentIntent: (...data)=> dispatch(getUserDetailsByPaymentIntent(...data)),
        signup: (...formData)=> dispatch(signup(...formData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SignUp))
