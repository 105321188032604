import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import styled from "styled-components";
import { ContactUsForm } from "../../components";
import { Helmet } from "react-helmet";
const OurProductFinancialManagement = (props) => {
  const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("our_Financemanagement_pagetitle")}</title>
        <meta name="description" content="Financial Management description"/>
        <meta property="og:title"content="Financial Management"/>
        <meta
        property="og:description"
        content="Financial Management description"/>
        <link rel="canonical" href="https://www.equerre.com/our_products/financial-management" />
        <meta property="og:image"  content="/assets/img/mrkt-img-7.png"/>
        <meta name="twitter:image" content="/assets/img/mrkt-img-7.png"/>
    </Helmet>
      <OurProductContainer>
        <div
          className="content"
          style={{
            backgroundImage: "url('/assets/img/Financial-management-Equerre.jpg')",
            backgroundSize: "100% auto",
            minHeight: '500px',
            paddingTop: '20px'
          }}>
          <div className="container">
            <div className="row">
              <div className="offset-lg-2 offset-md-2 col-lg-8 col-md-8 col-12">
                <div className="welcome-text">
                  <div className="hero-text mt-4 pl-4">
                    <p>{t("our_Financemanagement_paragrapa")}</p>
                    <h1>
                    {t("our_Financemanagement_titrea")}<br />{t("our_Financemanagement_titreb")}
                    </h1>
                    <p>
                    {t("our_Financemanagement_paragrapb")}
                    {" "}
                    </p>
                    <div className="button mt-4">
                      <a
                        href="/contact_us"
                        className="bizwheel-btn theme-1 effect"
                        style={{ border: "1px solid #009ADE" }}
                      >
                        {t("our_Financemanagement_page_head_button")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5"></div>
            </div>
          </div>
        </div>
      </OurProductContainer>

      {/* features */}


      <div style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row">
            {/* title */}
            <div className="col-12">
              <div className="section-title style2 text-center mb-0" style={{ padding: "0 2.4em", backgroundColor: "#fff" }}>
                <div className="section-top mt-5">
                  <h3 style={{fontWeight: 'lighter', color: '#000'}}>
                  {t("our_Financemanagement_titrec")}<br /> {t("our_Financemanagement_titreca")}
                  </h3>
                </div>
                <div className="section-bottom">
                  <div className="text-style-two botton"></div>
                </div>
              </div>
            </div>
            {/* end title */}
            <div
              className="col-4"
              style={{ padding: "2em 3em", backgroundColor: "#fff" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: ".6em",
                  color: "#fff",
                  backgroundColor: "var(--blue)",
                  height: "3em",
                  width: "3em",
                  borderRadius: "50%",
                }}
                className="key_features_nbr"
              >
                01
              </div>
              <h5 style={{ color: "#000" }}>{t("our_Financemanagement_bloca_title")}</h5>
              <p>
              {t("our_Financemanagement_bloca_paragraph")}
              </p>
            </div>
            <div
              className="col-4"
              style={{ padding: "2em 3em", backgroundColor: "#fff" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: ".6em",
                  color: "#fff",
                  backgroundColor: "var(--blue)",
                  height: "3em",
                  width: "3em",
                  borderRadius: "50%",
                }}
                className="key_features_nbr"
              >
                02
              </div>
              <h5 style={{ color: "#000" }}>{t("our_Financemanagement_blocb_title")}</h5>
              <p>
              {t("our_Financemanagement_blocb_paragraph")}

              </p>
            </div>
            <div
              className="col-4"
              style={{ padding: "2em 3em", backgroundColor: "#fff" }}
            >
              <div
                style={{
                  marginBottom: "1em",
                  fontWeight: "bolder",
                  textAlign: "center",
                  padding: ".6em",
                  color: "#fff",
                  backgroundColor: "var(--blue)",
                  height: "3em",
                  width: "3em",
                  borderRadius: "50%",
                }}
                className="key_features_nbr"
              >
                03
              </div>
              <h5 style={{ color: "#000" }}>{t("our_Financemanagement_blocc_title")}</h5>
              <p>
              {t("our_Financemanagement_blocc_paragraph")}

              </p>
            </div>
          </div>
        </div>
      </div>
      {/* emd features */}

      {/* Call To Action */}
      <section
        className="call-action"
        style={{ backgroundImage: 'url("/assets/img/mrkt-img-8.png")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="call-inner">
                <h5 style={{ color: "#fff" }}>
                {t("our_Financemanagement_blocd_title")}
                </h5>
                <p>
                {t("our_Financemanagement_blocd_paragraph")}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="button">
                <a
                  href="#brochure_unavailable_yet"
                  className="bizwheel-btn theme-1 effect"
                >
                 {t("our_Financemanagement_page_blocd_button")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End Call to action */}

      
      {/* features */}


      <PricingContainer>
        <div className="container">
          <div className="row">
            {/* title */}
            <div className="col-12">
              <div className="section-title style2 text-center mb-0" style={{ padding: "0 2.4em", backgroundColor: "#fff" }}>
                <div className="section-top mt-5">
                  <h3>{t("home_page_bloc5_crmetvente_titrec")}</h3>
                  <p>{t("home_page_bloc5_crmetvente_paragraph_c")}</p>
                </div>
                <div className="section-bottom">
                  <div className="text-style-two botton"></div>
                </div>
              </div>
            </div>
            {/* end title */}
            
          </div>
        </div>
      </PricingContainer>
      {/* emd features */}

      {/* pricing plan */}
      <PricingContainer style={{padding: '0 2rem 4rem 2rem' }} >
        <div className="container">
          <div className="row">
            <div className="registration_container" id='registration_container'> 
                <ContactUsForm />
            </div>
        </div>
      </div>
      </PricingContainer>
      {/* end pricing plan */}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (OurProductFinancialManagement));


const OurProductContainer = styled.div`
  overflow: hidden;
  display: block;
  position: relative;
  .content {
    .hero-text {
      padding-top: 2rem;
      padding-bottom: 1rem;
      position: relative;
      z-index: 1;
      p,
      h1 {
        font-weight: lighter;
        color: #fff;
      }
    }
    padding: 1rem;
  }
  .content:after {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    position: absolute;
  }
  .single-pricing.active .icon-head,
    .single-pricing:hover .icon-head{
        background:#009ADE;
        border-color:transparent;
        i {
        color:#fff !important;
        }
    }
`;

const PricingContainer = styled.div`
    background-color: #fff;
    h3 {
        font-weight: lighter;
        color: #000;
    }
    
    h2.price {
        color: #000;
        b, span {
            color: #000;
        }
    }
    .small-title {
        color: #000;
    }
    
`