import React from 'react'
import { withNamespaces } from 'react-i18next';
import HomePageBlock4 from '../../containers/HomePage/HomePageBlock4'
import HomeContactUsForm from '../HomeContactUsForm/HomeContactUsForm'

const ProductPageCommonComponent = (props) => {
    const { t, title } = props
  return (
    <>        
                {/* Contact Us */}
                
                <div id="joined_us" className="container title_content my-5" style={{textAlign: 'center'}}>
          <div className="title_content">
              <h2 style={{color: '#000' , fontSize: '2.5em', lineHeight: "1.2em"}}>Demandez une démo de {title}</h2>
              <p style={{fontSize:'1.5em', lineHeight:'initial', marginBottom: '1em'}} >Une webdémo ou une démo en présentiel sur l'ensemble des fonctionnalités pour vous faire une idée et poser vos questions. Complétez ce formulaire pour qu'un conseiller Equerre vous présente la solution {title}</p>
          </div>
        </div>

        <section className="contact-us section-space" style={{paddingTop: '17px'}}>
        <div className="container-lg">
            <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
                
                <img style={{height: '100%', objectFit: 'cover'}} src='/assets/img/img-6.jpg'/>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                {/* Contact Form */}
                <div className="contact-form-area">
                <HomeContactUsForm />
                </div>
                {/*/ End contact Form */}
            </div>
            </div>
        </div>
        </section>	
        {/*/ End Contact Us */}
        
        <section>
            <HomePageBlock4 />
        </section>
    </>
  )
}

export default withNamespaces() (ProductPageCommonComponent)