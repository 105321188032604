import React, { useEffect, useState }  from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { withNamespaces } from 'react-i18next';
import { env_config, API, GOOGLE_RECAPTCHA_KEY } from '../../env';
// import ReCAPTCHA from "react-google-recaptcha";
// import { useDispatch } from 'react-redux';
// import { editHeaderChild } from '../../store/actions/global';

const TEST_SITE_KEY = GOOGLE_RECAPTCHA_KEY;
const recaptchaRef = React.createRef();

export const CareerForm = (props) => {
    const { t } = props;
    const [isReCaptchaValid, setisReCaptchaValid] = useState(false);
	let [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false)
    
    const formik = useFormik({
      initialValues: {
        Name: "",
      //   Company: "",
        phoneNumber: "",
        email: "",
        message: "",
        civility: "mr",
      },
      validationSchema: Yup.object({
        civility: Yup.string()
          .max(2, "Must be either Mr or Mm")
          .min(2, "Must be either Mr or Mm")
          .required("Required"),
        Name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
      //   Company: Yup.string()
      //     .max(30, "Must be 30 characters or less")
      //     .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        phoneNumber: Yup.number()
          .typeError("That doesn't look like a phone number")
          .positive("A phone number can't start with a minus")
          .integer("A phone number can't include a decimal point")
          .required("A phone number is required"),
      }),
      onSubmit: async (values, { resetForm }) => {
        // document.getElementById("exampleModal").classList.add("show", "d-block");
        // document
        //   .querySelectorAll(".modal-backdrop")
        //   .forEach((el) => el.classList.add("modal-backdrop"));
        console.log(JSON.stringify(values, null, 2));
        console.log("-- values", values);
        if (!isReCaptchaValid) {
          return;
        }
        const recaptchaValue = recaptchaRef.current.getValue();
        console.log("-- recaptchaValue", recaptchaValue);
        try {
          await axios
            .post("https://overxls.com/mail.php", values, {
            //.post("http://localhost:5000/send_mail", values, {
              headers: {
                "Content-Type": "application/json",
              //baseUrl: "http://localhost:3000",
                baseUrl: "https://overxls.com",
                Agent: "*/*",
                "User-Agent": "localhost",
              },
            })
            .then((res) => {
              console.log("-- send mail res", res);
              // handleCloseModal();
  
              resetForm({ values: "" });
            });
        } catch (error) {
          console.error("-- error", error);
        }
      },
    });
    const onChangeReCAPTCHA = (value) => {
      console.log("Captcha value:", value);
      setisReCaptchaValid(true);
    };
  return (
    <>
    {message && message.code ? 
        message.code  == "200" ? (
        <div className="alert alert-success mb-5" role="alert">
            {message.message}
        </div>
        ) : (
            <div className="alert alert-danger mb-5" role="alert">
            {JSON.stringify(message?.message?.message)}
            </div>
        )          
    :null}
        
        <form
                onSubmit={formik.handleSubmit}
                id="career-form"
                className="form row"
              >
                <div className="col-md-8 mb-3">
                  
                  <input
                    className="form-control h-100 pl-3"
                    id="Name"
                    name="Name"
                    type="text"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Name}
                  />
                  {formik.touched.Name && formik.errors.Name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.Name}</div>
                  ) : null}
                </div>
                <div className="col-md-4 mb-3">
                  
                  <select className="form-select" id="civility">
                    <option
                      disabled
                      onChange={formik.handleChange}
                      value={formik.values.civility}
                    >
                      Choose...
                    </option>
                    <option value="mr">Mr</option>
                    <option value="mm">Mrs</option>
                  </select>
                  {formik.touched.Name && formik.errors.civility ? (
                    <div style={{ color: "#dc3545" }}>
                      {formik.errors.civility}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  
                  <input
                    className="form-control pl-3"
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.Name && formik.errors.email ? (
                    <div style={{ color: "#dc3545" }}>
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                 <div className="mb-3">
                  
                  <input
                    className="form-control"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="phone"
                    placeholder="Numéro"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.Name && formik.errors.phoneNumber ? (
                    <div style={{ color: "#dc3545" }}>
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div> 

                <div className="mb-3">
                  
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    type="phone"
                    placeholder="Votre message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  {formik.touched.Name && formik.errors.message ? (
                    <div style={{ color: "#dc3545" }}>
                      {formik.errors.message}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3"> 
                
                <input className="form-control form-control-lg" id="formFileLg" type="file" />
                </div>

                <div className="mb-3">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={TEST_SITE_KEY}
                    onChange={onChangeReCAPTCHA}
                  />
                  {!isReCaptchaValid ? (
                    <div className="help_text" style={{ color: "#dc3545" }}>
                      Validate The ReCaptcha
                    </div>
                  ) : null}
                </div>
                <button className="bizwheel-btn theme-2" type="submit">
                  Envoyez
                </button>
              </form>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (CareerForm))