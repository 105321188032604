import React from 'react'
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

const EquerreGdoc = (props) => {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre Gdoc"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre_gdoc" />
        <meta property="og:image"  content="/assets/img/equerre_gdoc-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/equerre_gdoc-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre_gdoc" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white hero_img_block" 
        style={{
          
        }}>
			<div className="" style={{backgroundColor: '#1c2427',}}>
				{/* <!-- Single Slider --> */}
				<div className="single-slider  py-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'white'}}>Equerre Gdoc </h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Gardez le contrôle sur votre système d’information et vos documents.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-1 px-3 effect mt-lg-3 primary-color">Démandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                    <div className="col-lg-5 col-md-6 col-12 equere_gdoc_hero_img_bloc">
                        
                        <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_gdoc-img-1.jpg'/>
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>  
    

        <section className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container-lg">
                <div className="row mb-4">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Découvrez comment Equerre Gdoc vous permet  <br/>de garder le contrôle sur votre système  <br/>d’information et vos documents.
                        
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className='offset-lg-3 col-lg-6 offset-md-2 col-md-8  col-12'>
                    <ul class="fa-ul">
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Dématérialisation des processus administratifs et gestion de tous les documents liés aux Ressources Humaines. </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Dématérialisation de l’ensemble des processus clients : envoi, signature, archivage. </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Traitement des factures, partage et échange d’information en interne et avec vos fournisseurs.</h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Maîtrise des notes de frais et autres dépenses. </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Création des workflows </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Facture électronique </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Signature électronique</h3></li>
                    </ul>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ProductPageCommonComponent title={"Equerre Gdoc"}/>
        </section>
    </>
  )
}

export default withNamespaces() (EquerreGdoc)