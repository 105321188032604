import { env_config, API } from '../env';

const config = env_config;

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  return req;
});
let _userToken = localStorage.getItem('userToken')
export const fetchModulesApi = () => API.get('/modules', config);

export const fetchModuleLinesApi = () => API.get('/modulelines', config);
export const fetchUserProductApi = (_token) => API.get(`/user_product?token=${_token}`, {
  ...config,
  params: {
    token: _token || _userToken
  }});

export const createUserProductApi = (user_product) => API.post('/user_product', user_product, config);

export const addModulineApi = (data) => API.post('/user_product/add', data, config);
export const removeModuleLineApi = (data) => API.post('/user_product/remove', data, config);

export const fetchPricingsApi = () => API.get('/pricing', config);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);

export const addPricingToUserProductApi = (data) => API.post('/user_product/add_pricing', data, config)

export const fetchStripePublicKeyApi = () => API.get('/pricing/stripe_public_key', config)

export const fetchClientKeyApi = (body) => API.post('/pricing/client_key', body, config)

export const sendSuccessCheckoutApi = (_body) => API.post('/send_success_payment_mail', _body, config)

export const getUserDetailsByPaymentIntentApi = (payment_intent, payment_intent_client_secret) => API.get('/user/by_payment_intent', {
  ...config,
  params: {
    ...config.params, 
    payment_intent,
    payment_intent_client_secret
  }
})

// export const signInApi = (body) => API.post('/user/signin', body, config)