

const modulelines1 = {
    'en': [
        {
            "_id": "1",
            "name": "Supply chain",
            "description": "Create benefits translating into higher profits, improved brand image and competitive advantage.",
            "slug_name": "Supply chain",
        },
        {
            "_id": "2",
            "name": "HR management",
            "description": "enhance your workflow configuration, with centralized data, automated employee records  and communications management.",
            "slug_name": "HR management",
        },
        {
            "_id": "3",
            "name": "financial management",
            "description": "Accelerate decision making with accurate reporting tools. Get customised views of your financial activity via personalized dashboards",
            "slug_name": "financial management",
        },
        {
            "_id": "4",
            "name": "CRM and Sales",
            "description": "From demographic analysis and campaign tracking to lead generation and sales conversion, get a cohesive solution to boost sales and ensure customers satisfaction",
            "slug_name": "CRM and Sales",
        },
        {
            "_id": "5",
            "name": "Others Tools",
            "description": "Equerre Gdoc, Equerre Project Management, Equerre Marketplace solutions for your specific needs",
            "slug_name": "Others Tools",
        },

        
    ],
    'fr':[
        {
            "_id": "1",
            "name": "Supply chain",
            "description": "Créer des avantages se traduisant par une augmentation des bénéfices, une amélioration de l'image de marque et un avantage concurrentiel.",
            "slug_name": "Supply chain",
        },
        {
            "_id": "2",
            "name": "Gestion des Ressources Humaines",
            "description": "Améliorer la configuration de votre flux de travail, avec des données centralisées, une gestion automatisée des dossiers des employés et des communications.",
            "slug_name": "HR management",
        },
        {
            "_id": "3",
            "name": "Gestion Financière",
            "description": "Accélérez la prise de décision grâce à des outils de reporting précis. Obtenez des vues personnalisées de votre activité financière grâce à des tableaux de bord personnalisés.",
            "slug_name": "financial management",
        },
        {
            "_id": "4",
            "name": "CRM et Ventes",
            "description": "From demographic analysis and campaign tracking to lead generation and sales conversion, get a cohesive solution to boost sales and ensure customers satisfaction",
            "slug_name": "CRM and Sales",
        },
        {
            "_id": "5",
            "name": "Utilitaires Intelligents",
            "description": "Equerre Gdoc, Equerre Project Management, Equerre Marketplace des solutions pour vos besoins spécifiques",
            "slug_name": "Others Tools",
        },
    
    
    ]
}

const modulelines2 = {
    'en': [
    {
        "_id": "1",
        "name": "Equerre project management",
        "description": "plan, organise and manage the work of your teams work from start to finish.",
        "slug_name": "Equerre project management",
    },
    {
        "_id": "2",
        "name": "Equerre Gdoc",
        "description": "The Equerre Gdoc solution is designed and developed to meet the needs of research and information sharing in companies",
        "slug_name": "Equerre Gdoc",
    },
    {
        "_id": "3",
        "name": "Equerre CloudHotel",
        "description": "Improve operating efficiency, and increase staff productivity with a all in one solution",
        "slug_name": "Equerre CloudHotel",
    },
    {
        "_id": "4",
        "name": "Equerre Health",
        "description": "A solution that enhances every aspect of your business helping you increase your revenue and simplify your operations. ",
        "slug_name": "Equerre Health",
    },
    {
        "_id": "5",
        "name": "Equerre Marketplace",
        "description": "An end-to-end solution is designed to address all stages of the employee lifecycle",
        "slug_name": "Equerre Marketplace",
    }
],
 'fr': [
    {
        "_id": "1",
        "name": "Equerre project management",
        "description": "Planifiez, organisez et supervisez le travail de vos équipes du début à la fin.",
        "slug_name": "Equerre project management",
    },
    {
        "_id": "2",
        "name": "Equerre Gdoc",
        "description": "La solution Gdoc d'Equerre est conçue et développée pour répondre aux besoins de recherche et de partage de l'information dans les entreprises.",
        "slug_name": "Equerre Gdoc",
    },
    {
        "_id": "3",
        "name": "Equerre CloudHotel",
        "description": "Améliorez l'efficacité opérationnelle et augmentez la productivité du personnel grâce à une solution tout-en-un.",
        "slug_name": "Equerre CloudHotel",
    },
    {
        "_id": "4",
        "name": "Equerre Health",
        "description": "Une solution qui améliore chaque aspect de votre activité en vous aidant à augmenter vos revenus et à simplifier vos opérations.",
        "slug_name": "Equerre Health",
    },
    {
        "_id": "5",
        "name": "Equerre Marketplace",
        "description": "Une solution de bout en bout est conçue pour prendre en compte toutes les étapes du cycle de vie des employés.",
        "slug_name": "Equerre Marketplace",
    }
]}
const datas = {
    modulelines1,
    modulelines2
}
//const mapStateToProps = (state) => ({})

//const mapDispatchToProps = {}

export default datas;
//export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (datas))