import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchUserProducts } from '../../redux/acitons/userProductAction'
import { useFormik } from "formik";
import * as Yup from "yup";
import { env_config, API, GOOGLE_RECAPTCHA_KEY } from '../../env';
import { ClipLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";


const RegistrationForm = ({fetchUserProducts, userProductData}) => {
	const history = useHistory();
	let [loading, setLoading] = useState(false);
	useEffect(() => {
	//   console.log('-- moduleData', moduleData)
	localStorage.getItem("userToken")
	fetchUserProducts()
	return () => {		
	}
	}, [])
	
    const [message, setMessage] = useState(false)
    const formik = useFormik({
      initialValues: {
        last_name: "",
        first_name: "",
        company: "",
        phoneNumber: "",
        email: "",
		company_size: 1,
		position: "",
		website: "",
		recaptcha: "",
      },
      validationSchema: Yup.object({

        first_name: Yup.string()
		.max(30, "Must be 30 characters or less")
		.required("Required"),
        last_name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        company: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        phoneNumber: Yup.number()
          .typeError("That doesn't look like a phone number")
          .positive("A phone number can't start with a minus")
          .integer("A phone number can't include a decimal point")
          .required("A phone number is required"),
		website: Yup.string()
		.matches(
			/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
			'Enter correct url!'
		)
		.required('Please enter website'),
		recaptcha: Yup.string().required(),
      }),
      onSubmit: async (values, { resetForm }) => {
		
		// values._ids = userProductData.reduce((arr, curr)=> { console.log('arr, curr', arr, curr) ;arr.push(curr._id); return arr} ,[])
		//   console.log('-- values', values)
		//   return
		  		  
		  const config = env_config;
		  
        try {
			setLoading(true)
          let res = await API
            .post("/user_product/add_user", {
				_ids: userProductData.reduce((arr, curr)=> { //console.log('arr, curr', arr, curr) ;
					arr.push(curr._id); return arr} ,[]),
				user: values
			}, config)			
            .then((res) => {
              
              setMessage({code:200, message: "User successfully register !!"});    
              setTimeout(() => {
                // Most recent value
			  history.push({
				pathname: `/checkout`, //?email=${values.email}`,
				// state: {email:values.email }
			})

              }, 2000);

              resetForm({ values: "" });
            })
			.catch((err, val)=>{
				console.log("-- error catch", err,err.message);
				setMessage({code: 404, message: err})
			}).finally(()=>{
				setLoading(false)
			});
        } catch (error) {
          console.log("-- error", error);
		  setMessage(false);    
          setMessage({code: 404, message: error})
        }
      },
    });

  return (
    <div>
        
		{/* <!-- Contact Us --> */}
		<section className="contact-us section-space">
			<div className="container">
				<div className="row">
					<div className="col-lg-7 col-md-7 col-12">
						{/* <!-- Contact Form --> */}
						<div className="contact-form-area m-top-30">
							
							{message && message.code ? 
								message.code  == "200" ? (
								<div className="alert alert-success mb-5" role="alert">
									{message.message}
								</div>
								) : (
									<div className="alert alert-danger mb-5" role="alert">
									{JSON.stringify(message?.message?.message)}
									</div>
								)          
							:null}

							<h4>Infos Personnels</h4>
							<form 
								onSubmit={formik.handleSubmit}
								id="registration-form"
								className="form" method="post" action="">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input
													
													id="first_name"
													name="first_name"
													type="text"
													placeholder="Prénom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.first_name}
												/>
												{formik.touched.first_name && formik.errors.first_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.first_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input													
													id="last_name"
													name="last_name"
													type="text"
													placeholder="Nom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.last_name}
												/>
												{formik.touched.last_name && formik.errors.last_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.last_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-envelope"></i></div>
												<input													
													id="email"
													name="email"
													type="email"
													placeholder="Email"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-globe"></i></div>

												<input													
													id="company_size"
													name="company_size"
													type="number"
													placeholder="Taille de l'entreprise"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.company_size}
												/>
												{formik.touched.company_size && formik.errors.company_size ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.company_size}</div>
												) : null}
												
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-address-book-o"></i></div>
											
											<input													
													id="position"
													name="position"
													type="text"
													placeholder="Titre du poste"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.position}
												/>
												{formik.touched.position && formik.errors.position ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.position}</div>
												) : null}
												
										</div>
									</div>
									
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-phone"></i></div>
											
											<input
												id="phoneNumber"
												name="phoneNumber"
												type="tel"
												placeholder="Numéro"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.phoneNumber}
											/>
											{formik.touched.Name && formik.errors.phoneNumber ? (
												<div style={{ color: "#dc3545" }}>
												{formik.errors.phoneNumber}
												</div>
											) : null}
												
										</div>
									</div>

									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-building"></i></div>
											
											<input													
													id="company"
													name="company"
													type="text"
													placeholder="Entreprise"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.company}
												/>
												{formik.touched.company && formik.errors.company ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.company}</div>
												) : null}
												
										</div>
									</div>
									
									<div className="col-lg-12 col-md-12 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-cloud"></i></div>
											
											<input													
													id="website"
													name="website"
													type="text"
													placeholder="Website"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.website}
												/>
												{formik.touched.website && formik.errors.website ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.website}</div>
												) : null}
												
										</div>
									</div>

									<div className="col-lg-12 col-md-12 col-12">
									<div className="form-group">
										<label>Recaptcha Validation</label>
										<ReCAPTCHA
										sitekey={GOOGLE_RECAPTCHA_KEY}
										render="explicit"
										onChange={(response) => {formik.setFieldValue("recaptcha", response); }}
										onExpired={() => { formik.setFieldValue("recaptcha", ""); }}
										/>
										
										{formik.touched.recaptcha && formik.errors.recaptcha ? (
											<div style={{ color: "#dc3545" }}>{formik.errors.recaptcha}</div>
										) : null}
									</div>                                       
									</div>

									<div className="col-12">
										<div className="form-group button">
											<button type="submit" className="bizwheel-btn theme-2">
													<div style={{display: 'flex', alignItems: 'center',}}>
													<div style={{marginRight: '.4em'}}>Envoyer</div> <ClipLoader color={"#fff"} loading={loading} size={20} />
													</div>
											</button>										
										</div>
									</div>
								</div>
							</form>
						</div>
						{/* <!--/ End contact Form --> */}
					</div>
					<div className="col-lg-5 col-md-5 col-12">
						<div className="contact-box-main m-top-30">
							<div className="contact-title">
								<h2>Company infos</h2>
								{/* <p>The number of users influence the pricing</p> */}
							</div>
							{/* <!-- Single Contact --> */}
							{/* <div className="single-contact-box">
								<div className="c-icon"><i className="fa fa-clock-o"></i></div>
								<div className="c-text">
									<h4>Opening Hour</h4>
									<p>Friday - Saturday<br/>08AM - 10PM (everyday)</p>
								</div>
							</div> */}
							{/* <!--/ End Single Contact --> */}
							{/* <!-- Single Contact --> */}
							<div className="single-contact-box">
								<div className="c-icon"><i className="fa fa-phone"></i></div>
								<div className="c-text">
									<h4>Call Us Now</h4>
									<p>Tel.: 888 765 4321<br/> Mob.: 765 654 3451</p>
								</div>
							</div>
							{/* <!--/ End Single Contact --> */}
							{/* <!-- Single Contact --> */}
							<div className="single-contact-box">
								<div className="c-icon"><i className="fa fa-envelope-o"></i></div>
								<div className="c-text">
									<h4>Email Us</h4>
									<p>contact@equerre.com<br/>info@equerre.com</p>
								</div>
							</div>
							{/* <!--/ End Single Contact --> */}
							<div className="button">
								<a href="/about_us" className="bizwheel-btn theme-1">About Us<i className="fa fa-angle-right"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>	
		{/* <!--/ End Contact Us --> */}
		
    </div>
  )
}

const mapStateToProps = (state) => {
      console.log('state.user_product.selected_modules', state.user_product.selected_modules)
      return {
        userProductData : state.user_product.selected_modules
      }
    }
    
    const mapDispatchToProps = dispatch => {
        return {
          fetchUserProducts: (token)=> dispatch(fetchUserProducts(token)),
        };
      };
    
    export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm)