import React, { Component } from 'react'
import { Footer, Header } from '../../components'

export class Layout extends Component {
  render() {
    return (
      <>
        <Header />
          {React.cloneElement(this.props.children)}
        <Footer />
      </>
    )
  }
}

export default Layout
