import React from 'react'
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

const EquerreHealth = (props) => {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre Santé"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre_health" />
        <meta property="og:image"  content="/assets/img/equerre_health-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/equerre_health-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre_health" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white" 
        style={{
          
        }}>
			<div className="" style={{backgroundColor: 'rgba(131, 151, 176, .90)'}}>
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 mb-lg-5 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'white'}}>Equerre Santé </h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Optimisez votre temps de travail grâce à notre logiciel médical.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-1 px-3 effect mt-lg-3 primary-color">Démandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                    <div className="col-lg-5 col-md-6 col-12 equere_gdoc_hero_img_bloc">
                        
                        <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_health-img-3.png'/>
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>  
    

        <section className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container-lg">
                <div className="row mb-4">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Découvrez comment Equerre santé s’adapte  <br/>facilement aux particularités de chaque  <br/>consultation et de chaque patient.
                        
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className='offset-lg-3 col-lg-6 offset-md-2 col-md-8  col-12'>
                    <ul class="fa-ul">
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Agenda </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Liste des patients </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Dossier médical </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Fiche d’observation </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Ordonnance </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Feuille de soins électronique</h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Recettes. </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Devis et factures </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Volet Administratif </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Tableaux de bords personnalisés… </h3></li>
                    </ul>
                    </div>
                </div>
            </div>
        </section>
        <section style={{backgroundColor: 'rgba(255, 230, 153, .97)'}}>
            <div className="container pt-4">
                <div className="row pt-4">
                    <h2 className='text-dark'>La solution Equerre Santé est adaptée à tous les <br/>établissements de la santé et modulables en<br/> fonction de vos besoins : </h2>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className="single-content mb-5">

                        {/* <p className='my-4' style={{fontSize:'1.5em', lineHeight:'initial'}}></p> */}

                        <div className="small-list-feature">
                            <ul>
                                <li><i className="fa fa-check" ></i><h3 style={{display: 'inline-block', color: '#000'}}>Adaptée à tous les modes d’exercices </h3></li>
                                <li><i className="fa fa-check"></i><h3 style={{display: 'inline-block', color: '#000'}}>Solution accessible en établissement de santé ou en mobilité </h3></li>
                                <li><i className="fa fa-check"></i><h3 style={{display: 'inline-block', color: '#000'}}>Vos données sont stockées sur site ou chez un Hébergeur agréé </h3></li>
                                <li><i className="fa fa-check"></i><h3 style={{display: 'inline-block', color: '#000'}}>Pas de limite de stockage de vos données médicales</h3></li>
                                <li><i className="fa fa-check"></i><h3 style={{display: 'inline-block', color: '#000'}}>Solution de gestion personnalisable avec des modules métiers spécifiques pensés par des professionnels de santé</h3></li>
                                <li><i className="fa fa-check"></i><h3 style={{display: 'inline-block', color: '#000'}}>Nombre d’utilisateurs non restreint</h3></li>
                            </ul>
                        </div>
                     </div>

                </div>
            </div>         

        </section>
        <section>
            <ProductPageCommonComponent title={"Equerre Santé"}/>
        </section>
    </>
  )
}

export default withNamespaces() (EquerreHealth)