import React from 'react'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next';
import './landing_page_styles.css'
import { Helmet } from 'react-helmet-async';
import HomeContactUsForm from '../../components/HomeContactUsForm/HomeContactUsForm';
import styled from 'styled-components'
import { FooterSummary } from '../../components';
import HomePageBlock2 from './HomePageBlock2';
import HomePageBlock3 from './HomePageBlock3';
import HomePageBlock4 from './HomePageBlock4';
import { useMediaQuery } from '../../utils/utlis';
const AFeature = styled.a`
  &:hover{
    color: var(--eqr-secondary-color) !important;
  }
`
const HomePage = (props) => { 

  const { t } = props
  const isRowBased = useMediaQuery('(min-width: 500px)');
  return (
    <div style={{backgroundColor: '#ffffff'}}>
        <Helmet>
            <title>{t("home_page_title_h1")}</title>
            <meta name="description" content="Your company resource manage 360 degrees"/>
            <meta property="og:title"content="Equerre Cloud ERP"/>
            <meta
            property="og:description"
            content="Your company resource manage 360 degrees"/>
            <link rel="canonical" href="https://www.equerre.com/" />
            <meta property="og:image"  content="/assets/img/logo.png"/>
            <meta name="twitter:image" content="/assets/img/logo.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

        </Helmet>
		{/* <!-- Hero Slider --> */}
    
		<section   className='hero_img_block' style={{backgroundImage: "linear-gradient(90deg,rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)), url('/assets/img/1664893694943.jpg')", backgroundPosition: 'center left'}}>
          
					<div className="container-fluid pt-lg-3">
            <canvas className=
            "erq_background"
            ></canvas>
            
						<div className="row">
							<div className="offset-lg-1 col-lg-6 col-md-8 col-12">
								<div className="welcome-text my-5"> 
									<div className="hero-text"> 
										<div className="p-text pb-lg-3">
										</div>
										<h1 style={{ fontSize: '4em', lineHeight:'1.2', color: 'white'}}>Apporter de la précision 
                    à votre business grâce à<br/>
                      <span style={{color:'var(--eqr-primary-color)'}}> La Suite Equerre</span></h1>

                      <p className='mt-2' style={{ fontSize: '3em', fontWeight: 'normal', color: 'white',fontSize:'2.5vh'}}>Un outil cohérent 
                      permettant d’avoir une vue d’ensemble bien structurée.</p>

										<div className="button mt-5">
                      <div className='row'>
                          <div className='col-lg-10 col-12'>
                            <div className='row'>
                              
                        <div className='col-6'><a  style={{height: '100%', display: 'block'}}  href="/product_list#financial_management"   className="bizwheel-btn theme-2  effect primary-color">Gestion financière et commerciale</a></div>
											<div className='col-6'><a  style={{height: '100%', display: 'block'}}  href="/product_list#erp_and_enterprise_management"   className="bizwheel-btn theme-2 effect text-dark">ERP et gestion d’entreprise </a></div>
                        <div className='col-6 mt-3'><a style={{height: '100%', display: 'block'}} href="/product_list#human_resource" className="bizwheel-btn theme-3 effect">Ressources humaines et paie</a></div>
                        <div className='col-6 mt-3'><a  style={{height: '100%', display: 'block'}} href="/product_list#other_products" className="bizwheel-btn theme-1 effect pt-sm-3">Autres produits</a></div>

                            </div>
                          </div>
                      </div>
										</div>
								</div>
							</div>
            <div className="col-lg-6 col-md-6 col-12 pt-lg-5">
                
                {/* <img style={{ height: '500px', objectFit: 'cover'}} src='/assets/img/hero-img-1.bk.png'/> */}
            </div>
						</div>
					</div>
			</div>
		</section>

    <HomePageBlock2 id='page-start' className='page-start'/>
    <HomePageBlock4 />
    <HomePageBlock3 />
        {/* new first part */}
        
        {/* new first part end */}
		{/* `<!--/` End Hero Slider --> */}
        {/* <div style={{backgroundColor: '#ffffff'}}>            
            <div className="container my-4" style={{height: "80%"}}>
                <div className="row align-items-end" style={{background: '#ffffff'}}>
                </div>
            </div>
        </div> */}
        {/* key feature new  */}
        

        {/* key features new end */}
        {/* section video  */}
        <section className="video-feature side overlay section-bg hero_img_block" style={{  backgroundImage:"linear-gradient(rgba(0, 154, 222, .7), rgba(0, 154, 222, .78)), url('/assets/img/img-5.jpg')",}}>
          <div className="container-lg">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
								<div className="welcome-text"> 
									<div className="hero-text"> 
										<h2 style={{fontSize:'4em', lineHeight:'initial', color: 'white'}}>Equerre RH 1000</h2>
                    <p className="mt-4" style={{fontSize:'3em', lineHeight:'initial', color: '#fff', fontWeight: 'normal'}}>Optez pour un logiciel complet et                   
                        intégré qui permet de gérer                                   
                        l'ensemble de vos processus RH
                    </p>
										<div className="button mt-4">
											<a href="/equerre-hr-1000#video-feature" className="bizwheel-btn theme-bloc-2">Voire la vidéo </a>
										</div>
									</div>
								</div>
              </div>
              <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
                
                {/* <div className="img-feature ">
                  <img src="assets/img/why-choose.jpg" alt="Video Thumbnail" />
                  <div className="video-play">
                    <a href="https://www.youtube.com/watch?v=RLlPLqrw8Q4" className="video video-popup mfp-iframe">
                      <i className="fa fa-play" />
                    </a>
                    <div className="waves-block">
                      <div className="waves wave-1" />
                      <div className="waves wave-2" />
                      <div className="waves wave-3" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        {/* section video end */}

        {/* end banner-img-1 */}
        {/* <div className='container'>
            <div className='row' style={{margin: "4em 0"}}>
                <div className='col-sm-12 col-lg-6 col-12'  style={{backgroundImage:"url('/assets/img/img-6-without-bg.png')", backgroundRepeat: 'no-repeat', backgroundSize: '100%', minHeight:'230px'}}> </div>
                <div className='col-sm-12 col-lg-6 col-12 mt-3' >
                    <h4 style={{marginBottom: '.7em'}}>UN <strong style={{color: '#FCB949'}}>ÉDITEUR</strong> PLUS PROCHE DE VOUS , REPONDANT A TOUTES VOS PROBLEMATIQUES METIERS</h4>
                    <p style={{marginBottom: '1em'}} >LoremIpsum DolorSitAmet , ConseCtetur Adipiscing Elit, SedDoEius modTemporAres.</p>
                    
                    <form id="contact-form form" className='form contact-form'>
                        <label>Name : <input type='text' placeholder='Name'/></label>
                        <label>Email : <input type='email' placeholder='Email'/></label>
                        <label>Phone : <input  type='text' placeholder='Phone'/></label>
                        <label>Subject : <input  type='text'  placeholder='Subject'/></label>
                        
                        <input className="bizwheel-btn theme-1 effect primary-color" type='submit' style={{width: '50%',}} value='En savoir plus'/>
                    
                    </form>
                </div>
            </div>
        </div> */}




                {/* Contact Us */}
                
          <div id="joined_us"></div>
        <div className="container title_content my-5" style={{textAlign: 'center'}}>
          <div className="title_content">
              <h2 style={{color: '#000' , fontSize: '2.5em', lineHeight: "1.2em"}}>Demandez une démo des solutions Equerre</h2>
              <p style={{fontSize:'1.5em', lineHeight:'initial', marginBottom: '1em'}} >Une webdémo ou une démo en présentiel sur l'ensemble des fonctionnalités pour vous faire une idée et poser vos questions. Complétez ce formulaire pour qu'un conseiller Equerre vous présente nos solutions</p>
          </div>
        </div>

        <section className="contact-us section-space"  style={{paddingTop: '17px'}}>
        <div className="container-lg">
            <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
                
                <img style={{height: '100%', objectFit: 'cover'}} src='/assets/img/img-6.jpg'/>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
                {/* Contact Form */}
                <div className="contact-form-area">
                <HomeContactUsForm />
                </div>
                {/*/ End contact Form */}
            </div>
            </div>
        </div>
        </section>	
        {/*/ End Contact Us */}


        

         {/* card */}
         
          <div className="collapse cartContainer" id="collapseExample">
            <div
              className="sticky-card p-1 fixed-bottom stickyAddCart d-flex align-items-center justify-content-space-between"
              id="stickyCard"
              hidden={true} style={{flexDirection: 'column'}}
            >
              <FooterSummary link={"/marketplace"} title={"MarketPlace"} />
            </div>
          </div>
         {/* end cart */}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (HomePage))

