import React from 'react'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next';


export const Footer = (props) => {
	const { t } = props
  return (
    <>      
		{/* <!-- Footer --> */}
		<footer className="footer" style={{backgroundImage:"url('./assets/img/map.png')"}}>
			{/* <!-- Footer Top --> */}
			<div className="footer-top pt-0" >
				<div className="container-lg container-sm">
					<div className="row">
						
						<div className="col-lg-3 col-md-6 col-12">	
							{/* <!-- Footer Contact -->		 */}
							<div className="single-widget footer_contact widget">	
								<h3 className="widget-title font-weight-bold">Entreprise</h3>
								<p></p>
								<ul className="address-widget-list">
									{/* <li className="footer-mobile-number"><a href='tel:+(600) 125-4985-214'><i className="fa fa-phone"></i>{t("our_Footer_Bloc3textea")}</a></li> */}
									<li className="footer-mobile-number widget-title"><a href="/about_us"><h6 className="widget-title font-weight-bold">A propos de Equerre</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/career"><h6 className="widget-title font-weight-bold">Carrières</h6></a></li>

									<li className="footer-mobile-number widget-title"><a href="/become_partner"><h6 className="widget-title font-weight-bold">Devenir partenaire </h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/actuality"><h6 className="widget-title font-weight-bold">Actualités / Evènements </h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/newsletter"><h6 className="widget-title font-weight-bold">Newsletter</h6></a></li>

								</ul>
							</div>		
							{/* <!--/ End Footer Contact --> */}
							
							<div className="single-widget footer-about widget mb-0">									
								<div className="social">
									{/* <!-- Social Icons --> */}
									<ul className="social-icons">
										<li><a className="facebook" href="https://web.facebook.com/profile.php?id=100088494751623&is_tour_dismissed=true" ><i className="fa fa-facebook"></i></a></li>
										<li><a className="twitter" href="https://twitter.com/EquerreSa" ><i className="fa fa-twitter"></i></a></li>
										<li><a className="linkedin" href="https://www.linkedin.com/company/equerre-sa/about/?viewAsMember=true" ><i className="fa fa-linkedin"></i></a></li>
										<li><a className="youtube" href="https://www.youtube.com/@equerre" ><i className="fa fa-youtube"></i></a></li>
										<li><a className="instagram" href="https://www.instagram.com/equerre_sa/" ><i className="fa fa-instagram"></i></a></li>
									</ul>
								</div>
								
								{/* <div className="logo mt-3">
									<div className="img-logo">
										<a className="logo" href="index.html">
											<img className="img-responsive logo-width-size" src="/assets/img/logo2.png" alt="logo"/>
										</a>
									</div>
								</div> */}

							</div>	

						</div>

						<div className="col-lg-3 col-md-6 col-12">	
							{/* <!-- Footer Contact -->		 */}
							<div className="single-widget footer_contact widget">	
								<h3 className="widget-title font-weight-bold">Produits & solutions</h3>
								<p></p>
								<ul className="address-widget-list">
									{/* <li className="footer-mobile-number"><a href='tel:+(600) 125-4985-214'><i className="fa fa-phone"></i>{t("our_Footer_Bloc3textea")}</a></li> */}
									<li className="footer-mobile-number widget-title"><a href="/equerre-smb-50"><h6 className="widget-title font-weight-bold">Equerre SMB 50</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre-smb-100"><h6 className="widget-title font-weight-bold">Equerre SMB 100</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre-hr-1000"><h6 className="widget-title font-weight-bold">Equerre RH 1000</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre-paie-500"><h6 className="widget-title font-weight-bold">Equerre paie 500</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre_gpao"><h6 className="widget-title font-weight-bold">Equerre GPAO</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre_cloud_erp"><h6 className="widget-title font-weight-bold">Equerre Cloud ERP</h6></a></li>
									
									<li className="footer-mobile-number widget-title"><a href="/equerre_sales_crm"><h6 className="widget-title font-weight-bold">Equerre vente et CRM</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre-Finance-1000"><h6 className="widget-title font-weight-bold">Equerre Finance 1000</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre_project_management"><h6 className="widget-title font-weight-bold">Equerre gestion de projet</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre_pos"><h6 className="widget-title font-weight-bold">Equerre POS </h6></a></li>
									
									<li className="footer-mobile-number widget-title"><a href="/equerre_gdoc"><h6 className="widget-title font-weight-bold">Equerre Gdoc</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre_health"><h6 className="widget-title font-weight-bold">Equerre Santé</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="/equerre_cloud_hotel"><h6 className="widget-title font-weight-bold">Equerre Cloud Hôtel</h6></a></li>
								</ul>
							</div>		
							{/* <!--/ End Footer Contact -->						 */}
						</div>
						
						<div className="col-lg-3 col-md-6 col-12">	
							{/* <!-- Footer Contact -->		 */}
							<div className="single-widget footer_contact widget">	
								<h3 className="widget-title font-weight-bold">Equerre Market place </h3>
								<p></p>
								<ul className="address-widget-list">
									{/* <li className="footer-mobile-number"><a href='tel:+(600) 125-4985-214'><i className="fa fa-phone"></i>{t("our_Footer_Bloc3textea")}</a></li> */}
									<li className="footer-mobile-number widget-title"><a href="#"><h6 className="widget-title font-weight-bold">Logiciels de gestion</h6></a></li>
									<li className="footer-mobile-number widget-title"><a href="#"><h6 className="widget-title font-weight-bold">Logiciels spécialisés</h6></a></li>
								</ul>
							</div>		
							{/* <!--/ End Footer Contact -->						 */}
						</div>

						<div className="col-lg-3 col-md-6 col-12">
							{/* <!-- Footer Contact --> */}
							<div className="single-widget footer_contact widget">
								<h3 className="widget-title">Contacts</h3>
								<h6 className='text-white'>USA & EUROPE</h6>
								<ul className="address-widget-list">
									{/* <li className="footer-mobile-number"><i className="fa fa-envelope"></i>info@yoursite.com
									</li> */}
									<li className="footer-mobile-number mb-0">12 Indian Grass CT, Germantown, Maryland 20874</li>
									<li className="footer-mobile-number mb-0">301.704.8534</li>

								</ul>
								<h6 className='text-white mt-4'>AFRIQUE</h6>
								<p className='text-white  mb-0'>Douala-Cameroun</p>
								<ul className="address-widget-list">
									<li className="footer-mobile-number mb-0">
										Immeuble IMD, 1 132 Rue Berthaud, Bali, B.P.3496 Douala
									</li>
									<li className="footer-mobile-number  mb-0">+(237) 233 48 09 03</li>

								</ul>

								<p className='text-white mt-4  mb-0'>Yaoundé-Cameroun</p>
								<ul className="address-widget-list">
									<li className="footer-mobile-number mb-0">
									Carrefour CEPER, Elig-Essono, B.P.11829 Yaoundé
									</li>
									<li className="footer-mobile-number mb-0">+(237) 233 48 09 03</li>
								</ul>
								
								<p className='text-white mt-4  mb-0'>Abidjan-Côte d’Ivoire</p>
								<ul className="address-widget-list">
									<li className="footer-mobile-number mb-0">
									BP 755 cidex 3 Riviera 
									</li>
									<li className="footer-mobile-number mb-0">+(225) 076 740 55 90</li>
								</ul>
							</div>
							{/* <!--/ End Footer Contact --> */}
						</div>

					</div>
				</div>
			</div>
			{/* <!-- Copyright --> */}
			<div className="copyright border-bottom-red">
				<div className="container-lg">
					<div className="row">
						<div className="col-12">
							<div className="copyright-content">
								{/* <!-- Copyright Text --> */}
								<p>© 2021 Equerre - All right reserved{" / "}<a href="/side_map">Plan du site</a>{" / "}<a href="/mentions_legales">Mentions legales</a>{" / "} <a href="mailto:info@equerre.com">info@equerre.com</a></p>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!--/ End Copyright --> */}
		</footer>
		
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (Footer))
