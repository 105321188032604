import React from 'react';
import {hydrate, render} from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import './i18n';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import { reducers } from './redux/reducers';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, {}, composeEnhances(applyMiddleware(thunk)));


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Provider store={store}>
    <HelmetProvider>
    <App />
  </HelmetProvider>
  </Provider>, rootElement);
} else {
  render(<Provider store={store}>
    <HelmetProvider>
    <App />
  </HelmetProvider>
  </Provider>, rootElement);
}

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root'),
// );

 