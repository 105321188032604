import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { useMediaQuery } from '../../utils/utlis';

function Actuality(props) {
    const { t } = props
    const isRowBased = useMediaQuery('(min-width: 500px)');
  return (
    <>
        <Helmet>
            <title>Actualités</title>
            <meta name="description" content="Your company resource manage 360 degrees"/>
            <meta property="og:title"content="actuality"/>
            <meta
            property="og:description"
            content="Your company resource manage 360 degrees"/>
            <link rel="canonical" href="https://www.equerre.com/actuality" />
            <meta property="og:image"  content="/assets/img/actuality-img-1.png"/>
            <meta name="twitter:image" content="/assets/img/actuality-img-1.png"/>


            <meta property="og:locale" content="en_US" />
            {/* <meta property="og:type" content="article" /> */}
            <meta property="og:url" content="https://www.equerre.com/actuality" />
            <meta property="og:site_name" content="Your company resource manage 360 degrees" />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta property="og:image:type" content="image/png" />
        </Helmet>
        
        {/* <!-- Hero Slider --> */}
        <section className="style1 section-bg-white pb-5"
        style={{ height: '647px', backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/actuality-img-2.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "top left", borderBottom: '5px solid #fff', backgroundPosition: "center right"}}
        >
			<div className="">
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-6 col-sm-12 py-5 mt-5 mb-5">
								<div className="welcome-text "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'3.5em', color: '#fff', lineHeight:'1em'}}>Actualités / Evènements</h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',color: '#fff', lineHeight:'1.2em', fontWeight: 'normal'}}>Découvrez les dernières actualités, tenez vous informés sur les dernières sorties et nouveautés produits, et plus encore.</p>
										<div className="button  my-4">       
										</div>
									</div>
								</div>
							</div>
                            <div className=" col-lg-5 col-md-6 hidden-sm col-sm-0">
                                
                                {/* <img className='center-img' style={{ height: `${isRowBased ? '360px':'unset'}`, objectFit: 'cover'}} src='/assets/img/actuality-img-1.png'/> */}
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>
        <section>
            <div className='container-lg'>
                <div className="row my-5">
                    <div className="col-lg-6 col-md-8 col-12">
                        <h2 style={{color:'var(--eqr-primary-color)', extTransform: "normal",fontSize:'3.5em', lineHeight:'1em'}}>Dernières nouvelles</h2>
                    </div>
                </div>

                <div className='row'>
                    <div>
                        <div className='row mb-5'>
                            <div className='col-12 '>
                                <h1 className='mb-1 text-dark'>Bange Bank Cameroun choisit Equerre Paie 500 pour internaliser et fiabiliser la gestion de sa paie. </h1>
                                <h4 style={{color: "#009ade"}} className='my-4 h4 fw-400'>L’entreprise</h4>
                                <p className='mb-1 h5 fw-normal text-dark'>BANGE (Banque Nationale de la Guinée Équatoriale) BANK CAMEROUN est une banque camerounaise au capital social de 10 000 000 000 francs CFA. Elle a débuté ses activités commerciales au Cameroun le 25 octobre 2021. Bange Bank Cameroun dispose d’un réseau d’agences en pleine croissance. </p>
                                {/* <br/> */}
                                <h4 style={{color: "#009ade"}} className='mt-4 mb-2 h4 fw-400'>Le défi</h4>
                                <p className='mb-1 h5 fw-normal text-dark'>Dans une entreprise en pleine croissance d’effectifs, la banque recherchait un logiciel flexible capable d’être déployée sur site et d’offrir un self-service RH à son personnel pour qu’il puisse accéder à son historique de paie sans contacter la Direction RH de la banque. En plus d’offrir une paie inverse fiable, les autres défis consistaient à dématérialiser la distribution des bulletins de paie, interfacer la biométrie et le core banking pour produire les rapports comptables attendus. </p>
                                {/* <br/> */}
                                <h4 style={{color: "#009ade"}} className='mt-4 h4 fw-400'>La solution</h4>
                                <p className='mb-4 h5 fw-normal text-dark'>Face à ces enjeux, Bange Bank Cameroun a choisi de faire confiance à Equerre Paie 500 pour piloter la gestion des rémunérations des employés et des autres collaborateurs de la banque. </p>

                                <div>
                                    <div className="panel-heading collapsed" data-toggle="collapse" data-target="#collapseOrderItems1">
                                    <h5 style={{color: "var(--eqr-primary-color)"}}>Voir plus..<i className="ml-2 chevron fa fa-fw" /></h5>
                                    </div>
                                    
                                    <div className='collapse' id="collapseOrderItems1">
                                                
                                        <p className='my-4 h5 fw-normal text-dark'>« Equerre Paie 500 est venu s’ajouter à notre parc logiciel déjà fourni, qui comprend notamment le core banking, la comptabilité, la biométrie, la gestion commerciale et la gestion des immobilisations, entre autres… », relève un cadre de la DRH de Bange Bank Cameroun.  </p>
                                        
                                        <p className='my-4 h5 fw-normal text-dark'>« La paie est un domaine très particulier où l’expertise métier et la maîtrise logicielle doivent se combiner. Equerre possède cette valeur ajoutée » : M. PONDY Alexandre, Responsable Paie Bange Bank Cameroun. </p>
                                    
                                        <p className='my-4 h5 fw-normal text-dark'>Equerre a apporté immédiatement à Bange Bank Cameroun un alignement aux contraintes légales du secteur bancaire en vigueur pour que la nouvelle banque puisse bénéficier d’une solution moderne et règlementaire sur le plan légal. </p>
                                        
                                        <p className='my-4 h5 fw-normal text-dark'> «… La gestion de la paie mobilise trois personnes : un assistant en Ressources Humaines, un responsable paie et moi-même. Nous traitons désormais notre paie mensuelle en 2 jours, sans difficulté particulière ni goulot d’étranglement dans la chaîne de traitement. Les workflows et les privilèges ont très bien été configurés. Nous avons énormément gagné en sérénité, bien accompagnés par OVERXLS, l’intégrateur de Equerre. Il est resté très présent à nos côtés… </p>
                                    
                                        <p className='my-4 h5 fw-normal text-dark'>…De plus, nous n’avions jusque-là aucun logiciel satisfaisant pour l’interfaçage avec la biométrie et le core banking de la banque. Equerre a remis tout cela à plat. À présent, Equerre Paie 500 répond véritablement à nos attentes… » : Patrice ENANGA, Directeur des Ressources Humaines Bange Bank Cameroun. </p>
                                    
                                    
                                        <h4 style={{color: "#009ade"}} className='mt-4 mb-2 h4 fw-400'>La situation post adoption</h4>
                                        <p className='mb-1 h5 fw-normal text-dark'>Après le déploiement de Equerre Paie 500 à Bange Bank Cameroun, la gestion de la paie est devenue très fluide ; les variations salariales de la paie de la banque sont traitées sans problème avec Equerre Paie 500. </p>
                                    
                                        <p className='my-4 h5 fw-normal text-dark'>« Equerre sait configurer la solution en conséquence à chaque changement de règle ou de taux d’imposition. Equerre nous a permis d’anticiper sur le change de grille salariale du secteur bancaire désormais en vigueur. C’est ce que nous avons fait et nous sommes vraiment satisfait. » Patrice ENANGA, DRH Bange Bank Cameroun.</p>
                                    

                                        <h4 style={{color: "#009ade"}} className='mt-4 mb-2 h4 fw-400'>Le prestataire</h4>
                                        <p className='mb-1 h5 fw-normal text-dark'>OVERXLS est intégrateur des logiciels de la suite Equerre. C’est vraiment un partenaire de confiance, qui me permet de me consacrer à d’autres priorités en fiabilisant notre système d’information de gestion : Hugues TENKEU, Directeur du Système d’Information de Bange Bank Cameroun.</p>
                                    
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>




                    {/* <div className='row mb-5'>
                    <div className='col-12 text-dark'>
                        <h3 className='mb-3 text-dark'>Pourquoi rejoindre Equerre ? </h3>
                        <p className='mb-3 h5 fw-normal'>Chez Equerre, nous cultivons l’excellence. Pour parvenir à cette excellence, il nous est indispensable de collaborer avec des profils qui poursuivent le même but. Nous sommes convaincus que l’élément prépondérant qui fait la réussite d’une organisation, est la caractéristique des personnes qui la compose.  </p>
                        <p className='mb-3 h5 fw-normal'>Ainsi, notre politique sociale est basée sur la motivation et la fidélisation de nos collaborateurs. Au-delà de nos offres de rémunérations motivantes, nous mettons l'accent sur la reconnaissance, un management de proximité et la mise en place de divers programmes de récompenses, d’avantages et de services, en ligne avec notre culture d’entreprise :</p>
                    </div>
                    </div>
                    
                    <div className='row mb-5'>
                    <div className='col-12 text-dark'>
                        <h3 className='mb-3 text-dark'>Contexte et description du poste </h3>
                        <p className='mb-3 h5 fw-normal'>Sous l'autorité d’un Tech Lead, vous avez en charge de concevoir et de coder des programmes et des applications fonctionnels. Vous travaillerez à la fois au sein d’une équipe et de façon individuelle, avec peu de supervision. Vous jouissez d’excellentes connaissances des langages de programmation et maîtrisez différentes plateformes, ainsi que différents systèmes d’exploitation. Ces compétences vous permettent de rédiger un code propre et sans faille, gage de production des applications logicielles entièrement fonctionnelles conformes aux exigences. </p>
                    </div>
                    </div> */}
                    <div className="button mb-4 d-flex" style={{ justifyContent: 'flex-end'}}>      
                        <a  style={{height: '100%', display: 'block'}}  href="/actuality_details" className="bizwheel-btn theme-2  effect primary-color">Voir plus d'actualites</a> 
					</div>
                </div>
            </div>
        </section>
        <section className="blog-layout blog-latest section-space" style={{backgroundColor: '#e2f6ff', borderBottom: '5px solid #fff'}}>
            <div className="container-lg">
                <div className="row my-5">
                    <div className="col-lg-6 col-md-8 col-12">
                        <h2 style={{color:'var(--eqr-primary-color)', extTransform: "normal",fontSize:'3.5em', lineHeight:'1em'}}>Suivez-nous sur les reseaux sociaux</h2>
                    </div>
                </div>

                <div className='row'>
                <div className="button" >
                    <a target="_blanc" style={{minHeight: '75px', width: '75px',}} href="https://www.youtube.com/@equerre"  className="mt-3 bizwheel-btn theme-2  effect primary-color" ><i class="pt-3 fa fa-youtube" style={{fontSize: '30px'}}></i></a>
                    <a target="_blanc" style={{minHeight: '75px', width: '75px'}}  href="https://web.facebook.com/profile.php?id=100088494751623&is_tour_dismissed=true"  className="mt-3 bizwheel-btn theme-3  effect facebook" ><i class="pt-3 fa fa-facebook" style={{fontSize: '30px'}}></i></a>
                    <a target="_blanc" style={{minHeight: '75px', width: '75px'}}  href="https://twitter.com/EquerreSa"  className="mt-3 bizwheel-btn theme-3  effect twitter" ><i class="pt-3 fa fa-twitter" style={{fontSize: '30px'}}></i></a>
                    <a target="_blanc" style={{minHeight: '75px', width: '75px'}}  href="https://www.linkedin.com/company/equerre-sa/about/?viewAsMember=true"  className="mt-3 bizwheel-btn theme-3 effect linkedin" ><i class="pt-3 fa fa-linkedin" style={{fontSize: '30px'}}></i></a>
                    <a target="_blanc" style={{minHeight: '75px', width: '75px'}}  href="https://www.instagram.com/equerre_sa/"  className="mt-3 bizwheel-btn theme-3 effect instagram" ><i class="pt-3 fa fa-instagram" style={{fontSize: '30px'}}></i></a>
                </div>
                

                </div>
            </div>
            </section>
        
    <section
      className="skill-area"
      style={{ borderTop: '1px solid #fbeefb', borderBottomColor:'var(--eqr-terciary-color)',  backgroundColor: '#FFF8FF' }}
    >
      <div className="container">
						<div className="row">
                
            <div className="col-lg-5 col-md-6 col-12">
                
                <img style={{ objectFit: 'cover'}} src='/assets/img/actuality-img-2.png'/>
            </div>

            <div className="py-5 my-5 offset-lg-1 col-lg-6 col-md-8 col-12">
                <div className="welcome-text"> 
                    <div className="hero-text"> 
                        <p className='mb-2'>VOUS ÊTES ANALYSTE, JOURNALISTE OU BLOGUEUR ? <br/> CONTACTEZ NOTRE RESPONSABLE RELATIONS PRESSE.</p>
                        <h2 className='mb-4'>Contact médias</h2>
                        <p className='mb-4 h5 fw-normal text-dark'><b>Claudia Esther Ngonguinya Mboule</b></p>
                        <p className='mb-4 h5 fw-normal text-dark'><a href='tel:Telephone :00237233480903'>Telephone : +(237) 233 48 09 03</a></p>
                        <p className='mb-4 h5 fw-normal text-dark'><a href='mailto:claudia.ngonguinya@equerre.com'>Email : claudia.ngonguinya@equerre.com</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>

    </>
  )
}

export default withNamespaces() (Actuality)