import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withNamespaces } from 'react-i18next';
import { sendSuccessCheckout } from '../../redux/acitons/pricingActions'

const SuccessCheckout = (props) => {
  
  const queryParams = new URLSearchParams(props.location.search.toString())
  const payment_intent = queryParams.get("payment_intent")
  const payment_intent_client_secret = queryParams.get("payment_intent_client_secret")
  const redirect_status = queryParams.get("redirect_status")
  const { t } = props
  // console.log('--- redirect_status', redirect_status)

  useEffect(() => {
    // let _redirect_status = queryParams.get("redirect_status")
    // if (!_redirect_status){return;}
    // if (_redirect_status === "succeeded"){
    //   let value = JSON.parse(localStorage.getItem("body"))
    //   value.body.user.payment_intent = payment_intent
    //   value.body.user.payment_intent_client_secret = payment_intent_client_secret
    //   props.sendSuccessCheckout(value.body)
    // }
    return () => {
      localStorage.removeItem("body")
      localStorage.removeItem("userToken")
    }
  }, [])
  
  return (
    
    <div className="container">
      {redirect_status && redirect_status === "succeeded" ? (
        <div className="row mb-5">
            <div className="col-md-6 mx-auto mt-5 p-0 single-feature">
                <div className="payment" >
                    <div className="payment_header mb-3 p-5" style={{backgroundColor: "#2aa270"}}>
                    <div className="d-flex justify-content-center align-items-center "><i className="fa fa-check" aria-hidden="true"></i></div>
                    </div>
                    <div className="content p-4 pb-5">
                    <h1>{t("our_SuccessCheckout_titlep1")}</h1>
                    <p><strong>{t("our_SuccessCheckout_titlepa1")}</strong>{t("our_SuccessCheckout_textpb1")}</p>
                    <a className='mt-4' href="/">{t("our_SuccessCheckout_lienp1")}</a>
                    </div>
                    
                </div>
            </div>
        </div>
      ):(
        
        <div className="row mb-5">
            <div className="col-md-6 mx-auto mt-5 p-0 single-feature">
                <div className="payment" >
                    <div className="payment_header mb-3 p-5" style={{backgroundColor: "#2aa270"}}>
                    <div className="d-flex justify-content-center align-items-center "><i className="fa fa-check" aria-hidden="true"></i></div>
                    </div>
                    <div className="content p-4 pb-5">
                    <h1>{t("our_SuccessCheckout_titlep2")}</h1>
                    <p><strong>{t("our_SuccessCheckout_titlepa2")}</strong>{t("our_SuccessCheckout_textpb2")}</p>
                    <a className='mt-4' href="/">{t("our_SuccessCheckout_lienp2")}</a>
                    </div>
                    
                </div>
            </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log('state :>>', state)
  return {
    userProductData : state.user_product.selected_modules,
  }
}

const mapDispatchToProps = dispatch => {
    return {
      sendSuccessCheckout:(data)=> dispatch(sendSuccessCheckout(data)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces() (SuccessCheckout))
