import React, { useEffect, useState, createRef } from 'react'
import { connect } from 'react-redux'
import { fetchUserProducts } from '../../redux/acitons/userProductAction'
import { useFormik } from "formik";
import * as Yup from "yup";
import { env_config, API, GOOGLE_RECAPTCHA_KEY } from '../../env';
import { ClipLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";

const _reCaptchaRef = createRef();
const TEST_SITE_KEY = GOOGLE_RECAPTCHA_KEY;
const ContactUsForm = ({fetchUserProducts, userProductData}) => {
	let [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false)
	useEffect(() => {
	//   console.log('-- moduleData', moduleData)
	localStorage.getItem("userToken")
	fetchUserProducts()
	return () => {		
	}
	}, [])
	
    const formik = useFormik({
      initialValues: {
        last_name: "",
        first_name: "",
        company: "",
        phoneNumber: "",
        email: "",
		message: "",
		subject: "",
		company_size: 1,
		recaptcha: "",
      },
      validationSchema: Yup.object({

        first_name: Yup.string()
		.max(30, "Must be 30 characters or less")
		.required("Required"),
        last_name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        company: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        phoneNumber: Yup.number()
          .typeError("That doesn't look like a phone number")
          .positive("A phone number can't start with a minus")
          .integer("A phone number can't include a decimal point")
          .required("A phone number is required"),
		subject: Yup.string()
		.required('Please enter subject'),
        message: Yup.string().required('Please let\'s us know your requirement'),
		company_size: Yup.number(),
		recaptcha: Yup.string().required(),
      }),
      onSubmit: async (values, { resetForm }) => {
        
		  		//   console.log('first', values)
		  const config = env_config;
		  
        try {
			setLoading(true)
          await API
            .post("/email/contact_us", {
                last_name: values.last_name,
                first_name: values.first_name,
                company: values.company,
                phoneNumber: values.phoneNumber,
				company_size: values.company_size,
                email: values.email,
                message: values.message,
                subject: values.subject
			}, config)
            .then((res) => {
              console.log('res', res)
              setMessage({code: 200, message:"Your request has reached our staff, we will get back to you."});    
              setTimeout(() => {
                // Most recent value
                setMessage(false);    
              }, 8000);
			  setLoading(false)

              resetForm({ values: "" });
            });
        } catch (error) {
          console.error("-- error", error);
		  setLoading(false)
          setMessage({code: 404, message: error})
        }
      },
    });

  return (
    <div>
        
		{/* <!-- Contact Us --> */}
		<section className="contact-us section-space py-0">
				<div className="row">
					<div className="col-12">
						{/* <!-- Contact Form --> */}
						<div className="contact-form-area m-top-30">
							
							{message && message.code ? 
								message.code  == "200" ? (
								<div className="alert alert-success mb-5" role="alert">
									{message.message}
								</div>
								) : (
									<div className="alert alert-danger mb-5" role="alert">
									{JSON.stringify(message?.message?.message)}
									</div>
								)          
							:null}

							<h4 style={{fontSize: '25px'}}>Prenez un rendez-vous</h4>
							<form 
								onSubmit={formik.handleSubmit}
								id="registration-form"
								className="form" method="post" action="">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input
													
													id="first_name"
													name="first_name"
													type="text"
													placeholder="Prénom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.first_name}
												/>
												{formik.touched.first_name && formik.errors.first_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.first_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input													
													id="last_name"
													name="last_name"
													type="text"
													placeholder="Nom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.last_name}
												/>
												{formik.touched.last_name && formik.errors.last_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.last_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-envelope"></i></div>
												<input													
													id="email"
													name="email"
													type="email"
													placeholder="Email"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
												) : null}
										</div>
									</div>
									
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-phone"></i></div>
											
											<input
												id="phoneNumber"
												name="phoneNumber"
												type="tel"
												placeholder="Numéro de téléphone"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.phoneNumber}
											/>
											{formik.touched.Name && formik.errors.phoneNumber ? (
												<div style={{ color: "#dc3545" }}>
												{formik.errors.phoneNumber}
												</div>
											) : null}
												
										</div>
									</div>

									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-building"></i></div>
											
											<input													
													id="company"
													name="company"
													type="text"
													placeholder="Companies"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.company}
												/>
												{formik.touched.company && formik.errors.company ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.company}</div>
												) : null}
												
										</div>
									</div>
									
									<div className="col-lg-6 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-cloud"></i></div>
											
											<input													
													id="subject"
													name="subject"
													type="text"
													placeholder="Objet"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.subject}
												/>
												{formik.touched.subject && formik.errors.subject ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.subject}</div>
												) : null}
												
										</div>                                        
									</div>
                                    <div className="col-12">
                                        <div className="form-group textarea">
                                            <div className="icon"><i className="fa fa-pencil"></i></div>
                                            <span className="wpcf7-form-control-wrap" data-name="textarea-162">
                                            <textarea 								
													id="message"
													name="message"
													type="text"
													placeholder="Votre message"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.message} cols="25" rows="6"></textarea>
                                                    
                                                    {formik.touched.message && formik.errors.message ? (
                                                        <div style={{ color: "#dc3545" }}>{formik.errors.message}</div>
                                                    ) : null}
                                            
                                            </span>
                                        </div>
                                    </div>
									

									<div className="col-lg-12 col-md-12 col-12">
									<div className="form-group">
										{/* <label>Recaptcha Validation</label> */}
										<ReCAPTCHA
										sitekey={TEST_SITE_KEY}
										render="explicit"
										onChange={(response) => {formik.setFieldValue("recaptcha", response); }}
										onExpired={() => { formik.setFieldValue("recaptcha", ""); }}
										/>
										
										{formik.touched.recaptcha && formik.errors.recaptcha ? (
											<div style={{ color: "#dc3545" }}>{formik.errors.recaptcha}</div>
										) : null}
									</div>                                       
									</div>

									
									<div className="col-5 d-flex justify-item-center">
										<div className="form-group button">
											<button disabled={loading} type="submit" className="bizwheel-btn theme-2">
													<div style={{display: 'flex', alignItems: 'center',}}>
													<div style={{marginRight: '.4em'}}>Envoyer</div> <ClipLoader color={"#fff"} loading={loading} size={20} />
													</div>
											</button>		
										</div>
									</div>
									
								</div>
							</form>
						</div>
						{/* <!--/ End contact Form --> */}
					</div>
				</div>
		</section>	
		{/* <!--/ End Contact Us --> */}
		
    </div>
  )
}

const mapStateToProps = (state) => {
      console.log('state.user_product.selected_modules', state.user_product.selected_modules)
      return {
        userProductData : state.user_product.selected_modules
      }
    }
    
    const mapDispatchToProps = dispatch => {
        return {
          fetchUserProducts: (token)=> dispatch(fetchUserProducts(token)),
        };
      };
    
    export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm)