import * as actionType from '../constants/actionTypes';

const moduleReducer = (state = { moduleList: []  }, action) => {
  switch (action.type) {
    case actionType.FETCH_MODULES:
      // localStorage.setItem('profile', JSON.stringify({ ...action?.data }));

      return { ...state, moduleList: action.payload };
    default:
      return state;
  }
};

export default moduleReducer;