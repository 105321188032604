import React from 'react'
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

const EquerreCloudHotel = (props) => {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre CloudHotel"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre_cloud_hotel" />
        <meta property="og:image"  content="/assets/img/equerre_cloud_hotel-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/equerre_cloud_hotel-img-1.png"/>

        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre_cloud_hotel" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white" 
                style={{ height: '647px', backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/cloudhotel-equerre.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "top left", borderBottom: '5px solid #fff', backgroundPosition: "top right"}}
                >
			<div className="">
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 py-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'black'}}>Equerre CloudHotel </h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#000', fontWeight: 'normal'}}>Une puissante plateforme de  <br/>hôtelière qui vous aide à augmenter vos  <br/>revenus et à simplifier vos opérations.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-1 px-3 effect mt-lg-3 primary-color">Démandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                    <div className="col-lg-5 col-md-6 col-12">
                        
                        {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_cloud_hotel-img-1.png'/> */}
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>  
    
    
        <section className='equerre_cloud_hotel hero_img_block' style={{ minHeight: '647px', borderBottom: '5px solid #fff', backgroundPosition: "top right"}}
>
            <div className="container-fluid">
                <div className='row'>
                    <div className='offset-lg-1 col-lg-6 col-12 my-2'>
                                
                        <div className="row pt-5">
                            <div className='col-12 mb-5'>                        
                                <h2 style={{color: '#000' , fontSize: '3.7em', lineHeight: "1.2em"}} className='text-dark'>Synchronisez tous vos portails de réservation </h2>
                                <p className='text-dark' style={{fontSize:'1.5em', lineHeight:'initial'}}>Equerre CloudHotel garantit que toutes vos réservations sont automatiquement synchronisées sur tous vos portails de réservation. </p>
                            </div>
                            <div className='col-12'>                        
                                <h2 style={{color: '#000' , fontSize: '3.7em', lineHeight: "1.2em"}} className='text-dark'>Toutes vos réservations dans un lieu unique</h2>
                                <p className='text-dark' style={{fontSize:'1.5em', lineHeight:'initial'}}>Votre cockpit vous fournit toutes les informations pertinentes et les indicateurs de performance clés en un coup d’œil. Visualisez toutes vos réservations à partir de tous les portails connectés dans une liste clairement organisée. Notre système de réservation (PMS) est entièrement intégré à notre Channel Manager. </p>
                            </div>
                        </div>
                
                    </div>

                    
                    <div className='col-4 my-5'>
                            {/* <img className='center-img pl-3' style={{ height: '360px', objectFit: 'cover', backgroundSize: '200%'}} src='/assets/img/Cloudhotel-Equerre-img-2.png'/>                 */}
                    </div>

                </div>
            </div>      

        </section>

        <section id="" className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container-lg">
                <div className="row mb-4">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Découvrez comment Equerre CloudHotel vous   <br/> permet de rationaliser les opérations et offrir des expériences <br/>client mémorables.
                        
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className='offset-lg-3 col-lg-6 offset-md-2 col-md-8  col-12'>
                    <ul class="fa-ul">
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestion de propriété </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Canaux de distribution </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Moteur de réservation </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Moteur de réservation de groupe </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestion des revenus</h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestion des paiements </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Intégration illimitée </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Développement de sites Web </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Point de vente  </h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Gestionnaire des commentaires des clients</h3></li>
                        <li><h3 className='text-dark mb-3'><span class="fa-li"><i class="fa fa-check"></i></span>Marketing par courriel…</h3></li>

                    </ul>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ProductPageCommonComponent title={"Equerre CloudHotel"}/>
        </section>
    </>
  )
}

export default withNamespaces() (EquerreCloudHotel)