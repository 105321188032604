import React from 'react'
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

function EquerreCloudERP(props) {
    const {t} =props
  return (
    <>
        <Helmet>
            <title>Equerre Cloud ERP</title>
            <meta name="description" content="Your company resource manage 360 degrees"/>
            <meta property="og:title"content="Equerre Cloud ERP"/>
            <meta
            property="og:description"
            content="Your company resource manage 360 degrees"/>
            <link rel="canonical" href="https://www.equerre.com/equerre_cloud_erp" />
            <meta property="og:image"  content="/assets/img/equerre_cloud_erp-img-1.png"/>
            <meta name="twitter:image" content="/assets/img/equerre_cloud_erp-img-1.png"/>


            <meta property="og:locale" content="en_US" />
            {/* <meta property="og:type" content="article" /> */}
            <meta property="og:url" content="https://www.equerre.com/equerre_cloud_erp" />
            <meta property="og:site_name" content="Your company resource manage 360 degrees" />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta property="og:image:type" content="image/png" />

        </Helmet>

        
		<section className="style1 section-bg-white hero_img_block" 
              style={{ backgroundImage: "linear-gradient(90deg,rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)), url('/assets/img/home-block-2-img-4.jpg')", borderBottom: '5px solid #fff', borderTop: '5px solid #fff' }}>
              
			<div className="">
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 py-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-6 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'white'}}>Equerre Cloud ERP </h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>La solution de gestion d’entreprise nouvelle génération dotée de technologies intelligentes intégrées.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-3 px-3 effect mt-lg-3">Demandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                    <div className="col-lg-6 col-md-6 col-12">
                        
                        {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_project_management-img-1.png'/> */}
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>  


        <section className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Qu’est-ce que Equerre Cloud ERP ?
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-12 mb-5'>
                        <p className="text-dark mt-4" style={{fontSize:'1.7em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre Cloud ERP est un système ERP complet disponible sur Cloud public ou privé, ou dans un environnement hybride. Destiné aux grandes entreprises qui souhaitent rendre leurs organisations plus agiles, Equerre Cloud ERP est la solution de gestion d’entreprise nouvelle génération dotée de technologies intelligentes intégrées. Elle permet aux entreprises de reprendre le contrôle et de gagner en agilité.</p>
                    </div>
                    <div className="col-lg-6 col-md-4 col-12">
                        
                        <div className="single-content">
                            <h3 className='text-dark mb-1'>Plus riche</h3>

                            <p className='my-4' style={{fontSize:'1.3em', lineHeight:'initial'}}>
                            Equerre Cloud ERP est le seul ERP du marché qui couvre quasiment tous les domaines de l’entreprise avec des fonctions d’une puissance exceptionnelle. Il répond aux problématiques des plus simples aux plus complexes. Nous avons ainsi fait le choix de proposer en natif une solution complète, plutôt qu’une politique du verre à moitié vide dont les « options » rendent très souvent l’addition indigeste pour les clients.</p>                                                        
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-12">
                        
                        <div className="single-content">
                            <h3 className='text-dark mb-1'>Plus flexible </h3>

                            <p className='my-4' style={{fontSize:'1.3em', lineHeight:'initial'}}>
                            Comme toutes les solutions de la Suite Equerre, Equerre Cloud ERP est ultra personnalisable. Il a été pensé pour pouvoir intégrer des fonctionnalités surmesure dans un délai raisonnable. La solution peut également être déployée dans le Cloud (gérée par Equerre) ou bien dans votre centre de données.</p>                                                        
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="services  section-space mt-0 py-5 hero_img_block" style={{ borderBottom: '5px solid #fff', backgroundImage: "linear-gradient(90deg,rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)), url('/assets/img/1664893694944.jpg')", backgroundSize: 'cover',  }}>
            <div className="container-fluid ml-lg-5">
                <div className="row mb-5">
                    <div className="offset-lg-1 col-lg-10 col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '2.6em', lineHeight: "1.2em"}}>
                        Découvrez comment un ERP Intelligent peut vous apporter les insights et les outils nécessaires pour développer votre business
                        </h2>
                    </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-7 col-12'>

                            
                        <div className='row'>
                            
                            <div className="col-lg-3 col-md-4 col-12">
                                
                                <div className="single-content">
                                    <h4 className='text-dark mb-4'>GESTION <br/>FINANCIERE </h4>
                                    <div className="single-widget widget">
                                        <ul className="address-widget-list">
                                        <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Comptabilité générale </h5></li>
        
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Comptabilité analytique</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Comptabilité Client </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Comptabilité fournisseur </h5></li>

                                            
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Facturation </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion de la trésorerie </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Automatisation des écritures OHADA, GAAP ou IFRS </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des immobilisations </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion de la fiscalité et calcul de la TVA </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des risques </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion budgétaire </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Reporting et Analyse financière </h5></li>
                                        </ul>
                                    </div>                                                        
                                </div>
        
                            </div>
                            
                            <div className="col-lg-3 col-md-4 col-12">
                                    
                                <div className="single-content">
                                    <h4 className='text-dark mb-4'>GESTION DES <br/>RESSOURCES  HUMAINES </h4>
                                    <p className='h5 text-dark mb-3 fw-semi-bold'> <b><i class="fa fa-check pl-1"></i>{" "}Gestion administrative des employés </b> ({" "}gestion du temps et des présences, gestion des congés et absences, gestion des missions des employés, gestion des dossiers du personnel, gestion des contrats, gestion sociale, gestion des sanctions, gestion de la newsletter{" "}) </p>
                                    <div className="single-widget widget">
                                        <ul className="address-widget-list">
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Paie du personnel </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Structure organisationnelle </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Recrutement  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Formation   </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Carrière </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Evaluations </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Self-service du personnel </h5></li>
                                        </ul>
                                    </div>                                                        
                                </div>
        
                            </div>
        
                            
                            <div className="col-lg-3 col-md-4 col-12">
                                    
                                <div className="single-content">
                                    <h4 className='text-dark mb-4'>GESTION <br/>COMMERCIALE </h4>
                                    <div className="single-widget widget">
                                        <ul className="address-widget-list">
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}<b>Chaine d'approvisionnement </b>(Achat, Stock, Vente, Logistique) </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}<b>CRM </b> (Gestion des clients, Gestion des prospects, Vente multicanaux, Gestion des rendez-vous, Journaux d'appels, Fidélisation…)</h5></li>
                                            {/* <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Vente </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Contrat </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des clients  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des prospects  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Vente multicanaux  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des rendez-vous  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Journaux d'appels  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Fidelisation </h5></li> */}
                                        </ul>
                                    </div>     
                                    
                                    {/* <h4 className='text-dark mb-4'>CHAINE <br/>D'APPROVISIONNEMENT </h4>
                                    <div className="single-widget widget">
                                        <ul className="address-widget-list">
                                        <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Achat </h5></li>
        
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Stock</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Logistique </h5></li>
                                            
                                        </ul>
                                    </div>  */}

                                </div>
        
                                <div className="single-content">
                                    <h4 className='text-dark mb-4'>UTILITAIRES <br/>INTELLIGENTS </h4>
                                    <div className="single-widget widget">
                                        <ul className="address-widget-list">
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des objectifs  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des sauvegardes  </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Sondages </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Annonces </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Partage de fichiers </h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Excel en ligne…  </h5></li>
                                        </ul>
                                    </div>                                                        
                                </div>

                            </div>

        
                            <div className="col-lg-3 col-md-4 col-12">
                                    
                            <div className="single-content">
                                    <h4 className='text-dark mb-4'>GESTION DE<br/>LA PRODUCTION </h4>
                                    <div className="single-widget widget">
                                        <ul className="address-widget-list">
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion de la production</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion de la qualité</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Suivi optimisé des calendriers de production</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Opérations digitales : gestion centralisée, à distance et sur mobile</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Gestion des projets</h5></li>
                                            <li className="footer-mobile-number mb-1"><h5 className='text-dark'><i class="fa fa-check pl-1"></i>{" "}Analytique avancée et en temps réel</h5></li>
                                        </ul>
                                    </div>                                                        
                                </div>
                                
        
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </section>


        
		<section className="style1 section-bg-white" 
        style={{
          
        }}>
			<div className="" style={{backgroundColor: 'rgba(232, 145, 26, .90)'}}>
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 py-lg-5" >
					<div className="container">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text pt-5 pb-2 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'4em', lineHeight:'1.2em', color: 'white'}}>Un ERP qui <br/>booste votre <br/>productivité </h2>
										{/* <div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-3 px-3 effect mt-lg-3">Regardez la vidéo de présentation </a>
										</div> */}
									</div>
								</div>
							</div>
                    <div className="col-lg-5 col-md-6 col-12 pb-5">
                        
						<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Equerre Cloud ERP prend en compte la complexité de vos métiers tout en vous offrant un meilleur confort d’utilisation et une ergonomie optimale. Equerre Cloud ERP s’intègre aisément dans votre système d’information et vous permet de digitaliser vos processus de travail en toute sérénité.</p>
                        
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>

        
        <section>
            <ProductPageCommonComponent title={"Equerre Cloud ERP"}/>
        </section>
    </>
  )
}

export default withNamespaces() (EquerreCloudERP)