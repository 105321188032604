import * as actionType from '../constants/actionTypes';

const moduleLineReducer = (state = { moduleLineList: []  }, action) => {
  switch (action.type) {

    case actionType.INITIATE_MODULE_LINE_REQUEST:
      return { ...state, loading: true };
    case actionType.FETCH_MODULELINES:
      return {...state, moduleLineList: action.payload}
    case actionType.CREATE_MODULELINES:
      return {
        ...state,
        loading: false,
        moduleLineList: [...state.moduleLineList, action.payload],
        error: '',
      };
    case actionType.INITIATE_MODULE_LINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default moduleLineReducer;