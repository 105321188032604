import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import utils from "../../utils/utlis";
import { Helmet } from 'react-helmet-async';
import { PropagateLoader } from "react-spinners";
import { FooterSummary, SummaryWidget } from "../../components";
import {
  createModuleLine,
  fetchModuleLines,
} from "../../redux/acitons/modulelines";
import { fetchModules } from "../../redux/acitons/modules";
import {
  addAllModuleLineOfParentModule,
  addModuleLine,
  fetchUserProducts,
  removeModuleLine,
  removeAllModuleLineOfParentModule,
} from "../../redux/acitons/userProductAction";
import { useEffect } from "react";
import MarketPlaceComponents from "./MarketPlaceComponents";

const { ExtendButton, AddToCartButton, DivMkplHomeHero } = MarketPlaceComponents;
// const { modules, modulelines } = datas;
const Marketplace = (props) => {
  const {
    removeAllModuleLineOfParentModule,
    removeModuleLine,
    addAllModuleLineOfParentModule,
    addModuleLine,
    fetchModules,
    moduleData,
    createModuleLine,
    fetchModuleLines,
    moduleLineData,
    fetchUserProducts,
    userProductData,
  } = props;

  // initiate
  const [modules, setModules] = useState([...moduleData]);
  const [selectAll, setSelectAll] = useState({});
  const [loading, setLoading] = useState(false);

  const [user_tokent, setUserToken] = useState();

  // loading data
  useEffect(() => {
    //   console.log('-- moduleData', moduleData)
    
    setLoading(true)
    const fecthData = async () => {
      let f_moduleslines = await fetchModuleLines();
      let _userToken = localStorage.getItem("userToken");
      let f_userproducts = await fetchUserProducts(_userToken);
      if (modules.length <= 0) {
        fetchModules();
      } else {
        setModules(moduleData);
      }
      return { f_moduleslines, f_userproducts };
    };

    fecthData()
      .then((res) => {
        // logic to determine wether a module has all its submodule selected or not
        const { f_moduleslines, f_userproducts } = res;
        // console.log('first', res,f_moduleslines, f_userproducts)
        f_userproducts &&
          f_userproducts.forEach((_user_product) => {
            let _parent_submodules = f_moduleslines.filter(
              (_modulesline) => _modulesline.parent === _user_product.module_id
            );
            if (
              _parent_submodules.length ===
              _user_product.module?.submodules?.length
            ) {
              setSelectAll({
                ...selectAll,
                [`${_user_product.module_id}`]: true,
              });
            }
          });
      })
      .finally(() => {
        // console.log('in finally', )
        setLoading(false);
      });
    return () => {};
  }, []);
  // logics
  const checkIfUserHasSubmodule = (_userProductData, _submoduleId) => {
    let hasModule = false;
    let user_products = [];
    // console.log('-- In checkIfUserHasSubmodule :>> ', _userProductData, _submoduleId)
    _userProductData instanceof Array && _userProductData.map((_userproduct) => {
      // console.log('_userproduct', _userproduct.module.submodules)
      // return;
      if (_userproduct) {
        // console.log('_userproduct', _userproduct)
        _userproduct &&
          _userproduct.module.submodules.map((_submodule) => {
            // console.log('_submodule', _submodule.id , _submoduleId, )
            // return;
            if (_submodule.id === _submoduleId) {
              hasModule = true;
              user_products = _userproduct;
              return;
            }
          });
      }
    });
    //
    // console.log('-- checkIfUserHasSubmodule', hasModule)
    return { hasModule: hasModule, user_products: user_products };
  };

  const selectUserProduct = (_moduleData, _parentId) => {
    let _parentModule = _moduleData.filter(
      (_module) => _module._id === _parentId
    );
    // console.log('-- _moduleData, _parentId', _parentModule)
    return _parentModule[0];
  };
  const existingSubmodules = (_userProductData, _parentId) => {
    // console.log('_userProductData, _parentId', _userProductData, _parentId)
    let existing_modules = _userProductData.filter(
      (_user_product) => _user_product.module_id === _parentId
    );
    // console.log('existing_modules', existing_modules[0]?.module.submodules)
    // return
    return existing_modules[0]?.module.submodules;
  };
  const handleChangeSelectAll = (e) => {
    const { name, value } = e.target;
    //   console.log('name, value', name, value, e.target.checked)
    //   console.log('selectAll', selectAll[name])
    setSelectAll({
      ...selectAll,
      [name]: e.target.checked,
    });
    let _module = moduleData.filter((_module) => _module._id === name)[0];
    let _module_lines = [];
    if (e.target.checked === true) {
      _module_lines = moduleLineData.filter(
        (_module_line) => _module_line.parent === name
      );
    } else if (e.target.value === false) {
      removeAllModuleLineOfParentModule(name);
      return;
    }
    //   console.log('--- _module, _module_lines', _module, _module_lines)
    addAllModuleLineOfParentModule(_module, _module_lines);
    return;
  };
  const isAllSubmoduleSelect = (module_id) => {
    let f_moduleslines = [...moduleLineData];
    let f_userproducts = [...userProductData];
    //   console.log('f_userproducts', f_userproducts)
    let _user_product = f_userproducts.filter(
      (user_product) => user_product.module_id === module_id
    );
    //   console.log('_user_product', _user_product, 'module_id', module_id)
    if (_user_product instanceof Array) {
      _user_product = _user_product[0] || {};
      let _parent_submodules = f_moduleslines.filter(
        (_modulesline) => _modulesline.parent === module_id
      );
      if (
        _parent_submodules.length === _user_product.module?.submodules?.length
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
    <Helmet>
        <title>Equerre | MarketPlace</title>
        <meta name="description" content="Un système ERP complet disponible Sur Cloud public ou privé, ou dans un environnement hybride"/>
        <meta property="og:title"content="Equerre | MarketPlace"/>
        <meta
        property="og:description"
        content="Un système ERP complet disponible Sur Cloud public ou privé, ou dans un environnement hybride"/>
        <link rel="canonical" href="https://www.equerre.com/marketplace" />
        <meta property="og:image"  content="/assets/img/mrkt-img-9.png"/>
        <meta name="twitter:image" content="/assets/img/mrkt-img-9.png"/>
    </Helmet>
      <DivMkplHomeHero
        className="home-hero"
        style={{
          backgroundImage: "url('./assets/img/mrkt-img-9.png')", //objectFit: 'contain',
          backgroundSize: "100% auto%",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-12 mb-lg-5 mb-5">
              <div className="welcome-text">
                <div className="hero-text mt-4 pl-4">
                  <h1>Market Place</h1>
                  <p style={{ color: "#fff" }}>
                    Un système ERP complet disponible Sur Cloud public ou privé,
                    ou dans un environnement hybride{" "}
                  </p>
                  <div className="button mt-4">
                    <a
                      href="/our_products"
                      className="bizwheel-btn theme-1 effect primary-color"
                    >
                      Découvrez EquerreCloud ERP
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5"></div>
            <div className="col-12">
                
        <SearchButtonContainer className="mt-5 pl-4">
          <form action="#" className="search-form" method="get">
            <Input
              type="text"
              name="s"
              style={{ paddingLeft: "1em" }}
              defaultValue=""
              placeholder="Search here"
            ></Input>
            <Button>
              <i className="fa fa-search"></i>
            </Button>
          </form>
        </SearchButtonContainer>
            </div>
          </div>
        </div>
      </DivMkplHomeHero>

      {/* <TitleContainer className="container title_content my-5">
        <div className="title_content">
          <h2>Nos Services</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore, Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore
          </p>
        </div>
      </TitleContainer> */}
      <div className="container pl-4 pr-4">
        <div className="row  px-sm-4">
          <div className="col-md-3 mt-5 mb-4">
            <SummaryWidget />
            <div className="text-style-two botton">
              <a
                href={`/pricing`}
                className="bizwheel-btn theme-3 effect primary-color"
                style={{ width: "100%" }}
              >
                Checkout Now
                <i className="p-2 fa fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="col-md-9 ">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  {/* <h2>{JSON.stringify(loading)}</h2> */}
                  <br/>
                  <PropagateLoader style={{ marginTop: '8em',display: 'flex', alignItems: 'center', justifyContent: 'center'}} color={"#009ade"} loading={loading} size={20} />
                </div> 
              {/* Features Area */}
              <section className="features-area section-bg pt-0">
                  {[...moduleData].map((module, module_index) => (
                    <div key={module_index}>
                      <div className="row">
                        <div className="col-12" >
                          <div className="section-title style2 text-center mb-0">
                            <div className="section-top mt-4">
                              <h3>{module.name}</h3>
                              <h4>
                                {module.description && module.description.toString().substring(0, 80)}
                              </h4>
                            </div>
                            <div className="section-bottom text-align-sm-start">
                              <div
                                className="text-style-two botton"
                                style={{ display: "-webkit-inline-box" }}
                              >
                                <a
                                  href={'#'}
                                  className="bizwheel-btn theme-3 effect primary-color"
                                >
                                  Decouvrez
                                </a>

                                <div
                                  className=""
                                  href={`#collapseExample${module_index}`}
                                  role="button"
                                  data-toggle="collapse"
                                  aria-expanded="false"
                                  aria-controls={`collapseExample${module_index}`}
                                >
                                  <ExtendButton className="bizwheel-btn theme-1 effect secondary-color-outline">
                                    Expend
                                    <i style={{ transition: 'all .2s !important'}} className="ml-2 fa fa-angle-down"></i>
                                  </ExtendButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="collapse mt-2 c-p "
                        id={`collapseExample${module_index}`}
                      >
                        <div className="row">
                          {moduleLineData
                            .filter(
                              (moduleline) => moduleline.parent === module._id
                            )
                            .map((moduleline, submodules_index) => (
                              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={submodules_index}>
                                {/* Single Feature */}
                                <IconContainer
                                  className={`single-feature ${
                                    checkIfUserHasSubmodule(
                                      [...userProductData],
                                      moduleline._id
                                    )?.hasModule
                                      ? " active"
                                      : ""
                                  }`}
                                >
                                  <h4>
                                    <a
                                      href={'#'}
                                    >
                                      {moduleline.name}
                                    </a>
                                  </h4>
                                  <p>
                                    {moduleline.description
                                      .toString()
                                      .substring(0, 70)}
                                    {"..."}
                                  </p>
                                </IconContainer>
                                <AddToCartButton className="botton">
                                  {checkIfUserHasSubmodule(
                                    [...userProductData],
                                    moduleline._id
                                  )?.hasModule ? (
                                    <>
                                    <label className="bizwheel-btn theme-1 effect" htmlFor={module.name && utils.slugify(moduleline.name)}>Remove</label>
                                    <input
                                      id={module.name && utils.slugify(moduleline.name)}
                                      name={module.name && utils.slugify(moduleline.name)}
                                      onChange={() =>
                                        removeModuleLine(
                                          checkIfUserHasSubmodule(
                                            [...userProductData],
                                            moduleline._id
                                          )?.user_products,
                                          moduleline,
                                          module_index,
                                          submodules_index
                                        )
                                      }
                                      className="hidden"
                                      checked={true}
                                      type="checkbox"
                                      // id="flexSwitchCheckDefault"
                                    />
                                    </>
                                  ) : (
                                    <>
                                    <label  className="bizwheel-btn theme-1 effect" htmlFor={module.name && utils.slugify(moduleline.name)}>Add To Cart</label>
                                    <input
                                      id={module.name && utils.slugify(moduleline.name)}
                                      name={module.name && utils.slugify(moduleline.name)}
                                      onChange={() =>
                                        addModuleLine(
                                          selectUserProduct(
                                            [...moduleData],
                                            moduleline.parent
                                          ),
                                          existingSubmodules(
                                            [...userProductData],
                                            moduleline.parent
                                          ),
                                          moduleline
                                        )
                                      }
                                      className="hidden bizwheel-btn theme-1 effect"
                                      checked={false}
                                      type="checkbox"
                                      // id="flexSwitchCheckDefault"
                                    />
                                    </>
                                  )}
                                </AddToCartButton>
                                {/*/ End Single Feature */}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </section>
              {/*/ End Features Area */}
          </div>
        </div>
      </div>
      {/* Call To Action */}
      <section
        className="call-action overlay-cst"
        style={{
          backgroundImage: 'url("./assets/img/mrkt-img-3.png")',
          marginBottom: "0rem",
        }}
      >
        <div className="container">
          <div className="row  px-sm-4">
            <div className="col-lg-9 col-12">
              <div className="call-inner">
                <h2>Brand Products &amp; Creativity is our Fashion</h2>
                <p>
                  ehicula maximus velit. Morbi non tincidunt purus, a hendrerit
                  nisi. Vivamus elementum
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="button">
                <a
                  href="#brochure_unavailable_yet"
                  className="bizwheel-btn primary-color"
                >
                  Download our brochure
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End Call to action */}


      <div className="collapse cartContainer" id="collapseExample">
        
        <div
          className="sticky-card p-1 fixed-bottom stickyAddCart d-flex"
          id="stickyCard"
          hidden={true}
        >
          {/* <FooterSummary link={"/pricing"} title={"CheckOut"} /> */}

          
    <div className="items">
        <div className="cart_items">
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>
            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

            
            <div className="single_item">
                <div className="single_item_up">
                    <div className="single_item_up_icon">Icon 1</div>
                    <ul className="single_item_up_submodules">
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 1">submodule 1</span><span>$1</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 2">submodule 2</span><span>$3</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 3">submodule 3</span><span>$7</span></li>
                        <li className="submodule_item"><span className="submodule_item_first" title="submodule 4">submodule 4</span><span>$2</span></li>
                    </ul>
                </div>
                <div  className="single_item_down">
                    Product description 1
                </div>
            </div>

        </div>
        <div className="divider">
            
        </div>
        <div className="cart_button"></div>
    </div>
    
        </div>
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  //   console.log('state', state)
  return {
    moduleData: state.module.moduleList,
    moduleLineData: state.moduleline.moduleLineList,
    userProductData: state.user_product.selected_modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchModules: () => dispatch(fetchModules()),
    fetchModuleLines: () => dispatch(fetchModuleLines()),
    createModuleLine: (module) => dispatch(createModuleLine(module)),
    removeModuleLine: (...data) => dispatch(removeModuleLine(...data)),
    addModuleLine: (...data) => dispatch(addModuleLine(...data)),
    fetchUserProducts: (token) => dispatch(fetchUserProducts(token)),
    addAllModuleLineOfParentModule: (...data) =>
      dispatch(addAllModuleLineOfParentModule(...data)),
    removeAllModuleLineOfParentModule: (...data) =>
      dispatch(removeAllModuleLineOfParentModule(...data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
const TitleContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  & > .title_content {
    text-align: center;
  }
  & > .title_content > p {
    font-size: 1.2em;
    font-weight: lighter;
  }
`;
const Button = styled.button`
  /* position: absolute; */
  width: 45px;
  border: none;
  top: 15px;
  line-height: 50px;
  height: 50px;
  border-radius: 0px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  right: 16px;
  background: #009ade;
  color: #fff;

  &:hover {
    color: #fff;
    background: var(--bs-warning);
  }
`;

const Input = styled.input`
  height: 50px;
  line-height: 45px;
  padding: 0 45px 0 20px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: none;
  background: #fff;
  color: #333;
  border-radius: 0px;
  width: 80%;
  font-size: 14px;
  border: 1px solid #ebebeb;
`;
const SearchButtonContainer = styled.div`
  /* margin: 0 auto; */
  width: 100%;
  
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);

  @media (min-width: 600px) {
    width: 50%;
  }
  @media (min-width: 1700px) {
    width: 35%;
    margin: 0 auto;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  & i {
    color: var(--bs-warning);
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 100%;
    font-size: 20px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &.single-feature {
    padding: 15px 7.5px;
  }
`;
