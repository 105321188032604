import React from "react";
import './landing_page_styles.css'
export default function HomePageBlock2() {
  return (
    <section id="page-start"
      className="skill-area second-block hero_img_block"
      // style={{ borderBottom: 'solid 10px #009ADE', backgroundColor: 'rgba(225, 0, 0, 0.5)'}}
      style={{  backgroundImage: "linear-gradient(90deg,rgba(0, 0, 0, .6), rgba(0, 0, 0, .2)), url('/assets/img/home-block-2-img-1.jpg')",  borderBottom: '5px solid #fff', borderTop: '5px solid #fff', backgroundPosition: 'center left' }}>
    
      <div className="container-lg"  >
						<div className="row">
							<div className="col-lg-6 col-md-8 col-12">
								<div className="welcome-text my-5 pt-5"> 
									<div className="hero-text"> 
										<h2 style={{fontSize:'4em', lineHeight:'initial', color: 'white'}}>Equerre Cloud ERP</h2>
                    <p className="mt-4" style={{fontSize:'3em', lineHeight:'initial', color: '#fff', fontWeight: 'normal'}}>Un système ERP complet :<br/> 
                        Gestion des approvisionnements, 
                        vente et CRM, RH, Finance...
                    </p>
										<div className="button mt-4">
											<a href="/equerre_cloud_erp"   className="bizwheel-btn theme-bloc-2">Découvrez Equerre Cloud ERP</a>
											<a href="#joined_us"   className="bizwheel-btn theme-bloc-2 mt-lg-0 mt-3">Demandez une demo</a>
										</div>
									</div>
								</div>
							</div>      
            <div className="offset-lg-1 col-lg-5 col-md-6 col-12 img-block">
                
                {/* <img className="inverse" style={{ height: '612px', objectFit: 'cover'}} src='/assets/img/skill-bg-1.png'/> */}
            </div>
						</div>      

      </div>
    </section>
  );
}
