import React from 'react'
import { connect } from 'react-redux'

export const Module = (props) => {
  return (
    <>
        
		<div className="breadcrumbs overlay" style={{backgroundImage:"url('img/breadcrumb.jpg')"}}>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="bread-inner">
							
							<div className="bread-menu">
								<ul>
									<li><a href="index.html">Home</a></li>
									<li><a href="portfolio.html">Portfolio</a></li>
								</ul>
							</div>
							
							<div className="bread-title">
								<h2>Our Works</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		

		
		<section className="portfolio section-space">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-12">
						<div className="section-title default text-center">
							<div className="section-top">
								<h1><span>Project</span><b>Our Works</b></h1>
							</div>
							<div className="section-bottom">
								<div className="text">
									<p>Lorem Ipsum Dolor Sit Amet, Conse Ctetur Adipiscing Elit, Sed Do Eiusmod Tempor
										Ares Incididunt Utlabore. Dolore Magna Ones Baliqua</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="">
					<div className="">
						<div className="portfolio-main">
							<div id="portfolio-item" className="portfolio-item-active row">

								<div className="col-4 mb-4 business animation">
									
									<div className="single-portfolio">
										<div className="portfolio-head overlay">
											<img src="/assets/img/portfolio/portfolio-1.jpg" alt="#"/>
											<a className="more" href="portfolio-single.html"><i
													className="fa fa-long-arrow-right"></i></a>
										</div>
										<div className="portfolio-content">
											<h4><a href="portfolio-single.html">Creative Marketing</a></h4>
											<p>Business, Aniamtion</p>
										</div>
									</div>
									
								</div>
								<div className="col-4 mb-4 seo consulting">
									
									<div className="single-portfolio">
										<div className="portfolio-head overlay">
											<img src="/assets/img/portfolio/portfolio-2.jpg" alt="#"/>
											<a className="more" href="portfolio-single.html"><i
													className="fa fa-long-arrow-right"></i></a>
										</div>
										<div className="portfolio-content">
											<h4><a href="portfolio-single.html">Creative Marketing</a></h4>
											<p>Seo, Consulting</p>
										</div>
									</div>
									
								</div>
								<div className="col-4 mb-4 marketing seo">
									
									<div className="single-portfolio">
										<div className="portfolio-head overlay">
											<img src="/assets/img/portfolio/portfolio-3.jpg" alt="#"/>
											<a className="more" href="portfolio-single.html"><i
													className="fa fa-long-arrow-right"></i></a>
										</div>
										<div className="portfolio-content">
											<h4><a href="portfolio-single.html">Creative Marketing</a></h4>
											<p>Marketing, SEO</p>
										</div>
									</div>
									
								</div>
								<div className="col-4 mb-4 animation branding">
									
									<div className="single-portfolio">
										<div className="portfolio-head overlay">
											<img src="/assets/img/portfolio/portfolio-4.jpg" alt="#"/>
											<a className="more" href="portfolio-single.html"><i
													className="fa fa-long-arrow-right"></i></a>
										</div>
										<div className="portfolio-content">
											<h4><a href="portfolio-single.html">Creative Marketing</a></h4>
											<p>Animation, Branding</p>
										</div>
									</div>
									
								</div>
								<div className="col-4 mb-4 branding consulting">
									
									<div className="single-portfolio">
										<div className="portfolio-head overlay">
											<img src="/assets/img/portfolio/portfolio-5.jpg" alt="#"/>
											<a className="more" href="portfolio-single.html"><i
													className="fa fa-long-arrow-right"></i></a>
										</div>
										<div className="portfolio-content">
											<h4><a href="portfolio-single.html">Creative Marketing</a></h4>
											<p>Branding, Consulting</p>
										</div>
									</div>
									
								</div>
								<div className="col-4 mb-4 business marketing">
									
									<div className="single-portfolio">
										<div className="portfolio-head overlay">
											<img src="/assets/img/portfolio/portfolio-6.jpg" alt="#"/>
											<a className="more" href="portfolio-single.html"><i
													className="fa fa-long-arrow-right"></i></a>
										</div>
										<div className="portfolio-content">
											<h4><a href="portfolio-single.html">Creative Marketing</a></h4>
											<p>Business</p>
										</div>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		

    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Module)