import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ShareButton } from '../../components';
function CareerBackEndDeveloper(props) 
{
    const { t } = props
  return (
    <div  style={{overflowX: 'clip'}}>
    <Helmet>
        <title>{t("Career | Back End Developer")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Career | Back End Developer"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/career-back-end-developer" />
        <meta property="og:image"  content="/assets/img/back-end-developer-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/back-end-developer-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/career-back-end-developer" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>
    <div className='container'>
        <div className='row my-5'>
          <div className='col-lg-6 col-12'>
              <div className='content-wrapper'>
                <h5 className=' h5 fw-normal text-dark'>NOM DU POSTE:</h5>
                <p className='mb-4 h5 fw-normal text-dark'>BACK END DEVELOPER</p>
                
                <div className='col-12'>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Localisation du poste :</p></div>
                    <div className='col-6'><p>Douala / Yaoundé / Abidjan </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Pays :</p></div>
                    <div className='col-6'><p>Cameroun / Côte d’Ivoire </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Qualification :</p></div>
                    <div className='col-6'><p>Agent de maîtrise </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Type de contrat :</p></div>
                    <div className='col-6'><p>CDI </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Rémunération :</p></div>
                    <div className='col-6'><p>350 K XAF de fixe <br/>+ primes selon barème interne  </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Langue 1 (requise) :</p></div>
                    <div className='col-6'><p>Français </p></div>
                  </div>
                  <div className='row h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Langue 2 (préférée) :</p></div>
                    <div className='col-6'><p>Anglais </p></div>
                  </div>
                  <div className='row mt-4'>                    
                    <div class=" pl-0  col-lg-4 col-6">
                      <a href="mailto:info@equerre.com" class="bizwheel-btn primary-color px-5">POSTULEZ</a>
                      
                {/* <div className="button" > */}
                    {/* <a target="_blanc" style={{width: '60px'}} href="https://www.youtube.com/@equerre"  className="bizwheel-btn theme-2  effect primary-color" ><i class="fa fa-youtube" style={{fontSize: '20px'}}></i></a>
                    <a target="_blanc" style={{width: '60px'}}  href="https://www.facebook.com/sharer/sharer.php?u=https://demo.equerre.com/career-back-end-developer"  className="bizwheel-btn theme-3  effect facebook" ><i class="fa fa-facebook" style={{fontSize: '20px'}}></i></a>
                    <a target="_blanc" style={{width: '60px'}}  href="https://twitter.com/EquerreSa"  className="bizwheel-btn theme-3  effect twitter" ><i class="fa fa-twitter" style={{fontSize: '20px'}}></i></a>
                    <a target="_blanc" style={{width: '60px'}}  href="https://www.linkedin.com/company/equerre-sa/about/?viewAsMember=true"  className="bizwheel-btn theme-3 effect linkedin" ><i class="fa fa-linkedin" style={{fontSize: '20px'}}></i></a>
                    <a target="_blanc" style={{width: '60px'}}  href="https://www.instagram.com/equerre_sa/"  className="bizwheel-btn theme-3 effect instagram" ><i class="fa fa-instagram" style={{fontSize: '20px'}}></i></a> */}
                {/* </div> */}


                    </div>
                    <div className=' col-lg-8 col-6'>
                      
                      <ShareButton title={'BACK END DEVELOPER'} url={'/career-back-end-developer'}/>
                    </div>
                  </div>
                </div>

              </div>
          </div>
          <div className='col-lg-6 col-12'>
            
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-12 text-dark'>
            <h3 className='mb-3 text-dark'>Pourquoi rejoindre Equerre ? </h3>
            <p className='mb-3 h5 fw-normal'>Chez Equerre, nous cultivons l’excellence. Pour parvenir à cette excellence, il nous est indispensable de collaborer avec des profils qui poursuivent le même but. Nous sommes convaincus que l’élément prépondérant qui fait la réussite d’une organisation, est la caractéristique des personnes qui la compose.  </p>
            <p className='mb-3 h5 fw-normal'>Ainsi, notre politique sociale est basée sur la motivation et la fidélisation de nos collaborateurs. Au-delà de nos offres de rémunérations motivantes, nous mettons l'accent sur la reconnaissance, un management de proximité et la mise en place de divers programmes de récompenses, d’avantages et de services, en ligne avec notre culture d’entreprise :</p>
          </div>
        </div>
        
        <div className='row mb-5'>
          <div className='col-12 text-dark'>
            <h3 className='mb-3 text-dark'>Contexte et description du poste </h3>
            <p className='mb-3 h5 fw-normal'>Sous l'autorité d’un Tech Lead, vous avez en charge de concevoir et de coder des programmes et des applications fonctionnels. Vous travaillerez à la fois au sein d’une équipe et de façon individuelle, avec peu de supervision. Vous jouissez d’excellentes connaissances des langages de programmation et maîtrisez différentes plateformes, ainsi que différents systèmes d’exploitation. Ces compétences vous permettent de rédiger un code propre et sans faille, gage de production des applications logicielles entièrement fonctionnelles conformes aux exigences. </p>
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-12 text-dark'>
            <h3 className='mb-3 text-dark'>Missions </h3>
            <p className='mb-3 h5 fw-normal'>Vos principales responsabilités sont les suivantes : </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Comprendre les besoins des clients et la façon dont ils se traduisent en fonctionnalités d’application. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Collaborer avec une équipe de professionnels de l’informatique afin de définir des spécifications pour les nouvelles applications.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Concevoir des prototypes créatifs en fonction des spécifications</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Rédiger du code source de haute qualité afin de programmer des applications complètes dans les délais impartis. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Procéder à des tests d’intégration et d’unités avant le lancement.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Réaliser des tests fonctionnels et non fonctionnels.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Dépanner et déboguer les applications</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Évaluer les applications existantes afin de reprogrammer, de mettre à jour et d’ajouter de nouvelles fonctionnalités</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Développer des documents et des manuels permettant de représenter avec précision la conception et le code de l’application </h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Reporting :</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Rapport d’activité journalier (Scrum)</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Rapport de performance mensuel</h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Indicateurs de performance liés au poste :</a> </h4>
            <h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>L’évaluation de la performance de votre travail sera basée sur la méthode « Agile » et se fera sur 6 axes : </h5>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Alignement (capacité à être en ligne avec les besoins métiers et à répondre efficacement aux besoins de l’utilisateur) </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Qualité (indicateurs de fiabilité, montée en charge, exploitabilité, ergonomie et facilité d’utilisation).</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Productivité (collaboration agile et processus d’amélioration continue)</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Evolutivité (capacité à minimiser la dette applicative).</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Prédictibilité (capacité à prévoir les résultats). </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Créativité  . </h5></li>
              </ul>
            </div>
            <h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Une fiche détaillée de KPI sera élaborée pour chaque mois. </h5>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Critères essentiels :</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Expérience confirmée en tant que Développeur d’applications. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Expérience dans le domaine de la conception et du développement d’applications.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Capacité à programmer au moins un langage de programmation tel que C#, Java (J2EE) etc. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Connaissances approfondies de la programmation pour différents systèmes d’exploitation et différentes plateformes à l’aide d’outils de développement.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Excellente compréhension des principes de conception et de programmation de logiciels</h5></li>

                
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Bon esprit d’équipe et excellentes aptitudes en matière de communications. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Esprit analytique et capacité de résolution de problèmes.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Un grand sens du détail et une grande capacité à gérer son temps. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Licence en sciences informatiques ou dans un domaine apparenté ; un master serait souhaitable.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Un développeur d’applications certifié serait un plus</h5></li>

              </ul>
            </div>
          </div>
        </div>

        <div className='row mb-5'>                    
          <div class="button pl-0 mt-4 ">
            <a href="mailto:info@equerre.com" class="bizwheel-btn primary-color px-5">POSTULEZ</a>
          </div>
        </div>
    </div>

    </div>
  )
}

export default withNamespaces() (CareerBackEndDeveloper)