import * as actionType from '../constants/actionTypes';

const initialState = {
    pricingData: {rate:{}, price_ranges:{}, stripe_public_key: null, client_secret_key: null, user:{}}
}


const pricingReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case actionType.FECTH_PRICING:
    return {...state, pricingData: payload[0]}
  case actionType.FETCH_STRIPE_PUBLIC_KEY:
    return {...state, stripe_public_key: payload}
  case actionType.FETCH_CLIENT_KEY:
    return {...state, client_secret_key:payload}
  case "GET_USER_BY_PAYMENT_INTENT":
    return {...state, user: payload}
  default:
    return state
  }
}

export default pricingReducer;