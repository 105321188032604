import React from 'react'
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

function EquerreFinance1000(props) {
    const {t} =props
  return (
    <>
        <Helmet>
            <title>Equerre Finance 1000</title>
            <meta name="description" content="Your company resource manage 360 degrees"/>
            <meta property="og:title"content="Equerre Finance 1000"/>
            <meta
            property="og:description"
            content="Your company resource manage 360 degrees"/>
            <link rel="canonical" href="https://www.equerre.com/equerre_finance_1000" />
            <meta property="og:image"  content="/assets/img/equerre_finance_1000-img-1.jpg"/>
            <meta name="twitter:image" content="/assets/img/equerre_finance_1000-img-1.jpg"/>


            <meta property="og:locale" content="en_US" />
            {/* <meta property="og:type" content="article" /> */}
            <meta property="og:url" content="https://www.equerre.com/equerre_finance_1000" />
            <meta property="og:site_name" content="Your company resource manage 360 degrees" />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta property="og:image:type" content="image/jpg" />

        </Helmet>


		<section className="style1 section-bg-white hero_img_block wrapper_blur equerre_finance_1000">
  
  <div class="foreground_blur hero_img_block" style={{backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .1)), url('/assets/img/equerre_finance_1000-img-1.jpg')", backgroundPosition: 'center right'}}
      ></div>
					<div className="container-fluid pt-lg-3">
            
						<div className="row">
							<div className="offset-lg-1 col-lg-4 col-md-8 col-12">
								<div className="welcome-text my-5"> 
									<div className="hero-text"> 
										<div className="p-text pb-lg-3">
										</div>
										<h1 style={{ fontSize: '4em', lineHeight:'1.2', color: 'white'}}>                                      Equerre<br/>
                                Finance 1000</h1>

                      <p className='mt-2' style={{ fontSize: '1.5em', fontWeight: 'normal', color: 'white', lineHeight:'1.5',textShadow: '0 0 1px #fff'}}>Prenez les décisions en toute sérénité et gardez en permanence  le contrôle de tous vos processus comptables et de vos données financières.</p>

                                    <div className="button mt-5">
                            
                                        <div className='col-6 mt-3'><a href="#joined_us" className="bizwheel-btn theme-3 effect px-5">Demandez une démo </a></div>

                                    </div>
								</div>
							</div>
            <div className="col-lg-6 col-md-6 col-12 pt-lg-5">
                
                {/* <img style={{ height: '500px', objectFit: 'cover'}} src='/assets/img/hero-img-1.bk.jpg'/> */}
            </div>
						</div>
					</div>
			</div>
		</section>


        <section className="services section-bg section-space pb-1 pt-5 mt-4 section-bg-white">
            <div className="container pb-5">
                <div className="row">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Qu’est-ce que Equerre Finance 1000 ?
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        
                        <div className="single-content">
                            <h4 className='text-dark mb-1 fw-normal'>Vous devez..</h4>
                            <div className="small-list-feature">
                                    
                                <ul>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i className="fa fa-arrow-right text-dark " style={{border: 'unset'}} ></i>Disposer d’une gestion intégrée et optimisée en temps réel de l’ensemble des flux financiers ?</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i className="fa fa-arrow-right text-dark " style={{border: 'unset'}} ></i>Faciliter l'automatisation des processus de la direction financière tout en en préservant l’agilité ?</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i className="fa fa-arrow-right text-dark " style={{border: 'unset'}} ></i>Adapter votre entreprise aux obligations réglementaires et professionnelles de votre secteur d’activité ?</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i className="fa fa-arrow-right text-dark " style={{border: 'unset'}} ></i>Améliorer la budgétisation, les prévisions et la planification tout en accélérant les activités de clôture et de reporting ?</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i className="fa fa-arrow-right text-dark " style={{border: 'unset'}} ></i>Gagner du temps et maîtriser les risques ?</h3></li>
                                </ul>
                            </div>                                                       
                        </div>
                    </div>
                    
                    <div className='col-12 mb-4'>
                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>C'est possible avec Equerre Finance 1000, système de gestion financière de nouvelle génération qui automatise la prise en charge des opérations financières quotidiennes et l'optimisation des profits, tout en assurant la pérennité de votre entreprise. </p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre Finance 1000 va plus loin que les logiciels traditionnels de gestion financière, avec l'intégration native des outils de l'analytique financière optimisée par le Machine Learning, pour l'exploration de jeux massifs de données structurées et non structurées, quasiment en temps réel. </p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Les directeurs financiers peuvent ainsi créer des prévisions et des plans plus précis, modéliser de futurs scénarios, comprendre les répercussions financières d'éventuelles décisions, générer des rapports à la demande, et prévoir les risques et les opportunités. Equerre Finance 1000 est une suite intégrée de 14 modules évolutifs pouvant être déployés selon vos besoins pour couvrir notamment :</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-12 pb-3'>
                                                    
                        <div className="single-content">
                            <div className="small-list-feature">
                                    
                                <ul>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion de la trésorerie</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Automatisation des écritures</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion des immobilisations</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion budgétaire</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion des risques</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion fiscale et calcul de la TVA</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Reporting et analyses</h3></li>
                                </ul>
                            </div>                                                       
                        </div>
                        
                    </div>

                    <div className='col-lg-6 col-md-6 col-12 pb-3'>
                                                    
                        <div className="single-content">
                            <div className="small-list-feature">
                                    
                                <ul>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Comptabilité générale </h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Comptabilité analytique</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Comptabilité auxiliaire clients</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Comptabilité auxiliaire fournisseurs</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion des paiements et règlements</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Gestion des achats</h3></li>
                                    <li><h3 className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-check text-dark " ></i>Facturation</h3></li>
                                </ul>
                            </div>                                                       
                        </div>
                        
                    </div>

                </div>
            </div>
        </section>


            {/* Services */}
            <section className="equerre_finance_1000 services section-space mt-0 pb-5" >
                <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title style2 text-center">
                            <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.3"}}>
                            Optez pour un logiciel de pilotage global de la fonction Comptabilité et Finance au sein de votre entreprise
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Comptabilité générale</h3>
                            </a>
                        </h4>
                        <p className=''>
                        La comptabilité générale d’Equerre Finance 1000 offre une image complète de votre patrimoine, de votre situation financière et des résultats de votre entreprise (plan comptable, bilans, comptes de résultat, journaux achats et ventes, banques, auxiliaires).
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Comptabilité analytique</h3>
                            </a>
                        </h4>
                        <p>Avec la comptabilité analytique, votre équipe finance quantifie la rentabilité de vos produits et de vos activités, et partant améliore la qualité de vos prises de décision. Notre module de comptabilité analytique robuste fournit les indicateurs pour comparer les postes de coûts et permet une analyse rapide des avantages d’une activité ou d’un processus. Couplée au module de comptabilité budgétaire, elle permet le suivi en temps réel des écarts entre le prévisionnel et le réalisé. Véritable outil de suivi de rentabilité, ce module vous procure une vue d’ensemble de l’affectation des ressources financières de votre entreprise et une image précise de sa santé financière.</p>
                        

                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Comptabilité Client</h3>
                            </a>
                        </h4>
                        <p className='mb-3'>La comptabilité auxiliaire d’Equerre Finance 1000 est par défaut subdivisée en comptabilité clients et comptabilité fournisseurs pour rationaliser et faciliter la gestion des mouvements financiers respectifs. Parce qu'il est essentiel pour toute entreprise de pouvoir gérer l’argent que lui doivent ses débiteurs, principalement ses clients, la compatibilité client suit les paiements, la gestion de la trésorerie et les factures correspondantes, automatise des actions comme la génération de factures périodiques et les relevés de compte, les règlements, les encours clients, les relances et le recouvrement.
                        </p>
                        {/* <p>Le module gestion des achats assure la gestion de vos achats de produits et de services et les processus qui y sont liés : demande interne de validation d’achat, bons de commande… Le couplage avec le module comptabilité fournisseur optimise la gestion de vos factures et sécurise le processus de rapprochement avec la commande avant de procéder au paiement.</p> */}
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                </div>
                <div className='row'>
                    
                <div className="equerre_finance_more_feature collapsed service-content" data-toggle="collapse" data-target="#collapseOrderItems1">
                        <h5 style={{color: "var(--eqr-primary-color)"}}><i className="mr-1 fa fa-plus cursor-pointer" />de fonctionnalités</h5>
                </div>
                        
                </div>
                <div className='collapse' id="collapseOrderItems1">
                    
                <div className='row' >
                    
                        <div className="col-lg-4 col-md-4 col-12">
                            {/* Single Service */}
                            <div className="single-service equerre-rh">
                                <div className="service-content">
                                <h4>
                                    <a href="#" className='fw-bold'>
                                    <h3 className='text-dark'>Comptabilité fournisseur</h3>
                                    </a>
                                </h4>
                                <p>
                                Sur l'autre versant de la comptabilité auxiliaire, la comptabilité fournisseur adresse les sommes dues à vos créditeurs, notamment vos fournisseurs. En automatisant cette fonction, votre entreprise gagne du temps, réduit ses coûts de fonctionnement et élimine le risque d’erreur humaine. Avec Equerre Finance 1000, vous traitez plus rapidement un plus grand volume de factures et de transactions financières avec vos fournisseurs, tout en optimisant vos flux de trésorerie dans la mesure où les données fournisseurs sont intégrées et reliées à la gestion des achats, également prise en charge par  Equerre Finance 1000. Que ce soit pour la comptabilité client ou fournisseur, Equerre Finance 1000 simplifie la réconciliation bancaire. 
                                </p>
                                {/* <p className='mt-lg-2'>Comptabilité fournisseur Sur l'autre versant de la comptabilité auxiliaire, la comptabilité fournisseur adresse les sommes dues à vos créditeurs, notamment vos fournisseurs. En automatisant cette fonction, votre entreprise gagne du temps, réduit ses coûts de fonctionnement et élimine le risque d’erreur humaine. Avec Equerre Finance 1000, vous traitez plus rapidement un plus grand volume de factures et de transactions financières avec vos fournisseurs, tout en optimisant vos flux de trésorerie dans la mesure où les données fournisseurs sont intégrées et reliées à la gestion des achats, également prise en charge par  Equerre Finance 1000. Que ce soit pour la comptabilité client ou fournisseur, Equerre Finance 1000 simplifie la réconciliation bancaire.</p> */}
                                </div>
                            </div>
                        {/*/ End Single Service */}
                        </div>
                        <div className='col-8'>
                            <div className='row'>
                                        
                                <div className="col-lg-6 col-md-6 col-12">
                                {/* Single Service */}
                                <div className="single-service equerre-rh">
                                    <div className="service-content">
                                    <h4>
                                        <a href="#" className='fw-bold'>
                                        <h3 className='text-dark'>Gestion des paiements et règlements</h3>
                                        </a>
                                    </h4>
                                    <p>Grâce au module gestion des paiements d'Equerre Finance 1000, vous automatisez les fonctionnalités suivantes :
                                    </p> 
                                    
                                <div className="single-content">
                                    <div className="small-list-feature">
                                            
                                        <ul>
                                            <li><p className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-minus text-dark " ></i>Comptabilisation des décaissements (prélèvement, chèques, virements, effets à payer, billet à ordre).</p></li>
                                            <li><p className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-minus text-dark " ></i>Comptabilisation des encaissements (chèques, espèces et virements reçus).</p></li>
                                            <li><p className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-minus text-dark " ></i>Gestion des bordereaux de remise de chèque.</p></li>
                                            <li><p className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-minus text-dark " ></i>Prise en charge des virements.</p></li>
                                            <li><p className='fw-normal text-dark' style={{display: 'inline-block',}}><i style={{border: 'none'}} className="fa fa-minus text-dark " ></i>Gestion de la multidevise…</p></li>
                                        </ul>
                                    </div>                                                       
                                </div>
                                
                                    {/* <p className='mt-lg-2'>Comptabilité fournisseur Sur l'autre versant de la comptabilité auxiliaire, la comptabilité fournisseur adresse les sommes dues à vos créditeurs, notamment vos fournisseurs. En automatisant cette fonction, votre entreprise gagne du temps, réduit ses coûts de fonctionnement et élimine le risque d’erreur humaine. Avec Equerre Finance 1000, vous traitez plus rapidement un plus grand volume de factures et de transactions financières avec vos fournisseurs, tout en optimisant vos flux de trésorerie dans la mesure où les données fournisseurs sont intégrées et reliées à la gestion des achats, également prise en charge par  Equerre Finance 1000. Que ce soit pour la comptabilité client ou fournisseur, Equerre Finance 1000 simplifie la réconciliation bancaire.</p> */}
                                    </div>
                                </div>
                                {/*/ End Single Service */}
                                </div>

                                <div className="col-lg-6 col-md-6 col-12">
                                    {/* Single Service */}
                                    <div className="single-service equerre-rh">
                                        <div className="service-content">
                                        <h4>
                                            <a href="#" className='fw-bold'>
                                            <h3 className='text-dark'>Gestion des achats</h3>
                                            </a>
                                        </h4>
                                        <p>
                                        Le module gestion des achats assure la gestion de vos achats de produits et de services et les processus qui y sont liés : demande interne de validation d’achat, bons de commande… Le couplage avec le module comptabilité fournisseur optimise la gestion de vos factures et sécurise le processus de rapprochement avec la commande avant de procéder au paiement.
                                        </p>
                                        </div>
                                    </div>
                                {/*/ End Single Service */}
                                </div>

                            </div>
                            <div className='row'>
                                <div className="col-lg-12 col-md-12 col-12">
                                {/* Single Service */}
                                <div className="single-service equerre-rh">
                                    <div className="service-content">
                                    <h4>
                                        <a href="#" className='fw-bold'>
                                        <h3 className='text-dark'>Facturation</h3>
                                        </a>
                                    </h4>
                                    <p>
                                    Facturation Le cycle de facturation est un processus sensible qui intègre plusieurs tâches administratives chronophages et nécessite précision, cohérence et une vérification systématique des données. La force de l’automatisation par Equerre Finance 1000 se révèle dans le chaînage automatique des données entre fonctions : les factures sont éditées automatiquement sur Equerre Vente et CRM par le service commercial, ensuite Equerre Finance 1000 récupère et transfère la facture en comptabilité, avec tous ses paramètres. Une fois la facture payée, Equerre Finance 1000 intègre l’écriture de banque, met à jour les encours bancaires, mais aussi le calcul de la TVA ainsi que le suivi systématique entre la facture et son règlement. Vous disposez ainsi, en temps réel, de données toujours justes et à jour, sans manipulation fastidieuse entre différents services et logiciels.
                                    </p>
                                    </div>
                                </div>
                                {/*/ End Single Service */}
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4>
                                <a href="#" className='fw-bold'>
                                <h3 className='text-dark'>Gestion de la trésorerie</h3>
                                </a>
                            </h4>
                            <p>
                            Une bonne gestion de trésorerie est à la base de la productivité et de la pérennité de votre entreprise. Pour disposer d’une trésorerie rigoureuse, il est indispensable de mettre en place un outil de calcul de vos besoins en fonds de roulement. Equerre Finance 1000 identifie les financements nécessaires et les échéances dont il vous faut tenir compte, collecte les informations de vos différents comptes bancaires et établit les rapprochements bancaires. Ainsi vous suivez les informations indispensables au plus près afin de maîtriser au mieux votre cash-flow et votre risque financier, posant les bases de la pérennité de votre entreprise.
                            </p>
                                
                                <h4 className='mt-4'>
                                <h3 className='text-dark'>Gestion de la fiscalité et calcul de la TVA</h3>
                                </h4>
                                <p>Cet aspect d'Equerre Finance 1000 réunit tous les paramètres fiscaux indispensables aux calculs des taxes et de votre TVA. Il rassemble en un seul répertoire tous les documents nécessaires et assure votre conformité aux obligations fiscales.</p>
                                
        
                            </div>
                        </div>
                        {/*/ End Single Service */}
                        </div>
                        
                        <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4>
                                <a href="#" className='fw-bold'>
                                <h3 className='text-dark'>Automatisation des écritures OHADA, GAAP ou IFRS</h3>
                                </a>
                            </h4>
                            <p>
                            Grâce Equerre Finance 1000, vous réduisez ou supprimez la saisie manuelle de vos écritures comptables selon les référentiels OHADA, GAAP ou IFRS. Ainsi, les différentes tâches liées à la saisie des écritures comptables sont automatiquement prises en charge. En effet, en automatisant les processus comptables fastidieux, vous diminuez le nombre d’erreurs de saisies (montants, doublon…) et facilitez la gestion des différentes opérations courantes des entreprises : saisie des diverses informations, recherche de factures, vérification des opérations bancaires, vérification de la TVA, vérification de la conformité, archivage automatique…
                            </p>
    
                            <h4 className='mt-4'>
                            <h3 className='text-dark'>Gestion des risques</h3>
                            </h4>
                            <p>Ce module d'Equerre Finance 1000 permet de prévoir et d’analyser les risques susceptibles d’impacter la stabilité financière de votre entreprise. Par exemple, en identifiant clairement les risques de crédit, votre équipe comptable peut provisionner les liquidités nécessaires pour couvrir les risques d’impayés clients. Ce module d'Equerre Finance 1000 contribue également à maîtriser les risques liés à la sécurité, à la responsabilité légale ou à la conformité.</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                        </div>
                        
                        <div className="col-lg-4 col-md-4 col-12">
                            {/* Single Service */}
                            <div className="single-service equerre-rh">
                                <div className="service-content">
                                <h4>
                                    <a href="#" className='fw-bold'>
                                    <h3 className='text-dark'>Gestion des immobilisations</h3>
                                    </a>
                                </h4>
                                <p>
                                Avec ce module d'Equerre Finance 1000, votre entreprise suit et gère les actifs et calcule vos amortissements et évite de mauvais calculs qui peuvent générer de graves conséquences fiscales et de conformité réglementaire. La gestion des immobilisations instaure une meilleure visibilité sur l’utilisation, le coût et l’entretien de vos actifs. 
                                </p>
    
                                
                            <h4 className='mt-4'>
                            <h3 className='text-dark'>Gestion budgétaire</h3>
                            </h4>
                            <p>La granularité de la plateforme d'Equerre permet à votre service financier de disposer de toutes les données nécessaires pour entamer le processus de budgétisation, notamment avec les informations financières et les chiffres de vente procurées par les autres modules de la suite. Sur la base des données récupérées en temps réel, le module de comptabilité budgétaire d'Equerre Finance 1000 permet une estimation précise des différents postes de budget et vous permet, à tout moment, de mettre à jour votre budget en cours d’exercice, pour en faciliter le suivi. La comparaison avec le budget initial ou avec le budget de l’année précédente vous apporte de nombreuses informations pour éclairer vos différentes prises de décision.</p>
                            
    
                                </div>
                            </div>
                            {/*/ End Single Service */}
                        </div>                
                        
                        <div className=" col-12">
                            {/* Single Service */}
                            <div className="single-service equerre-rh">
                                <div className="service-content">
                                <h4>
                                    <a href="#" className='fw-bold'>
                                    <h3 className='text-dark'>Reporting et Analyse financière</h3>
                                    </a>
                                </h4>
                                <p>
                                
    Equerre Finance 1000 est une solution puissante et efficace qui fonde la base des informations nécessaires pour votre reporting financier et l’optimisation de vos décisions. Les outils d’aide à la décision et les fonctions analytiques avancées intégrées à la brique financière vous fournissent toutes les combinaisons indispensables à une finesse d’analyses créatrice de valeur. Vous avez accès aux données de votre entreprise en temps réel, et nos outils d'analytique financière avancée par Machine Learning et intelligence artificielle peuvent servir à piloter les activités de l'entreprise de manière optimale pour assurer sa pérennité à long terme. Vous réalisez une grande partie des rapports de gestion et financiers de façon grandement automatisée et personnalisée. Ainsi, vous disposez d’une visibilité financière claire. En tant que responsable financier, avec Equerre Finance 1000 prenez des décisions en toute sérénité et gardez, en permanence, le contrôle de tous vos processus comptables et de vos données financières.
    
    
    Avec Equerre Finance Faites le pari d’un système de gestion financière spécialisé, intégré à une plateforme ERP complète, qui accélère la digitalisation de la fonction finance et contribue à la performance financière et extra financière de votre entreprise.
    
    
                                </p>
    
    
                                </div>
                            </div>
                            {/*/ End Single Service */}
                        </div>
                    </div>
                </div>
                </div>
            </section>
            {/*/ End Services */} 


        <section>
            <ProductPageCommonComponent title={"Equerre Finance 1000"}/>
        </section>
    </>
  )
}

export default withNamespaces() (EquerreFinance1000)