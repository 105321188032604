import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

function EquerrePaie500(props) {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre Paie 500"/>
        <meta
        property="og:description" content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre-paie-500" />
        <meta property="og:image"  content="/assets/img/equerre-paie-500-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/equerre-paie-500-img-1.png"/>

        
        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre-paie-500" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image_url" content="/assets/img/equerre-paie-500-img-1.png" />
        
        <meta property="og:type" content="article" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="www.equerre.com" />
        <meta name="twitter:creator" content="@Equerre" />
        <meta property="twitter:title" content="querre Paie 500" />
        <meta property="twitter:description" content="Your company resource manage 360 degrees" />

    </Helmet>
        
    <section className="style1 section-bg-white" >
			<div className="" 
            // style={{backgroundColor: 'rgb(215 94 15 / 90%)'}}
            style={{ height: '90vh', backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, .8), rgba(0, 0, 0, .0)), url('/assets/img/paie-500-img-2.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "bottom left", borderBottom: '5px solid #fff'}}
            >
				{/* <!-- Single Slider --> */}
				<div className="single-slider py-lg-5" >
					<div className="container-lg">
						<div className="row my-5">
							<div className="col-lg-7 col-md-6 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'3.5em', lineHeight:'1em', color: 'white'}}>Equerre paie 500</h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Un outil puissant et complet pour gérer la paie et les déclarations sociales de votre entreprise.</p>
										<div className="button  my-4 ">       
											<a href="#joined_us"  className="bizwheel-btn theme-3 effect mt-lg-3 px-4">Demandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                            <div className="col-lg-5 col-md-6 col-12">
                                
                                {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/smb-img-1.png'/> */}
                            </div>
						</div>
					</div>
				</div>
			</div>
    </section>

    
    <section className="call-action section-bg-white" style={{backgroundImage: 'url("img/cta-bg.jpg")', backgroundColor: '#fff'}}>
            <div className="container-lg">
                <div className="row">
                <div className="offset-lg-1 col-lg-10 col-12">
                    <div className="call-inner" style={{textAlign: 'center', }}>
                    <h2 style={{color: '#000', fontSize: '3.2em',lineHeight: '1.1em',fontWeight: 500}}>Découvrez comment Equerre paie 500 couvre tous les besoins de votre entreprise </h2>
                    {/* <p style={{color: '#000'}}>Gestion des objectifs, Gestion des sondages, Gestion des annonces, Gestion des sauvegardes, Excel en ligne</p> */}
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Gestion des contrats
                            </a>
                        </h4>
                        <p>
                        Configurez et mettez à jour les éléments fixes de salaire des employés avec affichage de salaire brut et de salaire net estimé. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Paie inverse
                            </a>
                        </h4>
                        <p>
                        Avec cette fonctionnalité, vous avez la possibilité d'effectuer un arbitrage sur la composition des salaires bruts en vous basant sur les salaires nets négociés avec les nouvelles recrues. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Gestion des éléments variables
                            </a>
                        </h4>
                        <p>
                        Donnez la possibilité aux gestionnaires de configurer les éléments variables de paie, et de les saisir mois par mois par employé à payer. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Gestion des heures <br/>supplémentaires
                            </a>
                        </h4>
                        <p>
                        Bénéficiez du paramétrage des heures supplémentaires conformément à la réglementation et d'un état global pour exploitation. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Allocation taxables et imposables
                            </a>
                        </h4>
                        <p>
                        Donnez la possibilité aux gestionnaires de configurer les éléments de retenue sur paie conformément à la réglementation de référence, notamment sur les impôts et retenues sociales. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Gestion des retenues /<br/> déductions
                            </a>
                        </h4>
                        <p>
                        Paramétrez et administrez les retenues à la source sur les salaires suivant les règles de gestion préétablies. Effectuez les déductions liées aux absences entraînant prorata de salaire. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Gestion des prêts / Avances sur salaire
                            </a>
                        </h4>
                        <p>
                        Gérez les prêts à court, moyen et long terme dans un environnement flexible avec la possibilité de mettre en place des workflow de validation en ligne.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Traitement dynamique de la paie
                            </a>
                        </h4>
                        <p>
                        Produisez la paie mensuelle en toute flexibilité et facilité, en créant des modèles de bulletins de paie pour des groupes spécifiques d'employés ou des périodes spécifiques de paie, avec la possibilité d'effectuer directement des ajustements sur chaque salarié depuis un espace dédié. Clôturez la paie en temps opportun.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Encaissements congés 
                            </a>
                        </h4>
                        <p>
                        Gérez de façon automatisée les congés des employés : calcul des soldes de congés et édition des états associés
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Etats post-paie 
                            </a>
                        </h4>
                        <p>
                        Produisez les états de paie en toute simplicité : états par type ou par rubrique, états avant et après clôture, rapports divers. Profitez d'une grande flexibilité dans les options de tri, de filtre et d'exportation des données
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Télédéclarations fiscales et sociales
                            </a>
                        </h4>
                        <p>
                        Equerre 500 paie est un logiciel conforme aux dernières règlementations fiscales et sociales. Vos échanges avec les administrations sont rapides et sécurisés. Vous réglez vos cotisations à échéance et évitez ainsi les pénalités de retard. Vos cotisations se calculent automatiquement et il suffit d’un clic pour les envoyer directement depuis votre logiciel de paie sans aucune ressaisie. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>

                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#">
                            Formule Excel d'élément de paie
                            </a>
                        </h4>
                        <p>
                        Profitez de la disponibilité d'un espace tableur pour effectuer vos analyses de manière flexible directement dans l'application
                        </p>
                        <h4 className='mt-4'>
                            <a href="#">
                            Bulletin de paie envoyé par email
                            </a>
                        </h4>
                        <p>
                        Procédez à la distribution des bulletins et fiches de paie par envoi mail automatique ou programmé à chaque employé. 
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>


            </div>
                
            </div>
            </section>


    <section>
        <ProductPageCommonComponent title={'Equerre Paie 500'} />
    </section>
    </>
  )
}

export default withNamespaces() (EquerrePaie500)