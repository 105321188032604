import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchUserProducts } from '../../redux/acitons/userProductAction';

const SummaryWidget = (props) => {
    const { userProductData, fetchUserProducts } =props
useEffect(() => {
    let userToken = localStorage.getItem("userToken");
    fetchUserProducts(userToken);
    return () => {};
    }, []);
  return (
    <>
        <h4 className="d-flex justify-content-between align-items-center mb-3">
              My Cart
              <span className="badge badge-secondary badge-pill">{calculatePricing([...userProductData]).total}</span>
            </h4>
            <ul className="" style={{maxHeight: '290px', overflow: 'auto'}}>
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                      <h4
                          style={{ fontSize: "1.2em", fontWeight: "lighter" }}
                          className="my-0"
                      >
                          {userProductData.length >= 0  && userProductData[0] && ` Payment Cycle : ${userProductData[0].pricing?.paiement_cycle || 'monthly'}`}
                      </h4>
                      <small className="text-muted">
                        {userProductData.length >= 0  && userProductData[0] && ` Number of Users : ${userProductData[0].pricing?.users || 1}`}
                      </small>
                      </div>
                  <span className="text-muted"></span>
              </li>
                
            {userProductData.length >= 0  && [...userProductData]?.map((_data, i) => (
                <Fragment key={i}>
                    {_data && _data.module ? (
                        <Fragment key={_data.module._id}>
                            {_data.module.submodules.map((submodule, i) => (
                                <Fragment key={i}>                                
                                    <li className="list-group-item d-flex justify-content-between lh-condensed" key={i}>
                                        <div>
                                            <h4
                                                style={{ fontSize: "1.2em", fontWeight: "lighter" }}
                                                className="my-0"
                                            >
                                                {submodule.name}
                                            </h4>
                                            <small className="text-muted">{submodule.description}</small>
                                            </div>
                                        <span className="text-muted">$ {parseFloat((submodule.price[userProductData[0].pricing?.paiement_cycle || 'monthly']) *( userProductData[0].pricing?.users || 1)).toFixed(2)}</span>
                                    </li>
                                </Fragment>
                            ))}
                            
                        </Fragment>
                    ):null}
                    </Fragment>
            ))}
            {userProductData.length === 0 && (
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                        <h4
                            style={{ fontSize: "1.2em", fontWeight: "lighter", textAlign: 'center' }}
                            className="my-0"
                        >
                            {'Empty Cart'}
                        </h4>
                        <small className="text-muted">You can by our product on the Market Place page</small>
                        </div>
                    <span className="text-muted"></span>
                </li>
            )}

            </ul>   
            <ul className='mb-3'>
                
            <li className="list-group-item d-flex justify-content-between">
                <span>Total (USD)</span>
                <strong>
                    $ {parseFloat(userProductData[0] && calculatePricing([...userProductData])[userProductData[0].pricing?.paiement_cycle || 'monthly'] * (userProductData[0]?.pricing?.users || 1) || 0).toFixed(2)}
                </strong>
            </li>
            </ul>
    </>
  )
}

const mapStateToProps = (state) => ({
    userProductData: state.user_product.selected_modules,
})

const mapDispatchToProps = (dispatch) => {
    return {
      fetchUserProducts: (token) => dispatch(fetchUserProducts(token)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(SummaryWidget)



const calculatePricing =(userProduct=[])=>{
    let _result = [...userProduct].reduce((prev, curr)=>{
      curr.module.submodules.reduce((acc, _curr) =>{        
        return parseFloat(acc) + parseFloat(_curr.price?.monthly)
      },0) 

      return {
        monthly: prev.monthly +curr.module.submodules.reduce((acc, _curr) => parseFloat(acc) + parseFloat(_curr.price.monthly),0), 
        quarterly: prev.quarterly +curr.module.submodules.reduce((acc, _curr) => parseFloat(acc) + parseFloat(_curr.price.quarterly),0), 
        yearly: prev.yearly +curr.module.submodules.reduce((acc, _curr) => parseFloat(acc) + parseFloat(_curr.price.yearly),0),
        total: prev.total + curr.module.submodules.length
      }
    },{monthly: 0, quarterly: 0, yearly: 0, total:0})
    _result.quarterly = _result.quarterly * 3
    _result.yearly = _result.yearly * 12
    return _result 
  }