import React from 'react'

function HomePageBlock3() {
  return (
    
    <section
      className="skill-area hero_img_block"
      // style={{  height: '647px', borderBottomColor:'var(--eqr-terciary-color)',  backgroundColor: 'rgba(225, 0, 0, .5)' }}
      style={{  backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8)), url('/assets/img/home-block-2-img-3.jpg')", borderBottom: '5px solid #fff'}}
    >
      <div className="container-lg">
						<div className="row">
                
            <div className="col-lg-5 col-md-6 col-12">
                
                {/* <img style={{ height: '616px', objectFit: 'cover'}} src='/assets/img/about-us.png'/> */}
            </div>

							<div className="py-5 my-5 offset-lg-1 col-lg-6 col-md-8 col-12">
								<div className="welcome-text text-dark"> 
									<div className="hero-text"> 
										<h2 style={{fontSize:'4em', lineHeight:'initial', color: 'white'}}>Equerre SMB</h2>
                        <p className="mt-4" style={{fontSize:'2.8em', wordSpacing: '.2em',lineHeight:'initial', color: '#fff', fontWeight: 'normal'}}>Le logiciel de gestion d’entreprise 
                            qui s’adapte aux exigences des PME 
                        </p>
										<div className="button mt-4">
											<a href="#joined_us"  className="bizwheel-btn theme-bloc-2">Demandez une démo </a>
										</div>
									</div>
								</div>
							</div>
						</div>
      </div>
    </section>
  )
}

export default HomePageBlock3