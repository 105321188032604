import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ShareButton } from '../../components';

function CareerBusinessDeveloperJunior(props) {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("Career | Career Business Developer junior")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Career | Career Business Developer junior"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/business-developer-junior" />
        <meta property="og:image"  content="/assets/img/business-developer-junior-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/business-developer-junior-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/business-developer-junior" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>
    <div className='container'>
        <div className='row my-5'>
          <div className='col-lg-6 col-12'>
              <div className='content-wrapper'>
                <h5 className=' h5 fw-normal text-dark'>NOM DU POSTE:</h5>
                <p className='mb-4 h5 fw-normal text-dark'>BUSINESS DEVELOPER JUNIOR</p>
                
                <div className='col-12'>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Localisation du poste :</p></div>
                    <div className='col-6'><p>Douala / Yaoundé / Abidjan </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Pays :</p></div>
                    <div className='col-6'><p>Cameroun / Côte d’Ivoire </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Qualification :</p></div>
                    <div className='col-6'><p>Cadre </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Type de contrat :</p></div>
                    <div className='col-6'><p>CDI </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Rémunération :</p></div>
                    <div className='col-6'><p>400 K XAF de fixe <br/>+ Commissions selon barème interne  </p></div>
                  </div>
                  <div className='row mb-2 h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Langue 1 (requise) :</p></div>
                    <div className='col-6'><p>Français </p></div>
                  </div>
                  <div className='row h5 fw-normal text-dark'>
                    <div className='col-6 pl-0'><p>Langue 2 (préférée) :</p></div>
                    <div className='col-6'><p>Anglais </p></div>
                  </div>
                  <div className='row mt-4'>                    
                    <div class=" pl-0  col-lg-4 col-6">
                      <a href="mailto:info@equerre.com" class="bizwheel-btn primary-color px-5">POSTULEZ</a>
                    </div>
                    <div className=' col-lg-8 col-6'>
                      
                      <ShareButton title={'BUSINESS DEVELOPER JUNIOR'} url={'/career-business-developer-junior'}/>
                    </div>
                  </div>
                </div>

              </div>
          </div>
          <div className='col-lg-6 col-12'>
            
          </div>
        </div>

        <div className='row mb-5'>
          <div className='col-12 text-dark'>
            <h3 className='mb-3 text-dark'>Pourquoi rejoindre Equerre ? </h3>
            <p className='mb-3 h5 fw-normal'>Chez Equerre, nous cultivons l’excellence. Pour parvenir à cette excellence, il nous est indispensable de collaborer avec des profils qui poursuivent le même but. Nous sommes convaincus que l’élément prépondérant qui fait la réussite d’une organisation, est la caractéristique des personnes qui la compose. </p>
            <p className='mb-3 h5 fw-normal'>Ainsi, notre politique sociale est basée sur la motivation et la fidélisation de nos collaborateurs. Au-delà de nos offres de rémunérations motivantes, nous mettons l'accent sur la reconnaissance, un management de proximité et la mise en place de divers programmes de récompenses, d’avantages et de services, en ligne avec notre culture d’entreprise :</p>
          </div>
        </div>
        
        <div className='row mb-5'>
          <div className='col-12 text-dark'>
            <h3 className='mb-3 text-dark'>Contexte et description du poste </h3>
            <p className='mb-3 h5 fw-normal'>Sous l'autorité d’un Business Developer Senior, vous participez activement au développement d’un réseau de clients, en local et dans votre sous-région. Vos principales missions s'articulent autour de : prospection clients, réponses à appels d'offres, analyse des besoins/propositions financières, etc. </p>
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-12 text-dark'>
            <h3 className='mb-3 text-dark'>Missions</h3>
            <p className='mb-1 h5 fw-normal'>Vos principales responsabilités sont les suivantes : </p>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Au niveau stratégique :</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Définir la politique commerciale locale et dans votre sous-région avec le Directeur commercial de Equerre. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Initier des stratégies de vente en collaboration avec la Direction commerciale.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Maintien d'une veille concurrentielle permanente  </h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Au niveau opérationnel :</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Prospecter l'ensemble du marché potentiel</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Mettre à jour les fichiers clients et prospects </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Répondre et coordonner les réponses aux appels d'offres</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Coordonner l’ensemble du processus de delivery, du bon de commande à la signature du PV de réception.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Dresser des bilans pour toute opération de stimulation des ventes</h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Reporting :</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Rapport d’activité hebdomadaire orienté objectif </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Etat trimestriel, semestriel et annuel des ventes</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Rapport d’activité annuel</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Construction et administration des outils d’analyse et de pilotage commercial, de reporting.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Récolte et analyse des indicateurs de performance commerciale, élaboration des recommandations d’amélioration des processus de vente et de delivery. </h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Indicateurs de performance liés au poste:</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Volume d’affaire généré. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Volume d’affaire business à très forte valeur ajoutée.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Résultat net réalisé. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Taux de pénétration des grands comptes.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Une fiche détaillée de KPI sera élaborée pour chaque trimestre.</h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="single-content">
            <h4 className='mb-3 text-dark fw-normal h4'><a href='#'>Critères essentiels :</a> </h4>
            <div className="small-list-feature">
              <ul>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>3 années d’expérience minimum dans la vente des solutions technologiques en B2B.</h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Formation supérieure, de préférence études commerciale. </h5></li>
                <li><i className="fa fa-check" /><h5 className='h5 fw-normal' style={{display: 'initial', color: 'rgb(102, 102, 102)'}}>Qualités requises : volontaire, créatif et autonome, excellent bagage technique. </h5></li>
              </ul>
            </div>
          </div>
        </div>

        <div className='row mb-5'>                    
          <div class="button pl-0 mt-4 ">
            <a href="mailto:info@equerre.com" class="bizwheel-btn primary-color px-5">POSTULEZ</a>
          </div>
        </div>
    </div>

    </>
  )
}

export default withNamespaces() (CareerBusinessDeveloperJunior)