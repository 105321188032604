import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { ContactUsForm, FooterSummary } from '../../components'

const ContactUs = (props) => {
  return (
    <>
    
    <Helmet>
        <title>Equerre | Contact Us</title>
        <meta name="description" content="let's us know what you need"/>
        <meta property="og:title"content="Equerre | Contact Us"/>
        <meta
        property="og:description"
        content="let's us know what you need"/>
        <link rel="canonical" href="https://www.equerre.com/contact_us" />
        <meta property="og:image"  content="/assets/img/contact-us-img-10.jpg"/>
        <meta name="twitter:image" content="/assets/img/contact-us-img-10.jpg"/>
    </Helmet>


    <section className="style1 section-bg-white" >
			<div className="" 
            // style={{backgroundColor: 'rgb(215 94 15 / 90%)'}}
            style={{ backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/contact-us-img-10.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "center left", borderBottom: '5px solid #fff'}}
            
            >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-6 col-12">
								<div className="welcome-text py-5"> 
									<div className="hero-text"> 
										<h2 style={{textTransform: "normal",fontSize:'4em', lineHeight:'1em', color: 'white'}}>Rencontrons-nous</h2>
										<div className="p-text">
											<p className="mt-4" style={{fontSize:'3em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Echangeons et discutons.</p>
										</div>
										<div className="button  my-4">       
											{/* <a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3">Ecrivez-nous</a> */}
										</div>
									</div>
								</div>
							</div>
              <div className="col-lg-5 col-md-6 col-12">
                  
                  {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/smb-img-1.png'/> */}
              </div>
						</div>
					</div>
				</div>
			</div>
		</section>


      <section className="contact-us section-space pt-0">
        <div className="container">
          <div className="row">

            <div className="offset-lg-1 col-lg-3 col-md-5 col-12 m-top-30">
              <div className="contact-box-main m-top-30">
                {/* <div className="contact-title">
                  <h2 className='mt-lg-2'>Rencontrons-nous</h2>
                  <p>Echangeons et discutons</p>
                </div> */}
                
							<div className="single-widget footer_contact widget">
								<h6 className='text-dark'>USA & EUROPE</h6>
								<ul className="address-widget-list">
									{/* <li className="footer-mobile-number"><i className="fa fa-envelope"></i>info@yoursite.com
									</li> */}
									<li className="footer-mobile-number mb-0">12 Indian Grass CT, Germantown,<br/> Maryland 20874</li>
									<li className="footer-mobile-number mb-0">301.704.8534</li>

								</ul>
								<h6 className='text-dark mt-4'>AFRIQUE</h6>
								<p className='text-dark  mb-0'>Douala-Cameroun</p>
								<ul className="address-widget-list">
									<li className="footer-mobile-number mb-0">
										Immeuble IMD, 1 132 Rue Berthaud, Bali, B.P.3496 Douala
									</li>
									<li className="footer-mobile-number  mb-0">+(237) 233 48 09 03</li>

								</ul>

								<p className='text-dark mt-4  mb-0'>Yaoundé-Cameroun</p>
								<ul className="address-widget-list">
									<li className="footer-mobile-number mb-0">
									Carrefour CEPER, Elig-Essono, B.P.11829 Yaoundé
									</li>
									<li className="footer-mobile-number mb-0">+(237) 233 48 09 03</li>
								</ul>
								
								<p className='text-dark mt-4  mb-0'>Abidjan-Côte d’Ivoire</p>
								<ul className="address-widget-list">
									<li className="footer-mobile-number mb-0">
									BP 755 cidex 3 Riviera 
									</li>
									<li className="footer-mobile-number mb-0">+(225) 076 740 55 90</li>
								</ul>
							</div>

              </div>
            </div>

            <div className="col-lg-7 col-md-7 col-12">
              <div className="registration_container"> 
                  <ContactUsForm />
              </div>
            </div>

          </div>
        </div>
      </section>



    
         {/* card */}
         
         <div className="collapse cartContainer" id="collapseExample">
            <div
              className="sticky-card p-1 fixed-bottom stickyAddCart d-flex align-items-center justify-content-space-between"
              id="stickyCard"
              hidden={true}
            >
              <FooterSummary link={"/marketplace"} title={"MarketPlace"} />
            </div>
          </div>
         {/* end cart */}
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)