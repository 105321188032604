import { signIn, signUp } from '../../api';
import { AUTH } from '../constants/actionTypes';

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await signIn(formData);

    dispatch({ type: AUTH, data });

    router.push('/dashboard');
    return data
  } catch (error) {
    console.log(error);
    return error
  }
};

export const signup = (formData, router) => async (dispatch) => {
  console.log('in aciton signup formData', formData)
  console.log('router', router)
  try {
    const { data } = await signUp(formData);

    dispatch({ type: AUTH, data });

    // router.push('/dashboard');
    return data
  } catch (error) {
    console.log(error);
    return {code: error.code, message: error.message}
  }
};
