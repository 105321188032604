import React, { useEffect, useState, createRef } from 'react'
import { connect } from 'react-redux'
import { fetchUserProducts } from '../../redux/acitons/userProductAction'
import { useFormik } from "formik";
import * as Yup from "yup";
import { env_config, API, GOOGLE_RECAPTCHA_KEY } from '../../env';
import { ClipLoader } from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";

const _reCaptchaRef = createRef();
const TEST_SITE_KEY = GOOGLE_RECAPTCHA_KEY;
const NewsletterForm = ({fetchUserProducts, userProductData}) => {
	let [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false)
	useEffect(() => {
	//   console.log('-- moduleData', moduleData)
	localStorage.getItem("userToken")
	fetchUserProducts()
	return () => {		
	}
	}, [])
	
    const formik = useFormik({
      initialValues: {
        last_name: "",
        first_name: "",
        country: "",
        email: "",
		recaptcha: "",
      },
      validationSchema: Yup.object({

        first_name: Yup.string()
		.max(30, "Must be 30 characters or less")
		.required("Required"),
        last_name: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        country: Yup.string()
          .max(30, "Must be 30 characters or less")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
		recaptcha: Yup.string().required(),
      }),
      onSubmit: async (values, { resetForm }) => {
        
		  		//   console.log('first', values)
		  const config = env_config;
		  
        try {
			setLoading(true)
          await API
            .post("/email/newsletter_subscription", {
                last_name: values.last_name,
                first_name: values.first_name,
                country: values.country,
                email: values.email,
			}, config)
            .then((res) => {
              console.log('res', res)
              setMessage({code: 200, message:"Your request has reached our staff, we will get back to you."});    
              setTimeout(() => {
                // Most recent value
                setMessage(false);    
              }, 8000);
			  setLoading(false)

              resetForm({ values: "" });
            });
        } catch (error) {
          console.error("-- error", error);
		  setLoading(false)
          setMessage({code: 404, message: error})
        }
      },
    });

  return (
		<section className="contact-us section-space pt-0"
            
		>
			{/* <div className=""> */}
				<div className="row">
					<div className="px-lg-0 offset-lg-2 col-lg-8 col-12">
						{/* <!-- Contact Form --> */}
						<div className="contact-form-area mt-0" style={{backgroundColor: 'transparent', boxShadow: 'unset'}}>
							
							{message && message.code ? 
								message.code  == "200" ? (
								<div className="alert alert-success mb-5" role="alert">
									{message.message}
								</div>
								) : (
									<div className="alert alert-danger mb-5" role="alert">
									{JSON.stringify(message?.message?.message)}
									</div>
								)          
							:null}

							{/* <h4>S'abonner à notre newsletter</h4> */}
							<form 
								onSubmit={formik.handleSubmit}
								id="registration-form"
								className="form" method="post" action="">
								<div className="row">
									<div className="col-lg-12 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input
													
													id="first_name"
													name="first_name"
													type="text"
													placeholder="Prénom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.first_name}
												/>
												{formik.touched.first_name && formik.errors.first_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.first_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-12 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-user"></i></div>
												<input													
													id="last_name"
													name="last_name"
													type="text"
													placeholder="Nom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.last_name}
												/>
												{formik.touched.last_name && formik.errors.last_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.last_name}</div>
												) : null}
										</div>
									</div>
									<div className="col-lg-12 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-envelope"></i></div>
												<input													
													id="email"
													name="email"
													type="email"
													placeholder="Email"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
												) : null}
										</div>
									</div>
									

									<div className="col-lg-12 col-md-6 col-12">
										<div className="form-group">
											<div className="icon"><i className="fa fa-building"></i></div>
											
											<input													
													id="country"
													name="country"
													type="text"
													placeholder="Pays"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.country}
												/>
												{formik.touched.country && formik.errors.country ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.country}</div>
												) : null}
												
										</div>
									</div>									


									<div className="col-lg-12 col-md-12 col-12">
									<div className="form-group">
										{/* <label>Recaptcha Validation</label> */}
										<ReCAPTCHA
										sitekey={TEST_SITE_KEY}
										render="explicit"
										onChange={(response) => {formik.setFieldValue("recaptcha", response); }}
										onExpired={() => { formik.setFieldValue("recaptcha", ""); }}
										/>
										
										{formik.touched.recaptcha && formik.errors.recaptcha ? (
											<div style={{ color: "#dc3545" }}>{formik.errors.recaptcha}</div>
										) : null}
									</div>                                       
									</div>


									<div className="col-5 d-flex justify-item-center">
										<div className="form-group button">
											<button disabled={loading} type="submit" className="bizwheel-btn theme-2 primary-color ">
													<div style={{display: 'flex', alignItems: 'center',}}>
													<div style={{marginRight: '.4em', fontSize: '1.3rem'}}>Souscrire</div> <ClipLoader color={"#fff"} loading={loading} size={20} />
													</div>
											</button>		
										</div>
									</div>
									
								</div>
							</form>
						</div>
						{/* <!--/ End contact Form --> */}
					</div>
				</div>
			{/* </div> */}
		</section>	
  )
}

const mapStateToProps = (state) => {
      console.log('state.user_product.selected_modules', state.user_product.selected_modules)
      return {
        userProductData : state.user_product.selected_modules
      }
    }
    
    const mapDispatchToProps = dispatch => {
        return {
          fetchUserProducts: (token)=> dispatch(fetchUserProducts(token)),
        };
      };
    
    export default connect(mapStateToProps, mapDispatchToProps)(NewsletterForm)