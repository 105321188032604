import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ContactUsForm, ProductPageCommonComponent } from '../../components';

const EquerreSMB50 = (props) => {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{'Equerre SMB 50'}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre SMB 50"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre-smb-50" />
        <meta property="og:image"  content="/assets/img/smb-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/smb-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre-smb-50" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white hero_img_block wrapper_blur equerre_smb_50_style">
  
  <div class="foreground_blur" style={{ backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/eqr-smb-50-img-1.jpg')", borderBottom: '5px solid #fff',backgroundSize: 'cover', backgroundPosition: "top right"}}
      ></div>
			<div className="" 
      // style={{backgroundColor: 'rgb(217, 104, 112, .9)'}}
      >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{fontSize:'3.5em', lineHeight:'1em', color: 'white'}}>C’est facile de libérer le potentiel 
                                            des petites entreprises avec Equerre SMB 50</h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>A distance ou sur site, restez connectés à vos collaborateurs pour créer, ensemble, vos documents de vente en quelques clics, faciliter la tenue de votre trésorerie grâce à une comptabilité avancée, et profiter d’une gestion de stocks dynamique afin d’optimiser vos coûts.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-3 effect mt-lg-3">Démandez une démo</a>
										</div>
									</div>
								</div>
							</div>
            <div className="col-lg-5 col-md-6 col-12">
                
                {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/smb-img-1.png'/> */}
            </div>
						</div>
					</div>
				</div>
			</div>
		</section>

        
  {/* Services */}
  <section className="services section-bg-white section-space pb-0 ">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="section-title style2 text-center">
              <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1em"}}>
                Comment Equerre SMB 50 libère le potentiel de <br/>
                votre entreprise
              </h2>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-4 col-md-4 col-12">
          {/* Single Service */}
          <div className="single-service">
            <div className="service-head">
              <div className="icon-bg">
                <i className="fa fa-handshake-o" />
              </div>
              <img
                src="/assets/img/Equerre-smb-img-1.jpg"
                alt="#"
              />
            </div>
            <div className="service-content">
              <h4>
                <a href="#">
                    Gestion commerciale
                </a>
              </h4>
              <p>
                Du devis à la facturation, en passant par l’approvisionnement, les stocks et le pilotage, Equerre SMB 50 vous aide à optimiser la gestion de votre activité. Gestion de bout en bout du cycle de vente : devis, commandes et bons de livraison, jusqu’aux factures et transferts en comptabilité
              </p>
              {/* <a
                className="btn"
                href="#"
              >
                <i className="fa fa-arrow-circle-o-right" />
                View Service
              </a> */}
            </div>
          </div>
          {/*/ End Single Service */}
        </div>
        <div className="col-lg-4 col-md-4 col-12">
          {/* Single Service */}
          <div className="single-service">
            <div className="service-head">
              <div className="icon-bg">
                <i className="fa fa-calculator" />
              </div>
              {/* <div className="featured">Featured</div> */}
              <img
                src="/assets/img/Equerre-smb-img-2.jpg"
                alt="#"
              />
            </div>
            <div className="service-content">
              <h4>
                <a href="#">
                    Gestion comptable
                </a>
              </h4>
              <p>Equerre SMB 50 facilite votre gestion au quotidien grâce à la comptabilité automatisée. Tenez facilement votre comptabilité générale, analytique et budgétaire grâce à une solution de gestion comptable adaptée à un contexte légal et économique en perpétuelle évolution. 
              </p>
            </div>
          </div>
          {/*/ End Single Service */}
        </div>
        <div className="col-lg-4 col-md-4 col-12">
          {/* Single Service */}
          <div className="single-service">
            <div className="service-head">
              <div className="icon-bg">
                <i className="fa fa-bar-chart" />
              </div>
              <img
                src="/assets/img/Equerre-smb-img-3.jpg"
                alt="#"
              />
            </div>
            <div className="service-content">
              <h4>
                <a href="#">
                Reporting et tableaux de bord 
                </a>
              </h4>
              <p>
              Suivez l’activité de votre entreprise en temps réel et bénéficiez immédiatement d’informations exhaustives pour une prise de décision plus avisée
              </p>
            </div>
          </div>
          {/*/ End Single Service */}
        </div>
      </div>
        <div className="button mt-5 d-flex justify-content-center">
                {/* <a href="/our_products#management_software"   className="bizwheel-btn theme-bloc-4">Voir plus de fonctionnalités</a> */}
            </div>
        </div>
    </section>
    {/*/ End Services */} 

    <section className="pricing section-bg-white pt-5">
    <div className="container">
        <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-12">
                <div className="section-title default text-center">
                <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1em"}}>3 offres 100% Cloud pour simplifier la gestion de votre entreprise</h2>
                </div>
            </div>
        </div>

        <div className="row">
        <div className="col-lg-4 col-md-4 col-12">
            <div className="single-pricing">
            <div className="price-head">
                <h4 className="small-title" style={{ fontSize: '2.5em', lineHeight: "1em"}}>Equerre SMB 50<span>Standard</span></h4>
            </div>
            <h2 className="price"><span><b>$</b>20</span><b className="renew">HT/M</b></h2>
            <ul className="price-list">
                <li>Devis & Factures</li>
                <li>Gestion des bons de commandes et livraisons</li>
                <li>Bilans/Comptes de résultats  </li>
                <li>Excel en ligne</li>
            </ul>
            </div>
            <div className="button mt-3">
                <a className="bizwheel-btn theme-3 mr-0 primary-color" style={{display:'block'}} href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal" >Commandez maintenant</a>
                <a className="bizwheel-btn theme-3 mt-lg-2 mt-3" style={{display:'block'}} href="#joined_us" >Essayez gratuitement / 30j </a>                
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-12">
            <div className="single-pricing active">
            <div className="price-head">
                {/* <div className="p-best"><p>Best Plan <span>25% Off</span></p></div> */}
                <h4 className="small-title" style={{ fontSize: '2.5em', lineHeight: "1em"}}>Equerre SMB 50<span>Medium</span></h4>
            </div>
            <h2 className="price"><span><b>$</b>35</span><b className="renew">HT/M</b></h2>
            <ul className="price-list">
                <li>Devis & Factures</li>
                <li>Gestion des bons de commandes et livraisons</li>
                <li>Bilans/Comptes de résultats  </li>
                <li>Gestion de la trésorerie et des immobilisations </li>
                <li>Gestions des stocks et inventaires </li>
                <li>Rapports et tableaux de bord </li>
                <li>Excel en ligne</li>
            </ul>
            </div>
            <div className="button mt-3">
                <a className="bizwheel-btn theme-3 mr-0 primary-color" style={{display:'block'}}  href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal" >Commandez maintenant</a>
                <a className="bizwheel-btn theme-3 mt-lg-2 mt-3" style={{display:'block'}}  href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal" >Commandez maintenant</a>                
            </div>
        </div>
        <div className="col-lg-4 col-md-4 col-12">
            <div className="single-pricing">
            <div className="price-head">
                <h4 className="small-title" style={{ fontSize: '2.5em', lineHeight: "1em"}}>Equerre SMB 50<span>Premium</span></h4>
            </div>
            <h2 className="price"><span><b>$</b>49</span><b className="renew">HT/M</b></h2>
            <ul className="price-list">
                <li>Devis & Factures</li>
                <li>Gestion des bons de commandes et livraisons</li>
                <li>Bilans/Comptes de résultats  </li>
                <li>Gestion de la trésorerie et des immobilisations </li>
                <li>Gestions des stocks et inventaires </li>
                <li>Rapports et tableaux de bord </li>
                <li>Excel en ligne</li>
                <li>Partage des fichiers</li>
                <li>Gestion des sauvegardes</li>
            </ul>
            </div>
            <div className="button mt-3">
                <a className="bizwheel-btn theme-3 mr-0 primary-color" style={{display:'block'}} href="#" type="button" data-toggle="modal" data-target="#myMarketplaceModal" >Commandez maintenant</a>
                <a className="bizwheel-btn theme-3 mt-lg-2 mt-3" style={{display:'block'}} href="#joined_us" >Essayez gratuitement / 30j </a>                
            </div>
        </div>
        </div>
    </div>
    </section>

    <section>
            <ProductPageCommonComponent title={'Equerre SMB 50'} />
        </section>
    </>
  )
}

export default withNamespaces() (EquerreSMB50)