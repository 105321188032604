import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ContactUsForm } from '../../components';
import BecomePartnerRVAForm from './BecomePartnerRVAForm';
import FindPartnerForm from './FindPartnerForm';

const BecomePartner = (props) => {
    const { t } = props
    useEffect(() => {
      
    }, [])
    
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre | About Us"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/about_us" />
        <meta property="og:image"  content="/assets/img/about_us-img-1.jpg"/>
        <meta name="twitter:image" content="/assets/img/about_us-img-1.jpg"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/about_us" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white hero_img_block" 
            style={{  backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/become_partner-img-1.jpg')", backgroundPosition: "top left",backgroundSize: 'cover', borderBottom: '5px solid #fff'}}
            >
			<div className="" >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 py-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'white'}}>Vous voulez devenir<br/>partenaire Equerre ? </h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Le partenariat Equerre, c'est un engagement pour le succès de tous.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-1 px-3 effect mt-lg-3">Rejoignez-nous </a>
										</div>
									</div>
								</div>
							</div>
                    <div className="col-lg-5 col-md-6 col-12">
                        
                        {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/smb-img-1.png'/> */}
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>  
    

        <section id="joined_us" className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container-lg">
                <div className="row">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                        Rejoignez la famille Equerre selon vos profils et   <br/>
                        votre expérience 
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-12'>
                        <p className="text-dark mt-4" style={{fontSize:'1.7em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Vous êtes une Entreprise de service numérique (ESN), un Intégrateur de logiciels, un Consultant indépendant, un Cabinet de conseils ou un Bureau d’étude. Vous êtes les bienvenus dans la grande famille Equerre.  </p>
                        <p className="text-dark mt-4" style={{fontSize:'1.7em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>En tant que Revendeur à valeur ajoutée (RVA), vous serez capable d’assurer la prescription, l’avant-vente, la vente, la mise en œuvre et la maintenance des produits Equerre. Vous vous familiariserez également avec nos modes de commercialisation On premise, SaaS, Cloud ou via souscription.</p>
                        
                        <div className="button  my-4">       
                            <a type='button' data-toggle="modal" data-target="#BecomePartnerRVAForm" className="bizwheel-btn theme-3 effect mt-lg-3 primary-color">Devenir partenaire Equerre</a>
                            <a type='button' data-toggle="modal" data-target="#FindPartnerForm"  className="bizwheel-btn theme-3 effect mt-lg-3 text-light mt-lg-0 mt-3">Je recherche un partenaire Equerre</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

                {/* The Modal */}
        <div className="modal mt-5 pt-2" id="BecomePartnerRVAForm">
            <BecomePartnerRVAForm />
        </div>

        
        <div className="modal mt-5 pt-2" id="FindPartnerForm">
            <FindPartnerForm />
        </div>


    </>
  )
}

export default withNamespaces() (BecomePartner)