import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const UserAdminLayout = (props) => {

  const [linkDefault, setLinkDefault] = useState('style')
  const [userLinkDefault, setUserLinkDefault] = useState('user-style-default')
  const [container, setContainer] = useState('[data-layout]')
  const [isFluid, setIsFluid] = useState('isFluid')
  const [linkRTL, setLinkRTL] = useState('style-rtl')
  const [userLinkRTL, setUserLinkRTL] = useState('user-style-rtl')
  const [isRTL, setIsRTL] = useState('isRTL')
  
useEffect(() => {  
  document.head.innerHTML =[]
  const cssUrl = "/admin_assets/css/theme-rtl.min.css";
  addStyle(cssUrl);
  const themeUrl = "/admin_assets/css/theme.min.css";
  addStyle(themeUrl);
  const userUrl = "/admin_assets/css/user-rtl.min.css";
  addStyle(userUrl);
  const userMinUrl = "/admin_assets/css/user.min.css";
  addStyle(userMinUrl);
  const testUrl = "/admin_assets/test.css";
  addStyle(testUrl);
  const font1 = "https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700%7cPoppins:300,400,500,600,700,800,900&amp;display=swap"
  addStyle(font1);
  
  const font = document.createElement("link");
  font.href = 'https://fonts.gstatic.com';
  font.rel = "preconnect";
  font.async = true;

  document.head.appendChild(font);

}, [])

  const addStyle = url => {
      const style = document.createElement("link");
      style.href = url;
      style.rel = "stylesheet";
      style.async = true;

      document.head.appendChild(style);
  };
  return (
    <div className=''>
        {React.cloneElement(props.children)}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminLayout)