import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, FooterSummary, RegistrationForm } from '../../components'

import './Registration.css'
const Registration = (props) => {
  return (
    <div className="registration_container"> 
        <Breadcrumb links={[{link: './', title: 'Home'},{link: './pricing', title: 'Pricing'},{link: './registration', title: 'Registration'}]} title={'Register Ourself'}/>
        <RegistrationForm />
      <div className='summary_container'>
        <FooterSummary link={'./checkout'} title={'Checkout'}/>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Registration)