import React from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

function EquerreRH1000(props) {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{t("home_page_title_h1")}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre RH 1000"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre-smb-50" />
        <meta property="og:image"  content="/assets/img/smb-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/smb-img-1.png"/>

        
        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre-smb-50" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image_url" content="/assets/img/smb-img-1.png" />
        
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Equerre Rh 1000" />
        <meta property="og:description" content="Your company resource manage 360 degrees" />
        <meta property="og:image" content="/assets/img/smb-img-1.png" />
        <meta property="og:url" content="ENLACE PERMANENTE" />
        <meta property="og:site_name" content="NOMBRE DEL SITIO" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@nytimesbits" />
        <meta name="twitter:creator" content="@nickbilton" />
        <meta property="twitter:title" content="Equerre Rh 1000" />
        <meta property="twitter:description" content="Your company resource manage 360 degrees" />
        <meta name="twitter:image" content="/assets/img/smb-img-1.png" />
        <meta property="og:url" content="/assets/img/smb-img-1.png" />
        <meta property="og:title" content="A Twitter for My Sister" />
        <meta property="og:description" content="Your company resource manage 360 degrees." />
        <meta property="og:image" content="/assets/img/smb-img-1.png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white hero_img_block wrapper_blur mb-lg-0 mb-5">
  
  <div class="foreground_blur" style={{ height: '640px', backgroundImage: "linear-gradient(45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url('/assets/img/rh-1000-mg-1.jpg')", backgroundSize: 'cover',backgroundRepeat: 'no-repeat' , backgroundPosition: "bottom left", borderBottom: '5px solid #fff', backgroundPosition: "top right"}}
      ></div>

			<div className="" 
            // style={{backgroundColor: 'rgb(215 94 15 / 90%)'}}
            
            
            >
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 pt-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-6 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'3.5em', lineHeight:'1em', color: 'white'}}>Avec Equerre RH 1000,</h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Gérez les opérations complexes de management des ressources humaines, grâce à un large spectre de fonctionnalités dont le self - service des employés et la gestion de la paie.</p>
										<div className="button  my-4">       
											<a href="/equerre-hr-1000#video-feature" className="bizwheel-btn theme-1 effect mt-lg-3 primary-color">Voir la vidéo</a>
											<a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3">Demandez une démo</a>
										</div>
									</div>
								</div>
							</div>
                            <div className="col-lg-5 col-md-6 col-12">
                                
                                {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/smb-img-1.png'/> */}
                            </div>
						</div>
					</div>
				</div>
			</div>
		</section>

        
            {/* Services */}
            <section className="equerre_rh_1000 services section-bg section-space mt-0 pb-5 section-bg-white">
                <div className="container">
                    
                <div className="row">
                    <div className="col-12">
                        <div className="section-title style2 text-center">
                            <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
                            Qu’est-ce que Equerre RH 1000 ?
                            </h2>
                        </div>
                    </div>
                    
                    <div className='col-12 mb-4'>
                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Tout d'abord, qu'est-ce que Equerre RH 1000 n'est pas ?</p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre RH 1000 n'est pas un logiciel de gestion, avec des bribes de fonctionnalités RH.</p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Il n'est pas non plus un logiciel spécialisé de gestion des ressources humaines, qui traite spécifiquement de la paie ou de la formation ou du recrutement etc. </p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre RH 1000 est bien plus que ça !</p>
                    </div>
                    
                    
                    <div className='col-12 mb-4'>
                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre RH 1000 est une solution complète et intégrée de gestion des ressources humaines.</p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>C'est un outil de pilotage global de la fonction RH au sein de votre entreprise.</p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Il n'est pas non plus un logiciel spécialisé de gestion des ressources humaines, qui traite spécifiquement de la paie ou de la formation ou du recrutement etc. </p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Il parcourt de bout en bout, l'ensemble de vos process métier RH tels que :</p>

                        <p className="h3 text-dark mt-3" style={{fontSize:'1.35em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>le Dossier du personnel ; la Gestion sociale ; la Gestion des Contrats ; la Gestion des Congés et des absences ; la Gestion des Missions ; la Gestion des Départs ; la Gestion de la Paie et de la Paie inverse ; la Gestion de l'Organisation ; la Gestion du Recrutement ; la Gestion de la Formation ; la Gestion de la carrière ; la Gestion des Evaluations… Et bien plus !</p>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="section-title style2 text-center">
                            <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.3"}}>
                            Optez pour un logiciel de pilotage global de la <br/>fonction RH au sein de votre entreprise
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion de la structure organisationnelle</h3>
                            </a>
                        </h4>
                        <p className=''>
                        Dans Equerre RH 1000, vous pouvez gérer la structure organisationnelle de votre société grâce aux fonctionnalités de création et mise à jour des organigrammes des départements et des emplois. Vous bénéficiez en plus d'un affichage dynamique pour vos besoins d'analyses
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion administrative des employés</h3>
                            </a>
                        </h4>
                        <p>La gestion administrative du personnel est couverte par plus de 10 fonctionnalités au rang desquelles : la gestion du temps et des présences que vous pouvez d'ailleurs interfacer avec votre système biométrique ; la gestion des congés et absences que vous pouvez dématérialiser dans des workflow ; la gestion des missions des employés ; la gestion des dossiers du personnel, avec la possibilité de télécharger tous les dossiers physiques des employés collectés lors du processus de recrutement et tout au long du parcours des employés ; la gestion des contrats ; la gestion sociale ; la gestion des sanctions ; la gestion de la newsletter à laquelle chaque employé peut accéder depuis son compte créé lors de son enrôlement, etc.  
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion de la paie du personnel</h3>
                            </a>
                        </h4>
                        <p>
                        Le module de gestion de la paie permet la Saisie et le  paramétrage des éléments fixes de salaire ainsi que la gestion des éléments variables de la paie. Conformément aux contraintes réglementaires, vous procéderez au paramétrage des retenues légales. Vous avez la possibilité de gérer l'attribution de certains avantages au personnel tel que les avances sur salaires. Equerre RH 1000 vous permet d'exploiter la puissance d’Excel pour effectuer des ajustements sur la paie, d'éditer vos bulletins de paie dans un environnement multi société et multi contrats au besoin. La flexibilité dans l'édition des rapports offre  la possibilité d'éditer tout type de rapport réglementaire sous la forme voulue.
    Avec Equerre RH 1000, vous pouvez également négocier librement les salaires nets des recrues et simuler les bulletins de paie grâce à la fonctionnalité "Paie inverse"

                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des recrutements</h3>
                            </a>
                        </h4>
                        <p>
                        Le module Gestion des recrutements vous permet de gérer vos recrutements de bout en bout, de publier les offres d'emploi sur le portail RH intégré, de filtrer les candidatures, de planifier vos tests et entretiens de sélection en toute quiétude et de procéder à l'intégration des nouveaux employés. 
                        </p>
                        <p className='mt-lg-2'>Equerre RH 1000 vous permet également de transférer tous les éléments recueillis lors du processus de recrutement dans le dossier de l'employé enrôlé en un clic, et d'y retrouver absolument toutes les informations que vous n'avez désormais plus besoin de saisir à nouveau.</p>
                        {/* <a
                            className="btn"
                            href="#"
                        >
                            <i className="fa fa-arrow-circle-o-right" />
                            View Service
                        </a> */}
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion de la formation</h3>
                            </a>
                        </h4>
                        <p>
                        Equerre RH 1000 vous permet de générer vos catalogues de formation, enrichis en fonction des besoins collectés; vous pouvez aussi entrer des programmes métiers ou transversaux et mettre en ligne des modules de formation à la portée des collaborateurs. Couplée aux fonctions Self-service employé et Workflow, vous pouvez suivre l'évolution de vos équipes en évaluant leurs acquis et en contrôlant vos dépenses de formation

                        </p>

                        <h4 className='mt-5'>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des carrières</h3>
                            </a>
                        </h4>
                        <p>
                        Recensez, organisez et anticipez l'évolution des employés en fonction de leurs acquis et des exigences des emplois au sein de votre organisation. Equerre RH 1000 vous donne la latitude de regrouper les compétences pour déterminer des parcours types par métier, et retrace le vécu de chaque collaborateur en termes de formation, d'emplois occupés, d'évolutions de salaires et bien plus. 

                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des évaluations</h3>
                            </a>
                        </h4>
                        <p>
                        Equerre RH 1000 vous permet de suivre les performances de vos équipes, de la fixation des objectifs à la définition des critères de performances. Vous allez également dégager des perspectives de développement des compétences des équipes en analysant les résultats produits par vos tableaux de bord de gestion des performances

                        </p>

                        <h4 className='mt-5'>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Le self-service du personnel</h3>
                            </a>
                        </h4>
                        <p>
                        Tout employé de votre entreprise accède au portail RH où il peut : consulter ses informations personnelles (bulletins de paie, fichier social...) ;  émettre des demandes spécifiques en direction des services des ressources humaines (présences, congés, missions, absences, départs, etc.)

                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>
                    
                    <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion automatisée des E-mails</h3>
                            </a>
                        </h4>
                        <p>
                        Equerre RH 1000 vous donne la possibilité de créer des modèles d’emails dans divers processus pour entretenir une communication permanente avec le personnel ou même des candidats à l'embauche.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                    </div>

                    
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4>
                                <a href="#" className='fw-bold'>
                                <h3 className='text-dark'>Workflow applicatifs pour diverses procédures</h3>
                                </a>
                            </h4>
                            <p>
                            Pour chacun de vos processus RH, vous pouvez intégrer des formulaires avec des demandes d’approbations dans une chaîne de décision que vous définissez librement : il peut s'agir d'un formulaire lié au recrutement, à la formation, à la gestion de missions, et bien d'autres. 
                            </p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                </div>
                
                </div>
            </section>
            {/*/ End Services */} 

            <section className="equerre_rh_1000 call-action section-bg-white" style={{borderBottom: '5px solid #fff', backgroundImage: 'url("img/cta-bg.jpg")', backgroundColor: '#f1f1f1f2', borderBottom: '5px solid #fff'}}>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="call-inner" style={{textAlign: 'center', lineHeight: '3em !important'}}>
                    <h2 style={{color: '#000'}}>Des fonctionnalités totalement gratuites pour mieux vous accompagner dans le pilotage global de la fonction RH</h2>
                    </div>
                </div>
                
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des objectifs</h3>
                            </a>
                        </h4>
                        <p>
                        Mesurez la productivité du personnel en enregistrant et en suivant de façon automatisée leurs objectifs directement dans l'application.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des sondages</h3>
                            </a>
                        </h4>
                        <p>
                        Montez en toute simplicité des sondages à administrer au personnel et recevez les avis des employés, dans une totale confidentialité.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                           <h3 className='text-dark'>Gestion des annonces</h3>
                            </a>
                        </h4>
                        <p>
                        Avec cette fonctionnalité, envoyez des messages à des groupes d'employés ou même à l'ensemble des employés enregistrés sur la plateforme.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Gestion des sauvegardes</h3>
                            </a>
                        </h4>
                        <p>
                        Sauvegardez et restaurez à tout moment les données et les paramètres de l'ensemble du système.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                    {/* Single Service */}
                    <div className="single-service equerre-rh">
                        <div className="service-content">
                        <h4>
                            <a href="#" className='fw-bold'>
                            <h3 className='text-dark'>Excel en ligne</h3>
                            </a>
                        </h4>
                        <p>
                        Exploitez la puissance d'un tableur intégré directement en ligne avec la possibilité de partager et éditer en live.
                        </p>
                        </div>
                    </div>
                    {/*/ End Single Service */}
                </div>
                </div>
                
            </div>
            </section>

            {/* section video  */}
            <section id="video-feature" className="video-feature side overlay section-bg hero_img_block" style={{height: '643px', borderBottom: '10px solid rgba(225, 225, 225, .9)', backgroundImage:"linear-gradient(rgba(225, 25, 25, .7), rgba(225, 25, 25, .7)), url('/assets/img/img-5.jpg')"}}>
            <div className="container-lg">
                <div className="row mt-5">
                <div className="col-lg-5 col-md-6 col-12">
                                    <div className="welcome-text"> 
                                        <div className="hero-text pt-lg-5"> 
                                            <h2 className='' style={{fontSize:'4em', lineHeight:'1.5em', color: 'white', textShadow: '0 0 1px #ffffff'}}>Regardez la video de presentation de Equerre RH 1000</h2>
                        {/* <p className="mt-4" style={{textTransform: "capitalize",fontSize:'3em', lineHeight:'initial', color: '#fff', fontWeight: 'normal'}}>Optez pour un logiciel complet et                   
                            intégré qui permet de gérer                                   
                            l'ensemble de vos processus RH
                        </p> */}
                                            {/* <div className="button mt-4">
                                                <a href="/our_products#management_software"   className="bizwheel-btn theme-bloc-2">Téléchargez la présentation vidéo </a>
                                            </div> */}
                                        </div>
                                    </div>
                </div>

                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/LDlPWVUwbq8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

                <div className="offset-lg-1 col-lg-5 col-md-6 col-12">
                    <div className="img-feature ">
                    {/* <img src="assets/img/why-choose.jpg" alt="Video Thumbnail" /> */}
                    <div className="video-play">
                        <a href="https://www.youtube.com/watch?v=LDlPWVUwbq8" className="video video-popup mfp-iframe">
                        <i className="fa fa-play" />
                        </a>
                        <div className="waves-block">
                        <div className="waves wave-1" />
                        <div className="waves wave-2" />
                        <div className="waves wave-3" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* section video end */}
            
    <section>
            <ProductPageCommonComponent title={'Equerre RH 1000'} />
        </section>

    </>
  )
}

export default withNamespaces() (EquerreRH1000)