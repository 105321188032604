import React from 'react'
import { connect } from 'react-redux'

const LicensePage1 = (props) => {
  return (
    <>
      <div className='container'>
        <div className='row' style={{marginBottom: '4rem'}}>
            <div className='col-6'>
                <h4 style={{color: '#F8BC5C', marginBottom: '1.5rem', marginTop: '2rem'}}>Ressources Humaines</h4>
                <h1 style={{color: '#003E9F', fontSize: '70px', lineHeight: '1em', marginBottom: '1.5rem'}}>UNE GESTION 360 DE VOS RH</h1>
                <p style={{color: '#000'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
                <a style={{backgroundColor: '#019EE1', color: '#F7C094', display: 'inline-block', padding:'.3em 1em', borderRadius: '5px', margin: '1.5em 0'}} href='#'>Demander une demo</a>
            </div>
            <div className='col-6' style={{backgroundImage:"url('/assets/img/img-10.png')",  backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat' }}>
            
            </div>
        </div>
      </div>
      {/* features */}
      <div style={{backgroundColor: '#004AAD'}}>
        <div className='container'>
            <div className='row'>
                <div className='col-4' style={{padding: '2em 3em', backgroundColor: "#fff"}}>
                    <div style={{marginBottom: '1em', fontWeight: 'bolder',  textAlign:'center',padding: '.6em', color: '#fff',backgroundColor: 'var(--blue)', height: '3em', width: '3em', borderRadius: '50%'}} className='key_features_nbr'>
                        01
                    </div>
                    <p style={{color: '#000'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
                </div>
                <div className='col-4' style={{padding: '2em 3em'}}>
                    <div style={{marginBottom: '1em', fontWeight: 'bolder',  textAlign:'center',padding: '.6em', color: '#fff',backgroundColor: 'var(--blue)', height: '3em', width: '3em', borderRadius: '50%'}} className='key_features_nbr'>
                        02
                    </div>
                    <p style={{color: '#fff'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
                </div>
                <div className='col-4' style={{padding: '2em 3em'}}>
                    <div style={{marginBottom: '1em', fontWeight: 'bolder',  textAlign:'center',padding: '.6em', color: '#fff',backgroundColor: 'var(--blue)', height: '3em', width: '3em', borderRadius: '50%'}} className='key_features_nbr'>
                        03
                    </div>
                    <p style={{color: '#fff'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
                </div>
            </div>
        </div>
      </div>
      {/* emd features */}
      {/* steps */}
      <div style={{backgroundColor: '#ECECEC'}}>
        <div className='container'>                
            <div className='row'>

                <div className='col-3' style={{textAlign: 'center', padding: '5em 1em'}}>
                    <div style={{ margin: '0 auto', textAlign: 'center', backgroundColor: '#019de5', width: '5rem', height: '5rem', borderRadius:'50%', padding: '.8rem'}}><img style={{position: 'relative'}} className='key_' src='/assets/img/img-14.png' /></div>
                    <h4 style={{color: '#000', marginBottom: '.7em'}}>Sed ut perspiciatis undeomnis</h4>
                    <p style={{color: '#000'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                </div>

                <div className='col-3' style={{textAlign: 'center', padding: '5em 1em'}}>
                    <div style={{ margin: '0 auto', textAlign: 'center', backgroundColor: '#019de5', width: '5rem', height: '5rem', borderRadius:'50%', padding: '.8rem'}}><img style={{position: 'relative'}} className='key_' src='/assets/img/img-11.jpg' /></div>
                    <h4 style={{color: '#000', marginBottom: '.7em'}}>Sed ut perspiciatis undeomnis</h4>
                    <p style={{color: '#000'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                </div>

                <div className='col-3' style={{textAlign: 'center', padding: '5em 1em'}}>
                    <div style={{ margin: '0 auto', textAlign: 'center', backgroundColor: '#019de5', width: '5rem', height: '5rem', borderRadius:'50%', padding: '.8rem'}}><img style={{position: 'relative'}} className='key_' src='/assets/img/img-12.jpg' /></div>
                    <h4 style={{color: '#000', marginBottom: '.7em'}}>Sed ut perspiciatis undeomnis</h4>
                    <p style={{color: '#000'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                </div>

                <div className='col-3' style={{textAlign: 'center', padding: '5em 1em'}}>
                    <div style={{ margin: '0 auto', textAlign: 'center', backgroundColor: '#019de5', width: '5rem', height: '5rem', borderRadius:'50%', padding: '.8rem'}}><img style={{position: 'relative'}} className='key_' src='/assets/img/img-13.jpg' /></div>
                    <h4 style={{color: '#000', marginBottom: '.7em'}}>Sed ut perspiciatis undeomnis</h4>
                    <p style={{color: '#000'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                </div>

            </div>
        </div>
      </div>
      {/* end steps */}

      {/* page 2 */}
        <div style={{color: "#ffffff"}}>
            <div className='container'>
                <div className='row'>                    
                    <div className='col-6' style={{padding: '4em 1em'}}>
                        <h4 style={{color: '#000', marginBottom: '.5em'}}>Sed ut perspiciatis unde omnis ed ut perspiciatis unde omnis</h4>
                        <p style={{color: '#000', marginBottom: '1em'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error ,Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                        <p style={{color: '#000', marginBottom: '1em'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error ,Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                        <p style={{color: '#000', marginBottom: '1em'}}>Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error ,Sed ut perspiciatis unde omnis iste natus error Sed ut perspiciatis unde omnis iste natus error</p>
                        <a style={{backgroundColor: '#019EE1', color: '#F7C094', display: 'inline-block', padding:'.3em 1em', borderRadius: '5px', margin: '1.5em 0'}} href='#'>Nos offres</a>
                    </div>
                    <div className='col-6' style={{backgroundImage: 'url("/assets/img/img-14.jpg")', backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat' }}>
                        
                    </div>
                </div>
            </div>
        </div>
      {/* end  page 2 */}
      {/* block 3 */}
        <a class="wplightbox" href="https://www.wonderplugin.com/wp-content/uploads/2020/08/WPcarousel.mp4">
            <div class="lightboxContainer">
                <img src="/assets/img/img-17.png" alt=""/>
            </div>
        </a>
      {/* end block 3 */}
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LicensePage1)