import React, {useEffect} from 'react'
import { Helmet } from 'react-helmet-async';
import { withNamespaces } from 'react-i18next';
import { ContactUsForm } from '../../components';

const AboutUsCst = (props) => {
    const { t } = props
    useEffect(() => {
      
    }, [])
    
  return (
    <>
    <Helmet>
        <title>{"About Us"}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre | About Us"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/about_us" />
        <meta property="og:image"  content="/assets/img/about_us-img-1.jpg"/>
        <meta name="twitter:image" content="/assets/img/about_us-img-1.jpg"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/about_us" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
		<section className="style1 section-bg-white  hero_img_block wrapper_blur" 
        style={{      
        }}>
            
  <div class="foreground_blur hero_img_block" style={{ 

backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0)), url('/assets/img/about_us-img-1.jpg')",
minheight: '90vh',
}}
      ></div>

			<div className="" style={{}}>
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 py-lg-5" >
					<div className="container-lg">
						<div className="row">
							<div className="col-lg-7 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'6em', lineHeight:'1.2em', color: 'white'}}>A propos de <br/>Equerre </h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Equerre est une entreprise de plus de 1000 employés créée en 2014 aux Etats-Unis d’Amérique. Nous sommes reconnus pour la qualité de nos solutions logicielles, avec une base clients répartie sur 3 continents.</p>
										{/* <div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-1 effect mt-lg-3 primary-color">Téléchargez la brochure</a>
										</div> */}
									</div>
								</div>
							</div>
                    <div className="col-lg-5 col-md-6 col-12">
                        
                        {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/smb-img-1.png'/> */}
                    </div>
						</div>
					</div>
				</div>
			</div>
		</section>  
    
    
  <section className="services section-bg section-space mt-0 py-5 section-bg-white">
    <div className="container-lg">
      <div className="row">
        <div className="col-12">
          <div className="section-title style2 text-center">
              <h2 style={{color: '#000' , fontSize: '3em', lineHeight: "1.2em"}}>
              Nous explorons sans relâche de nouvelles <br/>
              frontières technologiques 
              </h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className='col-12'>
            <p className="text-dark mt-4" style={{fontSize:'1.7em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>Equerre est à la pointe de la Recherche & Développement de logiciels d’entreprise et de gestion des contenus. Nous explorons sans relâche de nouvelles frontières techniques et technologiques dans l’intelligence artificielle, l’internet des Objets, la blockchain, le développement d’applications mobiles sur iPhone, iPad, Blackberry, Android, J2ME et Windows Mobile. Nos multinationales clientes nous sollicitent pour leurs grands projets de développement, déploiement et gestion de leurs solutions de mobilité, et leurs programmes de développement d’applications d’entreprise.</p>
            <p className="text-dark mt-4" style={{fontSize:'1.7em', wordSpacing: '.1em',lineHeight:'1.2em', fontWeight: 'normal'}}>La principale force de l’éditeur Equerre réside dans le fait qu’outre un réseau de partenaires commerciaux et d’intégrateurs à travers le monde, nous sommes physiquement présents dans tous nos principaux marchés cibles, (Amérique, Afrique, Europe…). Nos filiales ou agences, pourvues d’ingénieurs et de conseillers installés localement, vous apportent le support et l’ingénierie dont vous avez besoin. </p>
        </div>
        </div>
    </div>
    </section>
    
<section className="hero-agency pt-5">
  <div className="agency-inner-cst" >
    {/* <div className="agency-main pt-5"> */}
      <div className="container services">
        <div className="row mb-5" >
          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12">
            <div className="hero-text">
              <h2 className=' text-dark'> Aperçu général</h2>
            </div>
          </div>
        </div>
        
        <div className="row">
            <div className="col-md-6 col-lg-4">
                <div className="services-item">
                <img loading="lazy" width={167} height={168} src="/assets/img/service-icon-1.jpg" className="mx-auto wp-post-image" alt="true" />                        <a href="#" title="Financial Advisor">
                    <h3 className="services-item-title text-dark">Editeur et integrateur<br/>de solutions logicielles</h3>
                </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="services-item">
                <img width={167} height={168} src="/assets/img/service-icon-6.jpg" className="mx-auto wp-post-image" alt="true" loading="lazy" />                        <a href="#" title="Data Management">
                <h3 className="services-item-title text-dark">+1000 Ingenieurs<br/>et experts</h3>
                </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="services-item">
                <img width={167} height={168} src="/assets/img/about-us-img-8.png" className="mx-auto wp-post-image" alt="true" loading="lazy" />                        <a href="#" title="Business Analysis">
                <h3 className="services-item-title text-dark">+3 000 000 $ investis chaque annee en R&D</h3>

                </a>
                </div>
            </div>

            <div className="col-md-6 col-lg-4">
                <div className="services-item">
                <img loading="lazy" width={167} height={168} src="/assets/img/service-icon-4.jpg" className="mx-auto wp-post-image" alt="true" />                        <a href="#" title="Financial Advisor">
                    <h3 className="services-item-title text-dark">Maison mere et centre de competences, Maryland, USA</h3>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="services-item">
                <img width={120} height={168} src="/assets/img/home-page.png" className="mx-auto wp-post-image" alt="true" loading="lazy" />                        <a href="#" title="Data Management">
                    <h3 className="services-item-title text-dark mt-5">Filiales en Afrique et en Europe</h3>
                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="services-item">
                <img width={167} height={168} src="/assets/img/img-1.png" className="mx-auto wp-post-image" alt="true" loading="lazy" />                        <a href="#" title="Business Analysis">
                    <h3 className="services-item-title text-dark mt-5">Des clients dans 3 continents</h3>
                    </a>
                </div>
            </div> 
            
        </div>
            
            
      </div>
    </div>
  {/* </div> */}
</section>


    <section className="hero-agency pt-5" style={{borderBottom: '6px solid #fff'}}>
        <div className="agency-inner-cst" >
            {/* <div className="agency-main pt-5"> */}
            {/* <div className="container services">
            </div> */}
            <div className='services pl-lg-5 pb-5'style={{background: 'rgb(141 35 35 / 4%)'}}>
                <div className='container'>
                    
                <div className="row pt-5 text-center" >
                    <div className="col-12">
                        <div className="hero-text">
                            <h2 className=' text-dark'>Pourquoi choisir Equerre ?</h2>
                        </div>
                    </div>
                </div>
            <div className='row' style={{maxHeight: '50vh'}}>
                    <div className='pl-5 col-12 about_us_widget-container'>
                        <div className='about_us_widget-item single-item-1'>
                            <div>
                                <h5>Un éditeur plus proche de vous </h5>
                            </div>
                        </div>
                        <div className='about_us_widget-item single-item-2'>
                            <div>
                                <h5 className='' >Des solutions <br/>ultra <br/>personnali <br/>sables  </h5>
                            </div>
                        </div>
                        
                        <div className='about_us_widget-item single-item-3'>
                            <div>
                                <h5>Une réduction des coûts de maintenance</h5>
                            </div>
                        </div>
                        
                        <div className='about_us_widget-item single-item-4'>
                            <div>
                                <h5>Une réduction des coûts de développements IT</h5>
                            </div>
                        </div>

                        <div className='about_us_widget-item single-item-5'>
                            <div>
                                <h5>Une maîtrise des dernières technologies de recours</h5>
                            </div>
                        </div>

                        <div className='about_us_widget-item-main single-item-6'>
                            <div>
                                <h5>Une approche CCM « Customer Communication Management ».</h5>
                            </div>
                        </div>

                        <div className='about_us_widget-item single-item-7'>
                            <div>
                                <h5>Une intégration continue des feedbacks du client.</h5>
                            </div>
                        </div>

                        <div className='about_us_widget-item single-item-8'>
                            <div>
                                <h5>Des solutions performantes, intégrées & «user friendly».</h5>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>

        <section className="word_chart_equerre pt-5" style={{background: 'rgb(153 217 234 / 95%)',}}>
            
            <div className="container services">
                <div className="row mb-5" >
                    <div className= "col-12">
                        <div className="hero-text text-center">
                            <h2 className='text-dark'>Nos terrains de jeux</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-lg" >
                <div className='row'>
                    <div className='col-lg-10 offset-lg-1 col-12 bg-image' style={{backgroundImage: 'url("./assets/img/carte_du_monde-img-1.png")', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat'}}>

                    </div>
                </div>
            <div />
            </div>
        </section>

    </>
  )
}

export default withNamespaces() (AboutUsCst)