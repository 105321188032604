import React from 'react'
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { ProductPageCommonComponent } from '../../components';

const EquerreProjectManagement = (props) => {
    const { t } = props
  return (
    <>
    <Helmet>
        <title>{"Equerre gestion de projet"}</title>
        <meta name="description" content="Your company resource manage 360 degrees"/>
        <meta property="og:title"content="Equerre gestion de projet"/>
        <meta
        property="og:description"
        content="Your company resource manage 360 degrees"/>
        <link rel="canonical" href="https://www.equerre.com/equerre_project_management" />
        <meta property="og:image"  content="/assets/img/equerre_project_management-img-1.png"/>
        <meta name="twitter:image" content="/assets/img/equerre_project_management-img-1.png"/>


        <meta property="og:locale" content="en_US" />
        {/* <meta property="og:type" content="article" /> */}
        <meta property="og:url" content="https://www.equerre.com/equerre_project_management" />
        <meta property="og:site_name" content="Your company resource manage 360 degrees" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:type" content="image/png" />

    </Helmet>

    {/* <!-- Hero Slider --> */}
    

    <section className="style1 section-bg-white hero_img_block" 
              style={{ height: '643px', backgroundImage: "linear-gradient(45deg,rgba(0, 0, 0, 0), rgba(0, 0, 0, .7), rgba(0, 0, 0, 1)), url('/assets/img/project_mgt-mig-1.jpg')", borderBottom: '5px solid #fff', backgroundPosition: 'center left' }}>
              
			<div className="">
				{/* <!-- Single Slider --> */}
				<div className="single-slider vh-lg-95 py-lg-5" >
					<div className="container-fluid">
						<div className="row">
                    <div className="col-lg-7 col-md-6 col-12">
                        
                        {/* <img className='center-img' style={{ height: '360px', objectFit: 'cover'}} src='/assets/img/equerre_project_management-img-1.png'/> */}
                    </div>
							<div className="col-lg-5 col-md-8 col-12">
								<div className="welcome-text py-5 "> 
									<div className="hero-text"> 
										<div className="p-text">
										</div>
										<h2 style={{textTransform: "normal",fontSize:'5em', lineHeight:'1.2em', color: 'white'}}>Equerre Gestion <br/>de projet</h2>
											<p className="mt-4" style={{fontSize:'2em', wordSpacing: '.1em',lineHeight:'1.2em', color: '#fff', fontWeight: 'normal'}}>Le meilleur logiciel de gestion de projet pour toutes les entreprises.</p>
										<div className="button  mt-4">                      
											<a href="#joined_us" className="bizwheel-btn theme-3 px-3 effect mt-lg-3 primary-color">Demandez une démo</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>  

        <section id="" className="services section-bg section-space mt-0 py-5 section-bg-white">
            <div className="container-lg offset-lg-3 col-lg-6">
                <div className="row mb-4">
                    <div className="col-12">
                    <div className="section-title style2 text-center">
                        <h2 style={{color: '#000' , fontSize: '2.6em', lineHeight: "1.2em"}}>
                        Découvrez comment Equerre Gestion de projet vous permet de planifier, d’organiser et de gérer le travail de votre équipe de A à Z.
                        
                        </h2>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4 className='text-dark'><li className='fa fa-check mr-2'></li>Création de projet </h4>
                            <p>Planifier et structurer votre travail à votre façon.</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4 className='text-dark'><li className='fa fa-check mr-2'></li>Gestion  de projet </h4>
                            <p>Gérez vos projets de A à Z et suivez les responsabilités et échéances de chacun en regroupant tous vos projets sur Equerre gestion de projet.</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4 className='text-dark'><li className='fa fa-check mr-2'></li>Vues et rapports</h4>
                            <p>Observez facilement l’avancée du travail et tout ce qui se passe dans votre organisation grâce aux rapports.</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4 className='text-dark'><li className='fa fa-check mr-2'></li>Commandes d’administration</h4>
                            <p>Créez et gérez équipes et paramètres d’équipe pour que chacun puisse accéder aux bonnes informations.</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4 className='text-dark'><li className='fa fa-check mr-2'></li>Communication </h4>
                            <p>Reliez vos communications à vos équipes, projets ou tâches sur Equerre GP pour que votre équipe sache toujours où elle en est.</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                        {/* Single Service */}
                        <div className="single-service equerre-rh">
                            <div className="service-content">
                            <h4 className='text-dark'><li className='fa fa-check mr-2'></li>Intégrations  </h4>
                            <p>Connectez Equerre GP à vos outils quotidiens pour suivre aisément l’ensemble de votre travail en un seul endroit et gagner du temps</p>
                            </div>
                        </div>
                        {/*/ End Single Service */}
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ProductPageCommonComponent title={"Equerre Gestion de projet"}/>
        </section>
    </>
  )
}

export default withNamespaces() (EquerreProjectManagement)