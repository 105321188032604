import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FooterSummary } from "../../components";
import {
  createModuleLine,
  fetchModuleLines,
} from "../../redux/acitons/modulelines";
import { fetchModules } from "../../redux/acitons/modules";
import {
  addAllModuleLineOfParentModule,
  addModuleLine,
  fetchUserProducts,
  removeModuleLine,
  removeAllModuleLineOfParentModule,
} from "../../redux/acitons/userProductAction";
// import dummyModules from './dummy_data'

import "./Home.css";
import { Helmet } from 'react-helmet-async';
export const Home = ({
  removeAllModuleLineOfParentModule,
  removeModuleLine,
  addAllModuleLineOfParentModule,
  addModuleLine,
  fetchModules,
  moduleData,
  createModuleLine,
  fetchModuleLines,
  moduleLineData,
  fetchUserProducts,
  userProductData,
}) => {
  const [modules, setModules] = useState([...moduleData]);
  const [selectAll, setSelectAll] = useState({});
  const [loading, setLoading] = useState(false);
  // const [modulines, setModuleLines] = useState([...moduleLineData])
  const [user_tokent, setUserToken] = useState();
  const checkUserToken = () => {
    let _user_tokent = localStorage.get("userToken");
    if (_user_tokent !== undefined) setUserToken(_user_tokent);
  };
  const handleClickModule = (_module) => {
    createModuleLine(_module);
    setModules([...moduleData]);
  };
  //   console.log('modules', modules)
  useEffect(() => {
    //   console.log('-- moduleData', moduleData)
    const fecthData = async () => {
      let f_moduleslines = await fetchModuleLines();
      let _userToken = localStorage.getItem("userToken");
      let f_userproducts = await fetchUserProducts(_userToken);
      if (modules.length <= 0) {
        fetchModules();
      } else {
        setModules(moduleData);
      }
      return { f_moduleslines, f_userproducts };
    };

    fecthData()
      .then((res) => {
        // logic to determine wether a module has all its submodule selected or not
        const { f_moduleslines, f_userproducts } = res;
        // console.log('first', res,f_moduleslines, f_userproducts)
        f_userproducts &&
          f_userproducts.forEach((_user_product) => {
            let _parent_submodules = f_moduleslines.filter(
              (_modulesline) => _modulesline.parent === _user_product.module_id
            );
            if (
              _parent_submodules.length ===
              _user_product.module?.submodules?.length
            ) {
              setSelectAll({
                ...selectAll,
                [`${_user_product.module_id}`]: true,
              });
            }
          });
      })
      .finally(() => {
        // console.log('in finally', )
        setLoading(true);
      });
    return () => {};
  }, []);
  // console.log('-- moduleData', moduleData, modules)
  const checkIfUserHasSubmodule = (_userProductData, _submoduleId) => {
    let hasModule = false;
    let user_products = [];
    // console.log('-- In checkIfUserHasSubmodule :>> ', _userProductData, _submoduleId)
    _userProductData.map((_userproduct) => {
      // console.log('_userproduct', _userproduct.module.submodules)
      // return;
      if (_userproduct) {
        // console.log('_userproduct', _userproduct)
        _userproduct &&
          _userproduct.module.submodules.map((_submodule) => {
            // console.log('_submodule', _submodule.id , _submoduleId, )
            // return;
            if (_submodule.id === _submoduleId) {
              hasModule = true;
              user_products = _userproduct;
              return;
            }
          });
      }
    });
    //
    // console.log('-- checkIfUserHasSubmodule', hasModule)
    return { hasModule: hasModule, user_products: user_products };
  };
  const selectUserProduct = (_moduleData, _parentId) => {
    let _parentModule = _moduleData.filter(
      (_module) => _module._id === _parentId
    );
    // console.log('-- _moduleData, _parentId', _parentModule)
    return _parentModule[0];
  };
  const existingSubmodules = (_userProductData, _parentId) => {
    // console.log('_userProductData, _parentId', _userProductData, _parentId)
    let existing_modules = _userProductData.filter(
      (_user_product) => _user_product.module_id === _parentId
    );
    // console.log('existing_modules', existing_modules[0]?.module.submodules)
    // return
    return existing_modules[0]?.module.submodules;
  };
  const handleChangeSelectAll = (e) => {
    const { name, value } = e.target;
    //   console.log('name, value', name, value, e.target.checked)
    //   console.log('selectAll', selectAll[name])
    setSelectAll({
      ...selectAll,
      [name]: e.target.checked,
    });
    let _module = moduleData.filter((_module) => _module._id === name)[0];
    let _module_lines = [];
    if (e.target.checked === true) {
      _module_lines = moduleLineData.filter(
        (_module_line) => _module_line.parent === name
      );
    } else if (e.target.value === false) {
      removeAllModuleLineOfParentModule(name);
      return;
    }
    //   console.log('--- _module, _module_lines', _module, _module_lines)
    addAllModuleLineOfParentModule(_module, _module_lines);
    return;
  };
  const isAllSubmoduleSelect = (module_id) => {
    let f_moduleslines = [...moduleLineData];
    let f_userproducts = [...userProductData];
    //   console.log('f_userproducts', f_userproducts)
    let _user_product = f_userproducts.filter(
      (user_product) => user_product.module_id === module_id
    );
    //   console.log('_user_product', _user_product, 'module_id', module_id)
    if (_user_product instanceof Array) {
      _user_product = _user_product[0] || {};
      let _parent_submodules = f_moduleslines.filter(
        (_modulesline) => _modulesline.parent === module_id
      );
      if (
        _parent_submodules.length === _user_product.module?.submodules?.length
      ) {
        return true;
      }
    }

    return false;
  };
  return (
    <>
    <Helmet>
      <title>EquerreCloud ERP</title>
      <link rel="canonical" href="https://www.equerre.com/" />
    </Helmet>
      {/* <!-- Hero Slider --> */}
      <section className="hero style1">
        <div
          className="home-hero"
          style={{
            backgroundImage:
              "linear-gradient(to right, rgba(0, 0, 0, 0.75), transparent), url('./assets/img/hero-img/hero-img-1.jpg')",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-12">
                <div className="welcome-text">
                  <div className="hero-logo">
                    <img src="/assets/img/logo-equerre-cloud-erp.gif" />
                  </div>
                  <div className="hero-text mt-4">
                    <h1 style={{ color: "#fff" }}>
                      Un système ERP complet disponible Sur Cloud public ou
                      privé, ou dans un environnement hybride{" "}
                    </h1>
                    <div className="button mt-4">
                      <a
                        href="/contact_us"
                        className="bizwheel-btn theme-1 effect"
                      >
                        Découvrez EquerreCloud ERP
                      </a>
                      <a
                        href="/contact_us"
                        className="bizwheel-btn theme-1 effect"
                      >
                        Demandez une demo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 right-img">
                {/* <img src='./assets/img/hero-img/hero-img-1.jpg'/> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ End Hero Slider --> */}

      {/* <!-- Features Area --> */}
      {/* <h1>{JSON.stringify(selectAll)}</h1> */}
      <section className="features-area section-bg">
        <div className="container">
          <div className="section-title default text-center row">
            <div className="offset-2 col-8 section-top">
              <h1 style={{ fontSize: "2.5em", lineHeight: "1.2em" }}>
                <b>
                  Essayez 2 gratuitement pendant 30 jours nos applications en
                  mode Saas
                </b>
              </h1>
            </div>
            {/* <div className="section-bottom">
					<div className="text"><p>some of our great clients and their review</p></div>
				</div> */}
          </div>

          <div className="row col-lg-10" style={{margin: "0 auto"}}>
            {[...moduleData].map((module, module_index) => (
              <div className="col-lg-4 col-md-6 col-12" key={module_index}>
                {/* <!-- Single Feature --> */}
                <div
                  className={`single-feature ${
                    isAllSubmoduleSelect(module._id) ? " active" : ""
                  }`}
                >
                  <div
                    onClick={() => handleClickModule(module)}
                    className="icon-head"
                  >
                    <i className="fa fa-podcast"></i>
                  </div>
                  <h4>
                    <a href={`/module/${module._id}`}>{module.name}</a>
                  </h4>
                  <p>{module.description}</p>
                  <div className="button row">
                    <a
                      href={`/module/${module._id}`}
                      className="bizwheel-btn col-md-12"
                    >
                      <i className="fa fa-arrow-circle-o-right"></i>Learn More
                    </a>
                    <div
                      className="d-flex mt-3"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <div className="form-switch">
                        {loading && (
                          <input
                            id="select_all_submodules"
                            checked={isAllSubmoduleSelect(
                              module._id
                            )} /*selectAll && selectAll[`${module._id}`] || false*/
                            name={`${module._id}`}
                            type="checkbox"
                            className="form-check-input"
                            onChange={handleChangeSelectAll}
                          />
                        )}
                      </div>

                      <div
                        className=""
                        href={`#collapseExample${module_index}`}
                        role="button"
                        data-toggle="collapse"
                        aria-expanded="false"
                        aria-controls={`collapseExample${module_index}`}
                      >
                        <a className="" style={{ color: "var(--red)" }}>
                          Select Submodules
                        </a>
                        <i className="ml-2 fa fa-angle-down"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    className="collapse mt-2 c-p"
                    id={`collapseExample${module_index}`}
                  >
                    {moduleLineData
                      .filter((moduleline) => moduleline.parent === module._id)
                      .map((moduleline, submodules_index) => (
                        <div
                          className="form-check form-switch mt-3"
                          style={{ textAlign: "start", paddingLeft: "3rem" }}
                          key={moduleline._id}
                        >
                          {/* {console.log('-- first', checkIfUserHasSubmodule([...userProductData], moduleline._id))} */}
                          {/* {JSON.stringify(moduleline)} */}

                          {checkIfUserHasSubmodule(
                            [...userProductData],
                            moduleline._id
                          )?.hasModule ? (
                            <input
                              onChange={() =>
                                removeModuleLine(
                                  checkIfUserHasSubmodule(
                                    [...userProductData],
                                    moduleline._id
                                  )?.user_products,
                                  moduleline,
                                  module_index,
                                  submodules_index
                                )
                              }
                              className="form-check-input"
                              checked={true}
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                          ) : (
                            <input
                              onChange={() =>
                                addModuleLine(
                                  selectUserProduct(
                                    [...moduleData],
                                    moduleline.parent
                                  ),
                                  existingSubmodules(
                                    [...userProductData],
                                    moduleline.parent
                                  ),
                                  moduleline
                                )
                              }
                              className="form-check-input"
                              checked={false}
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                          )}

                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="modules_name">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M50.73 58.53C58.86 42.27 75.48 32 93.67 32H208V160H0L50.73 58.53zM240 160V32H354.3C372.5 32 389.1 42.27 397.3 58.53L448 160H240zM448 416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V192H448V416z" />
                              </svg>
                              <label
                                className="form-check-label m-l-1"
                                style={{
                                  paddingLeft: "0px",
                                  fontSize: "0.8em",
                                }}
                                htmlFor="flexSwitchCheckDefault"
                              >
                                {moduleline.name}
                              </label>
                            </div>
                            <div>$ {moduleline.price?.monthly || 0}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* <!--/ End Single Feature --> */}
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <!--/ End Features Area --> */}
      <div className="collapse cartContainer" id="collapseExample">
        <div
          className="sticky-card p-1 fixed-bottom stickyAddCart d-flex align-items-center justify-content-space-between"
          id="stickyCard"
          hidden={true}
        >
          <FooterSummary link={"./pricing"} title={"Continue"} />
        </div>
      </div>
      {/* <!-- Testimonials --> */}
      <section
        className="testimonials section-space"
        style={{
          backgroundImage: "url('https://via.placeholder.com/1500x700')",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-9 col-12">
              <div className="section-title default text-left">
                <div className="section-top">
                  <h1>
                    <b>Our Satisfied Clients</b>
                  </h1>
                </div>
                <div className="section-bottom">
                  <div className="text">
                    <p>some of our great clients and their review</p>
                  </div>
                </div>
              </div>
              <div className="testimonial-inner">
                <div className="testimonial-slider">
                  {/* <!-- Single Testimonial --> */}
                  <div className="single-slider">
                    <ul className="star-list">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <p>
                      simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the{" "}
                      <strong>industry's standard</strong> dummy text ever
                      sinsimply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard
                    </p>
                    {/* <!-- Client Info --> */}
                    <div className="t-info">
                      <div className="t-left">
                        <div className="client-head">
                          <img
                            src="https://via.placeholder.com/70x70"
                            alt="#"
                          />
                        </div>
                        <h2>
                          Julias Dien <span>CEO / Creative IT</span>
                        </h2>
                      </div>
                      <div className="t-right">
                        <div className="quote">
                          <i className="fa fa-quote-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- / End Single Testimonial --> */}
                  {/* <!-- Single Testimonial --> */}
                  <div className="single-slider">
                    <ul className="star-list">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <p>
                      simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the{" "}
                      <strong>industry's standard</strong> dummy text ever
                      sinsimply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard
                    </p>
                    {/* <!-- Client Info --> */}
                    <div className="t-info">
                      <div className="t-left">
                        <div className="client-head">
                          <img
                            src="https://via.placeholder.com/70x70"
                            alt="#"
                          />
                        </div>
                        <h2>
                          Buman Panama <span>Founder, Jolace Group</span>
                        </h2>
                      </div>
                      <div className="t-right">
                        <div className="quote">
                          <i className="fa fa-quote-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- / End Single Testimonial --> */}
                  {/* <!-- Single Testimonial --> */}
                  <div className="single-slider">
                    <ul className="star-list">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <p>
                      simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the{" "}
                      <strong>industry's standard</strong> dummy text ever
                      sinsimply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard
                    </p>
                    {/* <!-- Client Info --> */}
                    <div className="t-info">
                      <div className="t-left">
                        <div className="client-head">
                          <img
                            src="https://via.placeholder.com/70x70"
                            alt="#"
                          />
                        </div>
                        <h2>
                          Donald Bonam <span>Developer, Soft IT</span>
                        </h2>
                      </div>
                      <div className="t-right">
                        <div className="quote">
                          <i className="fa fa-quote-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- / End Single Testimonial --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ End Testimonials --> */}

      {/* <!-- Latest Blog --> */}
      <section className="latest-blog section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
              <div className="section-title default text-center">
                <div className="section-top">
                  <h1>
                    <span>Latest</span>
                    <b> Published</b>
                  </h1>
                </div>
                <div className="section-bottom">
                  <div className="text">
                    <p>
                      Lorem Ipsum Dolor Sit Amet, Conse Ctetur Adipiscing Elit,
                      Sed Do Eiusmod Tempor Ares Incididunt Utlabore. Dolore
                      Magna Ones Baliqua
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="blog-latest blog-latest-slider">
                <div className="single-slider">
                  {/* <!-- Single Blog --> */}
                  <div className="single-news ">
                    <div className="news-head overlay">
                      <span
                        className="news-img"
                        style={{
                          backgroundImage:
                            "url('https://via.placeholder.com/700x530')",
                        }}
                      ></span>
                      <a href="#" className="bizwheel-btn theme-2">
                        Read more
                      </a>
                    </div>
                    <div className="news-body">
                      <div className="news-content">
                        <h3 className="news-title">
                          <a href="blog-single.html">
                            We Provide you Best &amp; Creative Consulting
                            Service
                          </a>
                        </h3>
                        <div className="news-text">
                          <p>
                            Sed tempus pulvinar augue ut euismod. Donec a nisi
                            volutpat, dignissim mauris eget. Quisque vitae nunc
                            sit amet eros pellentesque tempus at sit amet sem.
                            Maecenas feugiat mauris
                          </p>
                        </div>
                        <ul className="news-meta">
                          <li className="date">
                            <i className="fa fa-calendar"></i>April 2020
                          </li>
                          <li className="view">
                            <i className="fa fa-comments"></i>0
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <!--/ End Single Blog --> */}
                </div>
                <div className="single-slider">
                  {/* <!-- Single Blog --> */}
                  <div className="single-news ">
                    <div className="news-head overlay">
                      <span
                        className="news-img"
                        style={{
                          backgroundImage:
                            "url('https://via.placeholder.com/700x530')",
                        }}
                      ></span>
                      <a href="#" className="bizwheel-btn theme-2">
                        Read more
                      </a>
                    </div>
                    <div className="news-body">
                      <div className="news-content">
                        <h3 className="news-title">
                          <a href="blog-single.html">
                            We Provide you Best &amp; Creative Consulting
                            Service
                          </a>
                        </h3>
                        <div className="news-text">
                          <p>
                            Sed tempus pulvinar augue ut euismod. Donec a nisi
                            volutpat, dignissim mauris eget. Quisque vitae nunc
                            sit amet eros pellentesque tempus at sit amet sem.
                            Maecenas feugiat mauris
                          </p>
                        </div>
                        <ul className="news-meta">
                          <li className="date">
                            <i className="fa fa-calendar"></i>April 2020
                          </li>
                          <li className="view">
                            <i className="fa fa-comments"></i>0
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <!--/ End Single Blog --> */}
                </div>
                <div className="single-slider">
                  {/* <!-- Single Blog --> */}
                  <div className="single-news ">
                    <div className="news-head overlay">
                      <span
                        className="news-img"
                        style={{
                          backgroundImage:
                            "url('https://via.placeholder.com/700x530')",
                        }}
                      ></span>
                      <a href="#" className="bizwheel-btn theme-2">
                        Read more
                      </a>
                    </div>
                    <div className="news-body">
                      <div className="news-content">
                        <h3 className="news-title">
                          <a href="blog-single.html">
                            We Provide you Best &amp; Creative Consulting
                            Service
                          </a>
                        </h3>
                        <div className="news-text">
                          <p>
                            Sed tempus pulvinar augue ut euismod. Donec a nisi
                            volutpat, dignissim mauris eget. Quisque vitae nunc
                            sit amet eros pellentesque tempus at sit amet sem.
                            Maecenas feugiat mauris
                          </p>
                        </div>
                        <ul className="news-meta">
                          <li className="date">
                            <i className="fa fa-calendar"></i>April 2020
                          </li>
                          <li className="view">
                            <i className="fa fa-comments"></i>0
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <!--/ End Single Blog --> */}
                </div>
                <div className="single-slider">
                  {/* <!-- Single Blog --> */}
                  <div className="single-news ">
                    <div className="news-head overlay">
                      <span
                        className="news-img"
                        style={{
                          backgroundImage:
                            "url('https://via.placeholder.com/700x530')",
                        }}
                      ></span>
                      <a href="#" className="bizwheel-btn theme-2">
                        Read more
                      </a>
                    </div>
                    <div className="news-body">
                      <div className="news-content">
                        <h3 className="news-title">
                          <a href="blog-single.html">
                            We Provide you Best &amp; Creative Consulting
                            Service
                          </a>
                        </h3>
                        <div className="news-text">
                          <p>
                            Sed tempus pulvinar augue ut euismod. Donec a nisi
                            volutpat, dignissim mauris eget. Quisque vitae nunc
                            sit amet eros pellentesque tempus at sit amet sem.
                            Maecenas feugiat mauris
                          </p>
                        </div>
                        <ul className="news-meta">
                          <li className="date">
                            <i className="fa fa-calendar"></i>April 2020
                          </li>
                          <li className="view">
                            <i className="fa fa-comments"></i>0
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <!--/ End Single Blog --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ End Latest Blog --> */}

      {/* <!-- Client Area --> */}
      <div className="clients section-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="partner-slider">
                {/* <!-- Single client --> */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" >
                      <img src="/assets/img/client/client-1.png" alt="#" />
                    </a>
                  </div>
                </div>
                {/* <!--/ End Single client --> */}
                {/* <!-- Single client --> */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" >
                      <img src="/assets/img/client/client-2.png" alt="#" />
                    </a>
                  </div>
                </div>
                {/* <!--/ End Single client --> */}
                {/* <!-- Single client --> */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" >
                      <img src="/assets/img/client/client-3.png" alt="#" />
                    </a>
                  </div>
                </div>
                {/* <!--/ End Single client --> */}
                {/* <!-- Single client --> */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" >
                      <img src="/assets/img/client/client-4.png" alt="#" />
                    </a>
                  </div>
                </div>
                {/* <!--/ End Single client --> */}
                {/* <!-- Single client --> */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" >
                      <img src="/assets/img/client/client-5.png" alt="#" />
                    </a>
                  </div>
                </div>
                {/* <!--/ End Single client --> */}
                {/* <!-- Single client --> */}
                <div className="single-slider">
                  <div className="single-client">
                    <a href="#" >
                      <img src="/assets/img/client/client-6.png" alt="#" />
                    </a>
                  </div>
                </div>
                {/* <!--/ End Single client --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--/ End Client Area --> */}
    </>
  );
};

const mapStateToProps = (state) => {
  //   console.log('state', state)
  return {
    moduleData: state.module.moduleList,
    moduleLineData: state.moduleline.moduleLineList,
    userProductData: state.user_product.selected_modules,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchModules: () => dispatch(fetchModules()),
    fetchModuleLines: () => dispatch(fetchModuleLines()),
    createModuleLine: (module) => dispatch(createModuleLine(module)),
    removeModuleLine: (...data) => dispatch(removeModuleLine(...data)),
    addModuleLine: (...data) => dispatch(addModuleLine(...data)),
    fetchUserProducts: (token) => dispatch(fetchUserProducts(token)),
    addAllModuleLineOfParentModule: (...data) =>
      dispatch(addAllModuleLineOfParentModule(...data)),
    removeAllModuleLineOfParentModule: (...data) =>
      dispatch(removeAllModuleLineOfParentModule(...data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
