const { default: Axios } = require("axios");

let BACKEND_SERVER_DOMAIN = "http://localhost:5000"
let ADMIN_DOMAIN = 'http://localhost:3001'
let API_BASE = "http://localhost:3000"

// if (process.env.MODE_ENV === 'production') {
  BACKEND_SERVER_DOMAIN = "https://saas-server.equerre.com";
  ADMIN_DOMAIN = 'https://admin.equerre.com'
  API_BASE = 'https://www.equerre.com'
// }

module.exports = {
  API_BASE_BK: 'https://www.equerre.com',
  GOOGLE_RECAPTCHA_KEY: "6LcMEh4iAAAAABeT-OeUSZ3vA286rESK39AOnGMb",
  API_BASE: API_BASE,
  BACKEND_IP: ADMIN_DOMAIN,
  BACKEND_DOMAIN: ADMIN_DOMAIN,  
  STRIPE_PUB_KEY: 'pk_test_TMIaDVpOozoClT4C9KOSs5Dw00VHiK65gX',
  BACKEND_SERVER_DOMAIN: BACKEND_SERVER_DOMAIN,
  PAYPAL_CLIENT_ID: 'AXLa8NIkJSVcp1EQCnO2WQd3pY_HjbsnIE8OIHLR-dVb6Hjtz-LhSaM2bWK3UC8GRTVCHr5T97Yhrnw0',
  env_config: {
    headers:{
      "Accept": "*/*",
      "Access-Control-Allow-Origin": API_BASE,
      'Content-Type': 'application/json'
    }
  },
  API: Axios.create({ baseURL: `${BACKEND_SERVER_DOMAIN}` })
};